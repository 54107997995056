import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../../atoms/atoms";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import SimpleDataGrid from "../../../../components/Datagrid/SimpleDataGrid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DatePicker from "../../../../components/DatePicker";
import { getTableHeight } from "../../../../common";

const EditSupplierFinanceDetails = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  const columns = [
    {
      field: "id",
      headerName: "UID",
      flex: 2,
      sortable: false,
    },
    {
      field: "ordernumber",
      headerName: "Order Number",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Model",
      field: "descriptionshort",
      flex: 3,
      sortable: false,
    },
    {
      headerName: "Qty",
      field: "qty",
      flex: 2,
      sortable: false,
    },
    {
      field: "supplierperunitcost",
      headerName: "Per Unit Cost",
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <TextField
          sx={{ mt: 0.5 }}
          type="number"
          defaultValue={params.value.toFixed(2)}
          onBlur={(e) =>
            handleInputChange(e, "supplierperunitcost", params.row.id)
          }
          size={"small"}
        />
      ),
    },
    {
      field: "internationalshippingcost",
      headerName: "Shipping Cost",
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <TextField
          sx={{ mt: 0.5 }}
          type="number"
          defaultValue={params.value.toFixed(2)}
          onBlur={(e) =>
            handleInputChange(e, "internationalshippingcost", params.row.id)
          }
          size={"small"}
        />
      ),
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        // Perform the calculation for total cost
        const totalCost =
          params.row.supplierperunitcost * params.row.qty +
          params.row.internationalshippingcost;

        return (
          <Typography sx={{ mt: 1.5 }}>${totalCost.toFixed(2)}</Typography>
        );
      },
    },
  ];

  const handleInputChange = (e, name, uid) => {
    if (!uid) {
      setState((currentState) => ({
        ...currentState,
        [name]: e.target.name === "boolean" ? e.target.checked : e.target.value,
      }));
    } else
      setState((currentState) => {
        return {
          ...currentState,
          rows: currentState.rows.map((row) =>
            row.id === uid
              ? { ...row, [name]: parseFloat(e.target.value) || 0 }
              : row
          ),
        };
      });
  };

  useEffect(() => {
    const orders = data.orders;
    const uniqueBillReferences = [
      ...new Set(orders.map((order) => order.supplierbillreference)),
    ];
    setState({
      datePaid: dayjs(new Date()),
      billReference:
        uniqueBillReferences.length === 1 ? uniqueBillReferences[0] : "",
      readyForPayment: true,
      paid: true,
      receivedPayment: true,
      rows: orders.map((order) => {
        return {
          ordernumber: order.wixlocalordernumber,
          id: order.uid,
          descriptionshort: order.descriptionshort,
          qty: order.qty,
          supplierperunitcost: parseInt(order.supplierperunitcost) || 0,
          internationalshippingcost:
            parseInt(order.internationalshippingcost) || 0,
          totalCost: 0,
        };
      }),
      totalCostOwed: orders.reduce(
        (acc, order) => acc + order.suppliertotalcostowed,
        0
      ),
    });
  }, [data.orders, setState]);

  const tableHeight = getTableHeight(state?.rows?.length);

  return (
    <Box>
      <Box sx={{ margin: "auto", mt: 2, height: tableHeight, width: "100%" }}>
        {state.rows && (
          <SimpleDataGrid
            sx={{ height: tableHeight }}
            rows={state.rows}
            columns={columns}
            hideFooter
            disableColumnMenu
          />
        )}
      </Box>{" "}
      <Grid container mt={1}>
        <Grid item xs={12} sm={2.5}>
          <FormControlLabel
            sx={{ mt: 1 }}
            control={
              <Checkbox
                checked={state.readyForPayment || true}
                onChange={(e) => handleInputChange(e, "readyForPayment")}
                name="boolean"
                color="primary"
              />
            }
            label="Ready for payment"
          />
        </Grid>
        <Grid item xs={12} sm={1.5}>
          <FormControlLabel
            sx={{ mt: 1 }}
            control={
              <Checkbox
                checked={state.paid || true}
                onChange={(e) => handleInputChange(e, "paid")}
                name="boolean"
                color="primary"
              />
            }
            label="Paid"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {" "}
          <FormControlLabel
            sx={{ mt: 1 }}
            control={
              <Checkbox
                checked={state.receivedPayment || true}
                onChange={(e) => handleInputChange(e, "receivedPayment")}
                name="boolean"
                color="primary"
              />
            }
            label="Received Payment"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7} mt={3}>
          <DatePicker
            label={"Date Paid *"}
            value={state?.datePaid}
            onChange={(newValue) =>
              setState({ ...state, datePaid: dayjs(newValue) })
            }
            startingTimezone={data.orders[0].ordercountrycode}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            required
            fullWidth
            label={"Bill Reference"}
            value={state.billReference || ""}
            onChange={(e) => handleInputChange(e, "billReference")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditSupplierFinanceDetails;
