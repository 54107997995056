import * as React from "react";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import Avatar from "@mui/material/Avatar";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useNavigate } from "react-router-dom";
import { loadUser } from "../../../common";
import { updateMessages } from "../../../api/Messages";
import { useEffect, useState } from "react";

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  let hours = date.getHours();
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const strTime = `${hours}:${minutes} ${ampm}`;

  if (date >= today) {
    return `Today at ${strTime}`;
  } else if (date >= yesterday && date < today) {
    return `Yesterday at ${strTime}`;
  } else {
    // Format as "dd/mm/yyyy at hh:mm am/pm"
    return `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} at ${strTime}`;
  }
};

const renderContent = (content) => {
  const mentionRegex = /\@\[([^\]]+)\]\([^\)]+\)/g;
  return content.split(mentionRegex).map((part, index) => {
    if (index % 2 === 1) {
      return "";
    }
    return part;
  });
};

export default function NotificationsDataGrid({
  users,
  rows = [], // Ensure rows is always an array
  loading,
  handleRowChange,
  handleRowSelection,
  selectedRows,
}) {
  const navigate = useNavigate();

  const userID = JSON.parse(loadUser()).id;

  const columns = [
    {
      field: "starred",
      headerName: "",
      renderCell: (params) => {
        const currentUser = JSON.parse(loadUser());
        const isStarredByCurrentUser = params?.value?.includes(currentUser.id);

        return (
          <IconButton onClick={(e) => handleStar(e, params.id)}>
            {isStarredByCurrentUser ? (
              <StarIcon color={"primary"} />
            ) : (
              <StarBorderIcon color={"primary"} />
            )}
          </IconButton>
        );
      },
    },
    {
      field: "orderID",
      headerName: "Order ID",
      renderCell: (params) => (
        <a
          style={{
            width: "50px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={(e) => {
            if (params.row.orderID.slice(0, 3) === "INV")
              navigate(`/inventoryOrder/${params.row.orderID}`);
            else navigate(`/order/${params.row.orderID}`);
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          #{params.value}
        </a>
      ),
      flex: 1,
    },
    {
      field: "userID",
      headerName: "User",
      flex: 2,
      renderCell: (params) => {
        const user = users?.find((user) => user.id === params.value);
        return (
          <Chip
            avatar={<Avatar src={user?.avatarImage} />}
            label={user?.email}
            variant="outlined"
          />
        );
      },
    },
    {
      field: "content",
      headerName: "Comment",
      flex: 6,
      renderCell: (params) => (
        <Typography
          variant="body2"
          noWrap
          title={params.value}
          sx={{
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            mt: 2,
          }}
        >
          {renderContent(params.value)}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Time",
      flex: 2,
      renderCell: (params) => (
        <Typography sx={{ mt: 2 }} variant="body2" noWrap>
          {formatDate(params.value)}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            if (params.row.orderID.slice(0, 3) === "INV")
              navigate(`/inventoryOrder/${params.row.orderID}`);
            else navigate(`/order/${params.row.orderID}`);
          }}
        >
          <DirectionsIcon color={"primary"} />
        </IconButton>
      ),
    },
  ];

  const handleStar = (e, id) => {
    e.stopPropagation();

    const currentRow = rows.find((row) => row.id === id);
    if (!currentRow) return;
    const isStarredByCurrentUser = currentRow.starred.includes(userID);
    const newStarred = isStarredByCurrentUser
      ? currentRow.starred.filter((userId) => userId !== userID)
      : [...currentRow.starred, userID];

    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, starred: newStarred } : row
    );
    handleRowChange(updatedRows.find((row) => row.id === id));

    updateMessages([id], isStarredByCurrentUser ? "removeStar" : "addStar")
      .then((rsp) => {
        console.log([id], rsp);
      })
      .catch((err) => {
        console.error("Error updating message starred status:", err);
        handleRowChange(currentRow);
      });
  };
  // console.log(loading, rows);
  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <DataGridPremium
        sx={{
          "& .unread-theme": {
            backgroundColor: "rgba(255, 165, 0, 0.1)",
            "&:hover": {
              backgroundColor: "rgba(255, 165, 0, 0.2)",
            },
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #f0f0f0",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "primary.light",
            color: "primary.contrastText",
          },
        }}
        loading={loading}
        rows={rows || []}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        checkboxSelection
        disableSelectionOnClick
        onRowSelectionModelChange={handleRowSelection}
        selectionModel={selectedRows}
        getRowClassName={(params) => {
          if (!params.row.read?.includes(userID)) return "unread-theme";
        }}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}
