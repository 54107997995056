// components/Permissions.js
import React, { useEffect } from "react";
import { Box, Typography, TextField, Alert, Divider } from "@mui/material";
import RecursiveAccordion from "./RecursiveAccordion";
import { usePermissionData } from "./usePermissionsData";
import IconSelector from "../../../../../../components/IconSelector";
import Loading from "../../../../../../components/Loading";

const Permissions = ({
  icon,
  setIcon,
  name,
  description,
  checkedState,
  setName,
  setDescription,
  setCheckedState,
  open,
  roleID,
  isEdit,
  setMessage,
  nameError,
}) => {
  const { data, loading, error, roleData } = usePermissionData(
    roleID,
    isEdit,
    open
  );

  useEffect(() => {
    if (roleData) {
      setIcon(roleData.icon);
      setName(roleData.name);
      setDescription(roleData.description);
      setCheckedState(parseRoleData(roleData));
    } else if (!isEdit) {
      setName("");
      setDescription("");
      setIcon(null);
      setCheckedState(initializeCheckedState(data));
    }
  }, [roleData, isEdit, data]);

  const parseRoleData = (roleData) => {
    let initialState = {};
    const parseFields = (data, prefix = "") => {
      Object.entries(data).forEach(([key, value]) => {
        if (
          typeof value === "object" &&
          value !== null &&
          !(value instanceof Array)
        ) {
          if (value.columns) {
            Object.keys(value.columns).forEach((field) => {
              initialState[`${prefix}${key}.columns.${field}`] =
                value.columns[field];
            });
          }
          if (value.actions) {
            Object.keys(value.actions).forEach((action) => {
              initialState[`${prefix}${key}.actions.${action}`] =
                value.actions[action];
            });
          }
          parseFields(value, `${prefix}${key}.`);
        } else {
          initialState[`${prefix}${key}`] = value;
        }
      });
    };
    parseFields(roleData);
    return initialState;
  };

  const initializeCheckedState = (data) => {
    const initState = {};
    const initFieldState = (key, value) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (item && item.fieldName) {
            initState[`${key}.${item.fieldName}`] = false;
          }
        });
      } else if (typeof value === "object" && value !== null) {
        Object.keys(value).forEach((childKey) => {
          const newKey = key === "general" ? key : `${key}.${childKey}`;
          initFieldState(newKey, value[childKey]);
        });
      }
    };
    Object.entries(data).forEach(([key, value]) => {
      initFieldState(key, value);
    });
    return initState;
  };

  const handleCheck = (key, checked) => {
    setCheckedState((prevState) => {
      let newState = { ...prevState };
      const updateState = (currentKey, newChecked) => {
        newState[currentKey] = newChecked;
        const updateChildren = (key, checked) => {
          const childData = key
            .split(".")
            .reduce(
              (acc, level) => (acc && acc[level] ? acc[level] : null),
              data
            );
          if (Array.isArray(childData)) {
            childData.forEach((item) => {
              if (item && item.fieldName) {
                let childKey = `${key}.${item.fieldName}`;
                newState[childKey] = checked;
                updateChildren(childKey, checked);
              }
            });
          } else if (typeof childData === "object" && childData !== null) {
            Object.keys(childData).forEach((subkey) => {
              let nestedChildKey = `${key}.${subkey}`;
              if (
                typeof childData[subkey] === "object" &&
                childData[subkey] !== null
              ) {
                updateChildren(nestedChildKey, checked);
              } else {
                newState[nestedChildKey] = checked;
              }
            });
          }
        };
        updateChildren(currentKey, newChecked);
        const updateParent = (key) => {
          const levels = key.split(".");
          levels.pop();
          if (levels.length === 0) return;
          const parentKey = levels.join(".");
          let allChecked = true;
          let someChecked = false;
          const checkDescendantsState = (currentKey) => {
            const currentData = currentKey
              .split(".")
              .reduce(
                (acc, level) => (acc && acc[level] ? acc[level] : null),
                data
              );
            if (typeof currentData === "object" && currentData !== null) {
              Object.keys(currentData).forEach((childKey) => {
                const fullChildKey = `${currentKey}.${childKey}`;
                if (newState[fullChildKey] === true) {
                  someChecked = true;
                } else if (
                  newState[fullChildKey] === false ||
                  newState[fullChildKey] === "indeterminate"
                ) {
                  allChecked = false;
                }
                checkDescendantsState(fullChildKey);
              });
            }
          };
          checkDescendantsState(parentKey);
          newState[parentKey] = allChecked
            ? true
            : someChecked
            ? "indeterminate"
            : false;
          updateParent(parentKey);
        };
        updateParent(key);
      };
      updateState(key, checked);
      return newState;
    });
  };

  if (loading) return <Loading sx={{ width: "50px", m: "auto" }} />;
  if (error) return <Alert color="error">{error}</Alert>;

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Typography fontWeight="bold" sx={{ mb: 2 }}>
        Details:
      </Typography>
      <TextField
        label="Name"
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        error={nameError}
        helperText={nameError && "Required field."}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        rows={3}
        multiline
        sx={{ mb: 2 }}
      />
      <IconSelector
        selectedIcon={icon}
        handleSelectedIcon={(name) => setIcon(name)}
      />
      <Divider sx={{ my: 2 }} />
      <Typography fontWeight="bold" sx={{ mb: 2 }}>
        Table Settings:
      </Typography>
      <RecursiveAccordion
        data={data}
        checkedState={checkedState}
        handleCheck={handleCheck}
      />
    </Box>
  );
};

export default Permissions;
