import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function getCustomerOrderHistory(wixOrderNumber) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/getOrderHistory?wixOrderNumber=${wixOrderNumber} `
    )
    .then((response) => response.data);
}
function getInventoryOrderHistory(warehouseOrderNumber) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/getInventoryOrderHistory?warehouseOrderNumber=${warehouseOrderNumber} `
    )
    .then((response) => response.data);
}
function getInventoryOrderSummaryHistory(sid) {
  return axios
    .get(`${API_ORDER_LINK_URL}/getInventorySummaryHistory?sid=${sid} `)
    .then((response) => response.data);
}

export {
  getCustomerOrderHistory,
  getInventoryOrderHistory,
  getInventoryOrderSummaryHistory,
};
