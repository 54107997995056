import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const SelectField = ({
  label,
  value,
  onChange,
  options,
  fullWidth,
  minWidth,
}) => {
  const handleDelete = (optionToDelete) => (event) => {
    event.stopPropagation();
    const newValue = value.filter((v) => v !== optionToDelete);
    onChange(newValue.length ? newValue : ["*ANY"]);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.includes("*ANY") && newValue.length === 1) {
      onChange(["*ANY"]);
    } else {
      onChange(newValue.filter((v) => v !== "*ANY"));
    }
  };

  return (
    <FormControl size="small" fullWidth={fullWidth} sx={{ minWidth }}>
      <InputLabel size="small">{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        label={label}
        size="small"
        sx={{
          "& .MuiSelect-select": {
            display: "flex",
            flexWrap: "wrap",
            gap: 0.5,
            alignItems: "center",
          },
        }}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={value === "*ANY" ? undefined : handleDelete(value)}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                    style={{ fontSize: "1rem" }}
                  />
                }
                size="small"
                sx={{
                  fontSize: "0.75rem",
                  "& .MuiChip-deleteIcon": {
                    width: 16,
                    height: 16,
                  },
                }}
              />
            ))}
          </Box>
        )}
      >
        <MenuItem value="*ANY">*ANY</MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
