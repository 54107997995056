import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";

import { useNavigate } from "react-router-dom";

export const NavigationButtons = ({ pathName, pages }) => {
  let navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const currentPath = window.location.pathname.split("/").pop();
  const subDirectory = window.location.href.split("/").filter(Boolean).pop();

  const currentIndex = pages.findIndex(
    (page) => page.subDirectory === currentPath
  );
  const [activeIndex, setActiveIndex] = useState(
    currentIndex >= 0 ? currentIndex : 0
  );

  const handleNavigation = (direction) => {
    const newIndex = (activeIndex + direction + pages.length) % pages.length;
    setActiveIndex(newIndex);
    navigate(`${pathName}/${pages[newIndex].subDirectory}`);
  };

  if (isMobile) {
    // Mobile view with carousel-like navigation
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
        <Button
          onClick={() => handleNavigation(-1)}
          disabled={pages.length <= 1}
        >
          <ArrowBackIosNewIcon />
        </Button>

        <Button
          onClick={() =>
            navigate(`${pathName}/${pages[activeIndex].subDirectory}`)
          }
          variant="contained"
          color="primary"
          sx={{ minWidth: "195px" }}
        >
          {pages[activeIndex].title}
        </Button>

        <Button
          onClick={() => handleNavigation(1)}
          disabled={pages.length <= 1}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    );
  } else {
    // Non-mobile view with all buttons displayed
    return (
      <Box display="flex" gap={{ xs: 0, sm: 1 }} m={"auto"}>
        {pages.map((page, index) => (
          <Button
            key={index}
            onClick={() => navigate(`${pathName}/${page.subDirectory}`)}
            variant={
              subDirectory === page.subDirectory ? "contained" : "outlined"
            }
            color="primary"
            sx={{ width: "190px" }}
          >
            {page.title}
          </Button>
        ))}
      </Box>
    );
  }
};
