import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function createFilter(filter) {
  return axios
    .post(`${API_ORDER_LINK_URL}/filters`, { filter: filter })
    .then((response) => response.data);
}

function deleteFilter(filterID) {
  return axios
    .delete(`${API_ORDER_LINK_URL}/filters?filterID=${filterID}`)
    .then((response) => response.data);
}

function getAllFilters(dataGridName) {
  return axios
    .get(`${API_ORDER_LINK_URL}/filters?dataGridName=${dataGridName}`)
    .then((response) => response.data);
}

function getFilter(filterID) {
  return axios
    .get(`${API_ORDER_LINK_URL}/filters?filterID=${filterID}`)
    .then((response) => response.data);
}

function updateFilter(filterID, updatedFilter, dataGridName) {
  return axios
    .patch(`${API_ORDER_LINK_URL}/filters`, {
      filterID,
      updatedFilter,
      dataGridName,
    })
    .then((response) => response.data);
}

export { createFilter, deleteFilter, getFilter, getAllFilters, updateFilter };
