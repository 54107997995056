import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate } from "react-router-dom";

function ResponsiveAppBar(props) {
  const navigate = useNavigate();

  return (
    <AppBar>
      <Container sx={{ height: "50px", display: "flex" }} maxWidth="xl">
        <Box
          sx={{
            display: "flex", // Make this a flex container
            justifyContent: "center", // Center children horizontally
            alignItems: "center", // Center children vertically
            flexGrow: 1,
            textAlign: "center", // Center text inside the Typography component
            height: "100%", // Ensure the Box takes up some space for verti
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: "18px", sm: "24px" },
            }}
          >
            {props.page}
          </Typography>
        </Box>

        {props.cancel && (
          <Box sx={{ position: "absolute", right: 10 }}>
            <IconButton onClick={() => navigate("/offlineOrders")}>
              <CloseIcon
                sx={{
                  fontSize: "30px",
                  pt: "2px",
                  pl: "1px",
                  pr: "1px",
                  color: "white",
                }}
              />
            </IconButton>
          </Box>
        )}
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
