import { useEffect, useState } from "react";
import { loadDisplayView, setDisplayView } from "../common";

const useViewToggle = (table, initialView = "table") => {
  const [view, setView] = useState(null);

  useEffect(() => {
    setView(loadDisplayView(table) || initialView);
  }, [table]);
  const toggleView = (view) => {
    setDisplayView(view, table);
    setView(view);
  };

  return [view, toggleView];
};

export default useViewToggle;
