import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { loadUser } from "../../../common";

const ActionsMenu = ({
  notifications,
  selectedRows,
  handleReadSubmit,
  handleStarSubmit,
}) => {
  const userID = JSON.parse(loadUser()).id;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (actionType) => {
    handleClose();

    if (actionType === "addStar" || actionType === "removeStar") {
      handleStarSubmit(actionType);
    } else {
      handleReadSubmit(actionType);
    }
  };

  // Determine if any selected notification can be marked as read or unread
  const anyUnread = selectedRows?.some(
    (id) =>
      !notifications?.find((notif) => notif.id === id)?.read?.includes(userID)
  );
  const anyRead = selectedRows?.some((id) =>
    notifications?.find((notif) => notif.id === id)?.read?.includes(userID)
  );

  // Determine if any selected notification can be starred or unstarred
  const anyUnstarred = selectedRows?.some(
    (id) =>
      !notifications
        ?.find((notif) => notif.id === id)
        ?.starred?.includes(userID)
  );
  const anyStarred = selectedRows.some((id) =>
    notifications?.find((notif) => notif.id === id)?.starred?.includes(userID)
  );

  return (
    <Box alignContent={"center"}>
      {selectedRows.length !== 0 && (
        <Button
          aria-controls="order-actions-menu"
          aria-haspopup="true"
          onClick={handleOpen}
          endIcon={<KeyboardArrowDownIcon />}
          variant="outlined"
        >
          Order Actions
        </Button>
      )}
      <Menu
        id="order-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {anyUnread && (
          <MenuItem onClick={() => handleSubmit("markRead")}>
            Mark as read
          </MenuItem>
        )}
        {anyRead && (
          <MenuItem onClick={() => handleSubmit("markUnread")}>
            Mark as unread
          </MenuItem>
        )}
        {anyUnstarred && (
          <MenuItem onClick={() => handleSubmit("addStar")}>Add star</MenuItem>
        )}
        {anyStarred && (
          <MenuItem onClick={() => handleSubmit("removeStar")}>
            Remove star
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default ActionsMenu;
