import { useTheme } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { ItemList } from "./ItemList";

export const Column = ({ table, column, index, listKey }) => {
  const theme = useTheme();
  return (
    <Draggable key={column.id} draggableId={column.id} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            minWidth: 320,
            maxWidth: 320,
            height: "calc(100% - 8px)",
            mb: 1,
            boxShadow: 3,
            backgroundColor: theme.palette.background.default,
            borderRadius: "20px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            "&:hover": {
              boxShadow: 6,
              cursor: "pointer",
            },
          }}
        >
          <Typography
            variant="h6"
            {...provided.dragHandleProps}
            sx={{ p: 2, textAlign: "center", cursor: "grab", fontSize: 18 }}
          >
            {column.title} ({column.cards.length})
          </Typography>
          <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
            <ItemList
              table={table}
              listKey={listKey}
              column={column}
              index={index}
            />
          </Box>
        </Box>
      )}
    </Draggable>
  );
};
