import React from "react";
import { TextField, Autocomplete, Chip } from "@mui/material";
import { List, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import Box from "@mui/material/Box";

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;

  // Initialize CellMeasurerCache
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 50, // Adjust this value based on your average row height
  });

  return (
    <Box ref={ref} {...other}>
      <List
        height={250}
        width={
          document.getElementById("virtual-autocomplete").parentNode.offsetWidth
        }
        rowCount={itemCount}
        rowHeight={cache.rowHeight}
        deferredMeasurementCache={cache}
        overscanRowCount={5}
        rowRenderer={({ index, key, parent, style }) => (
          <CellMeasurer
            key={key}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}
          >
            {({ measure, registerChild }) => (
              // Use a div instead of Box to avoid MUI Box's default width behavior
              <div
                ref={registerChild}
                onLoad={measure}
                style={{
                  ...style,
                  width: "100%", // Ensure full width
                  boxSizing: "border-box", // Include padding and border in the width calculation
                }}
              >
                {/* Wrap children in a Box if additional MUI styling is needed */}
                <Box
                  sx={{ p: "4px 10px", display: "flex", alignItems: "center" }}
                >
                  {children[index]}
                </Box>
              </div>
            )}
          </CellMeasurer>
        )}
        role={role}
      />
    </Box>
  );
});

export default function VirtualizedAutocomplete({
  options,
  handleValueChange,
  error,
  value,
}) {
  return (
    <>
      {options && (
        <Autocomplete
          id="virtual-autocomplete"
          value={value}
          multiple
          disableListWrap
          ListboxComponent={ListboxComponent}
          options={options}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          // Set menu max height (optional)
          renderTags={(value, getTagProps) => (
            <Box sx={{ maxHeight: "200px", overflow: "auto", width: "100%" }}>
              {value.map(
                (
                  option,
                  index // Customizing each selected value with Chip
                ) => (
                  <Chip
                    label={option.label} // Display label of the option
                    {...getTagProps({ index })}
                    onDelete={getTagProps({ index }).onDelete} // Handling delete
                    sx={{
                      backgroundColor: "lightgrey", // Change background color of the chip
                      margin: "5px", // Add some space around each chip
                    }}
                  />
                )
              )}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Options"
              fullWidth
              error={error}
              helperText={error && "Please fill out this field."}
            />
          )}
          onChange={(e, newValue) => handleValueChange(newValue)}
        />
      )}
    </>
  );
}
