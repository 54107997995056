import OrderActionsMenu from "../../components/OrderActionsMenu";
import OfflineOrderActionsMenu from "../../components/OfflineOrderActionsMenu";
import React from "react";

export const OrderActions = ({
  shouldDisplayProductActionsMenu,
  shouldDisplayOfflineOrderActionsMenu,
  products,
  handleActionSelect,
}) => (
  <>
    {shouldDisplayProductActionsMenu && (
      <OrderActionsMenu
        products={products}
        onActionSelect={handleActionSelect}
      />
    )}
    {shouldDisplayOfflineOrderActionsMenu && (
      <OfflineOrderActionsMenu
        products={products}
        onActionSelect={handleActionSelect}
      />
    )}
  </>
);
