import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { cardViewSettingsState } from "../../atoms/atoms";
import { Droppable } from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import { VariableSizeList as List } from "react-window";
import { Row } from "./Row";
import { Item } from "./Card";
import { useTheme } from "@mui/material";

const CARDS_PER_ROW = 1;

export const ItemList = React.memo(function ItemList({
  table,
  column,
  index,
  listKey,
}) {
  const listRef = useRef();
  const outerRef = useRef();
  const settings = useRecoilValue(cardViewSettingsState);
  const [rowHeights, setRowHeights] = useState({});
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [listHeight, setListHeight] = useState(0);

  const theme = useTheme();

  const getRowHeight = useCallback(
    (index) => {
      return rowHeights[Math.floor(index / CARDS_PER_ROW)] || 300;
    },
    [rowHeights]
  );

  const setRowHeight = useCallback((index, height) => {
    const rowIndex = Math.floor(index / CARDS_PER_ROW);
    setRowHeights((prev) => {
      const newHeight = Math.max(prev[rowIndex] || 0, height);
      if (prev[rowIndex] !== newHeight) {
        const newHeights = { ...prev, [rowIndex]: newHeight };
        if (listRef.current) {
          listRef.current.resetAfterIndex(rowIndex * CARDS_PER_ROW);
        }
        return newHeights;
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    setIsInitialRender(true);
    setRowHeights({});
  }, [settings.columnsDisplayed, column.cards]);

  useEffect(() => {
    if (!isInitialRender) {
      listRef.current?.resetAfterIndex(0);
    }
  }, [isInitialRender, rowHeights]);

  useEffect(() => {
    const updateListHeight = () => {
      if (outerRef.current) {
        setListHeight(outerRef.current.offsetHeight);
      }
    };

    updateListHeight();
    window.addEventListener("resize", updateListHeight);

    return () => window.removeEventListener("resize", updateListHeight);
  }, []);

  const renderRow = useCallback(
    ({ index, style }) => (
      <Row
        index={index}
        style={{ ...style, height: getRowHeight(index) }}
        data={{
          items: column.cards,
          table: table,
          setRowHeight: setRowHeight,
          isInitialRender: isInitialRender,
        }}
      />
    ),
    [column.cards, table, setRowHeight, isInitialRender, getRowHeight]
  );

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          table={table}
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.cards[rubric.source.index]}
        />
      )}
    >
      {(provided, snapshot) => {
        const itemCount = snapshot.isUsingPlaceholder
          ? column.cards.length + 1
          : column.cards.length;
        return (
          <Box
            ref={outerRef}
            sx={{
              height: "calc(100% - 30px)", // Subtract the height of the column title
              borderRadius: 3,
              "& .task-list": { pb: 1 },
            }}
          >
            {listHeight > 0 && (
              <List
                key={listKey}
                height={listHeight}
                itemCount={itemCount}
                itemSize={getRowHeight}
                width={320 * CARDS_PER_ROW}
                outerRef={provided.innerRef}
                className="task-list"
                ref={listRef}
              >
                {renderRow}
              </List>
            )}
          </Box>
        );
      }}
    </Droppable>
  );
});
