import {
  Box,
  IconButton,
  InputAdornment,
  List,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";
import Loading from "../../../../components/Loading";
import dayjs from "dayjs";

import isToday from "dayjs/plugin/isToday";
import { useDateFormat } from "../../../../contexts/DateFormatContext";
import { formatDate } from "../../../../utils/dateFormatter";
import ConversationItem from "./ConversationItem"; // Import the isToday plugin
dayjs.extend(isToday);

export const Conversations = ({
  handleOpenAdd,
  searchTerm,
  handleSearchChange,
  conversations,
  handleConversationSelection,
  userMap,
}) => {
  const { dateFormat } = useDateFormat();

  return (
    <Box sx={{ flex: 1, overflowY: "auto", p: 1 }}>
      {!conversations && !userMap && (
        <Box width={50} m={"auto"} mt={2}>
          <Loading />
        </Box>
      )}
      {conversations && userMap && (
        <>
          <Box display="flex" p={2}>
            <TextField
              sx={{ mr: 1 }}
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton onClick={handleOpenAdd}>
              <AddCircleIcon />
            </IconButton>
          </Box>
          <List dense>
            {conversations.map((conversation, index) => (
              <ConversationItem
                key={index}
                conversations={conversations}
                conversation={conversation}
                handleConversationSelection={handleConversationSelection}
                userMap={userMap}
                dateFormat={dateFormat}
                formatDate={formatDate}
                index={index}
              />
            ))}
          </List>
        </>
      )}
    </Box>
  );
};
