import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Box } from "@mui/material";

import BuildInventoryOrderScreen from "./pages/BuildOrder";
import ReviewOrderScreen from "./pages/ReviewOrder";
import MainContainer from "../../layouts/main";
import Error from "../../components/Error";
import ColumnSelector from "../../components/TileView/ColumnSelector";

import {
  getInStockOrders,
  getInventoryOrderRecommendedQty,
  getInventoryOrders,
  performInventoryAction,
} from "../../api/InventoryOrders";
import { getProductsByCountryAndWarehouse } from "../../api/Products";
import { getFields } from "../../api/SearchFields";
import { buildQueryString } from "../../utils/filterUtils";

const BuildInventoryOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const warehouseOrderNumber = searchParams.get("warehouseOrderNumber");
  const warehouse = searchParams.get("warehouse");

  const [currentScreen, setCurrentScreen] = useState("build");
  const [orderData, setOrderData] = useState({
    products: null,
    supplier: "",
    dateOrdered: dayjs(),
    notes: "",
  });
  const [containerSize, setContainerSize] = useState(
    () => localStorage.getItem("lastSelectedContainerSize") || "40"
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState(() => {
    if (warehouse) return warehouse;
    const stored = localStorage.getItem("selectedWarehouse");
    return stored === "all" ? "AUGC" : stored || "AUGC";
  });
  const [warehouseProducts, setWarehouseProducts] = useState(null);
  const [error, setError] = useState("");
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [searchFields, setSearchFields] = useState([]);
  const [lockedProducts, setLockedProducts] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);

  const [originalProducts, setOriginalProducts] = useState(null);

  const fetchWarehouseData = useCallback(async () => {
    if (containerSize === "blank") return;

    try {
      const [inStockOrdersResponse, productDetailsResponse] = await Promise.all(
        [
          getInStockOrders(),
          getProductsByCountryAndWarehouse(
            selectedWarehouse.slice(0, 2),
            selectedWarehouse
          ),
        ]
      );

      const combinedProducts = combineProductData(
        inStockOrdersResponse.results,
        productDetailsResponse.countryProducts
      );

      setWarehouseProducts(
        combinedProducts.filter((prod) => prod.warehouse === selectedWarehouse)
      );
      setIsInitialDataLoaded(true);
    } catch (err) {
      console.error(err);
      setWarehouseProducts([]);
      setError(
        "We couldn't retrieve the product list for this warehouse. Please try again or select a different warehouse."
      );
      setIsInitialDataLoaded(true);
    }
  }, [selectedWarehouse, containerSize]);

  const fetchRecommendedQuantities = useCallback(
    async (isReset) => {
      try {
        const rsp = await getInventoryOrderRecommendedQty(
          selectedWarehouse,
          containerSize,
          isReset ? {} : lockedProducts
        );
        if (isReset) setLockedProducts({});
        updateOrderDataWithRecommendedQuantities(rsp);
      } catch (err) {
        console.error(err);
        setOrderData((prevState) => ({ ...prevState, products: [] }));
        setError(
          "We encountered an issue while calculating recommended quantities. Please try again later."
        );
      }
    },
    [warehouseProducts, selectedWarehouse, lockedProducts, containerSize]
  );

  const fetchOrders = async (filter) => {
    try {
      const [inventoryOrdersResponse, productDetailsResponse] =
        await Promise.all([
          getInventoryOrders(filter),
          getProductsByCountryAndWarehouse(
            selectedWarehouse.slice(0, 2),
            selectedWarehouse
          ),
        ]);

      const combinedProducts = combineProductData(
        inventoryOrdersResponse.results,
        productDetailsResponse.countryProducts
      );

      const processedProducts = combinedProducts.map((item) => ({
        ...item,
        qty: item.quantityordered,
        serialnumbers:
          typeof item.serialnumbers === "string"
            ? item.serialnumbers.split("\n").filter((sn) => sn)
            : item.serialnumbers,
        deleteRow: false, // Initialize deleteRow field
      }));

      setOrderData((prevState) => ({
        ...prevState,
        products: processedProducts,
      }));

      setOriginalProducts(processedProducts);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch orders. Please try again later.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setCurrentScreen("build");
      setError("");
      if (
        selectedWarehouse &&
        containerSize !== "blank" &&
        !warehouseOrderNumber
      ) {
        setLockedProducts({});
        setWarehouseProducts(null);
        setOrderData((prevState) => ({ ...prevState, products: null }));
        setIsInitialDataLoaded(false);
        await fetchWarehouseData();
      } else if (warehouseOrderNumber) {
        await fetchWarehouseData();
        const filterDetails = {
          filters: [
            {
              searchTypes: [
                "Contains",
                "Does not contain",
                "Is one of",
                "Is not one of",
                "Is equal to",
                "Is not equal to",
                "Is blank",
                "Is not blank",
              ],
              fieldName: "warehouseordernumber",
              displayName: "Warehouse Order Number",
              values: [
                {
                  label: warehouseOrderNumber,
                  key: 2,
                },
              ],
              type: 2,
            },
          ],
        };
        const fetchQuery = buildQueryString("", filterDetails, "and");
        await fetchOrders(fetchQuery);
      }
    };

    fetchData();
  }, [
    selectedWarehouse,
    fetchWarehouseData,
    warehouseOrderNumber,
    containerSize,
  ]);

  useEffect(() => {
    if (isInitialDataLoaded && warehouseProducts && !warehouseOrderNumber) {
      fetchRecommendedQuantities();
    }
  }, [isInitialDataLoaded, warehouseProducts, warehouseOrderNumber]);

  useEffect(() => {
    getFields("InventoryInStock")
      .then((rsp) => {
        setSearchFields(
          rsp.searchFields.map((field, i) => ({
            ...field,
            label: field.displayName,
            key: i,
          }))
        );
      })
      .catch((err) => console.error("Failed to fetch search fields:", err));
  }, []);

  const combineProductData = (inStockProducts, detailedProducts) => {
    return inStockProducts.map((inStockProduct) => {
      const detailedProduct = detailedProducts?.find(
        (detailProduct) => detailProduct.pid === inStockProduct.pid
      );
      return { ...inStockProduct, ...detailedProduct };
    });
  };

  const updateOrderDataWithRecommendedQuantities = (recommendedQuantities) => {
    setOrderData((prevState) => {
      const updatedProducts = recommendedQuantities.map((prod) => {
        const warehouseProduct = warehouseProducts?.find(
          (wp) => wp.pid === prod.pid
        );
        return { ...warehouseProduct, ...prod };
      });

      setLockedProducts((prevLockedProducts) => {
        const updatedLockedProducts = { ...prevLockedProducts };
        Object.keys(updatedLockedProducts).forEach((pid) => {
          const updatedProduct = updatedProducts.find((p) => p.pid === pid);
          if (updatedProduct) {
            updatedLockedProducts[pid] = updatedProduct.qty;
          }
        });
        return updatedLockedProducts;
      });
      return {
        ...prevState,
        products: updatedProducts,
      };
    });
  };

  const handleCreateOrder = (data) => {
    setOrderData((prevState) => ({
      ...prevState,
      ...data,
      products: data.products.filter((prod) => prod.qty > 0),
    }));
    setCurrentScreen("review");
  };

  const handleChange = useCallback(
    (value, field, id) => {
      setOrderData((prevState) => {
        if (!id) {
          return { ...prevState, [field]: value };
        }

        return {
          ...prevState,
          products: prevState.products.map((prod) =>
            prod.id === id
              ? {
                  ...prod,
                  [field]: field === "qty" ? parseInt(value, 10) || 0 : value,
                }
              : prod
          ),
        };
      });

      if (lockedProducts.hasOwnProperty(id) && field === "qty") {
        setLockedProducts((prevState) => ({
          ...prevState,
          [id]: parseInt(value, 10) || 0,
        }));
      }
    },
    [lockedProducts]
  );

  const handleAddProduct = (newProduct) => {
    setOrderData((prevState) => {
      const existingProductIndex = prevState.products.findIndex(
        (product) => product.pid === newProduct.pid
      );

      if (existingProductIndex !== -1) {
        const updatedProducts = [...prevState.products];
        updatedProducts[existingProductIndex] = {
          ...updatedProducts[existingProductIndex],
          qty: updatedProducts[existingProductIndex].qty + 1,
        };
        return { ...prevState, products: updatedProducts };
      } else {
        return {
          ...prevState,
          products: [...prevState.products, { ...newProduct, qty: 1 }],
        };
      }
    });
  };

  const handleDeleteOrder = useCallback((product) => {
    setOrderData((prevState) => ({
      ...prevState,
      products: prevState.products.filter((prod) => prod.pid !== product.pid),
    }));

    setLockedProducts((prevState) => {
      const { [product.pid]: removed, ...rest } = prevState;
      return rest;
    });
  }, []);

  const handleWarehouseChange = (e) => {
    setSelectedWarehouse(e.target.value);
    localStorage.setItem("selectedWarehouse", e.target.value);
  };

  const handleLockToggle = (productId, isLocked) => {
    setLockedProducts((prevState) => {
      if (isLocked) {
        const product = orderData.products?.find(
          (prod) => prod.pid === productId
        );
        return { ...prevState, [productId]: product ? product.qty : 0 };
      } else {
        const { [productId]: removed, ...rest } = prevState;
        return rest;
      }
    });
  };

  const handleContainerSizeChange = (e) => {
    const newContainerSize = e.target.value;
    if (newContainerSize === "blank") {
      setOrderData((prevState) => ({ ...prevState, products: [] }));
      setLockedProducts({});
    } else {
      setContainerSize(newContainerSize);
      localStorage.setItem("lastSelectedContainerSize", newContainerSize);
    }
  };

  const handleSubmitOrder = () => {
    setError("");

    let payload;
    let hasError = false;

    if (warehouseOrderNumber) {
      const deletedProducts = originalProducts
        .filter(
          (origProd) =>
            !orderData.products.some((prod) => prod.id === origProd.id)
        )
        .map((deletedProd) => ({
          ...deletedProd,
          deleteRow: true,
        }));

      const updatedProducts = [
        ...orderData.products.map((prod) => ({
          id: prod.id,
          quantityOrdered: prod.qty,
          tradeFinanceOrder: prod.tradefinanceorder,
          notes: prod.notes,
          deleteRow: false,
          controlBoardPanelModel: prod.controlboardpanelmodel,
          serialNumbers: prod.serialnumbers,
          supplier: prod.supplier,
        })),
        ...deletedProducts,
      ];

      for (let item of updatedProducts) {
        if (!item.supplier || item.supplier.trim() === "") {
          setError(`Supplier is required for all items.`);
          hasError = true;
          break;
        }
        if (!item.quantityOrdered || item.quantityOrdered <= 0) {
          setError(
            `Quantity is required and must be greater than 0 for all items.`
          );
          hasError = true;
          break;
        }
      }

      if (!hasError) {
        payload = {
          action: "EDIT_INVENTORY_ORDER",
          details: updatedProducts,
        };
      }
    } else {
      if (!orderData.supplier || orderData.supplier.trim() === "") {
        setError("Supplier is required for the order.");
        hasError = true;
      } else {
        const newOrderDetails = {
          dateOrdered: orderData.dateOrdered,
          warehouse: selectedWarehouse,
          supplier: orderData.supplier,
          orderNotes: orderData.notes,
          items: orderData.products.map((prod) => ({
            pid: prod.pid,
            inventoryItem: prod.inventoryitem,
            quantityOrdered: prod.qty,
            tradeFinanced: prod.tradefinanceorder || false,
            notes: prod.notes || "",
          })),
        };

        for (let item of newOrderDetails.items) {
          if (!item.quantityOrdered || item.quantityOrdered <= 0) {
            setError(
              `Quantity is required and must be greater than 0 for all items.`
            );
            hasError = true;
            break;
          }
        }

        if (!hasError) {
          payload = {
            action: "NEW_INVENTORY_ORDER",
            details: newOrderDetails,
          };
        }
      }
    }

    if (hasError) {
      return;
    }

    setSubmitLoading(true);
    performInventoryAction(payload)
      .then(() => {
        navigate("/inventoryOrders/stockOrders");
      })
      .catch((err) => {
        console.error(err);
        setSubmitLoading(false);
        setError(
          "An error occurred while building the order. Please try again later."
        );
      });
  };

  const defaultSettings = {
    showHeaders: true,
    columnsDisplayed: searchFields.filter(
      (field) =>
        !field.isHiddenForTile &&
        field.fieldName !== "everywhere" &&
        field.dataType !== "image"
    ),
  };

  return (
    <MainContainer
      title={
        currentScreen === "build"
          ? warehouseOrderNumber
            ? `Edit Inventory Order: ${warehouseOrderNumber}`
            : "Build Inventory Order"
          : "Review Order"
      }
    >
      {error && (
        <Error errorMessage={error} sx={{ borderRadius: 1, m: 1, mb: 0 }} />
      )}

      {currentScreen === "build" ? (
        <>
          <Box
            sx={{
              position: "fixed",
              top: 5,
              right: { xs: 95, sm: 95, md: 105 },
              zIndex: 3000,
            }}
          >
            {searchFields && (
              <ColumnSelector
                page={"buildInventoryOrder"}
                searchFields={searchFields}
                defaultSettings={defaultSettings}
              />
            )}
          </Box>

          <BuildInventoryOrderScreen
            error={error}
            handleAddProduct={handleAddProduct}
            handleProductsChange={handleChange}
            handleDeleteOrder={handleDeleteOrder}
            onCreateOrder={handleCreateOrder}
            orderData={orderData}
            handleLockedPidChange={handleLockToggle}
            selectedWarehouse={selectedWarehouse}
            handleWarehouseChange={handleWarehouseChange}
            warehouseProducts={warehouseProducts}
            containerSize={containerSize}
            handleContainerSizeChange={handleContainerSizeChange}
            fetchRecommendedQuantities={fetchRecommendedQuantities}
            lockedProducts={lockedProducts}
          />
        </>
      ) : (
        <ReviewOrderScreen
          orderData={orderData}
          onBack={() => setCurrentScreen("build")}
          onSubmit={handleSubmitOrder}
          handleProductsChange={handleChange}
          warehouseProducts={warehouseProducts}
          loading={submitLoading}
          error={!!error}
        />
      )}
    </MainContainer>
  );
};

export default BuildInventoryOrder;
