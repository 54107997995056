import React, { useState } from "react";
import { Paper, Typography, Divider, Container, Box } from "@mui/material";
import TextField from "../../../components/TextField";
import { updatePassword } from "../../../api/Users";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Password() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [successfulMessage, setSuccessfulMessage] = useState(false);
  const [unsuccessfulMessage, setUnsuccessfulMessage] = useState(false);

  const handleSubmit = () => {
    const user = JSON.parse(localStorage.getItem("USER"));

    setSuccessfulMessage(false);
    setUnsuccessfulMessage(false);
    setLoading(true);

    if (newPassword !== confirmNewPassword) {
      setLoading(false);
      setUnsuccessfulMessage("Error: New passwords don't match.");
      return setTimeout(() => {
        setUnsuccessfulMessage(false);
        setSuccessfulMessage(false);
      }, 4000);
    }

    updatePassword(user.id, currentPassword, newPassword, confirmNewPassword)
      .then(() => {
        setLoading(false);
        setSuccessfulMessage(true);
      })
      .catch((err) => {
        console.log(err);
        setUnsuccessfulMessage(err.response.data);
        setLoading(false);
      })
      .finally(() => {
        setTimeout(() => {
          setUnsuccessfulMessage(false);
          setSuccessfulMessage(false);
        }, 4000);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      });
  };

  return (
    <Container maxWidth="sm">
      {" "}
      {(successfulMessage || unsuccessfulMessage) && (
        <Alert
          severity={successfulMessage ? "success" : "error"}
          sx={{ mb: 3, borderRadius: 2 }}
        >
          {successfulMessage
            ? "Password updated successfully"
            : unsuccessfulMessage}
        </Alert>
      )}
      <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, borderRadius: 2 }}>
        <Typography variant="h5" fontWeight="bold" component="h2" gutterBottom>
          Change Password
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <TextField
          label="Current Password"
          required
          value={currentPassword}
          changeValue={setCurrentPassword}
          fullWidth
          type="password"
          sx={{ mb: 3 }}
        />
        <Divider sx={{ mb: 3 }} />
        <TextField
          required
          sx={{ mb: 3 }}
          label="New Password"
          value={newPassword}
          changeValue={setNewPassword}
          fullWidth
          type="password"
        />
        <TextField
          required
          sx={{ mb: 3 }}
          label="Confirm New Password"
          value={confirmNewPassword}
          changeValue={setConfirmNewPassword}
          fullWidth
          type="password"
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            size="large"
            sx={{ minWidth: 120 }}
          >
            Update
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
}
