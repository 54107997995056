import * as React from "react";

import Box from "@mui/material/Box";
import { useGridApiRef } from "@mui/x-data-grid-premium";

import { getAllOfflineOrders } from "../../api/OfflineOrders";
import { getOfflineOrdersFields } from "../../api/OfflineOrders";
import { markAsPaid } from "../../api/Other";

import { useRecoilValue } from "recoil";
import { permissionsState } from "../../atoms/atoms";

import DataGrid from "../../components/Datagrid/Datagrid";
import Error from "../../components/Error";
import MainContainer from "../../layouts/main";

import DetailPanelContent from "./components/DetailPanelContent";
import MarkAsPaid from "./components/MarkAsPaidModal";
import { errorMsg } from "../../common";
import Loading from "../../components/Loading";

export default function OfflineOrders() {
  const [allOrderDetails, setAllOrderDetails] = React.useState([]);
  const [searchFieldsLoading, setSearchFieldsLoading] = React.useState(true);
  const [ordersLoading, setOrdersLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [columns, setColumns] = React.useState(null);

  const permissions = useRecoilValue(permissionsState);

  const [searchFields, setSearchFields] = React.useState();
  const [columnPermissions, setColumnPermissions] = React.useState(null);

  const [openModal, setOpenModal] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState(null);

  const [markAsPaidLoading, setMarkAsPaidLoading] = React.useState(false);

  const handleOpen = (order) => {
    setOpenModal(true);
    setOrderDetails({
      id: order.orderId,
      fullName: `${order.firstName} ${order.lastName}`,
    });
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  React.useEffect(() => {
    let didComponentMount = false;

    getOfflineOrdersFields()
      .then((rsp) => {
        if (didComponentMount) return;
        let columnOrderValues = {};
        const newColumns = [];

        rsp.searchFields.forEach((field, i) => {
          const fieldName = field.fieldName;
          const columns =
            permissions?.["offlineOrders"]?.["orderDetails"]?.["columns"];

          if (
            permissions?.id === "Super Admin" ||
            (columns && columns[fieldName])
          ) {
            newColumns.push({ id: i, ...field });
          }
        });
        setColumnPermissions(columnOrderValues);

        setColumns(newColumns);
        setSearchFields(rsp.searchFields);
        setSearchFieldsLoading(false);

        getAllOfflineOrders()
          .then((rsp) => {
            const sortedOrderUnits = rsp.allOrderUnits.sort((a, b) => {
              return new Date(b.orderDate) - new Date(a.orderDate);
            });

            setAllOrderDetails(sortedOrderUnits);
            setOrdersLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setErrorMessage(errorMsg);
            setAllOrderDetails([]);
            setOrdersLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        setSearchFieldsLoading(false);
      });

    return () => {
      didComponentMount = true;
    };
  }, []);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 400, []);

  const apiRef = useGridApiRef();

  const onRowClick = React.useCallback(
    (params, e) => {
      if (e.target.tagName !== "A") {
        apiRef.current.toggleDetailPanel(params.id);
      }
    },
    [apiRef]
  );

  const markAsPaidSubmit = () => {
    setMarkAsPaidLoading(true);

    markAsPaid(orderDetails.id)
      .then((rsp) => {
        setMarkAsPaidLoading(false);
        setAllOrderDetails(
          allOrderDetails.map((order) => ({
            ...order,
            inProgressMarkAsPaid:
              orderDetails.id === order.id ? true : order.inProgressMarkAsPaid,
          }))
        );
        handleClose();
      })
      .catch((err) => {
        setMarkAsPaidLoading(false);
        console.log(err);
      });
  };

  return (
    <MainContainer title="Offline Orders">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          height: "calc(100vh - 50px)",
        }}
      >
        {searchFieldsLoading && (
          <Box width={"100px"} margin={"auto"}>
            <Loading />
          </Box>
        )}

        {errorMessage && (
          <Error
            sx={{ width: "100%", margin: "auto", mb: 2, borderRadius: 0 }}
            errorMessage={errorMessage}
          />
        )}

        {searchFields && columnPermissions && (
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <DataGrid
              noBorder
              rowID="id"
              dataGridName="offlineOrders"
              searchFields={columns}
              rows={allOrderDetails}
              storage={{
                columnWidths: "offlineOrdersWidths",
                columns: "offlineOrdersColumns",
                visibilityModel: "offlineOrdersVisibility",
              }}
              apiRef={apiRef}
              onRowClick={onRowClick}
              rowThreshold={0}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              loading={ordersLoading}
              hideFooter
              openModal={handleOpen}
            />
          </Box>
        )}
        {orderDetails && (
          <MarkAsPaid
            markAsPaidLoading={markAsPaidLoading}
            orderDetails={orderDetails}
            open={openModal}
            handleClose={handleClose}
            onSubmit={markAsPaidSubmit}
          />
        )}
      </Box>
    </MainContainer>
  );
}
