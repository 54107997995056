import Box from "@mui/material/Box";
import DatePicker from "../../../components/DatePicker";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";

import DialogContentText from "@mui/material/DialogContentText";
import { useEffect } from "react";
import dayjs from "dayjs";

const HoldOrdersContent = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  const isHolding = data.shippingStatus === "HOLD_SHIPMENT";

  useEffect(() => {
    setState({ date: dayjs() });
  }, []);

  const handleDateChange = (date) => {
    setState({ date: dayjs(date) });
  };

  return (
    <Box>
      <DialogContentText sx={{ mb: 3 }}>
        {isHolding
          ? "Edit hold details"
          : "Are you sure you want to place this order on hold?"}
      </DialogContentText>
      <DatePicker
        label={"Hold Until Date"}
        value={state.date}
        onChange={handleDateChange}
        minDate={new dayjs()}
      />
    </Box>
  );
};
export default HoldOrdersContent;
