import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, IconButton, Paper } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import { performOrderAction } from "../../../../api/CustomerOrders";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { EditForm } from "./EditForm";
import {
  actionPerformedState,
  orderState,
  permissionsState,
} from "../../../../atoms/atoms";
import { DisplayInfo } from "./DisplayInfo";

const Buttons = ({ order }) => (
  <Box display="flex" alignItems={"center"}>
    <ActionButton href={order?.accustomerlink} icon={<SwitchAccountIcon />} />
    <ActionButton href={order?.xeroinvoicelink} icon={<DescriptionIcon />} />
  </Box>
);

const ActionButton = ({ href, icon }) => (
  <IconButton
    component="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    sx={{
      minWidth: "50px",
      height: "40px",
      border: 1,
      borderColor: "primary.main",
      borderRadius: "50%",
      backgroundColor: "primary.main",
      "&:hover": { backgroundColor: "primary.focus" },
      marginX: 0.5,
    }}
  >
    {React.cloneElement(icon, { sx: { color: "white" } })}
  </IconButton>
);

const multilineEllipsisStyle = {
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 5,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
};

const OrderDetails = ({ order }) => {
  const setOrders = useSetRecoilState(orderState);
  const permissions = useRecoilValue(permissionsState);
  const [state, setState] = useState({});
  const [addressType, setAddressType] = useState("commercial");
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const topOfPageRef = useRef(null);
  const setPerformAction = useSetRecoilState(actionPerformedState);

  useEffect(() => {
    setErrors({});
    setAddressType(order.shippingisresidential ? "residential" : "commercial");
    setState({
      firstName: order.shippingfirstname,
      lastName: order.shippinglastname,
      company: order.shippingcompany,
      email: order.shippingemail,
      phone: order.shippingphone,
      additionalPhone: order.shippingadditionalphone,
      billingCompany: order.billingcompany,
      billingStreet: order.billingstreet,
      billingCity: order.billingcity,
      billingState: order.billingstate,
      billingPostcode: order.billingpostcode,
      billingCountry: order.billingcountry,
      shippingCompany: order.shippingcompany,
      shippingStreet: order.shippingstreet,
      shippingCity: order.shippingcity,
      shippingState: order.shippingstate,
      shippingPostcode: order.shippingpostcode,
      shippingCountry: order.shippingcountry,
      shippingIsResidential: order.shippingisresidential,
    });
  }, [order, isEditMode]);

  const handleStateChange = (field) => (event) => {
    setState({ ...state, [field]: event.target.value });
  };

  const handleAddressTypeChange = (event) => {
    setAddressType(event.target.value);
    setState({
      ...state,
      shippingIsResidential: event.target.value === "residential",
    });
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const handleSubmit = () => {
    if (state.email && state.email !== order.shippingemail) {
      window.open(order.accustomerlink, "_blank");
    }

    const newErrors = {};
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "billingStreet",
      "billingCity",
      "billingPostcode",
      "billingCountry",
      "shippingStreet",
      "shippingCity",
      "shippingPostcode",
      "shippingCountry",
    ];

    requiredFields.forEach((field) => {
      if (!state[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const request = {
        action: "UPDATE_ORDER_INFORMATION",
        details: [{ ...state, wixOrderNumber: order.wixordernumber }],
      };

      setLoading(true);
      performOrderAction(request)
        .then((rsp) => {
          setPerformAction((prevState) => prevState + 1);
          setOrders(rsp);
          setIsEditMode(false);
          setLoading(false);
          topOfPageRef.current?.scrollIntoView();
        })
        .catch((err) => {
          console.log(err);
          setErrors({
            main: "Error: there was an error submitting. Please try again later.",
          });
          setLoading(false);
        });
    }
  };

  const formattedPhone = order?.shippingphone
    ? parsePhoneNumberFromString(order.shippingphone)?.formatInternational() ||
      order.shippingphone
    : "";

  return (
    <>
      <div ref={topOfPageRef} />
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }}>
        <Box
          display={{ xs: "flex", sm: "flex", md: "none" }}
          mb={1}
          justifyContent={"space-between"}
        >
          <Buttons order={order} />
          {permissions.customerOrders.actions.editOrderDetails && (
            <IconButton onClick={toggleEditMode}>
              {isEditMode ? (
                <CloseIcon fontSize="large" />
              ) : (
                <EditIcon fontSize="large" />
              )}
            </IconButton>
          )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={isEditMode ? 2 : 0}
        >
          {isEditMode ? (
            <Typography fontWeight="bold" variant="h5">
              Edit Order Details
            </Typography>
          ) : (
            <Box display="flex" alignItems="center">
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize={{ xs: "40px", sm: "48px" }}
                sx={{ ...multilineEllipsisStyle, mr: 2, maxWidth: "100%" }}
              >
                {`${order?.shippingfirstname} ${order?.shippinglastname}`}
              </Typography>
              <Box display={{ xs: "none", sm: "none", md: "block" }}>
                <Buttons order={order} />
              </Box>
            </Box>
          )}{" "}
          <Box display={{ xs: "none", sm: "none", md: "block" }}>
            {permissions.customerOrders.actions.editOrderDetails && (
              <IconButton onClick={toggleEditMode}>
                {isEditMode ? (
                  <CloseIcon fontSize="large" />
                ) : (
                  <EditIcon fontSize="large" />
                )}
              </IconButton>
            )}
          </Box>
        </Box>

        {isEditMode ? (
          <EditForm
            state={state}
            errors={errors}
            addressType={addressType}
            handleStateChange={handleStateChange}
            handleAddressTypeChange={handleAddressTypeChange}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        ) : (
          <DisplayInfo order={order} formattedPhone={formattedPhone} />
        )}
      </Paper>
    </>
  );
};

export default OrderDetails;
