import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function getConversations(orderID) {
  return axios
    .get(`${API_ORDER_LINK_URL}/conversations?orderID=${orderID}`)
    .then((response) => {
        let convos = response.data.conversations
        for (let convo of convos){
            convo.lastMessage ??= {}
        }
        return response.data
    });
}

function createConversation(orderID, users) {
  return axios
    .post(`${API_ORDER_LINK_URL}/conversations`, {
      orderID,
      users,
    })
    .then((response) => response.data);
}

export { getConversations, createConversation };
