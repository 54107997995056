import Pusher from "pusher-js";

import { PUSHER_CLUSTER, PUSHER_APP_KEY } from "../../consts";

const pusher = new Pusher(PUSHER_APP_KEY, {
  cluster: PUSHER_CLUSTER,
  forceTLS: true,
});

export default pusher;
