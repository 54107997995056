import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ListItemText from "@mui/material/ListItemText";
import { MenuList, Paper } from "@mui/material";
import Box from "@mui/material/Box";
export default function FileMenu({
  handleDownloadFile,
  handleDeleteFile,
  handleRenameFile,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ width: 200, maxWidth: "100%" }}>
          <MenuItem
            onClick={() => {
              handleClose();
              handleDownloadFile();
            }}
          >
            <ListItemIcon sx={{ mr: 1 }}>
              <CloudDownloadIcon />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
              handleRenameFile();
            }}
          >
            <ListItemIcon sx={{ mr: 1 }}>
              <DriveFileRenameOutlineIcon />
            </ListItemIcon>
            <ListItemText>Rename File</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleDeleteFile();
            }}
          >
            <ListItemIcon sx={{ mr: 1 }}>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
}
