import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { Menu, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import GroupIcon from "@mui/icons-material/Group";
import Typography from "@mui/material/Typography";
import CreateIcon from "@mui/icons-material/Create";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterModal from "./Modal";

import {
  getAllFilters,
  createFilter,
  updateFilter,
  deleteFilter,
  getFilter,
} from "../../api/Filters";
import Loading from "../Loading";
import Box from "@mui/material/Box";
import DeleteModal from "../DeleteModal";
import { loadFilter, loadUser, removeFilter } from "../../common";
import useSavedFilters from "../../hooks/useSavedFilters";
import { handleFilterChange } from "../../utils/filterUtils";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    ".FilterContainer:hover": { cursor: "pointer" },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const FiltersMenu = ({
  table,
  searchFieldOptions,
  refetchOrders,
  hasActions,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [filterSubmitLoading, setFilterSubmitLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(true);

  const [filter, setFilter] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [menuError, setMenuError] = useState("");

  const [selectedFilter, setSelectedFilter] = useState("");

  useEffect(() => {
    const getSavedFilter = async () => {
      const savedFilter = loadFilter(table);

      if (savedFilter) {
        try {
          const filter = (await getFilter(savedFilter)).filter;
          setSelectedFilter(filter);
        } catch (e) {
          let serverErrMsg = e.response?.data?.message ? ` (${e.response.data.message})` : ""
          console.error("Couldn't get filter for: " + savedFilter + serverErrMsg, e)
          if (e.response?.status === 500 && serverErrMsg.includes("Filter doesn't exist")) { // todo better detection?
            removeFilter(table)
          }
        }
      }
      setFilterLoading(false);
    };
    getSavedFilter();
  }, [table]);

  const handleSelectedFilter = (filter) => setSelectedFilter(filter);
  const handleFilterUpdate = (filter) => {
    handleFilterChange(filter, handleSelectedFilter, refetchOrders, table);
  };

  const {
    savedFiltersLoading,
    savedFiltersError,
    savedFilters,
    handleSavedFiltersChange,
  } = useSavedFilters(table);

  const handleFiltersOpen = (e) => {
    setFiltersExpanded(true);
    setAnchorEl(e.currentTarget);
  };

  const handleFiltersClose = () => {
    setFiltersExpanded(false);
    setAnchorEl(null);
  };

  const handleOpenDeleteModal = (e, filter) => {
    e.stopPropagation();

    setFilter(filter);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenModal = () => {
    setFilter(null);
    setIsEdit(false);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorMessage("");
  };

  const handleAddFilterSubmit = (data) => {
    setFilterSubmitLoading(true);
    createFilter({ ...data, dataGridName: table })
      .then((rsp) => {
        if (!rsp.filter) {
          setErrorMessage(rsp.message);
        } else {
          handleFilterUpdate(rsp.filter);
          handleSavedFiltersChange((prev) => [...prev, rsp.filter]);
          handleCloseModal();
          handleFiltersClose();
        }
        setFilterSubmitLoading(false);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.msg);
        console.log(err);
        setFilterSubmitLoading(false);
      });
  };

  const handleUpdateFilterSubmit = (newFilter) => {
    setFilterSubmitLoading(true);

    updateFilter(
      newFilter.id,
      {
        name: newFilter.name,
        isShared: newFilter.isShared,
        filters: newFilter.filters,
        andOr: newFilter.andOr,
      },
      table
    )
      .then((rsp) => {
        if (!rsp.updatedFilters) {
          setErrorMessage(rsp.message);
        } else {
          if (newFilter.id === selectedFilter.id) handleFilterUpdate(newFilter);
          handleSavedFiltersChange(rsp.updatedFilters);

          handleCloseModal();
          setFilterSubmitLoading(false);
        }
      })
      .catch((err) => {
        setErrorMessage(
          err.response?.data?.msg ||
            "Error: something went wrong please try again. "
        );
        setFilterSubmitLoading(false);
        console.log(err);
      });
  };

  const handleDeleteFilterSubmit = () => {
    setFilterSubmitLoading(true);
    deleteFilter(filter.id)
      .then((rsp) => {
        handleSavedFiltersChange(rsp.updatedFilters);
        setFilterSubmitLoading(false);
        handleCloseDeleteModal();
        if (filter.id === selectedFilter.id) handleFilterUpdate();
      })
      .catch((err) => {
        setErrorMessage(err.response.data.msg);
        console.log(err);
        setFilterSubmitLoading(false);
      });
  };
  const handleOpenEditFilterModal = (e, filter) => {
    e.stopPropagation();
    setFilter(filter);
    setIsEdit(true);
    setOpenModal(true);
  };

  const handleOpenCopyFilterModal = (e, filter) => {
    e.stopPropagation();
    setFilter(filter);
    setIsEdit(false);
    setOpenModal(true);
  };

  const userID = JSON.parse(loadUser())?.id;

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-expanded={filtersExpanded ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleFiltersOpen}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          minWidth: { xs: 120, sm: 150 },
          maxWidth: { xs: "calc(100% - 48px)", sm: "none" }, // Remove max-width constraint on larger screens
          width: { sm: "auto" }, // Allow button to grow on larger screens
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 1.5, sm: 2 },
          height: { xs: 33, sm: 40 },
        }}
        disabled={!!menuError}
        className={"filters-button"}
      >
        {!filterLoading && (
          <Tooltip
            title={selectedFilter ? selectedFilter.name : "Filters"}
            arrow
          >
            <Typography
              sx={{
                width: "100%",
                overflow: isMobile ? "hidden" : "visible",
                textOverflow: isMobile ? "ellipsis" : "clip",
                whiteSpace: isMobile ? "nowrap" : "normal",
                fontSize: { xs: 14, sm: 15 },
                textAlign: "left",
                lineHeight: isMobile ? 1 : null,
              }}
            >
              {selectedFilter
                ? selectedFilter.name
                : menuError || savedFiltersError
                ? "Error"
                : "Filters"}
            </Typography>
          </Tooltip>
        )}
        {filterLoading && <Loading size={10} />}
      </Button>

      <StyledMenu
        anchorEl={anchorEl}
        open={filtersExpanded}
        onClose={handleFiltersClose}
      >
        {savedFiltersLoading && (
          <Box m={"auto"} width={"20px"}>
            <Loading size={20} />
          </Box>
        )}

        {!savedFiltersLoading &&
          savedFilters?.map((filter) => (
            <Box
              key={filter.id}
              onClick={() => {
                handleFiltersClose();
                handleFilterUpdate(filter);
              }}
              display={"flex"}
              className={"FilterContainer"}
              alignItems={"center"}
              p={"3px"}
              pl={2}
              justifyContent={"space-between"}
              sx={{ "&:hover": { background: "#f3f3f3" } }}
            >
              <Typography
                noWrap
                sx={{
                  width: "180px",
                }}
              >
                {filter.name}
              </Typography>
              <Box>
                {filter.userID === userID && (
                  <Button
                    sx={{ minWidth: "20px", mr: "4px" }}
                    onClick={(e) => handleOpenEditFilterModal(e, filter)}
                  >
                    <CreateIcon
                      fontSize={"small"}
                      sx={{ color: "rgb(55, 65, 81)", mr: "0 !important" }}
                    />
                  </Button>
                )}
                <Button
                  sx={{
                    minWidth: "20px",
                    mr: filter.userID === userID ? "4px" : 0,
                  }}
                  onClick={(e) => handleOpenCopyFilterModal(e, filter)}
                >
                  <ContentCopyIcon
                    fontSize={"small"}
                    sx={{ color: "rgb(55, 65, 81)", mr: "0 !important" }}
                  />
                </Button>

                {filter.userID === userID && (
                  <Button
                    sx={{ minWidth: "20px" }}
                    onClick={(e) => handleOpenDeleteModal(e, filter)}
                  >
                    <DeleteIcon
                      fontSize={"small"}
                      sx={{ color: "rgb(55, 65, 81)", mr: "0 !important" }}
                    />
                  </Button>
                )}
              </Box>
            </Box>
          ))}

        <MenuItem
          sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
          onClick={handleOpenModal}
        >
          <Typography>New Filter... </Typography>
        </MenuItem>

        {savedFilters?.length > 0 && (
          <MenuItem
            sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
            onClick={() => {
              handleFiltersClose();
              handleFilterUpdate();
            }}
          >
            <Typography>Clear Filter... </Typography>
          </MenuItem>
        )}
      </StyledMenu>
      <FilterModal
        table={
          table === "currentStock" ||
          table === "stockPredictions" ||
          table === "stockOrders"
            ? "inventoryOrders"
            : "orderUnit"
        }
        isEdit={isEdit}
        selectedFilter={filter}
        handleAddFilterSubmit={handleAddFilterSubmit}
        handleUpdateFilterSubmit={handleUpdateFilterSubmit}
        filterLoading={filterSubmitLoading}
        searchFieldOptions={searchFieldOptions}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        errorMessage={errorMessage}
      />
      <DeleteModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDeleteItem={handleDeleteFilterSubmit}
        message={`Are you sure you want to delete filter: ${filter?.name}`}
        loading={filterSubmitLoading}
      />
    </>
  );
};
export default FiltersMenu;
