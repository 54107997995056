import React from "react";
import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  ".MuiTablePagination-toolbar": {
    alignItems: "center",
    height: "56px",
    minHeight: "56px",
    paddingRight: theme.spacing(2),
  },
  ".MuiTablePagination-selectLabel": {
    margin: 0,
  },
  ".MuiTablePagination-select": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  ".MuiTablePagination-displayedRows": {
    margin: 0,
  },
  ".MuiTablePagination-actions": {
    marginLeft: theme.spacing(2),
  },
}));

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: 16 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

const CustomTablePagination = (props) => {
  return (
    <StyledTablePagination
      {...props}
      ActionsComponent={TablePaginationActions}
      rowsPerPageOptions={[5, 10, 25, 50]}
      component="div"
    />
  );
};

export default CustomTablePagination;
