import { useState, useCallback, useEffect, useRef } from "react";
import { setupColumns } from "../setupColumns";
import {
  onColumnOrderChange,
  onColumnVisibilityModelChange,
  onColumnWidthChange,
} from "../utils";

export const useColumnManagement = (props, apiRef, gridColumns) => {
  const {
    storage,
    dataGridName,
    searchFields,
    role,
    dateFormat,
    onRowClick,
    productPIDs,
    handleOpenDeleteModal,
    openModal,
    navigate,
    handleRowSelection,
  } = props;

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const storedModel = localStorage.getItem(storage.visibilityModel);
    return storedModel ? JSON.parse(storedModel) : {};
  });
  const [pinnedColumnsLeft, setPinnedColumnsLeft] = useState([]);

  const [isMobileView, setIsMobileView] = useState(false);
  const isInitialized = useRef(false);

  const isPinned = JSON.parse(localStorage.getItem(`${dataGridName}_pinState`));
  const handleUnpinAllColumnsRef = useRef(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    setIsMobileView(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobileView(event.matches);
    };

    mediaQuery.addListener(handleMediaQueryChange);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const saveColumnProperties = useCallback(() => {
    const columnProperties = gridColumns.current.map((col) => ({
      field: col.field,
      width: col.width,
      isPinned: col.isPinned,
      isHidden: !columnVisibilityModel[col.field],
    }));
    localStorage.setItem(
      `${dataGridName}_columnProperties`,
      JSON.stringify(columnProperties)
    );
  }, [dataGridName, gridColumns, columnVisibilityModel]);

  useEffect(() => {
    if (!searchFields || isInitialized.current) return;

    const savedColumnProperties = JSON.parse(
      localStorage.getItem(`${dataGridName}_columnProperties`) || "[]"
    );

    const { newGridColumns, newColumnVisibilityModel, newPinnedLeft } =
      setupColumns({
        searchFields,
        storage,
        dataGridName,
        role,
        dateFormat,
        onRowClick,
        productPIDs,
        handleOpenDeleteModal,
        openModal,
        navigate,
        savedColumnProperties,
      });

    setColumnVisibilityModel(newColumnVisibilityModel);
    isInitialized.current = true;
    const initialPinnedColumns =
      isPinned || isPinned === null
        ? handleRowSelection
          ? ["selectable", ...newPinnedLeft]
          : newPinnedLeft
        : [];

    setPinnedColumnsLeft(initialPinnedColumns);
    if (gridColumns.current.length !== 0)
      apiRef.current?.setPinnedColumns({
        left: initialPinnedColumns,
        right: ["actions"],
      });

    gridColumns.current = newGridColumns.map((col) => {
      const savedProp = savedColumnProperties.find(
        (prop) => prop.field === col.field
      );
      return {
        ...col,
        isPinned: savedProp ? savedProp.isPinned : col.isPinned,
        width: savedProp ? savedProp.width : col.width,
      };
    });
  }, [dataGridName]);

  const handleColumnWidthChange = useCallback(
    (params) => {
      onColumnWidthChange(params, gridColumns, dataGridName);
      saveColumnProperties();
    },
    [dataGridName, gridColumns, saveColumnProperties]
  );

  const handleColumnVisibilityModelChange = useCallback(
    (newModel) => {
      onColumnVisibilityModelChange(
        newModel,
        storage,
        setColumnVisibilityModel,
        gridColumns,
        dataGridName
      );
      saveColumnProperties();
    },
    [storage, gridColumns, dataGridName, saveColumnProperties]
  );

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns) => {
      if (handleUnpinAllColumnsRef.current) {
        handleUnpinAllColumnsRef.current = false;
        return;
      }

      if (isInitialized.current) {
        isInitialized.current = false;
        return;
      }

      // Update the isPinned property for each column in gridColumns.current
      gridColumns.current = gridColumns.current.map((col) => ({
        ...col,
        isPinned: updatedPinnedColumns.left.includes(col.field),
      }));

      // Save the updated column properties
      const columnProperties = gridColumns.current.map((col) => ({
        field: col.field,
        width: col.width,
        isPinned: col.isPinned,
        isHidden: !columnVisibilityModel[col.field],
      }));

      localStorage.setItem(
        `${dataGridName}_columnProperties`,
        JSON.stringify(columnProperties)
      );

      // Update pinnedColumnsLeft state
      setPinnedColumnsLeft(updatedPinnedColumns.left);

      // Update overall pin state in localStorage
      localStorage.setItem(
        `${dataGridName}_pinState`,
        JSON.stringify(updatedPinnedColumns.left.length > 0)
      );
    },
    [dataGridName, columnVisibilityModel]
  );

  const handleUnpinAllColumns = useCallback(() => {
    handleUnpinAllColumnsRef.current = true;
    // Implement unpin all columns logic here
    setPinnedColumnsLeft([]);
    apiRef.current.setPinnedColumns({
      left: [],
      right: ["actions"],
    });
    localStorage.setItem(`${dataGridName}_pinState`, JSON.stringify(false));
  }, []);

  const handlePinAllColumns = useCallback(() => {
    const newPinnedLeft = [];
    const savedColumnProperties =
      JSON.parse(localStorage.getItem(`${dataGridName}_columnProperties`)) ||
      [];

    savedColumnProperties.forEach((col) => {
      if (col.isPinned) newPinnedLeft.push(col.field);
    });

    apiRef.current.setPinnedColumns({
      left: handleRowSelection
        ? ["selectable", ...newPinnedLeft]
        : newPinnedLeft,
      right: ["actions"],
    });
    localStorage.setItem(`${dataGridName}_pinState`, JSON.stringify(true));
  }, []);

  return {
    columnVisibilityModel,
    pinnedColumnsLeft,
    isPinned,
    isMobileView,
    handleUnpinAllColumns,
    handlePinAllColumns,
    handlePinnedColumnsChange,
    onColumnOrderChange: (columnOrder) =>
      onColumnOrderChange(
        columnOrder,
        handleRowSelection,
        dataGridName,
        gridColumns,
        storage
      ),
    onColumnVisibilityModelChange: handleColumnVisibilityModelChange,
    onColumnWidthChange: handleColumnWidthChange,
  };
};
