import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useMediaQuery, useTheme } from "@mui/material";
import NormalTable from "./NormalTable";
import TransposedTable from "./TransposedTable";
import TimezoneSelector from "./TimezoneSelector";
import { updateRowDates } from "./utils";
import { officeTimezoneMap } from "./constants";
import CustomTablePagination from "./CustomTablePagination";

const ReusableTable = ({
  columns,
  rows,
  onCellChange,
  handleAdd,
  handleDelete,
  globalTimezone: initialGlobalTimezone = "AU",
  pagination = false,
  rowsPerPage: initialRowsPerPage = 10,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const shouldTranspose = rows?.length < 4 && (isMobile || columns?.length > 7);
  const [globalTimezone, setGlobalTimezone] = useState(initialGlobalTimezone);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedRows = useMemo(() => rows, [rows]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleInputChange = useCallback(
    (value, field, id) => {
      onCellChange(value, field, id);
    },
    [onCellChange]
  );

  useEffect(() => {
    setGlobalTimezone(initialGlobalTimezone);
  }, [initialGlobalTimezone]);

  const handleGlobalTimezoneChange = (event) => {
    const newTimezone = event.target.value;
    setGlobalTimezone(newTimezone);

    const updatedRows = rows.map((row) =>
      updateRowDates(row, columns, newTimezone)
    );

    updatedRows.forEach((row) => {
      columns.forEach((column) => {
        if (column.type === "date") {
          onCellChange(row[column.field], column.field, row.id);
        }
      });
    });
  };

  const tableWidth = useMemo(
    () => columns.reduce((acc, col) => acc + (col.width || 150), 0),
    [columns]
  );

  const hasDatePicker = useMemo(() => {
    return columns.some((column) => column.type === "date");
  }, [columns]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = useMemo(() => {
    if (!pagination) return memoizedRows;
    const startIndex = page * rowsPerPage;
    return memoizedRows.slice(startIndex, startIndex + rowsPerPage);
  }, [memoizedRows, page, rowsPerPage, pagination]);

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {hasDatePicker && (
        <TimezoneSelector
          globalTimezone={globalTimezone}
          handleGlobalTimezoneChange={handleGlobalTimezoneChange}
          officeTimezoneMap={officeTimezoneMap}
        />
      )}

      <TableContainer
        component={Paper}
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            minWidth: shouldTranspose ? "auto" : tableWidth,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {shouldTranspose ? (
            <TransposedTable
              columns={memoizedColumns}
              rows={paginatedRows}
              handleInputChange={handleInputChange}
              handleDelete={handleDelete}
              globalTimezone={globalTimezone}
            />
          ) : (
            <NormalTable
              columns={memoizedColumns}
              rows={paginatedRows}
              handleInputChange={handleInputChange}
              handleAdd={handleAdd}
              handleDelete={handleDelete}
              globalTimezone={globalTimezone}
            />
          )}
        </Box>
        {shouldTranspose && handleAdd && (
          <Box display="flex" justifyContent="center" mt={2}>
            <IconButton onClick={handleAdd} size="large">
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </Box>
        )}
      </TableContainer>
      {pagination && (
        <CustomTablePagination
          count={rows.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

ReusableTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      width: PropTypes.number,
      maxWidth: PropTypes.number,
      editable: PropTypes.bool,
      type: PropTypes.string,
      renderEditCell: PropTypes.func,
      renderCell: PropTypes.func,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCellChange: PropTypes.func,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  globalTimezone: PropTypes.string,
  pagination: PropTypes.bool,
  rowsPerPage: PropTypes.number,
};

export default ReusableTable;
