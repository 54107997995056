import React, { useEffect, useRef, useState } from "react";
import { Paper } from "@mui/material";

import { Header } from "./Header";
import { Conversations } from "./Conversations";
import { Conversation } from "./Conversation";
import { AddConversation } from "./AddConversation";
import { getAllUsers } from "../../../../api/Users";
import {
  createConversation,
  getConversations,
} from "../../../../api/Conversations";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  activeConversationState,
  conversationsState,
  messagesByConversationState,
  orderFilesState,
} from "../../../../atoms/atoms";
import Alert from "@mui/material/Alert";
import { updateMessages } from "../../../../api/Messages";
import { loadUser } from "../../../../common";
import { updatedConversationsSelector } from "../../../../selectors/selectors";
import { subscribeToOrderChannel } from "../../../../services/pusher/channels/orderChannel";
const MessengerDisplay = ({ open, handleOpen }) => {
  const orderID = window.location.href.split("/").filter(Boolean).pop();
  const userID = JSON.parse(loadUser()).id;
  const [addOpen, setAddOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedConversation, setSelectedConversation] = useState(null);

  const [users, setUsers] = useState(null);
  const [userMap, setUserMap] = useState(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [conversations, setConversations] = useRecoilState(conversationsState);
  const [activeConversation, setActiveConversation] = useRecoilState(
    activeConversationState
  );
  const [messagesByConversation, setMessagesByConversation] = useRecoilState(
    messagesByConversationState
  );
  const setUpdatedConversations = useSetRecoilState(
    updatedConversationsSelector
  );

  const [, setFiles] = useRecoilState(orderFilesState);

  useEffect(() => {
    const cleanup = subscribeToOrderChannel(
      orderID,
      userID,
      setConversations,
      setUpdatedConversations,
      setMessagesByConversation,
      messagesByConversation,
      activeConversation,
      updateMessages,
      setFiles
    );

    return () => {
      cleanup();
    };
  }, [
    orderID,
    userID,
    setConversations,
    setUpdatedConversations,
    setMessagesByConversation,
    messagesByConversation,
    activeConversation,
    updateMessages,
  ]);

  useEffect(() => {
    getConversations(orderID)
      .then((rsp) => {
        setConversations(rsp.conversations);
      })
      .catch((err) => {
        console.log(err);
        setError(
          "Error: there was a problem with getting the conversations for this order. Please try again later"
        );
      });
  }, []);

  useEffect(() => {
    getAllUsers()
      .then((rsp) => {
        const userMap = rsp.users.reduce(
          (acc, user) => ({
            ...acc,
            [user.id]: {
              avatar: user.avatarImage,
              name: `${user.firstName} ${user.lastName}`,
            },
          }),
          {}
        );
        setUserMap(userMap);
        setUsers(rsp.users);
      })
      .catch((err) => {
        console.log(err);
        setError(
          "Error: there was a problem with getting the conversations for this order. Please try again later"
        );
      });
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleConversationSelection = (conversation) => {
    setActiveConversation(conversation?.id);
    setSelectedConversation(conversation);
  };

  const handleOpenAdd = () => {
    setAddOpen(!addOpen);
  };
  const handleAddConversation = (users) => {
    setSubmitLoading(true);
    createConversation(
      orderID,
      users.map((user) => user.id)
    )
      .then((rsp) => {
        setConversations((prev) => [...prev, rsp.newConversation]);
        setSubmitLoading(false);
        handleOpenAdd();
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
        setError(
          "Error: there was a problem with creating a new conversation. Please try again later"
        );
      });
  };

  const filteredConversations =
    userMap &&
    conversations?.filter(
      (conversation) =>
        conversation.isDefault ||
        conversation?.members?.some((memberId) =>
          userMap[memberId].name.toLowerCase().includes(searchTerm)
        )
    );

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: open ? 1 : 0, // Make it expand when open
        minHeight: open ? "calc(100% - 98px)" : "80px",
        maxHeight: open ? "calc(100% - 90px)" : "80px",
        mb: 2,
        overflow: "hidden", // Prevent overflow when closed
      }}
    >
      <Header
        selectedConversation={selectedConversation}
        handleConversationSelection={handleConversationSelection}
        open={open}
        handleOpen={handleOpen}
        userMap={userMap}
      />
      {error && (
        <Alert color={"error"} sx={{ borderRadius: 0 }}>
          {error}
        </Alert>
      )}
      {open && (
        <>
          {!selectedConversation && (
            <Conversations
              searchTerm={searchTerm}
              handleSearchChange={handleSearchChange}
              conversations={filteredConversations}
              handleConversationSelection={handleConversationSelection}
              handleOpenAdd={handleOpenAdd}
              userMap={userMap}
            />
          )}
          {!!selectedConversation && (
            <Conversation
              conversation={selectedConversation}
              users={users}
              isDefault={selectedConversation.isDefault}
              userMap={userMap}
              // handleConversationUpdate={handleConversationUpdate}
            />
          )}
          {addOpen && (
            <AddConversation
              users={users}
              open={addOpen}
              handleClose={handleOpenAdd}
              handleAddSubmit={handleAddConversation}
              loading={submitLoading}
            />
          )}
        </>
      )}
    </Paper>
  );
};

export default MessengerDisplay;
