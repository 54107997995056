import dayjs from "dayjs";

export const ERROR_MESSAGES = {
  confirmRefund: "Please confirm if you have refunded the customer.",
  selectAtLeastOneItem: "Please select at least one item to proceed.",
  missingSerialNumber:
    "One or more items are missing a serial number. Please provide serial numbers for all selected items.",
  selectCurrency: "Please select a currency for the shipping costs.",
  selectShippingCompany:
    "Please select a shipping company to proceed with the shipment.",
  provideConsignmentNumber:
    "Please provide a consignment number for the shipment.",
  provideTrackingNumber: "Please provide a tracking number for the shipment.",
  provideValidDate: "Please provide a valid date.",
  provideValidHoldDate: "Please provide a valid hold until date.",
  provideValidDeliveredDate: "Please provide a valid delivered date.",
  selectShippingStatus: "Please select a shipping status to update.",
  selectWarehouse: "Please select a warehouse for the product.",
  selectProduct: "Please select a product from the dropdown.",
  provideQuantity: "Please provide a qty for the product.",
};
const officeTimezoneMap = {
  AU: "Australia/Brisbane",
  CA: "America/Vancouver",
  NZ: "Pacific/Auckland",
  US: "America/Chicago",
  UK: "Europe/London",
};
export const validateAction = (type, state) => {
  switch (type) {
    case "cancelOrder":
      if (!state.checked) return "confirmRefund";
      break;

    case "scheduleShipment":
      if (state.selectionModel.length === 0) return "selectAtLeastOneItem";
      if (!state.currency) return "selectCurrency";
      if (!state.shippingCompany) return "selectShippingCompany";
      if (!state.consignmentNumber) return "provideConsignmentNumber";
      break;

    case "shipItems":
      if (state.selectionModel.length === 0) return "selectAtLeastOneItem";
      if (state.serialNumberItems.some((item) => !item.serialNumber))
        return "missingSerialNumber";
      if (!state.currency) return "selectCurrency";
      if (!state.shippingCompany) return "selectShippingCompany";
      if (!state.trackingNumber) return "provideTrackingNumber";
      if (!state.date.isValid()) return "provideValidDate";
      break;

    case "holdOrder":
      if (!state.date.isValid()) return "provideValidHoldDate";
      break;

    case "markAsDelivered":
      if (state.selectionModel.length === 0) return "selectAtLeastOneItem";
      if (!state.date.isValid()) return "provideValidDeliveredDate";
      break;

    case "changeSerialNumber":
      const serialNumbers = {};
      let error = false;
      state.items.forEach((item) => {
        if (!item.serialNumber) return (error = true);
        serialNumbers[item.id] = item.serialNumber;
      });
      if (error) return "missingSerialNumber";
      break;

    case "forceStatusUpdate":
      if (!state.status) return "selectShippingStatus";
      if (state.status === "SHIPPED" && !state.shippedDate)
        return "provideValidDate";
      if (
        state.status === "DELIVERD" &&
        (!state.shippedDate || !state.deliveredDate)
      )
        return "provideValidDate";
      break;

    case "changeWarehouse":
      if (!state.warehouse) return "selectWarehouse";
      break;

    case "swapProduct":
      if (!state.pid) return "selectProduct";
      if (!state.warehouse) return "selectWarehouse";
      if (!state.qty) return "provideQuantity";
      break;

    default:
      break;
  }
  return null;
};

export const createRequestDetails = (type, data, state) => {
  const formatDateString = (date) => {
    return date.$x?.$timezone
      ? date.$d
      : dayjs(date.$d).tz(officeTimezoneMap[data.ordercountrycode]).format();
  };

  const formatDateTimeZone = (date) => {
    return date.$x?.$timezone || officeTimezoneMap[data.ordercountrycode];
  };

  switch (type) {
    case "resendOrderConfirmation":
    case "cancelOrder":
      return [
        {
          wixOrderNumber: `${data.uid.split("-")[0]}-${data.uid.split("-")[1]}`,
        },
      ];

    case "scheduleShipment":
      return state.selectionModel.map((uid) => ({
        uid: uid,
        serialNumber:
          state.serialNumberItems.find((item) => item.id === uid)
            ?.serialNumber ||
          data.items.find((item) => item.id === uid)?.serialNumber ||
          "",
        carrierName: state.shippingCompany,
        consignmentNumber: state.consignmentNumber || state.trackingNumber,
        shippingQuotedCost: parseFloat(
          state.shippingCostItems.find((item) => item.id === uid)?.shippingCost
        ),
        shippingCurrency: state.currency,
      }));

    case "shipItems":
      return state.selectionModel.map((uid) => ({
        uid: uid,
        serialNumber:
          state.serialNumberItems.find((item) => item.id === uid)
            ?.serialNumber ||
          data.items.find((item) => item.id === uid)?.serialNumber ||
          "",
        carrierName: state.shippingCompany,
        trackingNumber: state.trackingNumber,
        shippingQuotedCost: parseFloat(
          state.shippingCostItems.find((item) => item.id === uid)?.shippingCost
        ),
        shippingCurrency: state.currency,
        shippedDate: formatDateString(state.date),
        shippedDateTimeZone: formatDateTimeZone(state.date),
      }));

    case "endHold":
      return [
        {
          wixOrderNumber: data.wixOrderNumber,
          isOnHold: false,
        },
      ];
    case "holdOrder":
      return [
        {
          wixOrderNumber: data.wixOrderNumber,
          holdUntilDate: formatDateString(state.date),
          holdUntilDateTimeZone: formatDateTimeZone(state.date),
          isOnHold: true,
        },
      ];

    case "markAsDelivered":
      return state.selectionModel.map((uid) => ({
        uid: uid,
        deliveredDate: formatDateString(state.date),
        deliveredDateTimeZone: formatDateTimeZone(state.date),
      }));

    case "changeSerialNumber":
      return state.items.map((item) => ({
        uid: item.id,
        serialNumber: item.serialNumber,
      }));

    case "changeWarehouse":
      return [
        {
          uid: state.uid,
          warehouse: state.warehouse,
        },
      ];

    case "swapProduct":
      return [
        {
          uid: state.uid,
          pid: state.pid,
          qty: parseInt(state.qty),
          model: state.model,
          warehouse: state.warehouse,
        },
      ];

    case "updateShippingAnalytics":
      return [
        {
          ...state,
          shippedDate: !!state.shippedDate
            ? formatDateString(state.shippedDate)
            : null,
          shippedDateTimeZone: !!state.shippedDate
            ? formatDateTimeZone(state.shippedDate)
            : null,
          deliveredDate: !!state.deliveredDate
            ? formatDateString(state.deliveredDate)
            : null,
          deliveredDateTimeZone: !!state.deliveredDate
            ? formatDateTimeZone(state.deliveredDate)
            : null,
        },
      ];

    case "forceStatusUpdate":
      const { shippedDate, deliveredDate } = state;

      return [
        {
          uid: state.uid,
          shippingStatus: state.status,
          ...((state.status === "SHIPPED" || state.status === "DELIVERED") && {
            shippedDate: formatDateString(shippedDate),
            shippedDateTimeZone: formatDateTimeZone(shippedDate),
          }),
          ...(state.status === "DELIVERED" && {
            deliveredDate: formatDateString(deliveredDate),
            deliveredDateTimeZone: formatDateTimeZone(deliveredDate),
          }),
        },
      ];
    case "changeSupplierNotes":
      return [
        {
          uid: state.uid,
          internalSupplierNotes: state.internalSupplierNotes,
        },
      ];
    // Add additional cases as necessary for your application
    default:
      return [];
  }
};
