import React, { useEffect } from "react";
import Drawer from "../../../components/AppBarWithDrawer/Drawer";
import DrawerHeader from "../../../components/AppBarWithDrawer/DrawerHeader";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { Link } from "./Link";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useRecoilValue } from "recoil";
import { permissionsState } from "../../../atoms/atoms";
import { useNavigate, useLocation } from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useCookies } from "react-cookie";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import TocIcon from "@mui/icons-material/Toc";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const images = require.context("../../../images/general", true, /\.svg$/);
const imageSrc = images("./Commercial-Dehydrator-neg-type.svg");

const ConditionalWrapper = ({ children }) => {
  const checkVisibleChildren = React.useCallback((children) => {
    return React.Children.toArray(children).some((child) => {
      if (child.type === Link) {
        // For Link components, check if they should be visible based on permissions
        return child.props.permissions
          ? Object.values(child.props.permissions).some(Boolean)
          : true;
      }
      if (child.props && child.props.children) {
        return checkVisibleChildren(child.props.children);
      }
      return false;
    });
  }, []);
  const [hasVisibleChildren, setHasVisibleChildren] = React.useState(() => {
    // Perform initial check synchronously
    return checkVisibleChildren(children);
  });
  React.useEffect(() => {
    // Update state if children change
    setHasVisibleChildren(checkVisibleChildren(children));

    const observer = new MutationObserver(() => {
      setHasVisibleChildren(checkVisibleChildren(children));
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [children, checkVisibleChildren]);

  return hasVisibleChildren ? children : null;
};

export const NavigationDrawer = ({ open, handleDrawerClose }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const signOut = useSignOut();

  const [, setCookies] = useCookies(["roleID"]);
  const permissions = useRecoilValue(permissionsState);

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const isInventoryPage = location.pathname.startsWith("/inventoryOrders");
  const [expandedInventory, setExpandedInventory] = React.useState(
    !isInventoryPage || "panel1"
  );

  const navbarMainBackgroundColour =
    process.env.REACT_APP_API_ORDER_LINK_URL?.includes("mclellanhill-gcp")
      ? "#3f4b52"
      : process.env.REACT_APP_DEPLOYMENT_SIDEBAR_COLOR || "#6c2929";

  const NavbarSecondaryBackgroundColour =
    process.env.REACT_APP_API_ORDER_LINK_URL?.includes("mclellanhill-gcp")
      ? "#2d353b"
      : process.env.REACT_APP_DEPLOYMENT_SIDEBAR_COLOR || "#2d353b";

  const handleNavigate = (url) => {
    if (matchesSM) handleDrawerClose();
    navigate(url);
  };

  const handleExpandInventory = (panel) => (event, isExpanded) => {
    setExpandedInventory(isExpanded ? panel : false);
  };

  return (
    <Drawer
      variant={"permanent"}
      open={open}
      sx={{
        ...(matchesSM &&
          open && {
            zIndex: 10000,
            position: "absolute",
            width: "100vw",
            height: "100vh",
            ".MuiDrawer-paper": {
              width: "100%",
            },
          }),
      }}
    >
      <DrawerHeader
        sx={{
          minHeight: "40px !important",
          backgroundColor: "#2D353B",
          borderBottom: "rgba(0, 0, 0, 0.12) 1px solid",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          py: 1,
        }}
      >
        <Box
          sx={{
            mr: { xs: 0, sm: "15px" },
            maxWidth: "180px",
          }}
        >
          <img
            style={{
              width: "100%",
              padding: "10px",
            }}
            src={imageSrc}
            alt="Commercial Dehydrators Main Logo"
          />
        </Box>

        <IconButton
          onClick={handleDrawerClose}
          sx={{
            position: "absolute",
            right: 0,
          }}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon sx={{ color: "white" }} />
          ) : (
            <ChevronLeftIcon sx={{ color: "white" }} />
          )}
        </IconButton>
      </DrawerHeader>

      <List
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: navbarMainBackgroundColour,
          py: 0,
        }}
      >
        <Box mt={1}>
          <Link
            open={open}
            link={{
              header: "Home",
              url: "/home",
              icon: <HomeIcon />,
            }}
            handleOnClick={() => handleNavigate("/home")}
          />
          <Link
            open={open}
            link={{
              header: "Customer Orders",
              url: "/customerOrders",
              icon: <LocalShippingIcon />,
            }}
            handleOnClick={() => handleNavigate("/customerOrders")}
            permissions={permissions?.customerOrders?.columns}
            permissionsRequired
          />
          <Link
            open={open}
            link={{
              header: "Offline Orders",
              url: "/offlineOrders",
              icon: <WifiOffIcon />,
            }}
            handleOnClick={() => handleNavigate("/offlineOrders")}
            permissions={permissions?.offlineOrders?.orderDetails?.columns}
            permissionsRequired
          />
          <Link
            open={open}
            link={{
              header: "Accounting",
              url: "/accounting/orderReimbursements/pending",
              icon: <RequestQuoteIcon />,
            }}
            handleOnClick={() =>
              handleNavigate("/accounting/orderReimbursements/pending")
            }
            permissions={
              permissions?.accounting?.orderReimbursements?.pending?.columns
            }
            permissionsRequired
          />
          {!open && (
            <ConditionalWrapper>
              <Divider sx={{ background: "white" }} />
              {permissions?.inventoryOrders?.orders?.actions
                ?.canBuildOrders && (
                <Link
                  open={open}
                  link={{
                    header: "Build Order",
                    url: "/inventoryOrders/buildOrder",
                    icon: <AddCircleOutlineIcon />,
                  }}
                  handleOnClick={() =>
                    handleNavigate("/inventoryOrders/buildOrder")
                  }
                  sx={{ pl: 4 }}
                  isSubItem
                />
              )}
              <Link
                open={open}
                link={{
                  header: "Current Stock",
                  url: "/inventoryOrders/currentStock",
                  icon: <WarehouseIcon />,
                }}
                handleOnClick={() =>
                  handleNavigate("/inventoryOrders/currentStock")
                }
                permissions={
                  permissions?.inventoryOrders?.currentStock?.columns
                }
                permissionsRequired
                sx={{ pl: 4 }}
                isSubItem
              />
              <Link
                open={open}
                link={{
                  header: "Stock Predictions",
                  url: "/inventoryOrders/stockPredictions",
                  icon: <EmojiObjectsIcon />,
                }}
                handleOnClick={() =>
                  handleNavigate("/inventoryOrders/stockPredictions")
                }
                permissions={permissions?.inventoryOrders?.predictions?.columns}
                permissionsRequired
                sx={{ pl: 4 }}
                isSubItem
              />
              <Link
                open={open}
                link={{
                  header: "Orders",
                  url: "/inventoryOrders/stockOrders",
                  icon: <TocIcon />,
                }}
                handleOnClick={() =>
                  handleNavigate("/inventoryOrders/stockOrders")
                }
                permissions={permissions?.inventoryOrders?.orders?.columns}
                permissionsRequired
                sx={{ pl: 4 }}
                isSubItem
              />
              <Divider sx={{ mt: 2, background: "white" }} />
            </ConditionalWrapper>
          )}

          {open && (
            <ConditionalWrapper>
              <Accordion
                sx={{
                  background: navbarMainBackgroundColour,
                  borderColor: navbarMainBackgroundColour,
                }}
                expanded={expandedInventory === "panel1"}
                onChange={handleExpandInventory("panel1")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: "rgb(197,197,197)" }} />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    backgroundColor: navbarMainBackgroundColour,
                    color: "rgb(197,197,197)",
                    mx: 2,
                    px: 2,
                  }}
                >
                  <WarehouseIcon
                    sx={{
                      color: "rgb(197,197,197)",
                      fontSize: "1.5rem",
                      mr: 2,
                    }}
                  />
                  <Typography sx={{ color: "rgba(197,197,197,0.7)" }}>
                    Inventory
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor: NavbarSecondaryBackgroundColour }}
                >
                  <List component="div" disablePadding>
                    {permissions.inventoryOrders?.orders?.actions
                      ?.canBuildOrders && (
                      <Link
                        open={open}
                        link={{
                          header: "Build Order",
                          url: "/inventoryOrders/buildOrder",
                          icon: <AddCircleOutlineIcon />,
                        }}
                        handleOnClick={() =>
                          handleNavigate("/inventoryOrders/buildOrder")
                        }
                        sx={{ pl: 4 }}
                        isSubItem
                      />
                    )}
                    <Link
                      open={open}
                      link={{
                        header: "Current Stock",
                        url: "/inventoryOrders/currentStock",
                        icon: <WarehouseIcon />,
                      }}
                      handleOnClick={() =>
                        handleNavigate("/inventoryOrders/currentStock")
                      }
                      permissions={
                        permissions?.inventoryOrders?.currentStock?.columns
                      }
                      permissionsRequired
                      sx={{ pl: 4 }}
                      isSubItem
                    />
                    <Link
                      open={open}
                      link={{
                        header: "Stock Predictions",
                        url: "/inventoryOrders/stockPredictions",
                        icon: <EmojiObjectsIcon />,
                      }}
                      handleOnClick={() =>
                        handleNavigate("/inventoryOrders/stockPredictions")
                      }
                      permissions={
                        permissions?.inventoryOrders?.predictions?.columns
                      }
                      permissionsRequired
                      sx={{ pl: 4 }}
                      isSubItem
                    />
                    <Link
                      open={open}
                      link={{
                        header: "Orders",
                        url: "/inventoryOrders/stockOrders",
                        icon: <TocIcon />,
                      }}
                      handleOnClick={() =>
                        handleNavigate("/inventoryOrders/stockOrders")
                      }
                      permissions={
                        permissions?.inventoryOrders?.orders?.columns
                      }
                      permissionsRequired
                      sx={{ pl: 4 }}
                      isSubItem
                    />
                  </List>
                </AccordionDetails>
              </Accordion>
            </ConditionalWrapper>
          )}

          {(permissions?.name === "Super Admin" ||
            permissions?.general?.addOthers) && (
            <Link
              open={open}
              link={{
                header: "Staff & Roles",
                url: "/iam",
                icon: <PersonIcon />,
              }}
              handleOnClick={() => handleNavigate("/iam")}
            />
          )}
        </Box>
        <Typography
          color="lightgrey"
          sx={{
            fontFamily: "system-ui",
            fontSize: "0.7em",
            whiteSpace: "pre-wrap",
            maxHeight: "82px", // limit to approx 4 lines of text
            overflow: 'auto', // scroll bar will show if needed
            px: 2,
            py: 1,
          }}
        >
          {!process.env.REACT_APP_API_ORDER_LINK_URL?.includes(
            "mclellanhill-gcp"
          ) && process.env.REACT_APP_LAST_COMMIT_INFO}
        </Typography>

        <Box>
          <Divider sx={{ my: 1, opacity: 0.2 }} />

          <Link
            open={open}
            link={{
              header: "Settings",
              url: "/settings",
              icon: <SettingsIcon />,
            }}
            handleOnClick={() => navigate("/settings")}
          />

          <Link
            open={open}
            link={{
              header: "Log out",
              icon: <LogoutIcon />,
            }}
            handleOnClick={() => {
              setCookies("roleID", "");
              signOut();
              navigate("/login");
            }}
          />
        </Box>
      </List>
    </Drawer>
  );
};
