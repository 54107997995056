import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function createMessage(orderID, conversationID, content, mentions, files) {
  return axios
    .post(`${API_ORDER_LINK_URL}/messages`, {
      orderID,
      conversationID,
      content,
      mentions,
      files,
    })
    .then((response) => response.data);
}

function getMessagesByConversationID(conversationID) {
  return axios
    .get(`${API_ORDER_LINK_URL}/messages?conversationID=${conversationID}`)
    .then((response) => response.data);
}
function getMessagesByUserID() {
  return axios
    .get(`${API_ORDER_LINK_URL}/messages`)
    .then((response) => response.data);
}

function updateMessages(messageIDs, actionType) {
  return axios
    .patch(`${API_ORDER_LINK_URL}/messages`, {
      messageIDs: messageIDs,
      actionType: actionType,
    })
    .then((response) => response.data);
}
function deleteMessage(messageID) {
  return axios
    .delete(`${API_ORDER_LINK_URL}/messages?messageID=${messageID}`)
    .then((response) => response.data);
}

export {
  getMessagesByUserID,
  createMessage,
  updateMessages,
  getMessagesByConversationID,
  deleteMessage,
};
