import React from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { orderSettingsState, permissionsState } from "../../../../atoms/atoms";
import { useDateFormat } from "../../../../contexts/DateFormatContext";
import { renderCellComponent } from "../../../../components/Datagrid/utils";
import ProductActionsMenu from "../../components/ProductActionsMenu";
import NewOrRefurbDialog from "./NewOrRefurb";
import ProductImage from "./ProductImage";
import Loading from "../../../../components/Loading";

const images = require.context(
  "../../../../images/flags-large",
  true,
  /\.webp$/
);

const Details = ({ product, longestLabelLength }) => {
  const { dateFormat } = useDateFormat();
  const orderSettings = useRecoilValue(orderSettingsState);

  return (
    <Grid container spacing={0.2}>
      {!orderSettings?.columnsDisplayed && <Loading />}
      {orderSettings?.columnsDisplayed?.map((field) => {
        if (
          field.level === "item" &&
          (product[field.fieldName] || field.dataType === "boolean")
        ) {
          return (
            <Grid
              container
              item
              xs={12}
              key={field.fieldName}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              <Box
                sx={
                  field.dataType === "boolean"
                    ? { minWidth: "max-content", alignItems: "center" }
                    : {}
                }
              >
                <Typography
                  fontWeight="bold"
                  sx={{
                    mr: { xs: 0.3, sm: 1 },
                    minWidth: { xs: "auto", sm: `${longestLabelLength}ch` },
                    wordBreak: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                  }}
                >
                  {field.label}:
                </Typography>
              </Box>
              <Box
                sx={{
                  minWidth: "50%",
                  wordBreak: "break-word",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                }}
              >
                {renderCellComponent(
                  field,
                  field.fieldName,
                  { value: product[field.fieldName], row: product },
                  null,
                  dateFormat
                )}
              </Box>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};

const FlagImage = ({ flag, isMobile }) => {
  const getImage = (imageName) => {
    try {
      return images(`./${imageName}`);
    } catch (e) {
      console.warn(`Could not find image: ${imageName}`);
      return null;
    }
  };

  const renderImage = (src) => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={"100%"}
      width="100%"
    >
      <img
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
        src={src}
        alt={flag.slice(0, -4)}
        loading="lazy"
      />
    </Box>
  );

  return renderImage(getImage(flag));
};
const ProductDetailsCard = ({
  product,
  handleActionSelect,
  longestLabelLength,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const permissions = useRecoilValue(permissionsState);

  const shouldDisplayProductActionsMenu =
    product.pid.split("-")[0] !== "XTRA" &&
    !product["canceled"] &&
    (product["ordersource"] !== "wf-offline" ||
      (product["ordersource"] === "wf-offline" &&
        product["orderpaymentstatus"] === "PAID"));

  return (
    <Paper sx={{ p: 2, mb: 1 }}>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="stretch"
      >
        {!isMobile && (
          <Box
            width={160}
            mr={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ProductImage product={product} />
          </Box>
        )}
        <Box flex={1} display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {product.descriptionshort}
              </Typography>
              {product.uid.split("-").includes("CMDH") && (
                <NewOrRefurbDialog
                  product={product}
                  permissions={permissions}
                />
              )}
            </Box>
          </Box>
          <Box mt={2} flex={1}>
            <Details
              longestLabelLength={longestLabelLength}
              product={product}
            />
          </Box>
        </Box>
        {!isMobile && (
          <Box display="flex" flexDirection="column" ml={2}>
            {shouldDisplayProductActionsMenu && (
              <ProductActionsMenu
                onActionSelect={handleActionSelect}
                product={product}
              />
            )}
            <Box
              sx={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                width: 150,
              }}
            >
              <FlagImage flag={product.flag} isMobile={isMobile} />
            </Box>
          </Box>
        )}
      </Box>
      {isMobile && (
        <Box
          display="flex"
          mt={2}
          justifyContent="space-between"
          flexDirection="column"
          gap={2}
        >
          <Box width="40%" display="flex" justifyContent="center">
            <ProductImage product={product} />
          </Box>
          <Box
            width="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FlagImage flag={product.flag} isMobile={isMobile} />
          </Box>
          {shouldDisplayProductActionsMenu && (
            <Box display="flex" justifyContent="flex-start">
              <ProductActionsMenu
                onActionSelect={handleActionSelect}
                product={product}
              />
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default ProductDetailsCard;
