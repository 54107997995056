import React, { useState, useEffect } from "react";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ComputerIcon from "@mui/icons-material/Computer";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

const IconMap = {
  Manager: <BusinessCenterIcon fontSize={"large"} />,
  Developer: <ComputerIcon fontSize={"large"} />,
  Support: <SupportAgentIcon fontSize={"large"} />,
  Engineer: <EngineeringIcon fontSize={"large"} />,
  Employee: <AccountCircleIcon fontSize={"large"} />,
  Logistics: <LocalShippingIcon fontSize={"large"} />,
  Maintenance: <CleaningServicesIcon fontSize={"large"} />,
  Retail: <StorefrontIcon fontSize={"large"} />,
};
const IconSelector = ({ selectedIcon, handleSelectedIcon }) => {
  const [icons, setIcons] = useState(null);
  const [icon, setIcon] = useState("");

  useEffect(() => {
    if (selectedIcon) setIcon(selectedIcon);
    const allIcons = Object.keys(IconMap)
      .sort()
      .map((key) => key);
    setIcons(allIcons);
  }, []);

  const handleChange = (e) => {
    handleSelectedIcon(e.target.value);
    setIcon(e.target.value);
  };

  return (
    <>
      <FormControl sx={{ width: 140 }}>
        <InputLabel>Role Icon</InputLabel>
        <Select label="Role Icon" value={icon} onChange={handleChange}>
          {icons?.map((name) => (
            <MenuItem key={name} value={name}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {IconMap[name]}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default IconSelector;
