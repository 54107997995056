import React, { useRef, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  customerOrdersRowIndexState,
  permissionsState,
} from "../../atoms/atoms";
import { StyledDataGrid } from "./StyledDataGrid";
import CustomToolbar from "./CustomToolBar";
import { CustomFooter } from "./CustomFooter";
import { getRowClassName } from "./utils";
import { useColumnManagement } from "./hooks/useColumnManagement";
import { useRowSelection } from "./hooks/useRowSelection";
import { useGroupExpansion } from "./hooks/useGroupExansion";
import { isGroupRow } from "../../utils/mui";
import { useDateFormat } from "../../contexts/DateFormatContext";
import api from "js-cookie";

export default function DataGrid(props) {
  const {
    dataGridRef,
    rows,
    dataGridName,
    handleRowSelection,
    rowID,
    processRowUpdate,
    onProcessRowUpdateError,
    components,
    loading,
    noBorder,
    borderTop,
    footer,
    componentProps,
    groupingModel,
    onGroupingModelChange,
    groupingColDef,
    getDetailPanelHeight,
    getDetailPanelContent,
    onContextMenu,
  } = props;

  const [customerOrdersRowIndex, setCustomOrderRowIndex] = useRecoilState(
    customerOrdersRowIndexState
  );
  const navigate = useNavigate();
  const role = useRecoilValue(permissionsState);

  const gridColumns = useRef([]);
  const apiRef = useGridApiRef();
  const [isInitialised, setIsInitialised] = useState(false);

  const { dateFormat } = useDateFormat();

  const {
    columnVisibilityModel,
    pinnedColumnsLeft,
    isPinned,
    handleUnpinAllColumns,
    handlePinAllColumns,
    handlePinnedColumnsChange,
    onColumnOrderChange,
    onColumnVisibilityModelChange,
    onColumnWidthChange,
  } = useColumnManagement(
    { ...props, role, navigate, dateFormat },
    apiRef,
    gridColumns
  );
  const {
    rowSelectionModel,
    isRowSelectable,
    handleRowSelectionModelChange,
    columns,
  } = useRowSelection(props, apiRef, gridColumns);

  const handleGridReady = useCallback(() => {
    if (apiRef.current && Object.keys(apiRef.current).length > 0) {
      if (dataGridRef) {
        dataGridRef.current = apiRef.current;
      }

      if (
        dataGridName === "customerOrders" &&
        customerOrdersRowIndex !== null &&
        customerOrdersRowIndex !== 0
      ) {
        setTimeout(() => {
          if (apiRef.current.scrollToIndexes) {
            apiRef.current.scrollToIndexes({
              rowIndex: customerOrdersRowIndex,
            });
            setCustomOrderRowIndex(0);
          }
        }, 100);
      }
    }
  }, [
    dataGridName,
    customerOrdersRowIndex,
    apiRef,
    dataGridRef,
    setCustomOrderRowIndex,
  ]);

  const getRowHeight = useCallback((params) => {
    if (isGroupRow(params.model)) return 50;
    return 85;
  }, []);

  const { expandedGroups } = useGroupExpansion(props, apiRef, isInitialised);

  useEffect(() => {
    if (apiRef.current) setIsInitialised(true);
  }, [apiRef]);

  const handleContextMenu = (event) => {
    if (onContextMenu) {
      const isTextSelected = window.getSelection().toString().length > 0;
      const targetIsText =
        event.target.nodeName === "INPUT" ||
        event.target.nodeName === "TEXTAREA" ||
        event.target.isContentEditable;

      if (!isTextSelected && !targetIsText) {
        event.preventDefault();
        const rowId = event.currentTarget.getAttribute("data-id");
        const rowData = rows.find((row) => row[rowID] === rowId);
        const { clientX, clientY } = event;
        onContextMenu({ rowData, position: { x: clientX, y: clientY } });
      }
    }
  };

  return (
    <>
      {gridColumns.current?.length > 0 &&
        columnVisibilityModel &&
        pinnedColumnsLeft && (
          <StyledDataGrid
            columns={columns()}
            rows={rows}
            disableRowSelectionOnClick
            checkboxSelection={!!handleRowSelection && groupingModel}
            isRowSelectable={isRowSelectable}
            disableSelectionOnClick
            sx={{
              borderWidth: noBorder ? 0 : 1,
              borderTopWidth: borderTop ? 1 : 0,
              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                  display: "none",
                },
            }}
            loading={loading}
            getRowId={(row) => row[rowID]}
            getRowClassName={getRowClassName}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            onColumnWidthChange={onColumnWidthChange}
            onColumnOrderChange={onColumnOrderChange}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            disableColumnFilter
            slots={{
              toolbar: componentProps?.toolbar?.handleSearch && CustomToolbar,
              footer: footer || CustomFooter,
            }}
            slotProps={{
              ...componentProps,
              row: {
                onContextMenu: handleContextMenu,
              },
              toolbar: {
                ...componentProps?.toolbar,
                onUnpinAllColumns: handleUnpinAllColumns,
                onPinAllColumns: handlePinAllColumns,
                isPinned: isPinned,
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            initialState={{
              rowGrouping: {
                model: groupingModel,
                expandedGroupIds: expandedGroups,
              },

              pinnedColumns: {
                left: pinnedColumnsLeft,
                right: ["actions"],
              },
            }}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            componentsProps={{
              columnsPanel: {
                sx: {
                  "& .MuiDataGrid-panelFooter button:first-child": {
                    display: "none",
                  },
                },
              },
            }}
            getRowHeight={getRowHeight}
            apiRef={apiRef}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}
            components={components}
            onStateChange={handleGridReady}
            groupingModel={groupingModel}
            onGroupingModelChange={onGroupingModelChange}
            groupingColDef={groupingColDef}
            experimentalFeatures={{ rowGrouping: true }}
            onCellContextMenu={handleContextMenu}
          />
        )}
    </>
  );
}
