import Box from "@mui/material/Box";
import React, { useEffect, useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";

import TextField from "@mui/material/TextField";
import ReusableTable from "../../../components/Table";
import { inventorySuppliers } from "../../../common";
import Autocomplete from "@mui/material/Autocomplete";
import SerialNumberInput from "../components/SerialNumberInput";
import { CircularProgress, Typography } from "@mui/material";
import { getProductsByCountryAndWarehouse } from "../../../api/Products";

const EditInventoryOrder = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);
  const [loading, setLoading] = useState(false);

  const handleTableChange = useCallback(
    (value, field, id) => {
      const updatedRows = state.rows.map((row) => {
        if (row.id === id) {
          let updatedRow = { ...row, [field]: value };
          // If the changed field is 'inventoryitem' or 'pid', update the other field accordingly
          if (field === "inventoryitem" || field === "pid") {
            const selectedProduct = state.products.find((product) =>
              field === "inventoryitem"
                ? product.description === value
                : product.pid === value
            );
            if (selectedProduct) {
              updatedRow = {
                ...updatedRow,
                inventoryitem: selectedProduct.description,
                pid: selectedProduct.pid,
              };
            }
          }

          return updatedRow;
        }
        return row;
      });

      setState((prevState) => ({ ...prevState, rows: updatedRows }));
    },
    [state.rows, state.products, setState]
  );

  useEffect(() => {
    const fetchProducts = async () => {
      if (data.orders && data.orders.length > 0) {
        const warehouse = data.orders[0].warehouse;
        setLoading(true);
        try {
          const countryCode = warehouse.slice(0, 2);
          const { countryProducts } = await getProductsByCountryAndWarehouse(
            countryCode,
            warehouse
          );
          setState((prevState) => ({
            ...prevState,
            products: countryProducts,
            rows: data.orders.map((order) => ({
              ...order,
              serialnumbers:
                typeof order.serialnumbers === "string"
                  ? order.serialnumbers.split("\n").filter((sn) => sn)
                  : order.serialnumbers,
            })),
          }));
        } catch (error) {
          console.error("Error fetching products:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchProducts();
  }, [data.orders, setState]);

  const columns = [
    {
      field: "rownumber",
      headerName: "Row Number",
      width: 100,
    },
    {
      field: "warehouseordernumber",
      headerName: "Warehouse Order Number",
      width: 100,
    },
    {
      field: "deleteRow",
      headerName: "Delete Row",
      width: 100,
      editable: true,
      type: "boolean",
    },
    {
      field: "controlboardpanelmodel",
      headerName: "Control Board Panel Model",
      width: 100,
      editable: true,
    },
    {
      field: "serialnumbers",
      headerName: "Serial Numbers",
      width: 400,
      editable: true,
      renderEditCell: (params) => (
        <SerialNumberInput
          value={params.row.serialnumbers || []}
          onChange={handleTableChange}
          rowId={params.row.id}
        />
      ),
    },
    {
      field: "dateordered",
      headerName: "Date Ordered",
      width: 310,
      editable: true,
      type: "date",
    },
    {
      field: "inventoryitem",
      headerName: "Inventory Item",
      width: 350,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          fullWidth
          options={state.products?.map((product) => product.description) || []}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Inventory Item" />
          )}
          value={row.inventoryitem || null}
          onChange={(_, newValue) =>
            handleTableChange(newValue, "inventoryitem", row.id)
          }
          isOptionEqualToValue={(option, value) =>
            option === value || (option === "" && value === null)
          }
        />
      ),
    },
    {
      field: "pid",
      headerName: "PID",
      width: 350,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          fullWidth
          options={state.products?.map((product) => product.pid) || []}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="PID" />
          )}
          value={row.pid || null}
          onChange={(_, newValue) => handleTableChange(newValue, "pid", row.id)}
          isOptionEqualToValue={(option, value) =>
            option === value || (option === "" && value === null)
          }
        />
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 400,
      editable: true,
      renderEditCell: ({ row }) => (
        <TextField
          multiline
          rows={3}
          value={row.notes || ""}
          onChange={(e) => handleTableChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },
    {
      field: "quantityordered",
      headerName: "Quantity Ordered",
      width: 100,
      editable: true,
      type: "number",
    },
    {
      field: "supplier",
      headerName: "Supplier",
      width: 250,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          value={row.supplier || null}
          onChange={(event, newValue) =>
            handleTableChange(newValue, "supplier", row.id)
          }
          options={inventorySuppliers}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
          size="small"
          fullWidth
        />
      ),
    },
    {
      field: "tradefinanceorder",
      headerName: "Trade Finance Order",
      width: 100,
      editable: true,
      type: "boolean",
    },
  ];

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      rows: data.orders.map((order) => ({
        ...order,
        serialnumbers:
          typeof order.serialnumbers === "string"
            ? order.serialnumbers.split("\n")
            : order.serialnumbers,
      })),
    }));
  }, [data.isNotBlank, setState]);

  const handleAdd = useCallback(() => {
    const newRows = [
      ...state.rows,
      { id: state.rows.length, description: "", pid: "", qty: 1 },
    ];
    setState((prevState) => ({ ...prevState, rows: newRows }));
  }, [state.rows, setState]);

  const handleDelete = useCallback(
    (rowID) => {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.filter((row) => row.id !== rowID),
      }));
    },
    [setState]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ margin: "auto", width: "100%", mt: 4 }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : state.products && state.rows?.length > 0 ? (
          <ReusableTable
            columns={columns}
            rows={state.rows}
            onCellChange={handleTableChange}
            verticalHeaders={true}
            // handleAdd={handleAdd}
            // handleDelete={handleDelete}
          />
        ) : (
          <Typography color="textSecondary">
            No data available or products not loaded.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default EditInventoryOrder;
