import pusher from "../pusherClient";

export const subscribeToConversation = (
  conversationID,
  userID,
  handleNewMessage,
  handleDeleteMessage
) => {
  let channel = pusher.channels.find(`conversation-${conversationID}`);

  if (!channel) {
    channel = pusher.subscribe(`conversation-${conversationID}`);
  }

  const handleNewMessageEvent = ({ message }) => {
    if (message.userID !== userID) {
      const updatedMessage = { ...message, read: [...message.read, userID] };
      handleNewMessage(updatedMessage);
    }
  };

  const handleDeleteMessageEvent = ({ messageID, deletedBy }) => {
    if (userID !== deletedBy) handleDeleteMessage(messageID);
  };

  channel.bind("new-message", handleNewMessageEvent);
  channel.bind("delete-message", handleDeleteMessageEvent);

  return () => {
    channel.unbind("new-message", handleNewMessageEvent);
    channel.unbind("delete-message", handleDeleteMessageEvent);
    pusher.unsubscribe(`conversation-${conversationID}`);
  };
};
