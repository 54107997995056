import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { currencies, shippingCompanies } from "../../../common";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";
import Table from "../../../components/Table";

const ShipItems = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({
      shippedDate: new dayjs(),
      shippingCurrency:
        currencies.find(
          (currency) =>
            currency.substring(0, 2) ===
            data.orders[0]?.warehouse.substring(0, 2)
        ) || "USD",
      rows: data.orders.map((order) => ({
        ...order,
        carriername:
          order.carriername ||
          (order?.warehouse === "AUGC"
            ? "TNT-AU"
            : order?.warehouse === "CAVC"
            ? "Acumen Logistics"
            : order?.warehouse === "NZAKL"
            ? "TSS Sensitive Freight NZ"
            : ""),
      })),
    });
  }, [data.orders, setState]);

  const handleCellChange = (value, field, id) => {
    setState((prevState) => {
      const updatedRows = prevState.rows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      return { ...prevState, rows: updatedRows };
    });
  };

  const columns = [
    {
      field: "ordernumber",
      headerName: "Order Number",
      width: 120,
    },
    {
      field: "buyerfullname",
      headerName: "Buyer Full Name",
      width: 136,
    },
    {
      headerName: "Model",
      field: "descriptionshort",
    },
    { headerName: "Qty", field: "qty", width: 40 },
    {
      headerName: "Shipping Cost *",
      field: "shippingquotedcost",
      editable: true,
    },
    {
      headerName: "Shipping Company *",
      field: "carriername",
      width: 300,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          value={params.row.carriername}
          onChange={(event, newValue) => {
            const updatedRows = state.rows.map((row) =>
              row.uid === params.row.uid
                ? { ...row, carriername: newValue }
                : row
            );
            setState({ ...state, rows: updatedRows });
          }}
          options={shippingCompanies}
          renderInput={(params) => (
            <TextField {...params} sx={{ mt: 0.5 }} size={"small"} />
          )}
        />
      ),
    },
    {
      headerName: "Tracking Number *",
      field: "trackingnumber",
      editable: true,
    },
    {
      headerName: "Serial Number",
      field: "serialnumber",
      editable: true,
    },
  ];

  return (
    <Box>
      <DialogContentText>
        The following order units will be shipped. Please ensure that all fields
        are correctly filled in before proceeding.
      </DialogContentText>
      <Box sx={{ margin: "auto", mt: 2, width: "100%" }}>
        <Table
          columns={columns}
          rows={state.rows}
          onCellChange={handleCellChange}
        />
      </Box>{" "}
      {state.shippingCurrency !== undefined && (
        <Autocomplete
          sx={{ width: 300, mt: 2 }}
          value={state.shippingCurrency}
          onChange={(event, newValue) =>
            setState((prevState) => ({
              ...prevState,
              shippingCurrency: newValue,
            }))
          }
          options={currencies} // Assuming this is an array of strings
          renderInput={(params) => (
            <TextField {...params} required label="Shipping Currency" />
          )}
        />
      )}
      <Box mt={3}>
        <DatePicker
          label={"Shipped Date"}
          value={state.shippedDate}
          onChange={(newValue) =>
            setState((prevState) => ({
              ...prevState,
              shippedDate: dayjs(newValue),
            }))
          }
          startingTimezone={data.orders[0].ordercountrycode}
          required
        />
      </Box>{" "}
    </Box>
  );
};

export default ShipItems;
