import "./App.css";
import Router from "./Router";
import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme";
import { DateFormatProvider } from "./contexts/DateFormatContext";
import initializeConfiguredSentry from "./services/sentry/sentryInit";
initializeConfiguredSentry()

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
