import React from "react";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import { useCookies } from "react-cookie";

import { getAllRoles } from "../../../../../api/Roles";

import TextField from "../../../../../components/TextField";

import Modal from "../../../../../components/Modal";
import ImageCropper from "../../../../../components/ImageCropper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
export default function CreateUserModal({
  handleAddSubmit,
  handleUpdateSubmit,
  isEdit,
  userDetails,
  message,
  userLoading,
  handleCloseModal,
  openModal,
}) {
  const [loading, setLoading] = React.useState(true);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [errors, setErrors] = React.useState({});

  const [roles, setRoles] = React.useState(null);
  const [role, setRole] = React.useState([]);

  const [avatarImage, setAvatarImage] = React.useState(null);

  const [cookies, setCookies] = useCookies(["_auth_state"]);

  const validateFields = () => {
    // ... validation logic for fields, producing an 'errors' object...
    const errors = {
      firstName: !firstName,
      lastName: !lastName,
      email: !email,

      roleID: !roles[role / 10 - 1].id,
    };
    setErrors(errors);
    return Object.values(errors).some((val) => val); // returns false if there are any errors
  };

  const handleSubmit = () => {
    if (!validateFields()) {
      if (!isEdit) {
        const joined = new Date();
        const createdBy = `${cookies["_auth_state"].firstName} ${cookies["_auth_state"].lastName}`;
        handleAddSubmit({
          firstName,
          lastName,
          email,
          roleID: roles[role / 10 - 1].id,
          avatarImage,
          joined,
          createdBy,
        });
      } else {
        handleUpdateSubmit(
          userDetails.id,
          firstName,
          lastName,
          email,
          roles[role / 10 - 1].id,
          avatarImage
        );
      }
    }
  };

  React.useEffect(() => {
    if (openModal) {
      setErrors({});
      setLoading(true);
      getAllRoles().then((rsp) => {
        let i = 10;

        const newPermissions = rsp.permissions.map((role) => {
          const value = i;
          i += 10;

          return { id: role.id, name: role.name, value };
        });

        setRoles(newPermissions);

        setEmail(userDetails?.email || "");
        setFirstName(userDetails?.firstName || "");
        setLastName(userDetails?.lastName || "");
        setAvatarImage(userDetails?.avatarImage || "");

        if (isEdit) {
          const currentPermission = newPermissions.find(
            (role) => role.id === userDetails?.roleID
          );
          setRole(currentPermission ? currentPermission.value : "");
        } else {
          setRole(newPermissions[0].value);
        }
        setLoading(false);
      });
    }
  }, [openModal]);

  const handleImageSave = (dataURL) => {
    setAvatarImage(dataURL);
  };

  return (
    <Modal
      title={isEdit ? "Edit Team Member" : "Add Team Member"}
      openModal={openModal}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
      loading={userLoading}
      errorMsg={message}
    >
      {loading && (
        <Box width={{ xs: "100%", sm: "550px" }}>
          <Box sx={{ textAlign: "center", mb: 1 }}>
            <CircularProgress size={50} />
          </Box>
        </Box>
      )}
      {!loading && (
        <Box width={"100%"} sx={{ overflowX: "hidden" }}>
          <ImageCropper
            margin={"auto"}
            image={avatarImage}
            handleImageSave={handleImageSave}
          />
          {roles && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              m={"auto"}
              width={"100%"}
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="role-select-label">Role*</InputLabel>
                <Select
                  labelId="role-select-label"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  label="Role*"
                  sx={{ borderRadius: "20px" }}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.value}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                sx={{ mb: 2 }}
                label={"First Name"}
                required
                autoComplete="no"
                value={firstName}
                changeValue={setFirstName}
                small
                error={errors.firstName && "Required field."}
              />

              <TextField
                sx={{ mb: 2 }}
                label={"Last Name"}
                required
                value={lastName}
                changeValue={setLastName}
                small
                error={errors.lastName && "Required field."}
              />

              <TextField
                sx={{ mb: 2 }}
                label={"Email"}
                required
                value={email}
                changeValue={setEmail}
                small
                error={errors.email && "Required field."}
              />
            </Box>
          )}
        </Box>
      )}
    </Modal>
  );
}
