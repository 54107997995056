import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const menuItemsConfig = {
  pending: [
    { label: "Add/Edit Costs", action: "editCosts" },
    {
      label: "Mark Ready for Payment",
      action: "markReadyForPayment",
    },
  ],
  readyForPayment: [
    { label: "Mark as Paid", action: "markAsPaid" },
    {
      label: "Unmark Ready for payment",
      action: "unmarkReadyForPayment",
    },
  ],
  paid: [
    {
      label: "Mark as Payment Received",
      action: "markAsPaymentReceived",
    },
    {
      label: "Unmark Items as Paid",
      action: "unmarkItemsAsPaid",
    },
  ],
  completed: [
    {
      label: "Unmark as Payment Received",
      action: "unmarkAsPaymentReceived",
    },
    {
      label: "Edit Supplier Finance Details",
      action: "editSupplierFinanceDetails",
    },
  ],
};

export const DynamicMenu = ({ onActionSelect, currentPage, selectedRows }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = menuItemsConfig[currentPage] || [];

  return (
    <div>
      {selectedRows && (
        <Button
          id="dynamic-menu-button"
          aria-controls={open ? "dynamic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
        >
          Actions
        </Button>
      )}
      <Menu
        id="dynamic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "dynamic-menu-button",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onActionSelect(item.action);
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
