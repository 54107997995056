import React from "react";
import { Paper, Box, Typography, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import { orderSettingsState } from "../../../../atoms/atoms";
import { useDateFormat } from "../../../../contexts/DateFormatContext";
import { formatDate } from "../../../../utils/dateFormatter";

import ProductDetailsCard from "../ProductsDetailsCard/ProductDetailsCard";
import OrderBanner from "../../components/OrderBanner";
import { OrderField } from "./OrderField";
import { OrderActions } from "./OrderActions";

const ProductDetails = ({ orderID, order, products, handleActionSelect }) => {
  const { dateFormat } = useDateFormat();
  const orderSettings = useRecoilValue(orderSettingsState);

  const shouldDisplayProductActionsMenu =
    (!order["canceled"] && order["ordersource"] !== "wf-offline") ||
    (order["ordersource"] === "wf-offline" &&
      order["orderpaymentstatus"] === "PAID");

  const shouldDisplayOfflineOrderActionsMenu =
    !order["canceled"] &&
    order["ordersource"] === "wf-offline" &&
    order["orderpaymentstatus"] !== "PAID";

  const getLongestLabelLength = (columns, level) =>
    columns?.reduce(
      (longest, column) =>
        column.level === level && column?.label?.length > longest
          ? column.label.length
          : longest,
      0
    ) || 0;

  const longestLengthItem = getLongestLabelLength(
    orderSettings?.columnsDisplayed,
    "item"
  );
  const longestLengthOrder = getLongestLabelLength(
    orderSettings?.columnsDisplayed,
    "order"
  );

  return (
    <Paper sx={{ p: 3, pt: 1, borderRadius: 2, mt: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Grid container spacing={1} mt={1} mb={{ xs: 2, sm: 1 }}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h5" fontWeight="bold">
                Order: {orderID}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} pt="0 !important" mb={1}>
              <Typography variant="h6">
                Order Date: {formatDate(order.orderdate, dateFormat)}
              </Typography>
            </Grid>
            {orderSettings?.columnsDisplayed
              ?.filter(
                (field) =>
                  field.level === "order" &&
                  (products[0][field.fieldName] || field.dataType === "boolean")
              )
              ?.map((field) => (
                <OrderField
                  key={field.fieldName}
                  field={field}
                  product={products[0]}
                  longestLength={longestLengthOrder}
                  dateFormat={dateFormat}
                />
              ))}
          </Grid>

          <Box
            display={{ xs: "flex", sm: "none" }}
            flexDirection="column"
            my={1}
            gap={1}
            width={200}
          >
            <OrderBanner
              products={products}
              order={order}
              onActionSelect={handleActionSelect}
            />
            <OrderActions
              shouldDisplayProductActionsMenu={shouldDisplayProductActionsMenu}
              shouldDisplayOfflineOrderActionsMenu={
                shouldDisplayOfflineOrderActionsMenu
              }
              products={products}
              handleActionSelect={handleActionSelect}
            />
          </Box>
        </Box>

        <Box
          display={{ xs: "none", sm: "flex" }}
          flexDirection={{ sm: "column", lg: "row" }}
          sx={{ gap: 2 }}
          alignItems="center"
        >
          <OrderBanner
            products={products}
            order={order}
            onActionSelect={handleActionSelect}
          />
          <OrderActions
            shouldDisplayProductActionsMenu={shouldDisplayProductActionsMenu}
            shouldDisplayOfflineOrderActionsMenu={
              shouldDisplayOfflineOrderActionsMenu
            }
            products={products}
            handleActionSelect={handleActionSelect}
          />
        </Box>
      </Box>

      <Box mt={1}>
        {products?.map((product) => (
          <ProductDetailsCard
            key={product.id}
            product={product}
            handleActionSelect={handleActionSelect}
            longestLabelLength={longestLengthItem}
          />
        ))}
      </Box>
    </Paper>
  );
};

export default ProductDetails;
