import { performOrderAction } from "../../../../api/CustomerOrders";
import {
  createRequestDetails,
  ERROR_MESSAGES,
  validateAction,
} from "./ModalActionHelpers";
import { customerOrdersFirstBatchLength } from "../../../../common";

const performAction = (customerOrders, request, handlers) => {
  const {
    setLoading,
    setCustomerOrders,
    setRefetchTrigger,
    setOrders,
    handleClose,
    setError,
    setPerformAction,
  } = handlers;
  setLoading(true);
  performOrderAction(request)
    .then((rsp) => {
      if (rsp) {
        const customerOrderIds = new Set(
          customerOrders.map((order) => order.id)
        );
        const allExist = rsp.every((rspItem) =>
          customerOrderIds.has(rspItem.id)
        );

        if (
          allExist &&
          customerOrders.length > customerOrdersFirstBatchLength
        ) {
          // If all rsp items exist, update the orders optimistically
          setCustomerOrders((prevOrders) => {
            const rspMap = new Map(rsp.map((item) => [item.id, item]));
            return prevOrders.map((order) => rspMap.get(order.id) || order);
          });
        } else {
          // If not all rsp items exist or customer orders is still fetching, trigger a re-fetch
          setRefetchTrigger((value) => value + 1);
        }

        const sortedOrders = rsp.sort((a, b) => a.index - b.index);
        setOrders(sortedOrders);
      }
      setPerformAction((prevState) => prevState + 1);
      handleClose();
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setError(
        "Error: there was an error submitting. Please try again later. "
      );
      setLoading(false);
    });
};

export const submitAction = async (
  type,
  customerOrders,
  state,
  data,
  handlers,
  actionType,
  setPerformAction
) => {
  const errorKey = validateAction(type, state);
  if (errorKey) {
    handlers.setError(ERROR_MESSAGES[errorKey]);
    return;
  }

  const formattedAction = type
    .replace(/([A-Z])/g, " $1")
    .trim()
    .replace(/\s+/g, "_")
    .toUpperCase();

  const details = createRequestDetails(
    actionType ? actionType : type,
    data,
    state
  );

  const request = {
    action: formattedAction,
    details,
  };

  await performAction(customerOrders, request, {
    ...handlers,
    handleClose: handlers.onClose,
    setPerformAction,
  });
};
