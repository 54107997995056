import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function createFile(attachmentType, wixOrderNumber, fileName) {
  return axios
    .post(`${API_ORDER_LINK_URL}/fileInfo`, {
      attachmentType,
      wixOrderNumber,
      fileName,
    })
    .then((response) => response.data);
}
function getFiles(wixOrderNumber) {
  return axios
    .get(`${API_ORDER_LINK_URL}/fileInfo?wixOrderNumber=${wixOrderNumber}`)
    .then((response) => response.data);
}
function deleteFile(fileID) {
  return axios
    .delete(`${API_ORDER_LINK_URL}/fileInfo?id=${fileID}`)
    .then((response) => response.data);
}
function renameFile(fileID, newName, wixOrderNumber) {
  return axios
    .patch(`${API_ORDER_LINK_URL}/fileInfo`, {
      id: fileID,
      newName: newName,
      wixOrderNumber: wixOrderNumber
    })
    .then((response) => response.data);
}

const uploadFile = (uploadUrl, file, contentType) => {
  return axios.put(uploadUrl, file, {
    headers: {
      "Content-Type": contentType,
    },
  });
};

export { uploadFile, createFile, getFiles, renameFile, deleteFile };
