import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
  Paper,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { getFieldValues } from "../../api/SearchFields";
import Virtualize from "./VirtualisedAutoComplete";
import Grid from "@mui/material/Grid";
import Loading from "../Loading";
import dayjs from "dayjs";

const AddButton = ({ addFilter }) => (
  <Button
    onClick={addFilter}
    sx={{
      minWidth: "40px",
      height: "40px !important",
      border: 1,
      borderColor: "primary.main",
      borderRadius: "50%", // This makes the button circular
      padding: "6px", // Adjust padding as needed
    }}
  >
    <AddIcon />
  </Button>
);

const RemoveButton = ({ removeFilter }) => (
  <Button
    onClick={removeFilter}
    sx={{
      minWidth: "40px",
      height: "40px !important",
      border: 1,
      borderColor: "primary.main",
      borderRadius: "50%", // This makes the button circular
      padding: "6px", // Adjust padding as needed
    }}
  >
    <RemoveIcon fontSize="small" />
  </Button>
);

const SearchFieldAutocomplete = ({ selectedField, searchFields, onUpdate }) => {
  return (
    <Autocomplete
      options={searchFields}
      value={selectedField}
      disablePortal={false}
      renderOption={(props, option) => (
        <li {...props} key={option.key}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ ".MuiInputBase-root": { borderRadius: "20px" } }}
          label="Search Field"
        />
      )}
      onChange={(e, value) => {
        if (value)
          onUpdate({
            displayName: value.label,
            fieldName: value.fieldName,
            dataType: value.dataType,
            searchTypes: value.searchTypes,
            type: 0,
            value: "",
          });
      }}
      isOptionEqualToValue={(option, value) => {
        if (!value?.label) return option?.label === value;
        return option?.label === value?.label;
      }}
    />
  );
};

const FilterTypeSelect = ({ selectedType, filterTypes, onUpdate }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Search Type</InputLabel>
      <Select
        // label="Type"
        onChange={(e) =>
          onUpdate({ type: e.target.value, value: "", values: [] })
        }
        value={selectedType}
        sx={{ background: "white", borderRadius: "20px", width: "100%" }}
        label={"Search Type"}
      >
        {filterTypes.map((type) => (
          <MenuItem key={type.i} value={type.i}>
            {type.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SearchValueField = ({ filter, actualFilterType, onUpdate, values }) => {
  const isBlank =
    actualFilterType === "Is blank" || actualFilterType === "Is not blank";
  const isAutocomplete =
    actualFilterType === "Is one of" || actualFilterType === "Is not one of";
  const isDateField = filter.dataType === "date";
  const isNumberField = filter.dataType === "number";

  if (isBlank) return;
  if (isAutocomplete) {
    return (
      <Virtualize
        value={filter.values}
        options={values}
        handleValueChange={(value) => onUpdate({ values: value })}
        error={filter.error}
      />
    );
  } else if (isDateField) {
    const date =
      typeof filter.value === "string" ? dayjs(filter.value) : filter.value;
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format="DD/MM/YYYY"
          onChange={(newValue) => onUpdate({ value: newValue })}
          value={date}
          label="Date"
          textField={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    );
  } else {
    return (
      <TextField
        type={isNumberField ? "number" : "string"}
        label={"Search Value"}
        onChange={(e) => onUpdate({ value: e.target.value })}
        value={filter.value}
        sx={{ width: "100%" }}
        helperText={
          filter.error &&
          (filter.value.length === 0
            ? "This field is required. "
            : "Please enter at least 3 characters.")
        }
        error={filter.error}
      />
    );
  }
};

const FilterComponent = ({
  index,
  table,
  filter,
  onAdd,
  onRemove,
  onUpdate,
  searchFieldOptions,
}) => {
  const [selectedField, setSelectedField] = useState(null);
  const [filterTypes, setFilterTypes] = useState([]);
  const [searchFieldData, setSearchFieldData] = useState([]);

  const [loadingValues, setLoadingValues] = useState(false);

  const fetchTypes = useCallback(() => {
    const newTypes = filter.searchTypes.map((type, i) => ({
      i: i,
      name: type,
    }));
    setFilterTypes(newTypes);
  }, [filter.searchTypes]);

  const shouldFetchAutocompleteValues = useMemo(() => {
    return (
      filter.searchTypes[filter.type] === "Is one of" ||
      filter.searchTypes[filter.type] === "Is not one of"
    );
  }, [filter.type]);

  useEffect(() => {
    if (shouldFetchAutocompleteValues && selectedField?.fieldName) {
      setLoadingValues(true);

      getFieldValues(table, selectedField.fieldName)
        .then((data) => {
          setSearchFieldData(
            data.results.map((value, i) => {
              return {
                key: i,
                label:
                  typeof value !== "string" ? JSON.stringify(value) : value,
              };
            })
          );
        })
        .then((rsp) => setLoadingValues(false));
    }
  }, [selectedField, shouldFetchAutocompleteValues]);

  useEffect(() => {
    setSelectedField(
      searchFieldOptions.filter(
        (option) => option.label === filter.displayName
      )[0]
    );
  }, [filter.displayName]);

  useEffect(() => {
    fetchTypes();
  }, [selectedField]);

  return (
    <Box mt={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <SearchFieldAutocomplete
            selectedField={selectedField}
            searchFields={searchFieldOptions}
            onUpdate={(data) => onUpdate({ ...filter, ...data })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FilterTypeSelect
            filterTypes={filterTypes}
            onUpdate={(data) => onUpdate({ ...filter, ...data })}
            selectedType={filter.type}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          {loadingValues ? (
            <Box
              sx={{
                border: 1,
                borderColor: "rgba(0, 0, 0, 0.23)",
                height: "100%",
                borderRadius: "20px",
                alignItems: "center",
              }}
            >
              <Loading
                size={30}
                sx={{ m: "auto", mt: "12px", width: "50px" }}
              />
            </Box>
          ) : (
            <SearchValueField
              filter={filter}
              actualFilterType={filterTypes[filter.type]?.name}
              values={searchFieldData}
              onUpdate={(data) => {
                onUpdate({ ...filter, ...data });
              }}
            />
          )}
        </Grid>{" "}
        <Grid
          item
          xs={12}
          sm={6}
          md={1}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {index !== 0 ? (
            <RemoveButton removeFilter={onRemove} />
          ) : (
            <AddButton addFilter={onAdd} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterComponent;
