import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { CookiesProvider } from "react-cookie";
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import MuiXLicense from "./MuiXLicense";

const authStore = createStore({
    authType:'cookie',
    authName:'_auth',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:'
})

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <CookiesProvider>
    <React.StrictMode>
      <RecoilRoot>
        <AuthProvider store={authStore}>
          <App />
          <MuiXLicense />
        </AuthProvider>
      </RecoilRoot>
    </React.StrictMode>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
