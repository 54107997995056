export const errorMsg =
  "We're sorry, but we encountered a problem while retrieving the data you requested. Please try again later or contact our support team for assistance. We apologize for any inconvenience caused.";

export const getTableHeight = (rows) => 58 + 50 * rows;
export const convertToQueryString = (and, query, limit, offset) => {
  return `searchDataJson=${encodeURIComponent(
    JSON.stringify({
      andOr: and,
      searchTermArr: query,
      limit: limit,
      offset: offset,
    })
  )}`;
};

export const convertFromQueryString = (queryString) => {
  // Extract the encoded JSON string from the query string
  const params = new URLSearchParams(queryString);
  const encodedJson = params.get("searchDataJson");

  // Decode and parse the JSON string
  try {
    const decodedJson = decodeURIComponent(encodedJson);
    return JSON.parse(decodedJson);
  } catch (error) {
    console.error("Error parsing JSON from query string:", error);
    return null;
  }
};

export const isString = (value) => {
  return value?.constructor === String;
};

export const loadNotificationsView = () =>
  localStorage.getItem("NOTIFICATIONSVIEW");
export const storeNotificationsView = (view) =>
  localStorage.setItem("NOTIFICATIONSVIEW", view);

export const loadDisplayView = (table) => localStorage.getItem(`${table}VIEW`);
export const setDisplayView = (view, table) =>
  localStorage.setItem(`${table}VIEW`, view);

export const loadSearchTerm = (name) =>
  localStorage.getItem(`${name}SEARCHTERM`);

export const storeSearchTerm = (searchTerm, name) =>
  localStorage.setItem(`${name}SEARCHTERM`, searchTerm);
export const loadFilter = (name) => localStorage.getItem(`${name}FILTER`);
export const removeFilter = (name) => localStorage.removeItem(`${name}FILTER`);

export const storeFilter = (filter, name) =>
  localStorage.setItem(`${name}FILTER`, filter);

export const loadUser = () => localStorage.getItem("USER");

export const setUser = (user) => localStorage.setItem("USER", user);

export const customerOrdersFirstBatchLength = 500;
export const shippingCompanies = [
  "AAA Cooper",
  "Acumen Logistics",
  "Allied Express",
  "Amazon",
  "Aramex",
  "Australia Post",
  "BWW",
  "Canpar Courier (Acumen)",
  "CAPITAL TRANSPORT AU",
  "Central Transport",
  "Couriers Please",
  "Day & Ross (Acumen)",
  "DHL",
  "FedEx",
  "FedEx (Acumen)",
  "FedEx Express",
  "FedEx Freight",
  "FedEx Ground",
  "Followmont Transport",
  "Northline",
  "Overland West (Acumen)",
  "Purolator (Acumen)",
  "Right Speed (Acumen)",
  "RL-CARRIERS",
  "TOLL",
  "TNT",
  "TNT-AU",
  "Timco Logistics",
  "TSS Sensitive Freight NZ",
  "UPS",
  "UPS (Acumen)",
  "UPS Freight",
  "USPS",
  "LOCAL PICKUP",
  "ABF Freight (P1)",
  "Averitt Express (P1)",
  "Estes (P1)",
  "FedEx (P1)",
  "Forward Air (P1)",
  "Roadrunner (P1)",
  "RL-Carriers (P1)",
  "SAIA Freight (P1)",
  "Southeastern Freight (P1)",
  "XPO Logistics (P1)",
];
export const currencies = ["AUD", "CAD", "GBP", "NZD", "USD"];
export const shippingStatuses = [
  "ORDER_CREATED",
  "PROCESSING_ORDER",
  "READY_FOR_SHIPMENT",
  "SHIPPED",
  "SYS_ARRIVED_AT_CUSTOMS",
  "SYS_CUSTOMS_DELAY",
  "SYS_CUSTOMS_CLEARED",
  "SYS_TRANSIT_DELAY",
  "OUT_FOR_DELIVERY",
  "AWAITING_COLLECTION",
  "ATTEMPTED_DELIVERY",
  "SYS_SHIPMENT_LOST",
  "DELIVERED",
];
export const warehouses = [
  "AUGC",
  "AUWLBY",
  "USTX",
  "USAMZ",
  "USWLBY",
  "CAVC",
  "CAWLBY",
  "NZAKL",
  "CNFS",
  "FD",
  "AU-X",
  "CA-X",
  "UK-X",
  "US-X",
  "NZ-X",
];
export const inventoryWarehouses = [
  "AUGC",
  "USTX",
  "USAMZ",
  "USWLBY",
  "CAVC",
  "NZAKL",
  "CNFS",
];
export const inventorySuppliers = [
  "Pinky",
  "Raya",
  "SilverChef",
  "Factory Direct",
  "CNFS",
  "FD",
  "USTX",
  "CAVC",
  "AUGC",
  "NZAKL",
  "USAMZ",
];
export const warehousesWithoutFD = [
  "AUGC",
  "AUWLBY",
  "USTX",
  "USAMZ",
  "USWLBY",
  "CAVC",
  "CAWLBY",
  "NZAKL",
  "CNFS",
  "AU-X",
  "CA-X",
  "UK-X",
  "US-X",
  "NZ-X",
];
export const INVENTORY_ORDER_STATUSES = [
  "ORDERED",
  "IN PRODUCTION",
  "ON SHIP",
  "IN CUSTOMS",
  "INTERNAL TRANSIT",
  "ARRIVED AT WAREHOUSE",
  "RECEIVED",
];
