import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchOrdersInput from "../../../components/SearchOrdersInput";
import FilterMenu from "../../../components/Filter";
import ViewToggleButtons from "../../CustomerOrders/components/ViewToggleButtons";
import TileView from "../../../components/TileView";
import Loading from "../../../components/Loading";
import ColumnSelector from "../../../components/TileView/ColumnSelector";
import { tileViewSettingsState } from "../../../atoms/atoms";
import { useRecoilState } from "recoil";
import { getSettings } from "../../../api/userSettings";
import {
  performInventoryAction,
  setWarehouseStockOverride,
} from "../../../api/InventoryOrders";
import Modal from "../../../components/Modal";
import HistoryModal from "../modals/historyModal";
const useSettings = (
  searchFieldOptions,
  searchFieldsLoading,
  setTileViewSettings,
  page,
  defaultSettings
) => {
  useEffect(() => {
    setTileViewSettings(null);
    if (searchFieldsLoading) return;

    getSettings(page, "tileViewSettings")
      .then((rsp) => {
        if (!rsp?.settings) {
          setTileViewSettings(defaultSettings);
        } else {
          const columnsDisplayed = rsp.settings.columnsDisplayed.map(
            (column) => {
              const searchFieldOption = searchFieldOptions.find(
                (searchField) => searchField.fieldName === column.fieldName
              );
              return searchFieldOption || column;
            }
          );
          setTileViewSettings({ ...rsp.settings, columnsDisplayed });
        }
      })
      .catch((err) => {
        console.log(err);
        setTileViewSettings(defaultSettings);
      });
  }, [page, searchFieldsLoading]);
};
export const InventoryTileView = ({
  page,
  sortedRows,
  searchFieldOptions,
  searchFieldsLoading,
  refetch,
  view,
  toggleView,
  handleOrdersChange,
  bottomBarIsVisible,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tileViewSettings, setTileViewSettings] = useRecoilState(
    tileViewSettingsState
  );
  const handleOpenHistoryModal = (item) => {
    setSelectedItem(item);
    setHistoryModalOpen(true);
  };

  const handleCloseHistoryModal = () => {
    setHistoryModalOpen(false);
    setSelectedItem(null);
  };

  const defaultSettings = {
    showHeaders: true,
    columnsDisplayed: searchFieldOptions.filter(
      (option) => !option.isHiddenForTile && option.fieldName !== "everywhere"
    ),
  };
  useSettings(
    searchFieldOptions,
    searchFieldsLoading,
    setTileViewSettings,
    page,
    defaultSettings
  );

  const handleMobileMenuClick = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const getHighlightInfo = useCallback(
    (item) => {
      if (page === "stockPredictions") {
        if (item.criticallowstockalert) {
          return {
            isHighlighted: true,
            highlightText: "Critical Low Stock",
            highlightHeaderColour: "#ff3b30",
            highlightColor: "rgba(255,59,48,0.2)",
          };
        } else if (item.lowstockalert) {
          return {
            isHighlighted: true,
            highlightText: "Low Stock",
            highlightHeaderColour: "#ff3b30",
          };
        }
      } else if (page === "currentStock") {
        if (item.alloworderswhenoos) {
          return {
            isHighlighted: true,
            highlightText: "Allow Orders When Out of Stock",
            highlightHeaderColour: "primary.main",
            highlightColor: "#FFF3E0",
          };
        }
      }
      return { isHighlighted: false };
    },
    [page]
  );

  const handleMenuItemClick = async (menuItem, item) => {
    if (menuItem.action === "toggleAllowOrdersWhenOOS") {
      try {
        handleOrdersChange(
          item.id,
          "alloworderswhenoos",
          !item.alloworderswhenoos
        );
        await setWarehouseStockOverride(item.sid, !item.alloworderswhenoos);
      } catch (err) {
        console.log(err);
        handleOrdersChange(
          item.id,
          "alloworderswhenoos",
          item.alloworderswhenoos
        );
      }
    } else if (menuItem.action === "openHistoryModal") {
      handleOpenHistoryModal(item);
    }
  };

  const getMenuItems = useCallback(
    (item) => {
      const items = [
        {
          label: "Show History",
          action: "openHistoryModal",
        },
      ];
      if (page === "currentStock")
        items.push({
          label: item.alloworderswhenoos
            ? "Disallow Orders When Out of Stock"
            : "Allow Orders When Out of Stock",
          action: "toggleAllowOrdersWhenOOS",
        });
      return items;
    },
    [page]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: theme.palette.background.paper,
          py: 0.5,
          px: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            gap: { xs: 1, sm: 2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {!isMobile && (
              <Box mr={1}>
                <ViewToggleButtons
                  view={view}
                  onToggleView={toggleView}
                  viewType={"tile"}
                />
              </Box>
            )}
            <SearchOrdersInput table={page} handleSearch={refetch} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            maxWidth={"100%"}
            gap={1}
          >
            <FilterMenu
              table={page}
              searchFieldOptions={searchFieldOptions}
              refetchOrders={refetch}
            />

            <ColumnSelector
              page={page}
              searchFields={searchFieldOptions}
              defaultSettings={defaultSettings}
            />

            {isMobile && (
              <IconButton size="small" onClick={handleMobileMenuClick}>
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={mobileMenuAnchorEl}
        open={Boolean(mobileMenuAnchorEl)}
        onClose={handleMobileMenuClose}
      >
        <MenuItem
          onClick={() => {
            toggleView("table");
            handleMobileMenuClose();
          }}
        >
          Switch to Table View
        </MenuItem>
      </Menu>
      <Box
        sx={{
          height: {
            xs: "calc(100% - 100px)",
            md: `calc(100% - ${bottomBarIsVisible ? "195px" : "100px"})`,
          },
          width: "100%",
          overflow: "auto",
        }}
      >
        {tileViewSettings?.columnsDisplayed ? (
          <Box p={1}>
            <TileView
              groupBy={"category"}
              items={sortedRows}
              getHighlightInfo={getHighlightInfo}
              getMenuItems={getMenuItems}
              onMenuItemClick={handleMenuItemClick}
            />
          </Box>
        ) : (
          <Box width={"40px"} m={"auto"} mt={4}>
            <Loading />
          </Box>
        )}
      </Box>
      {selectedItem && (
        <HistoryModal
          open={historyModalOpen}
          onClose={handleCloseHistoryModal}
          item={selectedItem}
          searchFields={searchFieldOptions}
        />
      )}
    </>
  );
};

export default InventoryTileView;
