import Box from "@mui/material/Box";
import SimpleDataGrid from "../../../components/Datagrid/SimpleDataGrid";

import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import { getTableHeight } from "../../../common";
import Table from "../../../components/Table";

const AddSerialNumberContent = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({
      items: data.items,
    });
  }, []);

  const serialNumbersColumns = [
    {
      field: "id",
      headerName: "UID",
      flex: 1,
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      flex: 1,
      editable: true,
      required: true,
    },
  ];

  const handleInputChange = (value, field, id) => {
    setState((prevState) => ({
      ...prevState,
      items: prevState.items.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      }),
    }));
  };

  return (
    <Box>
      <DialogContentText>
        Use the table below to add/edit the serial number.
      </DialogContentText>
      <Box width={"100%"} mt={2}>
        <Table
          columns={serialNumbersColumns}
          rows={state.items || []}
          onCellChange={handleInputChange}
        />
      </Box>
    </Box>
  );
};
export default AddSerialNumberContent;
