import { useState, useCallback } from "react";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-premium";
import { isGroupRow, getGroupKey } from "../../../utils/mui";

export const useRowSelection = (props, apiRef, gridColumns) => {
  const {
    handleRowSelection,
    handleSelectionCheck,
    groupingModel,
    groupingColDef,
  } = props;

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const isRowSelectable = useCallback(
    (params) => {
      const isGroup = isGroupRow(params.row);
      const groupKey = getGroupKey(params.row, groupingColDef?.headerName);

      if (selectedItemId) {
        const selectionAllowed =
          !handleSelectionCheck || !handleSelectionCheck(params);
        return (
          selectionAllowed &&
          !isGroup &&
          groupKey ===
            getGroupKey(
              apiRef.current.getRow(selectedItemId),
              groupingColDef?.headerName
            )
        );
      }
      if (selectedGroupId) {
        return groupKey === selectedGroupId;
      }
      return !(isGroup && !groupKey);
    },
    [
      selectedItemId,
      selectedGroupId,
      handleSelectionCheck,
      apiRef,
      groupingColDef,
    ]
  );

  const handleRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      let updatedSelectionModel = [...newSelectionModel];

      if (updatedSelectionModel.length === 0) {
        setSelectedGroupId(null);
        setSelectedItemId(null);
      } else {
        const selectedRow = apiRef.current.getRow(updatedSelectionModel[0]);

        if (isGroupRow(selectedRow)) {
          setSelectedGroupId(
            getGroupKey(selectedRow, groupingColDef?.headerName)
          );
          setSelectedItemId(null);
        } else {
          setSelectedGroupId(null);
          setSelectedItemId(updatedSelectionModel[0]);
          const groupKey = getGroupKey(selectedRow, groupingColDef?.headerName);
          updatedSelectionModel = updatedSelectionModel.filter((id) => {
            const row = apiRef.current.getRow(id);
            return (
              !isGroupRow(row) &&
              getGroupKey(row, groupingColDef?.headerName) === groupKey
            );
          });
        }
      }

      setRowSelectionModel(updatedSelectionModel);

      if (handleRowSelection) {
        const selectedRows = updatedSelectionModel
          .map((id) => apiRef.current.getRow(id))
          .filter(Boolean);
        handleRowSelection(
          selectedRows.length > 0,
          selectedRows,
          selectedGroupId ||
            (selectedItemId
              ? getGroupKey(apiRef.current.getRow(selectedItemId))
              : null)
        );
      }
    },
    [apiRef, handleRowSelection, groupingColDef]
  );

  const customCheckboxColumn = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    headerName: "",
    renderHeader: () => null,
    renderCell: (params) => {
      if (isRowSelectable(params)) {
        return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params);
      }
      return null;
    },
  };

  const columns = useCallback(() => {
    if (handleRowSelection) {
      if (groupingModel && groupingModel.length > 0) {
        return [customCheckboxColumn, ...gridColumns.current];
      } else if (gridColumns.current.length !== 0) {
        return [
          {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            field: "selectable",
            width: 70,
            renderHeader: () => null,
            renderCell: (params) => {
              if (handleSelectionCheck && handleSelectionCheck(params)) {
                return null;
              }
              return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params);
            },
          },
          ...gridColumns.current,
        ];
      }
    }
    return gridColumns.current;
  }, [
    handleRowSelection,
    gridColumns,
    groupingModel,
    handleSelectionCheck,
    customCheckboxColumn,
  ]);

  return {
    rowSelectionModel,
    isRowSelectable,
    handleRowSelectionModelChange,
    columns,
  };
};
