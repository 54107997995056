import AppBar from "../../../components/AppBarWithDrawer/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import * as React from "react";
import { useCookies } from "react-cookie";
import NotificationsMenu from "./NotificationsMenu";

export const HeaderBar = ({ open, handleDrawerOpen, title }) => {
  const [cookies] = useCookies(["_auth_state"]);

  const user =
    JSON.parse(localStorage.getItem("USER")) || cookies["_auth_state"];

  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{ background: "white", height: "50px" }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          minHeight: "48px !important",
          pr: { xs: 1, sm: 2 },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            minHeight: "48px !important",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#2D353B",
              height: "48px",
              width: open ? "0px" : { xs: "55px", sm: "64px" },
              position: "absolute",
              left: 1,
              top: 0,
              transition: "width 0.3s",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                ...(open && { display: "none" }),
                color: "rgba(197,197,197,0.7)",
                m: "auto",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Typography
            fontSize={{ xs: 17, sm: 20 }}
            noWrap
            component="div"
            fontWeight="600"
            sx={{ color: "black", ml: open ? 0 : 7 }}
          >
            {title}
          </Typography>
        </Box>
        <Box display="flex">
          <Box mr={1}>
            <NotificationsMenu />
          </Box>
          <Avatar sx={{ width: "40px", height: "40px" }}>
            {!user?.avatarImage ? (
              <ImageIcon sx={{ width: "25px", height: "25px" }} />
            ) : (
              <img
                style={{ width: "40px", height: "40px" }}
                src={user.avatarImage}
                alt="User avatar"
              />
            )}
          </Avatar>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
