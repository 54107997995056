import * as Sentry from "@sentry/browser";

function initializeConfiguredSentry () {
  if (!process.env.REACT_APP_API_ORDER_LINK_URL?.includes("mclellanhill-gcp")) {
    return
  }

  Sentry.init({
    dsn: "https://34761c841c70ba31b00d0967e5611c50@o369371.ingest.us.sentry.io/4507404399542272",
    maxBreadcrumbs: 50,
    release: process.env.REACT_APP_LAST_COMMIT_INFO,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/app\.[a-z]+\.com/, /^https:\/\/[\w-]*portal[\w-]+\.web.app/, /^https:\/\/m[\w-]+testing[\w-]+\.web.app/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export default initializeConfiguredSentry