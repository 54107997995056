import React from "react";
import { Box } from "@mui/material";

const ProductImage = ({ product }) => {
  const renderImage = (src) => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <img
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
        src={src}
        alt={product.descriptionshort}
      />
    </Box>
  );

  try {
    return renderImage(
      require(`../../../../images/products/${product.pid
        .replace(/:/g, " ")
        .replace(/\|/g, "-")}.webp`)
    );
  } catch (err) {
    return renderImage(
      require(`../../../../images/products/UNKNOWN-PRODUCT-IMAGE.webp`)
    );
  }
};

export default ProductImage;
