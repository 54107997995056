import React, { useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import TextField from "../TextField";

import LoadingButton from "@mui/lab/LoadingButton";
import FilterComponent from "./Container";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { loadUser } from "../../common";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
const FilterModal = ({
  isEdit,
  handleAddFilterSubmit,
  handleUpdateFilterSubmit,
  filterLoading,
  searchFieldOptions,
  selectedFilter,
  openModal,
  handleCloseModal,
  errorMessage,
  table,
}) => {
  const [isCreator, setIsCreator] = React.useState(false);
  const [isShared, setIsShared] = React.useState(null);

  const [filters, setFilters] = React.useState(null);
  const [name, setName] = React.useState("");
  const [andOr, setAndOr] = React.useState(10);
  const [nameError, setNameError] = React.useState(false);

  useEffect(() => {
    if (!openModal) return;

    const isUserCreator = selectedFilter
      ? selectedFilter.userID === JSON.parse(loadUser()).id
      : true;

    const initialFilters = selectedFilter
      ? selectedFilter.filters
      : [
          {
            ...searchFieldOptions[0],
            displayName: searchFieldOptions[0].label,
            type: 0,
            value: "",

            error: false,
          },
        ];
    setIsCreator(isUserCreator);
    setIsShared(selectedFilter?.isShared || false);
    setFilters(initialFilters);
    setName(selectedFilter?.name || "");
    setAndOr(selectedFilter ? (selectedFilter.andOr === "and" ? 10 : 20) : 10);
    setNameError(false);
  }, [openModal, selectedFilter, searchFieldOptions]);

  const handleShareChange = (event) => setIsShared(event.target.checked);
  const handleName = (value) => setName(value);

  const addFilter = () => {
    const newFilter = {
      dataType: searchFieldOptions[0].dataType,
      displayName: searchFieldOptions[0].label,
      fieldName: searchFieldOptions[0].fieldName,
      searchTypes: searchFieldOptions[0].searchTypes,
      type: 0,
      value: "",
      error: false,
    };

    setFilters([...filters, newFilter]);
  };

  const updateFilter = (index, updatedData) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter, i) =>
        i === index
          ? {
              ...filter,
              ...updatedData,
              value:
                filter.fieldName !== updatedData.fieldName
                  ? filter.value
                  : updatedData.value,
            }
          : filter
      )
    );
  };

  const removeFilter = (index) =>
    setFilters(filters.filter((_, i) => i !== index));

  const validateSubmit = () => {
    const validatedFilters = filters.map((filter) => {
      const type = filter.searchTypes[filter.type];
      const isBlank = type === "Is blank" || type === "Is not blank";
      return {
        ...filter,
        error:
          !isBlank &&
          (filter.value === "" ||
            (filter.fieldName === "everywhere" && filter.value.length < 3)) &&
          (!filter.values || filter.values.length === 0),
      };
    });

    setFilters(validatedFilters);
    setNameError(false);
    const hasError = validatedFilters.some((filter) => filter.error);
    const isNameValid = !!name;
    if (hasError || !isNameValid) {
      setNameError(!isNameValid);
      return;
    }

    const submitData = {
      name,
      isShared,
      filters: validatedFilters,
      andOr: andOr === 10 ? "and" : "or",
    };

    if (isEdit) {
      handleUpdateFilterSubmit({ ...submitData, id: selectedFilter.id });
    } else {
      handleAddFilterSubmit(submitData);
    }
  };

  const handleOnKeyUp = (e) => {
    if (e.key === "Enter") {
      validateSubmit();
    }
  };

  return (
    <Dialog
      maxWidth={"lg"}
      fullWidth
      open={openModal}
      onClose={handleCloseModal}
      onKeyUp={handleOnKeyUp}
    >
      <DialogTitle sx={{ fontWeight: 600 }}>
        {isEdit ? `Edit Filter: ${selectedFilter.name}` : "New Filter"}
      </DialogTitle>
      <DialogContent sx={{ px: { xs: 2, sm: 3 } }}>
        {errorMessage && (
          <Alert color={"error"} sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              sx={{ mt: "5px", width: { xs: "100%", sm: "80%", md: "60%" } }}
              label={"Name"}
              value={name}
              changeValue={handleName}
              error={nameError && "This field is required. "}
            />
          </Box>
        </Box>
        <Box mt={1}>
          {isCreator && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isShared}
                  onChange={handleShareChange}
                  name="shareFilter"
                  color="primary"
                />
              }
              label="Share Filter"
            />
          )}
        </Box>
        <Box mt={isCreator ? 1 : 2} mb={1}>
          <Typography sx={{ mb: 2 }}>
            Show rows that match all conditions:{" "}
          </Typography>
          <Box>
            {searchFieldOptions &&
              filters?.map((filter, index) => (
                <>
                  <FilterComponent
                    key={index}
                    index={index}
                    filter={filter}
                    onAdd={addFilter}
                    onRemove={() => removeFilter(index)}
                    onUpdate={(updatedData) => updateFilter(index, updatedData)}
                    table={table}
                    searchFieldOptions={searchFieldOptions}
                  />
                  {filters?.length > 1 && <Divider sx={{ mt: 3 }} />}
                </>
              ))}
            {filters?.length >= 2 && (
              <FormControl
                fullWidth
                sx={{
                  width: "120px",
                  mt: "24px !important",
                  ".MuiInputBase-root": {
                    borderRadius: "20px",
                    background: "white",
                  },
                }}
              >
                <InputLabel>AND/OR</InputLabel>
                <Select
                  label="AND/OR"
                  value={andOr}
                  onChange={(e) => setAndOr(e.target.value)}
                >
                  <MenuItem value={10}>AND</MenuItem>
                  <MenuItem value={20}>OR</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            float: "right",
            mb: 1,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCloseModal}
            color="error"
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={filterLoading}
            variant="outlined"
            onClick={validateSubmit}
          >
            Apply
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
