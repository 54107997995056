import React, { useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import Box from "@mui/material/Box";
import mentionsInputStyle from "./MentionsInputStyle";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

const MentionComponent = ({ message, handleChange, users, handleSubmit }) => {
  const allUsers = users.map((user) => ({
    id: user.id,
    display: `${user.firstName} ${user.lastName}`,
  }));

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent default to avoid line breaks in case of textarea
      handleSubmit();
    }
  };
  return (
    <Box width={"75%"}>
      <MentionsInput
        value={message}
        onChange={handleChange}
        style={mentionsInputStyle}
        placeholder="Type @ to mention a user..."
        allowSuggestionsAboveCursor
        onKeyDown={handleKeyDown}
      >
        <Mention
          trigger="@"
          displayTransform={(id, display) => `@${display}`}
          data={allUsers}
          renderSuggestion={(suggestion, search, highlightedDisplay) => (
            <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
              <Avatar
                src={users?.find((user) => user.id === suggestion.id).image}
                alt="User"
                sx={{ width: 24, height: 24, marginRight: 1.5 }}
              />
              <Typography variant="body2">{suggestion.display}</Typography>
            </Box>
          )}
          style={{ backgroundColor: "#cee4e5" }}
          appendSpaceOnAdd
        />
      </MentionsInput>
    </Box>
  );
};

export default MentionComponent;
