import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  permissionsState,
  orderState,
  orderSettingsState,
} from "../../atoms/atoms";
import { getInventoryOrders } from "../../api/InventoryOrders";
import { convertToQueryString } from "../../common";
import useSearchFields from "../../hooks/useSearchFields";
import MainContainer from "../../layouts/main";
import SecondaryAppBar from "../../components/SecondaryAppBar";
import Loading from "../../components/Loading";
import Error from "../../components/Error";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HistoryDisplay from "../Order/sections/HistoryDisplay";
import FilesSection from "../Order/sections/files/FileDisplay";
import MessengerDisplay from "../Order/sections/messenger/MessengerDisplay";
import { getSettings } from "../../api/userSettings";
import SettingsModal from "../Order/components/SettingsModal";
import InventoryProductDetails from "./sections/productDetails/ProductDetails";

const useInventoryOrderData = (warehouseOrderNumber) => {
  const setOrders = useSetRecoilState(orderState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setLoading(true);
        const queryString = convertToQueryString("and", [
          {
            field: "warehouseordernumber",
            type: "Contains",
            value: warehouseOrderNumber,
          },
        ]);
        const response = await getInventoryOrders(queryString);
        const sortedProducts = [...response.results].sort(
          (a, b) => a.index - b.index
        );
        setOrders(sortedProducts);
      } catch (err) {
        console.error(err);
        setError(`Failed to retrieve order details. Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchOrderDetails();
  }, [warehouseOrderNumber, setOrders]);

  return { error, loading };
};

const useOrderSettings = (searchFieldOptions, searchFieldsLoading) => {
  const setOrderSettings = useSetRecoilState(orderSettingsState);
  const defaultSettings = {
    columnsDisplayed: [
      {
        label: "Inventory Item",
        fieldName: "inventoryitem",
        dataType: "string",
        level: "item",
      },
    ],
  };

  useEffect(() => {
    if (searchFieldsLoading) return;

    getSettings("inventoryOrder", "orderSettings")
      .then((rsp) => {
        if (!rsp?.settings) {
          setOrderSettings(defaultSettings);
        } else {
          const columnsDisplayed = rsp.settings.columnsDisplayed.map(
            (column) => {
              const searchFieldOption = searchFieldOptions.find(
                (searchField) => searchField.fieldName === column.fieldName
              );
              return searchFieldOption || column;
            }
          );
          setOrderSettings({ ...rsp.settings, columnsDisplayed });
        }
      })
      .catch((err) => {
        console.log(err);
        setOrderSettings(defaultSettings);
      });
  }, [searchFieldsLoading, searchFieldOptions, setOrderSettings]);
};

const InventoryOrder = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [fileOpen, setFileOpen] = useState(false);

  const warehouseOrderNumber = window.location.href
    .split("/")
    .filter(Boolean)
    .pop();
  const [order, setOrder] = useRecoilState(orderState);
  const permissions = useRecoilValue(permissionsState);
  const orderSettings = useRecoilValue(orderSettingsState);

  const { error, loading } = useInventoryOrderData(warehouseOrderNumber);
  const { searchFieldOptions, searchFieldsLoading } = useSearchFields(
    permissions.inventoryOrders?.orders.columns,
    permissions.id,
    "inventoryorders"
  );
  useOrderSettings(searchFieldOptions, searchFieldsLoading);

  const handleBack = () => navigate("/inventoryOrders/stockOrders");
  const handleFileOpen = () => setFileOpen(!fileOpen);

  const containerTitle = isMobile
    ? warehouseOrderNumber
    : `Inventory Order: ${warehouseOrderNumber}`;

  const handleOrderChange = (updatedOrder) => {
    setOrder(updatedOrder);
  };

  return (
    <MainContainer title={containerTitle}>
      {orderSettings?.columnsDisplayed && (
        <SettingsModal
          dataGridName="inventoryOrder"
          searchFieldOptions={searchFieldOptions}
        />
      )}
      <SecondaryAppBar
        height={40}
        sx={{
          zIndex: 100,
          position: "fixed",
          boxShadow: "none",
          borderBottom: "1px solid #ccc",
        }}
        children={
          <Box
            width={{ xs: "100%", sm: "100%" }}
            m={{ sm: 0 }}
            ml={{ xs: 2, sm: 2 }}
            display={"flex"}
            onClick={handleBack}
            sx={{ ":hover": { cursor: "pointer" } }}
          >
            <ArrowBackIcon color={"primary"} />
            <Typography color={"primary"} sx={{ ml: 2 }}>
              Go back to inventory orders
            </Typography>
          </Box>
        }
      />
      {error && (
        <Box mt={5}>
          <Error errorMessage={error} sx={{ borderRadius: 0 }} />
        </Box>
      )}

      {loading && (
        <Box width={50} sx={{ mt: 10, mx: "auto" }}>
          <Loading size={50} />
        </Box>
      )}
      {!error && !loading && order && order.length !== 0 && (
        <Box
          sx={{
            flexGrow: 1,
            px: 2,
            overflow: { xs: "auto", lg: "auto", xl: "hidden" },
            height: `calc(100vh - ${error ? 142 : 93}px)`,
            mr: 0,
            mt: error ? 0 : 5,
            pt: 2,
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={8}
              sx={{
                overflow: "auto",
                maxHeight: { xs: "auto", lg: "auto", xl: "calc(100vh - 90px)" },
                pr: { xs: 0, lg: 0, xl: 1 },
                mt: 1,
                pt: "16px !important",
              }}
            >
              <InventoryProductDetails
                orderID={warehouseOrderNumber}
                products={order}
                handleOrderChange={handleOrderChange}
              />
              {!isMobile && (
                <HistoryDisplay
                  dataGridName="inventoryOrder"
                  orderID={warehouseOrderNumber}
                  searchFields={searchFieldOptions}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={4}
              sx={{
                maxHeight: "calc(100vh - 100px)",
                minHeight: "calc(100vh - 100px)",
                mb: { xs: 2, lg: 2, xl: 0 },
                pt: {
                  xs: "0 !important",
                  lg: "0 !important",
                  xl: "24px !important",
                },
                mt: isMobile ? 2 : 0,
              }}
            >
              <FilesSection
                orderID={warehouseOrderNumber}
                open={fileOpen}
                handleOpen={handleFileOpen}
                handleFileUpload={() => {}}
              />
              <MessengerDisplay
                orderID={warehouseOrderNumber}
                open={!fileOpen}
                handleOpen={handleFileOpen}
                handleFileUploadCallback={() => {}}
              />
              {isMobile && <HistoryDisplay orderID={warehouseOrderNumber} />}
            </Grid>
          </Grid>
        </Box>
      )}
    </MainContainer>
  );
};

export default InventoryOrder;
