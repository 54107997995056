import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  permissionsState,
  mainProductsState,
  extraProductsState,
  orderDetailsState,
  activeStepState,
} from "../../../atoms/atoms";
import { activeStepSelector } from "../../../selectors/selectors";
import { updateOfflineOrder } from "../../../api/OfflineOrders";
import { currencyFormatter } from "../../../helpers/money/CurrencyFormater";
import DataGrid from "../../../components/Datagrid/SimpleDataGrid";
import { Paper, useTheme } from "@mui/material";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useCallback, useEffect } from "react";

const SummaryTitle = ({ title }) => {
  return (
    <Typography
      sx={{
        background: "#f2f2f2",
        color: "rgba(0,0,0,.85)",
        borderRadius: "5px",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
        p: 1,
        pl: 2,
        fontWeight: 500,
        fontSize: 18,
      }}
      variant="h6"
      component="div"
    >
      {title}
    </Typography>
  );
};

const SummaryListItem = ({ label, value }) => {
  const theme = useTheme();
  return (
    <ListItem
      sx={{
        borderBottom: "1px solid #f2f2f2",
        py: 1,
        px: 2,
        display: "flex", // Ensuring the ListItem is flexible
        flexDirection: "column", // Stack elements vertically on small screens
        alignItems: "flex-start", // Align items to the start
        [theme.breakpoints.up("sm")]: {
          // Use theme breakpoints for consistency
          flexDirection: "row", // Elements in a row on larger screens
        },
      }}
    >
      <Grid item xs={12} sm={4}>
        <Typography
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            minWidth: { xs: "auto", sm: "250px" },
          }}
        >
          {`${label}:`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography
          sx={{
            textAlign: "left",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {value}
        </Typography>
      </Grid>
    </ListItem>
  );
};
export default function Summary(props) {
  const { productPIDs } = props;
  let navigate = useNavigate();
  let location = useLocation();

  const mainProducts = useRecoilValue(mainProductsState);
  const extraProducts = useRecoilValue(extraProductsState);
  const state = useRecoilValue(orderDetailsState);
  const setState = useSetRecoilState(orderDetailsState);
  const activeStep = useRecoilValue(activeStepState);
  const setActiveStep = useSetRecoilState(activeStepSelector);

  const [columns, setColumns] = React.useState(null);
  const [totals, setTotals] = React.useState({
    subTotal: 0,
    customShipping: 0,
    insurance: 0,
    priorityShipping: 0,
    discount: 0,
    tax: 0,
    total: 0,
  });

  const handleSubmit = () => {
    setState({ ...state, success: true });
    const order = {
      originalOrderNumber: window.location.href
        .split("/")
        .filter(Boolean)
        .pop(),
      newOrderDetails: state,
      items: mainProducts.map((product) => ({
        itemPID: product.itemPID,
        description: product.description,
        quantity: product.quantity,
        discount: product.discount,
        linePrice: product.linePrice,
        salesTaxDollarAmount: product.salesTaxDollarAmount,
        isSalesTaxIncluded: product.isSalesTaxIncluded,
        isRefurb: product.isRefurb,
        unitPrice: productPIDs.find((pid) => pid.label === product.itemPID)
          .price,
      })),
      summary: {
        discountAmount: totals.discount,
        expressShipping: totals.priorityShipping,
        expressShippingPid: "XTRA-1-PTYSHIP-0-0",
        customShipping: totals.customShipping,
        insurance: totals.insurance,
        insurancePid: "XTRA-1-INSURE1-0-0",
        tax: totals.tax,
        totalIncTax: totals.total,
      },
    };
    updateOfflineOrder(order)
      .then(() => {
        navigate(`/offlineorders/${location.pathname.split("/")[2]}/success`);
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, success: false });
      });
  };

  const calcTotals = useCallback(() => {
    const subTotal = mainProducts.reduce(
      (sum, item) => sum + (item.linePrice + (item.discount || 0)),
      0
    );
    const discountTotal = mainProducts.reduce(
      (sum, item) => sum + (item.discount || 0),
      0
    );

    const customShipping = extraProducts.customShipping
      ? state.customShippingPrice
      : 0;
    const insurance = extraProducts.insurance ? state.freightShippingPrice : 0;
    const priorityShipping = extraProducts.priorityShipping
      ? state.priorityShippingPrice
      : 0;

    const taxTotal = !state.isSalesTaxIncluded
      ? (subTotal -
          discountTotal +
          ((!state.isSalesTaxIncluded ? insurance : 0) +
            (!state.isSalesTaxIncluded ? customShipping : 0) +
            (!state.isSalesTaxIncluded ? priorityShipping : 0))) *
        state.taxRate
      : 0;

    const total =
      subTotal +
      customShipping +
      insurance +
      priorityShipping -
      discountTotal +
      (state.isSalesTaxIncluded ? 0 : taxTotal);

    setTotals({
      subTotal,
      customShipping,
      insurance,
      priorityShipping,
      discount: discountTotal,
      tax: taxTotal,
      total,
    });
  }, [mainProducts, extraProducts, state]);

  useEffect(() => {
    calcTotals();
    const newColumns = props.searchFieldOptions.map((col) => {
      const column = {
        field: col.fieldName,
        headerName: col.displayName,
        editable: false,
      };
      if (col.fieldName === "itemPID") {
        column.width = 200;
      }
      if (col.isCurrency) {
        column.valueFormatter = (value) =>
          currencyFormatter(state.countryCode === "UK" ? "UK" : "US").format(
            value
          );
      }
      if (col.dataType === "boolean") {
        column.renderCell = (params) => (
          <FormGroup>
            <FormControlLabel
              label={""}
              control={
                <Checkbox checked={params.row[col.fieldName]} disabled />
              }
            />
          </FormGroup>
        );
      }
      return column;
    });
    setColumns(newColumns);
  }, [props.searchFieldOptions, state.countryCode, calcTotals]);

  const handleStep = (forward) => {
    window.scrollTo(0, 0);
    setActiveStep(forward);
  };

  const tableHeight = mainProducts.length * 75 + 50;

  return (
    <Box sx={{ pb: 2, flexGrow: 1 }}>
      <Paper
        elevation={2}
        sx={{
          borderRadius: "20px",
          p: 4,
          px: { xs: 2, sm: 4 },
          textAlign: "left",
          maxHeight: "100%",
          height: "calc(100vh - 250px)",
          overflow: "auto",
        }}
      >
        <Grid item xs={12} md={6} mb={4}>
          <SummaryTitle title="Customer Details" />
          <List
            sx={{
              border: "#f2f2f2 1px solid",
              borderRadius: "5px",
              borderTopLeftRadius: "0",
              borderTopRight: "0",
              borderTop: "none",
              p: 0,
            }}
          >
            <SummaryListItem label="Store Country" value={state.storeCountry} />
            {state.storeCountry === "CA" && (
              <ListItem
                sx={{
                  minWidth: "150px",
                  borderBottom: "#f2f2f2 1px solid",
                  pl: 2,
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={true}
                        checked={state.GrenkeyCanadaGST}
                      />
                    }
                    label="Grenke Canada Application GST Only"
                  />
                </FormGroup>
              </ListItem>
            )}
            {state.storeCountry === "US" && (
              <ListItem
                sx={{
                  minWidth: "150px",
                  borderBottom: "#f2f2f2 1px solid",
                  pl: 2,
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox disabled={true} checked={state.isTaxExempt} />
                    }
                    label="Grenke Canada Application GST Only"
                  />
                </FormGroup>
              </ListItem>
            )}
            <SummaryListItem label="First Name" value={state.firstName} />
            <SummaryListItem label="Last Name" value={state.lastName} />
            <SummaryListItem
              label="Phone Number"
              value={parsePhoneNumberFromString(
                state.phoneNumber
              )?.formatInternational()}
            />
            <SummaryListItem
              label="Additional Phone Number"
              value={parsePhoneNumberFromString(
                state.additionalPhoneNumber
              )?.formatInternational()}
            />
            <SummaryListItem label="Email Address" value={state.emailAddress} />
            <SummaryListItem label="Company" value={state.company} />
            <SummaryListItem
              label="Street Address"
              value={state.streetAddress}
            />
            <SummaryListItem label="City" value={state.city} />
            <SummaryListItem label="State" value={state.state} />
            <SummaryListItem label="Country" value={state.country} />
            <SummaryListItem label="Zip" value={state.zip} />
            <Box ml={2}>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    name="isResidential"
                    checked={state.isResidential}
                  />
                }
                label="Is Residential"
              />
            </Box>{" "}
          </List>
        </Grid>

        <Box sx={{ height: tableHeight, width: "100%", mt: 2, mb: 2 }}>
          <SummaryTitle title="Products" />
          {columns && (
            <DataGrid
              sx={{ height: tableHeight }}
              rows={mainProducts}
              columns={columns}
              rowHeight={38}
              disableRowSelectionOnClick
              hideFooter
            />
          )}
        </Box>
        <Grid sx={{ mt: 2 }} item xs={12} md={6} mb={4} sx={{ mt: "76px" }}>
          <SummaryTitle title="Totals" />
          <List
            sx={{
              border: "#f2f2f2 1px solid",
              borderRadius: "5px",
              borderTopLeftRadius: "0",
              borderTopRightRadius: "0",
              borderTop: "none",
              p: 0,
            }}
          >
            <SummaryListItem
              label="Subtotal"
              value={`$${totals.subTotal?.toFixed(2)}`}
            />
            {totals.customShipping !== 0 && (
              <SummaryListItem
                label="+ Custom Shipping"
                value={`$${totals.customShipping?.toFixed(2)}`}
              />
            )}
            {totals.priorityShipping !== 0 && (
              <SummaryListItem
                label="+ Priority Shipping"
                value={`$${totals.priorityShipping?.toFixed(2)}`}
              />
            )}
            {totals.insurance !== 0 && (
              <SummaryListItem
                label="+ Insurance"
                value={`$${totals.insurance?.toFixed(2)}`}
              />
            )}{" "}
            <SummaryListItem
              label="- Discount"
              value={`$${totals.discount?.toFixed(2)}`}
            />
            <SummaryListItem
              label={state.isSalesTaxIncluded ? "Tax (incl)" : "+ Tax"}
              value={`$${totals.tax?.toFixed(2)}`}
            />
            <SummaryListItem
              label="Final Total"
              value={`$${totals.total?.toFixed(2)}`}
            />
          </List>
        </Grid>
      </Paper>
      <Paper
        elevation={2}
        sx={{
          width: "80%",
          m: "auto",
          mt: 2,
          p: 1,
          pr: 2,
          pt: "8px",
          borderRadius: "20px",
        }}
      >
        <MobileStepper
          variant="progress"
          steps={4}
          position="static"
          activeStep={activeStep}
          sx={{ p: 0 }}
          nextButton={
            <Button size="small" onClick={handleSubmit}>
              Confirm
            </Button>
          }
          backButton={
            <Button size="small" onClick={() => handleStep(false)}>
              Back
            </Button>
          }
        />
      </Paper>
    </Box>
  );
}
