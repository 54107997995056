import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";

const ResendConfirmationModalContent = ({ data }) => {
  return (
    <Box>
      <DialogContentText>
        Are you sure you would like to resend an order confirmation to{" "}
        {data.email} for order {data.orderNumber}?
      </DialogContentText>
    </Box>
  );
};
export default ResendConfirmationModalContent;
