import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import { inventorySuppliers, inventoryWarehouses } from "../../../common";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";

const CreateInventoryDetails = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState((prevState) => ({
      warehouse: prevState.warehouse || "",
      supplier: prevState.supplier || "",
      date: prevState.date || dayjs(new Date()),
      notes: prevState.notes || "",
      containerSize: prevState.containerSize || "",
    }));
  }, []);

  const handleDateChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      date: e["$d"],
    }));
  };

  const handleChange = (item, name) => {
    setState((prevState) => ({
      ...prevState,
      [name]: item,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format="DD/MM/YYYY"
          sx={{ width: "100%", maxWidth: 200 }}
          onChange={handleDateChange}
          value={state.date}
          label="Date"
        />
      </LocalizationProvider>

      <Autocomplete
        sx={{ width: "100%" }}
        value={state.warehouse}
        onChange={(event, newValue) => handleChange(newValue, "warehouse")}
        options={inventoryWarehouses}
        renderInput={(params) => (
          <TextField label="Warehouse" fullWidth {...params} />
        )}
      />

      <Autocomplete
        sx={{ width: "100%" }}
        value={state.supplier}
        onChange={(event, newValue) => handleChange(newValue, "supplier")}
        options={inventorySuppliers}
        renderInput={(params) => (
          <TextField label="Supplier" fullWidth {...params} />
        )}
      />

      <TextField
        multiline
        rows={3}
        label="Notes"
        value={state.notes}
        onChange={(e) => handleChange(e.target.value, "notes")}
        fullWidth
        sx={{ width: "100%" }}
      />
    </Box>
  );
};

export default CreateInventoryDetails;
