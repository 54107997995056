// src/components/ContainerSizeSelection.js
import React from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";

const ContainerSizeSelection = () => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  const handleContainerSizeSelection = (event) => {
    const size = event.target.value;
    setState((prevState) => ({
      ...prevState,
      containerSize: size,
    }));
  };
  return (
    <Box>
      <FormControl sx={{ width: "100%", mt: 2 }}>
        <InputLabel id="container-size-label">Container Size</InputLabel>
        <Select
          labelId="container-size-label"
          id="container-size"
          value={state.containerSize || 20}
          label="Container Size"
          onChange={handleContainerSizeSelection}
        >
          <MenuItem value={20}>20ft Container</MenuItem>
          <MenuItem value={40}>40ft Container</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default ContainerSizeSelection;
