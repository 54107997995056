import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import DataGrid from "../../../components/Datagrid/Datagrid";
import Error from "../../../components/Error";
import FilterMenu from "../../../components/Filter";

import { useRecoilValue } from "recoil";
import {
  accountingOrderReimbursementState,
  permissionsState,
} from "../../../atoms/atoms";

import MainContainer from "../../../layouts/main";

import Loading from "../../../components/Loading";
import useSearchFields from "../../../hooks/useSearchFields";
import SecondaryAppBar from "../../../components/SecondaryAppBar";
import { NavigationButtons } from "./components/NavigationButtons";

import { DynamicMenu } from "./components/DynamicMenu";
import useModalManager from "../../../hooks/useModalManager";
import DynamicModal from "./components/DynamicModal";
import useOrderReimbursements from "../../../hooks/useOrderReimbursements";

const pages = [
  { subDirectory: "pending", title: "Pending" },
  { subDirectory: "readyForPayment", title: "Ready For Payment" },
  { subDirectory: "paid", title: "Paid" },
  { subDirectory: "completed", title: "Completed" },
];
export default function OrderReimbursements() {
  const page = window.location.href.split("/").filter(Boolean).pop();
  const pageCapitalised = page.charAt(0).toUpperCase() + page.slice(1);

  const orders = useRecoilValue(accountingOrderReimbursementState);

  const [selectedRows, setSelectedRows] = useState(null);

  const permissions = useRecoilValue(permissionsState);

  const { openModal, closeModal, currentModal } = useModalManager();

  const { ordersLoading, ordersError, refetch } = useOrderReimbursements(page);

  useEffect(() => {
    setSelectedRows(null);
  }, [page]);

  const onActionSelect = (action) => {
    const selectedRowIDs = selectedRows.map((row) => row.uid);
    const selectedOrders = orders.filter((order) =>
      selectedRowIDs.includes(order.uid)
    );
    const data = { orders: selectedOrders };

    openModal(action, data);
  };
  const {
    searchFieldOptions,
    columns,
    searchFieldsLoading,
    searchFieldsError,
  } = useSearchFields(
    permissions?.accounting?.orderReimbursements?.[page]?.columns,
    permissions.id,
    `vw_OrderReimbursement_${pageCapitalised}`
  );

  const handleRowSelection = (isNotEmpty, items) => {
    setSelectedRows(isNotEmpty ? items : null);
  };

  return (
    <MainContainer title={"Order Reimbursements"}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {currentModal && (
          <DynamicModal
            table={`vw_OrderReimbursement_${pageCapitalised}`}
            onClose={closeModal}
            currentModal={currentModal}
            refetchOrders={refetch}
          />
        )}
        {(!!ordersError || !!searchFieldsError) && (
          <Error
            sx={{
              width: "100%",
              borderRadius: 0,
            }}
            errorMessage={ordersError || searchFieldsError}
          />
        )}
        <SecondaryAppBar>
          <Box
            display="flex"
            flexDirection={{
              xs: "column",
              md: "column",
              lg: "row",
            }}
            justifyContent="space-between"
            mb={1}
            mt={1}
            width="100%"
          >
            <NavigationButtons
              pathName="/accounting/orderReimbursements"
              pages={pages}
            />
          </Box>
        </SecondaryAppBar>

        {searchFieldsLoading && (
          <Box width={"100px"} margin={"auto"}>
            <Loading />
          </Box>
        )}

        {!searchFieldsLoading && columns && (
          <Box
            sx={{
              height: {
                xs: "calc(100% - 106px)",
                sm: "calc(100% - 115px)",
                lg: "calc(100% - 115px)",
              },
              width: "100%",
              overFlow: "hidden",
            }}
          >
            <DataGrid
              borderTop
              noBorder
              rowID="uid"
              rows={orders}
              rowHeight={75}
              storage={{
                pinned: `orderReimbursement${pageCapitalised}pinnedLeft`,
                columns: `orderReimbursement${pageCapitalised}Columns`,
                visibilityModel: `orderReimbursement${pageCapitalised}Visibility`,
              }}
              dataGridName={"orderReimbursements"}
              searchFields={columns}
              loading={ordersLoading}
              handleRowSelection={handleRowSelection}
              handleSearch={refetch}
              componentProps={{
                toolbar: {
                  tableName: "orderReimbursements",
                  searchFields: searchFieldOptions,
                  handleSearch: refetch,
                  onActionSelect,
                  page,
                  selectedRows,
                  ActionMenu: DynamicMenu,
                },
              }}
            />
          </Box>
        )}
      </Box>
    </MainContainer>
  );
}
