import React from "react";
import { Box, IconButton, TextField, Typography, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ClearIcon from "@mui/icons-material/Clear";
import MentionComponent from "./MentionComponent";
import Message from "./Message";
import Loading from "../../../../components/Loading";
import { useConversationState } from "./useConversationsState";

export const Conversation = ({ conversation, isDefault, users, userMap }) => {
  const {
    messagesEndRef,
    fileInputRef,
    message,
    setMessage,
    messages,
    loading,
    handleSubmit,
    handleFileSelection,
    handleUploadClick,
    isFarApart,
    handleDeleteMessage,
    previewFiles,
    handleRemoveFile,
    handleImageLoad,
  } = useConversationState(conversation);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {loading && (
        <Box width={40} m={"auto"} mt={2}>
          <Loading />
        </Box>
      )}
      {!loading && (
        <Box
          py={4}
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            p: 1,
            px: 2,
          }}
          ref={messagesEndRef}
        >
          <Box
            id="message-list"
            sx={{
              overflowY: "auto",
              maxHeight: "calc(100% - 140px)",
            }}
          >
            {messages.length === 0 && (
              <Box textAlign={"center"} mt={2}>
                <Typography
                  sx={{
                    borderRadius: 1,
                    width: "fit-content",
                    background: "#f4f4f4",
                    py: 1,
                    px: 2,
                    m: "auto",
                  }}
                >
                  Looks like it's pretty quiet here... Start the conversation!
                </Typography>
              </Box>
            )}
            {messages.map((message, index) => (
              <Message
                key={message.id}
                {...message}
                handleImageLoad={handleImageLoad}
                name={userMap[message.userID]?.name}
                image={userMap[message.userID]?.avatar}
                currentUserID={message.userID}
                handleDeleteMessage={handleDeleteMessage}
                createdAt={message.createdAt}
                showTimestamp={
                  index === 0 ||
                  isFarApart(messages[index - 1]?.createdAt, message.createdAt)
                }
              />
            ))}
            <div ref={messagesEndRef} />
          </Box>
        </Box>
      )}

      {previewFiles.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            p: 1,
            borderTop: "1px solid #e0e0e0",
          }}
        >
          {previewFiles.map((file, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                width: 100,
                height: 100,
                border: "1px solid #e0e0e0",
                borderRadius: 1,
                overflow: "hidden",
              }}
            >
              {file.type.startsWith("image/") ? (
                <img
                  src={file.preview}
                  alt={file.name}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <Typography variant="body2" align="center">
                    {file.name}
                  </Typography>
                </Box>
              )}
              <IconButton
                size="small"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                }}
                onClick={() => handleRemoveFile(index)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        py={2}
        sx={{
          boxShadow: "0 -1px 6px -4px black",
        }}
      >
        <input
          type="file"
          multiple
          onChange={handleFileSelection}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <IconButton sx={{ ml: 0.2 }} onClick={handleUploadClick}>
          <UploadFileIcon />
        </IconButton>
        {isDefault ? (
          users && (
            <MentionComponent
              users={users}
              message={message}
              handleChange={(event, newValue) => {
                setMessage(newValue);
              }}
              handleSubmit={handleSubmit}
            />
          )
        ) : (
          <TextField
            onKeyDown={handleKeyDown}
            label={"Send a message... "}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            sx={{ width: "75%", ml: 0.5, mr: 0.5 }}
          />
        )}
        <IconButton onClick={handleSubmit}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Conversation;
