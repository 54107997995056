// components/CreatePermissions.js

import React, { useState, useCallback, useEffect } from "react";

import Permissions from "./Permissions";
import Modal from "../../../../../../components/Modal";

const CreatePermissions = ({
  roleID,
  isEdit,
  message,
  permissionLoading,
  handleUpdateSubmit,
  handleAddSubmit,
  handleCloseModal,
  openModal,
  setMessage,
  title,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("");
  const [checkedState, setCheckedState] = useState({});
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    if (!openModal) {
      setName("");
      setDescription("");
      setIcon("");
      setCheckedState({});
      setNameError(false);
    }
  }, [openModal]);

  const convertToNestedStructure = (flatState) => {
    const nestedState = {};
    console.log(flatState);
    Object.entries(flatState).forEach(([key, value]) => {
      if (
        !key.includes(".") ||
        key.endsWith(".columns") ||
        key.endsWith(".actions") ||
        key.endsWith(".products") ||
        key.endsWith(".orderDetails") ||
        key.endsWith(".orders") ||
        key.endsWith(".currentStock") ||
        key.endsWith(".predictions")
      ) {
        return;
      }

      const parts = key.split(".");
      let current = nestedState;

      for (let i = 0; i < parts.length; i++) {
        if (i === parts.length - 1) {
          // We've reached the end of the path, set the value
          current[parts[i]] = value;
        } else {
          // We're still traversing the path
          if (current[parts[i]] === undefined) {
            current[parts[i]] = {};
          } else if (typeof current[parts[i]] !== "object") {
            // If the current value is not an object (e.g., it's a boolean),
            // convert it to an object with a value property
            current[parts[i]] = { _value: current[parts[i]] };
          }
          current = current[parts[i]];
        }
      }
    });
    console.log(nestedState);
    return nestedState;
  };

  const handleSubmit = useCallback(() => {
    if (!name) {
      setNameError(true);
      return;
    }
    setNameError(false);

    let newRole = convertToNestedStructure(checkedState);
    newRole.name = name;
    newRole.description = description;
    newRole.icon = icon;

    if (isEdit) {
      handleUpdateSubmit(roleID, newRole);
    } else {
      handleAddSubmit(newRole);
    }
  }, [
    name,
    checkedState,
    description,
    icon,
    isEdit,
    roleID,
    handleUpdateSubmit,
    handleAddSubmit,
  ]);

  return (
    <Modal
      large
      title={title}
      openModal={openModal}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
      loading={permissionLoading}
      errorMsg={message}
    >
      <Permissions
        icon={icon}
        setIcon={setIcon}
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        checkedState={checkedState}
        setCheckedState={setCheckedState}
        open={openModal}
        roleID={roleID}
        isEdit={isEdit}
        setMessage={setMessage}
        nameError={nameError}
      />
    </Modal>
  );
};

export default CreatePermissions;
