import React, { useState, useEffect, useCallback, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { activeStepSelector } from "../../../selectors/selectors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MobileStepper from "@mui/material/MobileStepper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteModal from "../../../components/DeleteModal";
import CustomFooter from "../../../components/Datagrid/Footer";
import DataGrid from "../../../components/Datagrid/Datagrid";
import AddMenu from "../components/AddMenu";
import { errorMsg } from "../../../common";
import {
  mainProductsState,
  extraProductsState,
  orderDetailsState,
  activeStepState,
  permissionsState,
} from "../../../atoms/atoms";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

export default function Products(props) {
  const {
    updateDiscountSymbol,
    applyDiscount,
    productPIDs,
    searchFieldOptions,
    error,
    updateProductValues,
  } = props;

  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowId, setRowId] = useState("");
  const [warning, setWarning] = useState("");

  const permissions = useRecoilValue(permissionsState);
  const [mainProducts, setMainProducts] = useRecoilState(mainProductsState);
  const [extraProducts, setExtraProducts] = useRecoilState(extraProductsState);
  const [orderState, setOrderState] = useRecoilState(orderDetailsState);
  const activeStep = useRecoilValue(activeStepState);
  const setActiveStep = useSetRecoilState(activeStepSelector);

  const dataGridRef = useRef(null);
  const inputEl = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenDeleteModal = (id) => {
    setRowId(id);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const updateDiscounts = useCallback(() => {
    const discountPerProduct = orderState.discount / (mainProducts.length + 1);
    if (discountPerProduct !== 0 && mainProducts.length !== 0) {
      setMainProducts((prevProducts) =>
        prevProducts.map((product) => ({
          ...product,
          discount: discountPerProduct,
        }))
      );
    }
  }, [orderState.discount, mainProducts.length, setMainProducts]);

  const handleDeleteProduct = useCallback(() => {
    updateDiscounts();
    setMainProducts((prevProducts) => {
      const newProducts = prevProducts.filter((p) => p.id !== rowId);
      return newProducts.map((product, index) => ({
        ...product,
        id: index,
      }));
    });
    setOpenDeleteModal(false);
  }, [rowId, setMainProducts, updateDiscounts]);

  const editProduct = useCallback(
    (id, values) => {
      setMainProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === id ? { ...product, ...values } : product
        )
      );
    },
    [setMainProducts]
  );

  const processRowUpdate = useCallback(
    (updateRow) => {
      const newValues = updateProductValues(updateRow, updateRow.discount);
      editProduct(updateRow.id, newValues);
      return newValues;
    },
    [updateProductValues, editProduct]
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    console.error("Error updating row:", error);
  }, []);

  const handleStep = useCallback(
    (forward) => {
      window.scrollTo(0, 0);
      setActiveStep(forward);
    },
    [setActiveStep]
  );

  const handleAddProduct = useCallback(
    (option) => {
      if (option === "Add Custom Shipping") {
        setOrderState((prevState) => ({
          ...prevState,
          customShippingPrice: 0,
        }));
        setExtraProducts((prev) => ({
          ...prev,
          customShipping: { itemPID: "CUSTOM-SHIPPING" },
        }));
      } else if (option === "Add Priority Shipping") {
        setExtraProducts((prev) => ({
          ...prev,
          priorityShipping: { itemPID: "XTRA-1-PTYSHIP-0-0" },
        }));
        setOrderState((prevState) => ({
          ...prevState,
          priorityShippingPrice: 0,
        }));
      } else if (option === "Add Insurance") {
        setExtraProducts((prev) => ({
          ...prev,
          insurance: { itemPID: "XTRA-1-INSURE1-0-0" },
        }));
        setOrderState((prevState) => ({
          ...prevState,
          freightShippingPrice: 0,
        }));
      } else if (option === "Add Product") {
        updateDiscounts();
        const taxRate = orderState.GrenkeyCanadaGST ? 0.05 : orderState.taxRate;
        const newProduct = {
          id:
            mainProducts.length !== 0
              ? mainProducts[mainProducts.length - 1].id + 1
              : 0,
          itemPID: "",
          description: "",
          discount: orderState.discount / (mainProducts.length + 1),
          quantity: 1,
          isSalesTaxIncluded: orderState.isSalesTaxIncluded,
          linePrice: 0,
          salesTaxDollarAmount: 0,
          salesTaxPercent: `${taxRate * 100}%`,
          taxRate: taxRate,
          isRefurb: orderState.isRefurb,
        };

        setMainProducts((prevState) => [...prevState, newProduct]);

        setTimeout(() => {
          if (dataGridRef.current && dataGridRef.current.startCellEditMode) {
            dataGridRef.current.startCellEditMode({
              id: newProduct.id,
              field: "description",
            });
          }
        }, 0);
      } else {
        handleOpen();
      }
    },
    [
      orderState,
      setOrderState,
      setExtraProducts,
      updateDiscounts,
      mainProducts,
      setMainProducts,
    ]
  );

  useEffect(() => {
    const inactiveProducts = mainProducts.filter(
      (product) =>
        product.itemPID && !productPIDs.some((p) => p.label === product.itemPID)
    );

    if (inactiveProducts.length > 0) {
      const inactiveProductIDs = inactiveProducts.map(
        (product) => product.itemPID
      );
      setWarning(
        `Warning: The following line items contain inactive products ('Carry' is not ticked): ${inactiveProductIDs.join(
          ", "
        )}`
      );
    } else {
      setWarning("");
    }
  }, [mainProducts, productPIDs]);

  const disableNext = mainProducts.some((row) => !row.itemPID);

  const columnHeaderHeight = 70;
  const footerHeight = 196;
  const rowHeight = 85;

  let tableHeight =
    mainProducts.length * rowHeight + columnHeaderHeight + footerHeight;

  if (extraProducts.customShipping) {
    tableHeight += 50;
  }
  if (extraProducts.insurance) {
    tableHeight += 42;
  }
  if (extraProducts.priorityShipping) {
    tableHeight += 42;
  }

  return (
    <Box pb={2}>
      {error && (
        <Stack
          sx={{ width: { xs: "100%", sm: "80%" }, margin: "auto", mb: 2 }}
          spacing={2}
        >
          <Alert severity="error">{errorMsg}</Alert>
        </Stack>
      )}
      {warning && (
        <Stack
          sx={{ width: { xs: "100%", sm: "80%" }, margin: "auto", mb: 2 }}
          spacing={2}
        >
          <Alert severity="warning">{warning}</Alert>
        </Stack>
      )}
      <Paper
        elevation={2}
        sx={{
          borderRadius: "20px",
          p: 4,
          px: { xs: 2, sm: 4 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: { xs: "90%", md: "90%", lg: "90%" },
          mx: "auto",
        }}
      >
        <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
          <Box mb={1}>
            {(permissions.id === "Super Admin" ||
              permissions?.offlineOrders?.products?.actions?.add) && (
              <AddMenu
                hasPriorityShipping={!!extraProducts.priorityShipping}
                hasInsurance={!!extraProducts.insurance}
                hasCustomShipping={!!extraProducts.customShipping}
                handleAddProduct={handleAddProduct}
              />
            )}
          </Box>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            sx={{ mb: 1, width: "100%", alignContent: "center" }}
          >
            Edit Line Items
          </Typography>
          <Box width={"40px"}></Box>
        </Box>

        <Divider sx={{ mb: 3, width: "100%" }} />
        {productPIDs && mainProducts.length !== 0 && (
          <Box
            sx={{
              height: tableHeight,
              width: "100%",
              maxWidth: "100%",
              mx: "auto",
              mb: 4,
              mt: 2,
              overflow: "auto",
            }}
          >
            {mainProducts && (
              <DataGrid
                dataGridRef={dataGridRef}
                isEditable
                rows={mainProducts}
                storage={{
                  columnsWidths: "productsWidths",
                  columns: "productsColumns",
                  visibilityModel: "productsVisibility",
                }}
                dataGridName="products"
                searchFields={searchFieldOptions}
                rowID="id"
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                footer={!error && CustomFooter}
                componentProps={{ footer: { handleOpen, handleAddProduct } }}
                handleOpenDeleteModal={handleOpenDeleteModal}
                productPIDs={productPIDs.filter(
                  (product) => product.itemPID?.split("-")[0] !== "XTRA"
                )}
                permissions={permissions?.offlineOrders?.products?.columns}
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f5f5f5",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              />
            )}
          </Box>
        )}
      </Paper>
      <Paper
        elevation={2}
        sx={{
          width: { xs: "80%", sm: "30%" },
          m: "auto",
          mt: 2,
          p: 1,
          pt: "8px",
          borderRadius: "20px",
        }}
      >
        <MobileStepper
          variant="progress"
          steps={4}
          position="static"
          activeStep={activeStep}
          sx={{ p: 0 }}
          nextButton={
            <Button
              disabled={!!warning || !!disableNext}
              size="small"
              onClick={() => handleStep(true)}
            >
              Next
            </Button>
          }
          backButton={
            <Button size="small" onClick={() => handleStep(false)}>
              Back
            </Button>
          }
        />
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onKeyUp={(e) => {
          const ENTER = 13;
          if (e.keyCode === ENTER) {
            handleClose();
            applyDiscount(inputEl.current.value);
          }
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", height: "70px", alignItems: "center" }}>
            <Typography width="160px">Add Order Discount</Typography>
            <FormControl sx={{ width: "65px", mr: 1 }}>
              <Select
                value={orderState.discountSymbol}
                onChange={updateDiscountSymbol}
              >
                <MenuItem value={10}>$</MenuItem>
                <MenuItem value={20}>%</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              id="discount"
              label="Discount"
              name="discount"
              type="number"
              inputRef={inputEl}
              autoComplete="discount"
              sx={{ mr: 2, width: "150px" }}
            />
            <Button
              onClick={() => {
                handleClose();
                applyDiscount(inputEl.current.value);
              }}
              sx={{ height: "35px" }}
              variant="outlined"
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
      <DeleteModal
        message="Are you sure you want to delete this product?"
        openDeleteModal={openDeleteModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDeleteItem={handleDeleteProduct}
      />
    </Box>
  );
}
