// "vw_OrderReimbursement_Pending",
//   "vw_OrderReimbursement_ReadyForPayment",
//   "vw_OrderReimbursement_Paid",
//   "vw_OrderReimbursement_Completed";

import { useEffect, useRef, useState } from "react";
import { getOrderReimbursements } from "../api/OrderReimbursements";
import { loadFilter, loadSearchTerm } from "../common";
import { getFilter } from "../api/Filters";
import { buildQueryString } from "../utils/filterUtils";
import axios, { isCancel } from "axios";
import { useRecoilState } from "recoil";
import { accountingOrderReimbursementState } from "../atoms/atoms";

const useOrderReimbursements = (table, permissions) => {
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [ordersError, setOrdersError] = useState(null);
  const [, setOrders] = useRecoilState(accountingOrderReimbursementState);
  const abortControllerRef = useRef(null);

  const fetchOrders = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    abortControllerRef.current = source;

    setOrdersLoading(true);
    setOrders([]);
    try {
      const filterId = loadFilter("orderReimbursements");
      const searchTerm = loadSearchTerm("orderReimbursements");
      const filterDetails = filterId
        ? (await getFilter(filterId)).filter
        : null;
      const andOr = filterDetails?.andOr || "and";
      const fetchQuery = buildQueryString(searchTerm, filterDetails, andOr);

      const data = await getOrderReimbursements(
        table,
        fetchQuery,
        source.token
      );
      // Check if the request was canceled before continuing
      if (source.token.reason) {
        console.log("Fetch canceled:", source.token.reason.message);
        return;
      }
      setOrders(data.results);
    } catch (err) {
      if (isCancel(err)) {
        console.log("Request canceled:", err.message);
      } else {
        console.error("Error fetching orders:", err);
        setOrdersError(
          "An error occurred while fetching order reimbursements. Please try again later."
        );
      }
    } finally {
      if (!source.token.reason) {
        // Only set loading to false if the requests were not canceled
        setOrdersLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchOrders();
  }, [table]);

  return { ordersLoading, ordersError, refetch: fetchOrders };
};
export default useOrderReimbursements;
