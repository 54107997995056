import axios from './axios'
import { API_ORDER_LINK_URL } from '../consts'

function getOfflineOrder(orderId) {
  return axios.get(`${API_ORDER_LINK_URL}/offlineOrders?orderId=${orderId}`).then(response => (response.data))
}

function getAllOfflineOrders() {
    return axios.get(API_ORDER_LINK_URL + '/listOfflineOrders').then(response => (response.data))
}

function getOfflineOrdersFields() {
    return axios.get(API_ORDER_LINK_URL + '/getSummarizedFields').then(response => (response.data))
}

function updateOfflineOrder( order) {
    return axios.post(API_ORDER_LINK_URL + '/offlineOrders', order).then(response => (response.data))
}

export {getOfflineOrder, getAllOfflineOrders, getOfflineOrdersFields, updateOfflineOrder}