import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function getSettings(dataGridName, type) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/userSettings?dataGridName=${dataGridName}&type=${type}`,
      {
        dataGridName: dataGridName,
        type: type,
      }
    )
    .then((response) => response.data);
}

function updateSettings(settings) {
  return axios
    .patch(`${API_ORDER_LINK_URL}/userSettings`, {
      settings,
    })
    .then((response) => response.data);
}

export { getSettings, updateSettings };
