import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Menu, MenuItem, Typography } from "@mui/material";
import DataGrid from "../../../components/Datagrid/Datagrid";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/DeleteModal";
import { performInventoryAction } from "../../../api/InventoryOrders";
import Modal from "../../../components/Modal";
import HistoryModal from "../modals/historyModal";

export const InventoryTableView = ({
  page,
  sortedRows,
  columns,
  ordersLoading,
  searchFieldOptions,
  refetch,
  handleRowSelection,
  handleSelectionCheck,
  groupingModel,
  setGroupingModel,
  selectedRows,
  toggleView,
  view,
  selectedWarehouse,
}) => {
  let navigate = useNavigate();
  const isStockOrders = page === "stockOrders";

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const onRowClick = (params) => {
    if (
      params?.row?.warehouseordernumber ||
      (params.field !== "flag" &&
        params.field !== "productimage" &&
        params.formattedValue)
    ) {
      navigate(
        `/inventoryOrder/${
          params?.row?.warehouseordernumber || params.formattedValue
        }`
      );
    }
  };

  const handleContextMenu = ({ rowData, position }) => {
    if (!isStockOrders) {
      setContextMenu({ rowData, position });
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // Handler for opening delete modal
  const handleOpenDeleteModal = (id) => {
    setDeleteItemId(id);
    setDeleteModalOpen(true);
    handleCloseContextMenu();
  };

  // Handler for closing delete modal
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setDeleteItemId(null);
  };

  // Handler for delete action
  const handleDeleteItem = async () => {
    setDeleteLoading(true);
    try {
      const payload = {
        action: "DELETE_INVENTORY_ORDER",
        details: [
          {
            warehouseOrderNumber: deleteItemId,
          },
        ],
      };
      const data = await performInventoryAction(payload);
      console.log(data);
      refetch(); // Refresh the data
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      setDeleteLoading(false);
      handleCloseDeleteModal();
    }
  };

  // Handler for opening history modal
  const handleOpenHistoryModal = () => {
    if (contextMenu) {
      setSelectedItem(contextMenu.rowData);
      setHistoryModalOpen(true);
      handleCloseContextMenu();
    }
  };

  // Handler for closing history modal
  const handleCloseHistoryModal = () => {
    setHistoryModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          height: {
            xs: `calc(100% - ${
              page === "stockPredictions" && selectedWarehouse !== "all"
                ? 200
                : 105
            }px)`,
            md: "calc(100% - 50px)",
            lg: "calc(100% - 50px)",
          },
          width: "100%",
          overflow: "hidden",
        }}
      >
        <DataGrid
          noBorder
          rowID={isStockOrders ? "ss_rowid" : "sid"}
          rows={sortedRows}
          onRowClick={onRowClick}
          rowHeight={75}
          storage={{
            pinned: `${page}pinnedLeft`,
            columns: `${page}Columns`,
            visibilityModel: `${page}Visibility`,
          }}
          dataGridName={page}
          searchFields={columns}
          loading={ordersLoading}
          handleRowSelection={isStockOrders && handleRowSelection}
          handleSearch={refetch}
          componentProps={{
            toolbar: {
              tableName: page,
              searchFields: searchFieldOptions,
              handleSearch: refetch,
              page,
              viewType: isStockOrders ? "card" : "tile",
              selectedRows,
              toggleView: toggleView,
              view: view,
            },
          }}
          groupingModel={groupingModel}
          onGroupingModelChange={(newModel) => setGroupingModel(newModel)}
          groupingColDef={{
            headerName: isStockOrders ? "Warehouse Order Number" : "Category",
          }}
          handleSelectionCheck={handleSelectionCheck}
          isGroupExpandedByDefault={() => isStockOrders}
          handleOpenDeleteModal={handleOpenDeleteModal}
          onContextMenu={handleContextMenu}
        />
      </Box>
      {!isStockOrders && (
        <Menu
          open={Boolean(contextMenu)}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu
              ? { top: contextMenu.position.y, left: contextMenu.position.x }
              : undefined
          }
        >
          <MenuItem onClick={handleOpenHistoryModal}>Show History</MenuItem>
        </Menu>
      )}
      {deleteItemId && (
        <DeleteModal
          openDeleteModal={deleteModalOpen}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDeleteItem={handleDeleteItem}
          message={`Are you sure you want to delete order ${deleteItemId}?`}
          loading={deleteLoading}
        />
      )}
      {selectedItem && (
        <HistoryModal
          open={historyModalOpen}
          onClose={handleCloseHistoryModal}
          item={selectedItem}
          searchFields={searchFieldOptions}
        />
      )}
    </Box>
  );
};
