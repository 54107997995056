import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import SimpleDataGrid from "../../../../components/Datagrid/SimpleDataGrid";

import DialogContentText from "@mui/material/DialogContentText";

import { Checkbox } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../../atoms/atoms";
import { getTableHeight } from "../../../../common";

const UnmarkAsPaymentReceived = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders.map((order) => {
        return {
          ordernumber: order.wixlocalordernumber,
          id: order.uid,
          descriptionshort: order.descriptionshort,
          buyerfullname: order.buyerfullname,
          qty: order.qty,
          supplierperunitcost: order.supplierperunitcost,
          internationalshippingcost: order.internationalshippingcost,
          totalprice: order.totalproductcost,
          shippingcurrency: order.shippingcurrency,
          suppliertotalcostowed: order.suppliertotalcostowed,
          readyforpaymentcheck: order.readyforpaymentcheck,
        };
      }),
    });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "UID",
      flex: 3,
      sortable: false,
    },
    {
      field: "ordernumber",
      headerName: "Order Number",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Full Name",
      field: "buyerfullname",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Model",
      field: "descriptionshort",
      flex: 3,
      sortable: false,
    },
    {
      headerName: "Qty",
      field: "qty",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Per Unit Cost",
      field: "supplierperunitcost",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Total Product Price",
      field: "totalprice",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "International Shipping Cost",
      field: "internationalshippingcost",
      flex: 2,
      sortable: false,
    },

    {
      headerName: "Shipping Currency",
      field: "shippingcurrency",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Total Cost Owed",
      field: "suppliertotalcostowed",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Ready For Payment",
      field: "readyforpaymentcheck",
      flex: 2,
      sortable: false,

      renderCell: (params) => (
        <Checkbox
          checked={params.value === true} // Assumes the field value is a boolean
          disabled // This makes the checkbox non-interactive
          inputProps={{ "aria-label": "Ready For Payment" }}
        />
      ),
    },
  ];

  const tableHeight = getTableHeight(state?.rows?.length);
  return (
    <Box>
      <DialogContentText>
        The following orders will be unmarked as payment received.{" "}
      </DialogContentText>
      <Box sx={{ margin: "auto", mt: 2, height: tableHeight, width: "100%" }}>
        {state.rows && (
          <SimpleDataGrid
            sx={{ height: tableHeight }}
            rows={state.rows}
            columns={columns}
            hideFooter
            disableColumnMenu
            isEditable={false}
          />
        )}
      </Box>
    </Box>
  );
};
export default UnmarkAsPaymentReceived;
