import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

const SecondaryAppBar = ({ sx, px, height, children }) => {
  return (
    <AppBar
      position={"relative"}
      sx={{
        ...sx,
        px,
        height: height,
        background: "white",
      }}
    >
      <Toolbar
        sx={{ px: "8px !important", minHeight: `${height}px !important` }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};
export default SecondaryAppBar;
