import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import GridViewIcon from "@mui/icons-material/GridView";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    borderRadius: theme.shape.borderRadius,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    background: "white",
  },
  " &.Mui-selected:hover": {
    color: theme.palette.primary.main,
    background: "divider",
  },

  padding: theme.spacing(1),
  minWidth: "48px",
}));

const ViewToggleButtons = ({ view, onToggleView, viewType }) => {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <StyledToggleButtonGroup
        value={view}
        exclusive
        onChange={(event, newView) => {
          if (newView !== null) {
            onToggleView(newView);
          }
        }}
        aria-label="view toggle"
      >
        <Tooltip title="Table View">
          <StyledToggleButton value="table">
            <TableChartOutlinedIcon fontSize="medium" />
          </StyledToggleButton>
        </Tooltip>
        {viewType === "card" && (
          <Tooltip title="Card View">
            <StyledToggleButton value="card">
              <ViewCarouselIcon fontSize="medium" />
            </StyledToggleButton>
          </Tooltip>
        )}{" "}
        {viewType === "tile" && (
          <Tooltip title="Tile">
            <StyledToggleButton value="tile">
              <GridViewIcon fontSize="medium" />
            </StyledToggleButton>
          </Tooltip>
        )}
      </StyledToggleButtonGroup>
    </Box>
  );
};

export default ViewToggleButtons;
