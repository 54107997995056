import {
  GridFooterContainer,
  gridClasses,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const CustomFooter = () => {
  const apiRef = useGridApiContext();
  const rowCount = apiRef.current.getRowsCount();

  return (
    <GridFooterContainer
      sx={{
        minHeight: "20px",
        alignItems: "center",
        "& .MuiTablePagination-root": {
          height: "100%",
        },
      }}
      className={gridClasses.footerContainer}
    >
      <Box sx={{ display: { xs: "none", sm: "flex" } }} flex={1}></Box>
      <Typography
        sx={{
          marginRight: 1,
          fontSize: 12,
        }}
      >
        Total Rows: {rowCount}
      </Typography>
    </GridFooterContainer>
  );
};
