import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import { Column } from "./Column";
// Create a new context
export const CssPropertiesContext = React.createContext();

// Context provider component
const CssPropertiesProvider = ({ children, value }) => (
  <CssPropertiesContext.Provider value={value}>
    {children}
  </CssPropertiesContext.Provider>
);
export const Board = ({ table, columns, searchFields }) => {
  const [state, setState] = useState(null);

  const [version, setVersion] = useState(0);

  useEffect(() => {
    setState(columns);
  }, [columns]);

  const onDragEnd = (result) => {
    const { source, destination, type } = result;

    // Nothing happens if the item is dropped outside the list
    if (!destination) {
      return;
    }

    // Destructuring for clarity
    const sourceIndex = source.index;
    const destIndex = destination.index;

    const sourceDroppableId = source.droppableId;
    const destDroppableId = destination.droppableId;
    // Clone the current state
    let newState = [...state];

    if (type === "column") {
      // Handling column reordering
      const [removedColumn] = newState.splice(sourceIndex, 1);
      newState.splice(destIndex, 0, removedColumn);
    } else {
      // Handling card reordering or moving between columns

      // Find the column from which the card is dragged
      const sourceColumn = newState.find((col) => col.id === sourceDroppableId);
      // Find the column in which the card is dropped
      const destColumn = newState.find((col) => col.id === destDroppableId);

      // Moving within the same column
      if (sourceColumn.id === destColumn.id) {
        const [removedCard] = sourceColumn.cards.splice(sourceIndex, 1);
        sourceColumn.cards.splice(destIndex, 0, removedCard);
      } else {
        // Moving from one column to another
        const [removedCard] = sourceColumn.cards.splice(sourceIndex, 1);

        destColumn.cards.splice(destIndex, 0, removedCard);
      }
    }
    setVersion((v) => v + 1); // Increment version to force re-render

    setState(() => newState);
  };

  const cssPropertiesLookup = searchFields.reduce((acc, field) => {
    acc[field.fieldName] = field;
    return acc;
  }, {});

  return (
    <CssPropertiesProvider value={cssPropertiesLookup}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <Droppable
            droppableId="all-droppables"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  display: "flex",
                  overflowX: "auto",
                  p: 1,
                  gap: 1,
                  pb: 0,
                  flexGrow: 1,
                }}
              >
                {state &&
                  Object.entries(state).map(([columnId, column], index) => (
                    <Column
                      key={columnId}
                      listKey={version}
                      column={column}
                      index={index}
                      table={table}
                    />
                  ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>
      </DragDropContext>
    </CssPropertiesProvider>
  );
};
