import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import TextField from "@mui/material/TextField";
import {
  Box,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDateFormat } from "../../contexts/DateFormatContext";
import ClearIcon from "@mui/icons-material/Clear";

dayjs.extend(utc);
dayjs.extend(timezone);

const officeTimezoneMap = {
  AU: "Australia/Brisbane",
  CA: "America/Vancouver",
  NZ: "Pacific/Auckland",
  US: "America/Chicago",
  UK: "Europe/London",
};

const CustomDatePicker = ({
  label,
  value,
  onChange,
  clearable,
  minDate,
  timezone,
  startingTimezone = "AU",
  hideTimezoneSelector = false,
  required,
}) => {
  const { dateFormat } = useDateFormat();
  const [dateValue, setDateValue] = useState(value ? dayjs(value) : null);
  const [selectedTimezone, setSelectedTimezone] = useState(startingTimezone);

  useEffect(() => {
    if (value) {
      const newDate = dayjs(value).tz(
        officeTimezoneMap[selectedTimezone],
        true
      );
      setDateValue(newDate);
    }
  }, [value, selectedTimezone]);

  useEffect(() => {
    setSelectedTimezone(timezone || startingTimezone);
  }, [timezone, startingTimezone]);

  const handleDateChange = (newDate) => {
    setDateValue(newDate);
    onChange(newDate);
  };

  const handleTimezoneChange = (event) => {
    const newTimezone = event.target.value;
    setSelectedTimezone(newTimezone);
    if (dateValue) {
      const newDate = dateValue.tz(officeTimezoneMap[newTimezone], true);
      setDateValue(newDate);
      onChange(newDate);
    }
  };

  const clearDate = () => {
    setDateValue(null);
    onChange(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 1,
        maxWidth: hideTimezoneSelector ? 300 : 400,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={!!label ? `${label} ${required ? "*" : ""}` : ""}
          value={dateValue}
          onChange={handleDateChange}
          format={dateFormat}
          minDate={minDate}
          components={{
            TextField: TextField,
          }}
          componentsProps={{
            textField: {
              fullWidth: true,
              InputProps: {
                startAdornment: clearable ? (
                  <InputAdornment position="start">
                    <IconButton onClick={clearDate}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              },
            },
          }}
        />
      </LocalizationProvider>

      {!hideTimezoneSelector && (
        <FormControl sx={{ width: 180 }}>
          <InputLabel id="timezone-select-label">Timezone</InputLabel>
          <Select
            label="Timezone"
            labelId="timezone-select-label"
            id="timezone-select"
            value={selectedTimezone}
            onChange={handleTimezoneChange}
          >
            {Object.keys(officeTimezoneMap).map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default CustomDatePicker;
