import { useState, useEffect } from "react";
import { getFields } from "../api/SearchFields";
import { useNavigate } from "react-router-dom"; // Adjust the import path as needed

const useSearchFields = (permissions, role, entityType) => {
  const navigate = useNavigate();

  const [searchFieldOptions, setSearchFieldOptions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchFieldsLoading, setSearchFieldsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isComponentMounted = true;

    const fetchSearchFields = async () => {
      setSearchFieldsLoading(true);
      try {
        const rsp = await getFields(entityType);
        if (isComponentMounted) {
          const newSearchFieldOptions = [];
          const newColumns = [];
          rsp.searchFields.forEach((field, i) => {
            const fieldName = field.fieldName;
            const columns = permissions;
            if (
              fieldName === "everywhere" ||
              role?.id === "Super Admin" ||
              (columns && columns[fieldName])
            ) {
              newColumns.push({ id: i, ...field });
              newSearchFieldOptions.push({
                key: i,
                label: field.displayName,
                ...field,
              });
            }
          });

          setColumns(newColumns);
          setSearchFieldOptions(newSearchFieldOptions);
        }
      } catch (err) {
        console.error(err);
        if (isComponentMounted) {
          setError("Failed to fetch search fields");
        }
      } finally {
        if (isComponentMounted) {
          setSearchFieldsLoading(false);
        }
      }
    };

    fetchSearchFields();

    return () => {
      isComponentMounted = false;
    };
  }, [permissions, navigate]);

  return { searchFieldOptions, columns, searchFieldsLoading, error };
};

export default useSearchFields;
