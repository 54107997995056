import {
  Box,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";

const FormField = ({
  error,
  label,
  value,
  onChange,
  multiline = false,
  required,
  helperText,
}) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    error={!!error}
    helperText={error || helperText}
    multiline={multiline}
    sx={{ mb: 2 }}
    required={required}
  />
);

export const EditForm = ({
  state,
  errors,
  addressType,
  handleStateChange,
  handleAddressTypeChange,
  handleSubmit,
  loading,
}) => (
  <Box>
    {errors.main && <Alert color="error">{errors.main}</Alert>}
    <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
      Personal Details:
    </Typography>
    <Divider sx={{ mb: 3 }} />
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.firstName}
          label="First Name"
          value={state.firstName}
          onChange={handleStateChange("firstName")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.lastName}
          label="Last Name"
          value={state.lastName}
          onChange={handleStateChange("lastName")}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          label="Company"
          value={state.company}
          onChange={handleStateChange("company")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.email}
          label="Email"
          value={state.email}
          onChange={handleStateChange("email")}
          helperText="Important: After updating the email address, please also update your profile in Active Campaign"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.phone}
          label="Phone Number"
          value={state.phone}
          onChange={handleStateChange("phone")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          label="Additional Phone Number"
          value={state.additionalPhone}
          onChange={handleStateChange("additionalPhone")}
        />
      </Grid>
    </Grid>

    <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
      Billing Details:
    </Typography>
    <Divider sx={{ mb: 3 }} />
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormField
          label="Company"
          value={state.billingCompany}
          onChange={handleStateChange("billingCompany")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          error={errors.billingStreet}
          label="Street"
          value={state.billingStreet}
          onChange={handleStateChange("billingStreet")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.billingCity}
          label="City"
          value={state.billingCity}
          onChange={handleStateChange("billingCity")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          label="State"
          value={state.billingState}
          onChange={handleStateChange("billingState")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.billingPostcode}
          label="Postcode"
          value={state.billingPostcode}
          onChange={handleStateChange("billingPostcode")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.billingCountry}
          label="Country"
          value={state.billingCountry}
          onChange={handleStateChange("billingCountry")}
          required
        />
      </Grid>
    </Grid>

    <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
      Shipping Details:
    </Typography>
    <Divider sx={{ mb: 3 }} />
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormField
          label="Company"
          value={state.shippingCompany}
          onChange={handleStateChange("shippingCompany")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          error={errors.shippingStreet}
          label="Street"
          value={state.shippingStreet}
          onChange={handleStateChange("shippingStreet")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.shippingCity}
          label="City"
          value={state.shippingCity}
          onChange={handleStateChange("shippingCity")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          label="State"
          value={state.shippingState}
          onChange={handleStateChange("shippingState")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.shippingPostcode}
          label="Postcode"
          value={state.shippingPostcode}
          onChange={handleStateChange("shippingPostcode")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          error={errors.shippingCountry}
          label="Country"
          value={state.shippingCountry}
          onChange={handleStateChange("shippingCountry")}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup row value={addressType} onChange={handleAddressTypeChange}>
          <FormControlLabel
            value="commercial"
            control={<Radio />}
            label="Commercial"
          />
          <FormControlLabel
            value="residential"
            control={<Radio />}
            label="Residential"
          />
        </RadioGroup>
      </Grid>
    </Grid>

    <LoadingButton
      loading={loading}
      size="large"
      variant="contained"
      onClick={handleSubmit}
      sx={{ mt: 3 }}
    >
      Submit
    </LoadingButton>
  </Box>
);
