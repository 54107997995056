import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

const Error = ({ errorMessage, sx, severity = true }) => {
  return (
    <Alert
      sx={{
        ...sx,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        ".MuiAlert-icon": { pt: "7px" },
      }}
      severity={severity ? "error" : "success"}
    >
      {" "}
      {errorMessage}{" "}
    </Alert>
  );
};
export default Error;
