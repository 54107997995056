import React, { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { cardViewSettingsState } from "../../atoms/atoms";
import { Draggable } from "react-beautiful-dnd";
import { areEqual } from "react-window";
import { Item } from "./Card";

const MIN_CARD_HEIGHT = 100; // Adjust this value as needed

export const Row = React.memo(function Row(props) {
  const settings = useRecoilValue(cardViewSettingsState);
  const { data, index, style } = props;
  const { table, items, setRowHeight, isInitialRender } = data;
  const item = items[index];
  const rowRef = useRef(null);

  useEffect(() => {
    if (rowRef.current && isInitialRender) {
      const height = Math.max(
        rowRef.current.getBoundingClientRect().height,
        MIN_CARD_HEIGHT
      );

      setRowHeight(index, height);
    }
  }, [index, setRowHeight, isInitialRender]);

  if (!item) {
    return null;
  }

  return (
    <Draggable
      isDragDisabled={!settings.canMoveCards}
      draggableId={item.id}
      index={index}
      key={item.id}
    >
      {(provided) => (
        <div
          ref={rowRef}
          style={{
            ...style,
            height: isInitialRender ? "auto" : style.height,
            minHeight: MIN_CARD_HEIGHT,
          }}
        >
          <Item
            table={table}
            provided={provided}
            item={item}
            style={{ height: "100%" }}
          />
        </div>
      )}
    </Draggable>
  );
}, areEqual);
