import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";

import { createTheme, easing, ThemeProvider } from "@mui/material/styles";
import { resetPassword } from "../../../api/Auth";
import { Paper } from "@mui/material";

const theme = createTheme();

export default function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  const [message, setMessage] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setEmailError(false);
    if (!email) return setEmailError(true);
    setLoading(true);
    resetPassword(email)
      .then((rsp) => {
        setMessage({
          err: false,
          msg: "An email has been sent. Please check your inbox. ",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setMessage({
          err: true,
          msg: "Error: We encountered a problem while attempting to send an email. Please verify your email address and try again. ",
        });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const handlerFunction = (e) => {
      if (e.code === "Enter") {
        e.preventDefault();
        handleSubmit(e);
      }
    };
    window.addEventListener("keydown", handlerFunction);
    // return cleanup function to remove the handler after use
    return () => {
      window.removeEventListener("keydown", handlerFunction);
    };
  }, [email]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        width={{ xs: "90%", sm: "500px" }}
        m={"auto"}
        sx={{ pt: 8, height: "100vh" }}
      >
        <Paper
          sx={{
            p: 4,
            borderRadius: "20px",
            textAlign: "center",
            background: "white",
          }}
        >
          <CssBaseline />

          <Avatar sx={{ m: "auto", my: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate>
            {message && (
              <Stack sx={{ width: "100%", mt: 2, mb: 1 }} spacing={2}>
                <Alert color={message.err ? "error" : "success"}>
                  {message.msg}{" "}
                </Alert>
              </Stack>
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              helperText={emailError && "Email required."}
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
              onClick={handleSubmit}
            >
              Send Email
            </LoadingButton>
            <Box sx={{ textAlign: "left" }}>
              <Link href="login" variant="body2">
                Sign In
              </Link>
            </Box>
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
