import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useRecoilValue } from "recoil";
import { modalState } from "../../../../atoms/atoms";
import { performOrderReimbursementAction } from "../../../../api/OrderReimbursements";
import LoadingButton from "@mui/lab/LoadingButton";

const ActionButtons = ({
  loading,
  onClose,
  onSubmit,
  submitLabel = "Submit",
}) => (
  <>
    <Button
      variant="outlined"
      color="error"
      onClick={onClose}
      sx={{ width: { xs: "120px", sm: "180px" } }}
    >
      Cancel
    </Button>
    <LoadingButton
      loading={loading}
      variant="outlined"
      onClick={onSubmit}
      sx={{ width: { xs: "120px", sm: "180px" } }}
    >
      {submitLabel}
    </LoadingButton>
  </>
);

export const RenderModalActions = ({
  table,
  currentModal,
  onClose,
  handleError,
  refetchOrders,
}) => {
  const state = useRecoilValue(modalState);

  const [loading, setLoading] = useState(false);

  const performAction = (action) => {
    setLoading(true);
    performOrderReimbursementAction(action)
      .then((rsp) => {
        setLoading(false);
        if (rsp) refetchOrders();
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        handleError(
          "Error: there was an issue completing that action. Please try again later. "
        );
      });
  };
  const editCostsSubmit = () => {
    const request = {
      action: "ADD_EDIT_SUPPLIER_COSTS",
      table: table,
      details: state.rows.map((row) => ({
        uid: row.id,
        supplierPerUnitCost: row.supplierperunitcost,
        shippingQuotedCost: row.internationalshippingcost,
        readyForPaymentCheck: state.markAsReady,
      })),
    };
    performAction(request);
  };

  const markReadyForPaymentSubmit = () => {
    const request = {
      action: "SUPPLIER_READY_FOR_PAYMENT",
      table: table,
      details: state.rows.map((row) => ({
        uid: row.id,
        readyForPaymentCheck: true,
      })),
    };
    performAction(request);
  };

  const markAsPaidSubmit = () => {
    if (!state.billReference)
      return handleError(
        "Error: Missing Bill Reference. Please provide the bill reference number to proceed."
      );
    const request = {
      action: "SUPPLIER_PAID",
      table: table,
      details: state.rows.map((row) => ({
        uid: row.id,
        supplierPaid: true,
        supplierBillReference: state.billReference,
        supplierDatePaid: state.datePaid,
      })),
    };
    performAction(request);
  };

  const unmarkReadyForPaymentSubmit = () => {
    const request = {
      action: "SUPPLIER_READY_FOR_PAYMENT",
      table: table,
      details: state.rows.map((row) => ({
        uid: row.id,
        readyForPaymentCheck: false,
      })),
    };
    performAction(request);
  };

  const markAsPaymentReceivedSubmit = () => {
    const request = {
      action: "SUPPLIER_PAYMENT_RECEIVED",
      table: table,
      details: state.rows.map((row) => ({
        uid: row.id,
        supplierReceivedPayment: true,
      })),
    };
    performAction(request);
  };

  const unmarkItemsAsPaidSubmit = () => {
    const request = {
      action: "SUPPLIER_PAID",
      table: table,
      details: state.rows.map((row) => ({
        uid: row.id,
        supplierPaid: false,
        supplierBillReference: null,
        supplierDatePaid: null,
      })),
    };
    performAction(request);
  };
  const unmarkAsPaymentReceivedSubmit = () => {
    const request = {
      action: "SUPPLIER_PAYMENT_RECEIVED",
      table: table,
      details: state.rows.map((row) => ({
        uid: row.id,
        supplierReceivedPayment: false,
      })),
    };
    performAction(request);
  };

  const editSupplierFinanceDetailsSubmit = () => {
    if (!state.billReference)
      return handleError(
        "Error: Missing Bill Reference. Please provide the bill reference number to proceed."
      );
    const request = {
      action: "EDIT_SUPPLIER_DETAILS",
      table: table,
      details: state.rows.map((row) => ({
        uid: row.id,
        supplierPerUnitCost: row.supplierperunitcost,
        shippingQuotedCost: row.internationalshippingcost,
        readyForPaymentCheck: state.readyForPayment,
        supplierPaid: state.paid,
        supplierBillReference: state.billReference,
        supplierDatePaid: state.datePaid,
        supplierReceivedPayment: state.receivedPayment,
      })),
    };
    performAction(request);
  };

  const actionStyle = {
    display: "flex",
    width: "100%",
    mx: 2,
    mb: 1,
    justifyContent: "space-between",
  };

  const actionMap = {
    unmarkAsPaymentReceived: unmarkAsPaymentReceivedSubmit,
    unmarkItemsAsPaid: unmarkItemsAsPaidSubmit,
    markAsPaymentReceived: markAsPaymentReceivedSubmit,
    unmarkReadyForPayment: unmarkReadyForPaymentSubmit,
    markAsPaid: markAsPaidSubmit,
    editCosts: editCostsSubmit,
    markReadyForPayment: markReadyForPaymentSubmit,
    editSupplierFinanceDetails: editSupplierFinanceDetailsSubmit,
  };

  const handleSubmit = actionMap[currentModal?.type];

  if (handleSubmit) {
    return (
      <Box sx={actionStyle}>
        <ActionButtons
          loading={loading}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      </Box>
    );
  }

  // Return null or a default component if no matching action is found
  return null;
};
