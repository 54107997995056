import React, { useState } from "react";
import { Typography, DialogContentText, Alert } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { actionPerformedState, orderState } from "../../../../atoms/atoms";
import { performOrderAction } from "../../../../api/CustomerOrders";
import Modal from "../../../../components/Modal";

const NewOrRefurbDialog = ({ product, permissions }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const setPerformAction = useSetRecoilState(actionPerformedState);
  const setOrders = useSetRecoilState(orderState);

  const handleSubmit = () => {
    setLoading(true);
    const request = {
      action: "CHANGE_ITEM_CONDITION",
      details: [
        {
          uid: product.uid,
          newOrRefurb: product.neworrefurb === "New" ? "Refurbished" : "New",
        },
      ],
    };
    performOrderAction(request)
      .then((rsp) => {
        setPerformAction((prevState) => prevState + 1);
        setOrders(rsp);
        setLoading(false);
        handleCloseModal();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(
          "Error: there was a problem updating the order. Please try again later."
        );
      });
  };

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => {
    setOpen(false);
    setError(""); // Clear any existing errors when closing the modal
  };

  return (
    <>
      <Typography
        color="primary"
        variant="body1"
        fontWeight="bold"
        sx={{
          maxWidth: product.neworrefurb === "New" ? 50 : 110,
          textDecoration: "underline",
          mt: "2px",
          cursor: permissions.customerOrders.actions.changeItemCondition
            ? "pointer"
            : "default",
        }}
        onClick={
          permissions.customerOrders.actions.changeItemCondition
            ? handleOpenModal
            : undefined
        }
      >
        {product.neworrefurb === "New" ? "(New)" : "(Refurbished)"}
      </Typography>
      <Modal
        title="Unit Change"
        openModal={open}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleSubmit}
        loading={loading}
        errorMsg={error}
        small
      >
        <DialogContentText sx={{ mb: 1 }}>
          Switch to {product.neworrefurb === "New" ? "Refurbished" : "New"}{" "}
          Unit.
        </DialogContentText>
      </Modal>
    </>
  );
};

export default NewOrRefurbDialog;
