import React from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Error from "../../components/Error";
import Users from "./pages/Users";
import Roles from "./pages/Roles";

import { getAllUsers } from "../../api/Users";
import { getAllRoles } from "../../api/Roles";
import MainContainer from "../../layouts/main";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonIcon from "@mui/icons-material/Person";
import { errorMsg } from "../../common";
const Home = () => {
  const [page, setPage] = React.useState(0);

  const [permissionsNumbers, setPermissionNumbers] = React.useState(null);

  const [users, setUsers] = React.useState(null);
  const [usersLoading, setUsersLoading] = React.useState(true);

  const [allPermissions, setAllPermissions] = React.useState(null);
  const [permissionsLoading, setPermissionsLoading] = React.useState(true);

  const [errorMessage, setErrorMessage] = React.useState("");
  const handleError = (setFunc, setLoading, message) => {
    setFunc([]);
    setLoading(false);
    setErrorMessage(message);
  };

  React.useEffect(() => {
    getAllUsers()
      .then((rsp) => {
        setUsers(rsp.users);
        handlePermissionNumbers(rsp.users);
        getAllRoles()
          .then((rsp) => {
            const permissions = [
              rsp.permissions.find((item) => item.name === "Super Admin"),
              ...rsp.permissions.filter((item) => item.name !== "Super Admin"),
            ];
            setAllPermissions(permissions);
            setPermissionsLoading(false);
            setUsersLoading(false);
          })
          .catch((err) => {
            handleError(setAllPermissions, setPermissionsLoading, errorMsg);
          });
      })
      .catch((err) => {
        handleError(setUsers, setUsersLoading, errorMsg);
      });
  }, []);

  const handlePermissionNumbers = (users) => {
    if (!users) return;

    const newPermissionNumbers = users?.reduce((acc, user) => {
      acc[user.roleID] = (acc[user.roleID] || 0) + 1;
      return acc;
    }, {});

    setPermissionNumbers(newPermissionNumbers);
  };

  const handleChange = (event, newValue) => {
    setPage(newValue);
  };

  return (
    <MainContainer title={"Staff & Roles"}>
      {errorMessage && (
        <Error
          sx={{ position: "relative", width: "100%", borderRadius: 0 }}
          errorMessage={errorMessage}
        />
      )}

      <Box
        sx={{
          boxShadow:
            " 0px 1px 2px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          transition:
            "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
          position: "relative",
          borderBottom: 1,
          borderColor: "divider",
          background: "white",
        }}
      >
        <Tabs value={page} onChange={handleChange}>
          <Tab
            sx={{ pb: 0 }}
            icon={<PersonIcon />}
            iconPosition="start"
            value={0}
            label={"Users"}
          />
          <Tab
            sx={{ pb: 0 }}
            icon={<LockOpenIcon />}
            iconPosition="start"
            value={1}
            label="Roles"
          />
        </Tabs>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          transition:
            "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
          p: 3,
        }}
      >
        <Box>
          {page === 0 && (
            <Users
              users={users}
              roles={allPermissions}
              loading={usersLoading}
              setLoading={(value) => setUsersLoading(value)}
              handleUserChange={(users) => {
                handlePermissionNumbers(users);
                setUsers(users);
              }}
            />
          )}

          {page === 1 && (
            <Roles
              allPermissions={allPermissions}
              setAllPermissions={(allPermissions) =>
                setAllPermissions(allPermissions)
              }
              permissionsLoading={permissionsLoading}
              permissionsNumbers={permissionsNumbers}
            />
          )}
        </Box>
      </Box>
    </MainContainer>
  );
};

export default Home;
