import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Divider,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { updateUser } from "../../../api/Users";
import TextField from "@mui/material/TextField";
import ImageCropper from "../../../components/ImageCropper";
import Loading from "../../../components/Loading";
import { useNavigate } from "react-router-dom";

export default function Personal() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [user, setUser] = useState(null);
  const [userID, setUserID] = useState("");
  const [avatarImage, setAvatarImage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [successfulMessage, setSuccessfulMessage] = useState(false);
  const [unsuccessfulMessage, setUnsuccessfulMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    setUserID(user.id);
    setAvatarImage(user.avatarImage);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setUser(user);
  }, [navigate]);

  const validateFields = () => {
    const errors = {
      firstName: !firstName,
      lastName: !lastName,
      email: !email,
    };
    setErrors(errors);
    return !Object.values(errors).some((val) => val);
  };

  const handleUpdate = () => {
    setSuccessfulMessage(false);
    setUnsuccessfulMessage(false);

    if (validateFields()) {
      setLoading(true);
      updateUser(userID, firstName, lastName, email, user.roleID, avatarImage)
        .then((rsp) => {
          setLoading(false);
          setSuccessfulMessage(true);
          localStorage.setItem("USER", JSON.stringify({ ...rsp.updatedUser }));
        })
        .catch((err) => {
          setLoading(false);
          setUnsuccessfulMessage(true);
        })
        .finally(() => {
          setTimeout(() => {
            setUnsuccessfulMessage(false);
            setSuccessfulMessage(false);
          }, 4000);
        });
    }
  };

  const handleImageSave = (dataURL) => {
    setAvatarImage(dataURL);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      {(successfulMessage || unsuccessfulMessage) && (
        <Alert
          severity={successfulMessage ? "success" : "error"}
          sx={{ mb: 3, borderRadius: 2 }}
        >
          {successfulMessage
            ? "Your personal details were updated successfully."
            : "There was an error updating your details. Please try again."}
        </Alert>
      )}
      <Paper
        elevation={3}
        sx={{
          borderRadius: 2,
          maxHeight: isMobile ? "calc(100vh - 155px)" : 640,
          overflow: "auto",
          overflowX: "none",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box m={{ xs: 2, sm: 4 }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            component="h2"
            gutterBottom
          >
            Personal Information
          </Typography>
          <Divider sx={{ mb: 3 }} />

          {!user ? (
            <Loading sx={{ mt: 0.5, width: "40px", mx: "auto" }} />
          ) : (
            <Box sx={{ flex: 1, overflow: "auto", overflowX: "none", pb: 1 }}>
              <Box width="122px" mb={2} mx={"auto"}>
                {avatarImage && (
                  <ImageCropper
                    image={avatarImage}
                    handleImageSave={handleImageSave}
                  />
                )}
              </Box>

              <TextField
                label="First Name"
                required
                autoComplete="no"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                sx={{ mb: 3 }}
                error={errors.firstName}
                helperText={errors.firstName && "Required field"}
              />

              <TextField
                label="Last Name"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                sx={{ mb: 3 }}
                error={errors.lastName}
                helperText={errors.lastName && "Required field"}
              />

              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
                sx={{ mb: 3 }}
                error={errors.email}
                helperText={errors.email && "Required field"}
              />

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  loading={loading}
                  onClick={handleUpdate}
                  variant="contained"
                  size="large"
                  sx={{ minWidth: 120 }}
                >
                  Update
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
}
