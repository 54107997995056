import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Paper,
  Typography,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
  Grid,
  Alert,
  useTheme,
  useMediaQuery,
  TextField,
  Chip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Loading from "../../../components/Loading";
import { getSettings, updateSettings } from "../../../api/userSettings";
import { inventorySuppliers, warehouses } from "../../../common";
import { getProducts } from "../../../api/Products";
import Autocomplete from "@mui/material/Autocomplete";
import SelectField from "../components/SelectField";

const CONTAINER_SIZES = ["20ft", "40ft"];
const ANY_OPTION = { pid: "*ANY", label: "*ANY" };

const NotificationOption = ({
  label,
  name,
  checked,
  onChange,
  isProduct,
  isSupplier,
  isContainerSize,
  showWarehouse = true,
  selectedWarehouses,
  products,
  suppliers,
  containerSizes,
  onWarehousesChange,
  onProductsChange,
  onSuppliersChange,
  onContainerSizesChange,
  allProducts,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const filteredProducts = useMemo(() => {
    if (!isProduct || !checked) return [];

    let productsToFilter = allProducts;

    // If specific warehouses are selected, filter by those warehouses
    if (
      selectedWarehouses?.length > 0 &&
      !selectedWarehouses.includes("*ANY")
    ) {
      productsToFilter = allProducts.filter((p) =>
        p.stockedInWarehouses.some((warehouse) =>
          selectedWarehouses.includes(warehouse)
        )
      );
    }

    // Create a Map to store unique products by PID
    const uniqueProductsMap = new Map();

    productsToFilter.forEach((product) => {
      if (
        !uniqueProductsMap.has(product.pid) ||
        (uniqueProductsMap.has(product.pid) &&
          product.description &&
          !uniqueProductsMap.get(product.pid).description)
      ) {
        uniqueProductsMap.set(product.pid, product);
      }
    });

    // Convert the Map back to an array
    return Array.from(uniqueProductsMap.values());
  }, [allProducts, selectedWarehouses, isProduct, checked]);
  const renderSecondSelect = () => {
    if (isSupplier) {
      return (
        <SelectField
          label="Supplier"
          value={suppliers || []}
          onChange={(newValue) => onSuppliersChange(name, newValue)}
          options={inventorySuppliers}
          fullWidth
          minWidth={150}
        />
      );
    } else if (isContainerSize) {
      return (
        <SelectField
          label="Container Size"
          value={containerSizes || []}
          onChange={(newValue) => onContainerSizesChange(name, newValue)}
          options={CONTAINER_SIZES}
          fullWidth
          minWidth={150}
        />
      );
    } else if (isProduct) {
      return (
        <Autocomplete
          multiple
          size="small"
          options={[ANY_OPTION, ...filteredProducts]}
          getOptionLabel={(option) =>
            option.pid === "*ANY" ? "*ANY" : `${option.pid}`
          }
          isOptionEqualToValue={(option, value) =>
            (typeof option === "string" ? option : option.pid) ===
            (typeof value === "string" ? value : value.pid)
          }
          value={
            products?.length > 0
              ? products.map(
                  (p) =>
                    filteredProducts.find((fp) => fp.pid === p) || { pid: p }
                )
              : [ANY_OPTION]
          }
          onChange={(event, newValue) =>
            onProductsChange(
              name,
              newValue.filter((v) => v.pid !== "*ANY").map((v) => v.pid)
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Products"
              InputProps={{
                ...params.InputProps,
                sx: { minHeight: 40 },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {option.pid === "*ANY" ? (
                "*ANY"
              ) : (
                <div>
                  <strong>{option.pid}</strong>
                  {option.description && (
                    <Typography variant="body2" color="text.secondary">
                      {option.description}
                    </Typography>
                  )}
                </div>
              )}
            </li>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.pid}
                {...getTagProps({ index })}
                key={option.pid}
                size="small"
              />
            ))
          }
          fullWidth
          sx={{
            minWidth: 150,
            "& .MuiAutocomplete-inputRoot": {
              minHeight: 40,
              flexWrap: "wrap",
              alignItems: "center",
              gap: 0.5,
              paddingTop: "4px",
              paddingBottom: "4px",
            },
          }}
        />
      );
    }
  };
  const renderInputs = () => {
    if (isMobile) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
          <Typography sx={{ mb: 2, fontSize: "0.875rem" }}>
            When warehouse is
          </Typography>
          <SelectField
            label="Warehouse"
            value={selectedWarehouses || []}
            onChange={(newValue) => onWarehousesChange(name, newValue)}
            options={warehouses}
            minWidth={220}
          />
          <Typography
            sx={{ mt: 1, mb: 1, fontSize: "0.875rem", lineHeight: "40px" }}
          >
            {isSupplier
              ? "and supplier is"
              : isContainerSize
              ? "and container size is"
              : "and product is"}
          </Typography>
          {renderSecondSelect()}
        </Box>
      );
    } else {
      return (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} lg={6}>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  mr: 2,
                  minWidth: 140,
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  lineHeight: "40px",
                }}
              >
                When warehouse is
              </Typography>
              <SelectField
                label="Warehouse"
                value={selectedWarehouses || []}
                onChange={(newValue) => onWarehousesChange(name, newValue)}
                options={warehouses}
                minWidth={220}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  mr: 2,
                  minWidth: 120,
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  lineHeight: "40px",
                }}
              >
                {isSupplier
                  ? "and supplier is"
                  : isContainerSize
                  ? "and container size is"
                  : isProduct
                  ? "and product is"
                  : ""}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>{renderSecondSelect()}</Box>
            </Box>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} name={name} />}
        label={label}
      />
      {checked && showWarehouse && (
        <Box sx={{ pl: isMobile ? 0 : 4 }}>{renderInputs()}</Box>
      )}
    </Box>
  );
};

const Notifications = () => {
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [settings, setSettings] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    Promise.all([
      getSettings("notifications", "notifications"),
      getProducts("ALL", ""),
    ])
      .then(([settingsRsp, productsRsp]) => {
        setSettings(settingsRsp.settings || getDefaultSettings());

        setAllProducts(productsRsp.products);

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setSettings(getDefaultSettings());
        setLoading(false);
      });
  }, []);
  const getDefaultSettings = () => ({
    general: {
      chatMessages: { enabled: false },
    },
    orders: {
      newOrder: { enabled: false, warehouses: ["*ANY"], products: [] },
      warehouseChange: { enabled: false, warehouses: ["*ANY"], products: [] },
    },
    inventory: {
      newOrders: { enabled: false, warehouses: ["*ANY"], suppliers: ["*ANY"] },
      lowStockAlert: { enabled: false, warehouses: ["*ANY"], products: [] },
      criticalLowStockAlert: {
        enabled: false,
        warehouses: ["*ANY"],
        products: [],
      },
      containerCapacityReached: {
        enabled: false,
        warehouses: ["*ANY"],
        containerSizes: ["*ANY"],
      },
      inventoryReceivedQty: { enabled: false, warehouses: ["*ANY"] },
    },
  });

  const handleSettingChange = (section, name, field, value) => {
    setSettings((prevSettings) => {
      const updatedSettings = {
        ...prevSettings,
        [section]: {
          ...prevSettings[section],
          [name]: {
            ...prevSettings[section][name],
            [field]: value,
          },
        },
      };

      // If the warehouses changed and include *ANY, reset the products
      if (field === "warehouses" && value.includes("*ANY")) {
        updatedSettings[section][name].products = [];
      }

      // If the field is "products", ensure we're saving an array of PIDs
      if (field === "products") {
        updatedSettings[section][name].products = value
          .map((product) =>
            typeof product === "object" ? product.pid : product
          )
          .filter((pid) => pid !== "*ANY");
      }

      return updatedSettings;
    });
  };

  const handleUpdate = () => {
    setSuccessMessage(false);
    setErrorMessage(false);
    setSubmitLoading(true);
    updateSettings({
      type: "notifications",
      dataGridName: "notifications",
      ...settings,
    })
      .then((rsp) => {
        setSubmitLoading(false);
        setSuccessMessage(true);
        setSettings(rsp.updatedSettings);
      })
      .catch((err) => {
        console.error(err);
        setSubmitLoading(false);
        setErrorMessage(true);
      });
  };
  const notificationOptions = useMemo(
    () => [
      {
        section: "General",
        options: [
          {
            label: "Chat Messages",
            name: "chatMessages",
            showWarehouse: false,
          },
        ],
      },
      {
        section: "Orders",
        options: [
          { label: "New Order", name: "newOrder", isProduct: true },
          {
            label: "Warehouse Change",
            name: "warehouseChange",
            isProduct: true,
          },
        ],
      },
      {
        section: "Inventory",
        options: [
          { label: "New Orders", name: "newOrders", isSupplier: true },
          { label: "Low Stock Alert", name: "lowStockAlert", isProduct: true },
          {
            label: "Critical Low Stock Alert",
            name: "criticalLowStockAlert",
            isProduct: true,
          },
          {
            label: "Container Capacity Reached",
            name: "containerCapacityReached",
            isContainerSize: true,
          },
          {
            label: "Inventory Received Qty ≠ Ordered Qty",
            name: "inventoryReceivedQty",
            showWarehouse: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <Container maxWidth="lg">
      {(successMessage || errorMessage) && (
        <Alert
          severity={successMessage ? "success" : "error"}
          sx={{ mb: 2, borderRadius: 2 }}
        >
          {successMessage
            ? "Notification settings were updated successfully."
            : "There was an error updating the settings. Please try again later."}
        </Alert>
      )}
      <Paper elevation={3} sx={{ mb: 2, p: { xs: 2, sm: 4 }, borderRadius: 2 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Subscribe for Email Notifications:
        </Typography>
        <Divider sx={{ mb: 3 }} />
        {loading ? (
          <Loading sx={{ mt: 0.5, width: "40px", mx: "auto" }} />
        ) : (
          <>
            {notificationOptions.map((section, sectionIndex) => (
              <React.Fragment key={section.section}>
                {sectionIndex > 0 && <Divider sx={{ my: 3 }} />}
                <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: 2 }}>
                  {section.section}
                </Typography>
                {section.options.map((option) => (
                  <NotificationOption
                    key={option.name}
                    {...option}
                    checked={
                      settings?.[section.section.toLowerCase()]?.[option.name]
                        ?.enabled ?? false
                    }
                    onChange={(e) =>
                      handleSettingChange(
                        section.section.toLowerCase(),
                        option.name,
                        "enabled",
                        e.target.checked
                      )
                    }
                    selectedWarehouses={
                      settings?.[section.section.toLowerCase()]?.[option.name]
                        ?.warehouses ?? []
                    }
                    products={
                      settings?.[section.section.toLowerCase()]?.[option.name]
                        ?.products ?? []
                    }
                    suppliers={
                      settings?.[section.section.toLowerCase()]?.[option.name]
                        ?.suppliers ?? []
                    }
                    containerSizes={
                      settings?.[section.section.toLowerCase()]?.[option.name]
                        ?.containerSizes ?? []
                    }
                    onWarehousesChange={(name, value) =>
                      handleSettingChange(
                        section.section.toLowerCase(),
                        name,
                        "warehouses",
                        value
                      )
                    }
                    onProductsChange={(name, value) =>
                      handleSettingChange(
                        section.section.toLowerCase(),
                        name,
                        "products",
                        value
                      )
                    }
                    onSuppliersChange={(name, value) =>
                      handleSettingChange(
                        section.section.toLowerCase(),
                        name,
                        "suppliers",
                        value
                      )
                    }
                    onContainerSizesChange={(name, value) =>
                      handleSettingChange(
                        section.section.toLowerCase(),
                        name,
                        "containerSizes",
                        value
                      )
                    }
                    allProducts={allProducts}
                  />
                ))}
              </React.Fragment>
            ))}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <LoadingButton
                loading={submitLoading}
                onClick={handleUpdate}
                variant="contained"
                size="large"
                sx={{ minWidth: 120 }}
              >
                Update
              </LoadingButton>
            </Box>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default Notifications;
