import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Typography from "@mui/material/Typography";
import DraggableList from "../../../components/DraggableList/DraggableList";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import { SearchFieldAutocomplete } from "../../../components/AutoComplete/AutoComplete";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { orderSettingsState } from "../../../atoms/atoms";
import { updateSettings } from "../../../api/userSettings";

const SettingsModal = ({ dataGridName, searchFieldOptions }) => {
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedFields, setSelectedFields] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const orderSettings = useRecoilValue(orderSettingsState);
  const setOrderSettings = useSetRecoilState(orderSettingsState);
  const [state, setState] = useState(orderSettings);

  useEffect(() => {
    if (open) {
      setError("");
      setState(orderSettings);
    }
  }, [open]);

  const handleUpdateColumnsDisplayed = () => {
    setState((prevState) => {
      // Check if the selectedField already exists in columnsDisplayed
      const selectedColumns = selectedFields?.filter(
        (selectedField) =>
          !prevState.columnsDisplayed.some(
            (column) => column.fieldName === selectedField.fieldName
          )
      );

      return {
        ...prevState,
        columnsDisplayed: [...prevState.columnsDisplayed, ...selectedColumns],
      };
    });
    setOpenAdd(false);
    setSelectedFields(null);
  };
  const handleOpen = () => setOpen(!open);
  const updateSelectedColumn = (value) => {
    setSelectedFields(value);
  };
  const submit = async () => {
    setLoading(true);
    await updateSettings({
      ...state,
      type: "orderSettings",
      dataGridName: dataGridName,
    })
      .then((rsp) => {
        setOrderSettings(rsp.updatedSettings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError("Error updating order settings, please try again later.");
        setLoading(false);
      });
    handleOpen();
  };

  return (
    <>
      <IconButton
        sx={{
          position: "fixed",
          zIndex: 2000,
          right: { xs: 95, sm: 105 },
          top: 5,
        }}
        onClick={handleOpen}
      >
        <SettingsIcon />
      </IconButton>
      <Modal
        title={"Settings"}
        openModal={open}
        handleCloseModal={handleOpen}
        handleSubmit={submit}
        errorMsg={error}
        loading={loading}
      >
        <Box mb={2} display={"flex"} justifyContent={"space-between"}>
          <Typography fontWeight={"bold"} mt={1}>
            Column Displayed on Card
          </Typography>
          <IconButton
            sx={{
              minWidth: "40px",
              height: "40px",
              border: 1,
              borderColor: "primary.main",
              borderRadius: "50%",
              marginX: 0.5,
              mr: 1,
            }}
            onClick={() => setOpenAdd(true)}
            color={"primary"}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, mt: 1 }}>
          {state.columnsDisplayed.length !== 0 && (
            <DraggableList state={state} setState={setState} />
          )}
        </Box>
      </Modal>
      <Modal
        title="Add Column"
        openModal={openAdd}
        handleCloseModal={() => setOpenAdd(false)}
        handleSubmit={handleUpdateColumnsDisplayed}
        loading={loading}
        small
      >
        <Box sx={{ minWidth: "250px" }}>
          {searchFieldOptions && (
            <SearchFieldAutocomplete
              label={"Columns"}
              selectedField={selectedFields}
              searchFields={searchFieldOptions.slice(1)}
              onUpdate={updateSelectedColumn}
              multiple
            />
          )}
        </Box>
      </Modal>
    </>
  );
};
export default SettingsModal;
