import React, { createContext, useState, useContext, useEffect } from "react";
import { getSettings } from "../api/userSettings";

const DateFormatContext = createContext();

export const useDateFormat = () => useContext(DateFormatContext);

export const DateFormatProvider = ({ children }) => {
  const [dateFormat, setDateFormat] = useState("DD/MM/YYYY");

  useEffect(() => {
    getSettings("general", "general")
      .then((rsp) => {
        setDateFormat(rsp.settings?.dateFormat || "DD/MM/YYYY");
      })
      .catch((err) => {
        console.log(err);
        setDateFormat("DD/MM/YYYY");
      });
  }, []);

  return (
    <DateFormatContext.Provider value={{ dateFormat, setDateFormat }}>
      {children}
    </DateFormatContext.Provider>
  );
};
