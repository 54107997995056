import React, { useEffect } from "react";
import { Autocomplete, TextField, Grid, Typography } from "@mui/material";
import DatePicker from "../../../components/DatePicker";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import dayjs from "dayjs";
import { currencies, shippingCompanies } from "../../../common";
import DialogContentText from "@mui/material/DialogContentText";

const UpdateShippingAnalytics = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({
      uid: data.order.uid,
      shippingCurrency: data.order.ordercurrency,
      shippingQuotedCost: data.order.shippingquotedcost,
      shippingAdjustedCost: data.order.shippingadjustedcost,
      shippingVarianceReason: "",
      carrierName: data.order.carriername,
      trackingNumber: data.order.trackingnumber,
      consignmentNumber: data.order.consignmentnumber,
      shippedDate: data.order.shippeddate ? data.order.shippeddate : "",
      deliveredDate: data.order.delivereddate ? data.order.delivereddate : "",
      ordercountrycode: data.order.ordercountrycode,
    });
  }, []);

  const handleChange = (name, newValue) => {
    setState((prevState) => ({ ...prevState, [name]: newValue }));
  };

  return (
    <>
      <DialogContentText>
        Please update the shipping information below to reflect the most
        accurate and current details.
      </DialogContentText>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          {state.shippingCurrency && (
            <Autocomplete
              value={state.shippingCurrency}
              onChange={(event, newValue) =>
                handleChange("shippingCurrency", newValue)
              }
              options={currencies} // Assuming this is an array of strings
              renderInput={(params) => (
                <TextField {...params} label="Shipping Currency" />
              )}
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {state.carrierName && (
            <Autocomplete
              value={state.carrierName}
              onChange={(event, newValue) =>
                handleChange("carrierName", newValue)
              }
              options={shippingCompanies} // Assuming this is an array of strings
              renderInput={(params) => (
                <TextField {...params} label="Shipping Company" />
              )}
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            label="Shipping Costs"
            value={state.shippingQuotedCost}
            onChange={(e) => handleChange("shippingQuotedCost", e.target.value)}
            InputProps={{
              startAdornment: <Typography>$</Typography>,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            label="Real Shipping Cost"
            value={state.shippingAdjustedCost}
            onChange={(e) =>
              handleChange("shippingAdjustedCost", e.target.value)
            }
            InputProps={{
              startAdornment: <Typography>$</Typography>,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Reason for Adjustment"
            value={state.shippingVarianceReason}
            onChange={(e) =>
              handleChange("shippingVarianceReason", e.target.value)
            }
            multiline
            rows={4}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Tracking Number"
            value={state.trackingNumber}
            onChange={(e) => handleChange("trackingNumber", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Consignment Number"
            value={state.consignmentNumber}
            onChange={(e) => handleChange("consignmentNumber", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} mt={2} mb={1}>
          <DatePicker
            label="Shipped Date"
            value={state.shippedDate}
            onChange={(newValue) =>
              handleChange("shippedDate", dayjs(newValue))
            }
            clearable
            startingTimezone={data.order.ordercountrycode}
            timezone={data.order.ordercountrycode}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <DatePicker
            label="Delivered Date"
            value={state.deliveredDate}
            onChange={(newValue) =>
              handleChange("deliveredDate", dayjs(newValue))
            }
            clearable
            startingTimezone={data.order.ordercountrycode}
            timezone={data.order.ordercountrycode}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateShippingAnalytics;
