import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import DraggableList from "../DraggableList/DraggableList";
import { SearchFieldAutocomplete } from "../AutoComplete/AutoComplete";
import Modal from "../Modal";
import { getSettings, updateSettings } from "../../api/userSettings";
import { useRecoilState } from "recoil";
import { tileViewSettingsState } from "../../atoms/atoms";
import Divider from "@mui/material/Divider";

const ColumnSelector = ({ page, searchFields, defaultSettings }) => {
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedFields, setSelectedFields] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showHeaders, setShowHeaders] = useState(false);

  const [tileViewSettings, setTileViewSettings] = useRecoilState(
    tileViewSettingsState
  );
  const [state, setState] = useState(null);

  useEffect(() => {
    if (open) {
      setState(tileViewSettings);
      setShowHeaders(tileViewSettings?.showHeaders || false);
      setError("");
    }
  }, [tileViewSettings, open]);

  useEffect(() => {
    if (searchFields)
      getSettings(page, "tileViewSettings")
        .then((rsp) => {
          setTileViewSettings(
            rsp.settings ||
              defaultSettings || { columnsDisplay: [], showHeaders: true }
          );
        })
        .catch((err) => {
          console.log(err);
          setTileViewSettings(
            defaultSettings || { columnsDisplay: [], showHeaders: true }
          );
        });
  }, [searchFields]);

  const handleOpen = () => setOpen(!open);

  const handleOpenAdd = () => setOpenAdd(true);

  const handleCloseAdd = () => {
    setOpenAdd(false);
    setSelectedFields(null);
  };

  const updateSelectedColumn = (value) => {
    setSelectedFields(value);
  };

  const handleUpdateColumnsDisplayed = () => {
    setState((prevState) => {
      // Check if the selectedField already exists in columnsDisplayed
      const selectedColumns = selectedFields?.filter(
        (selectedField) =>
          !prevState.columnsDisplayed.some(
            (column) => column.fieldName === selectedField.fieldName
          )
      );

      return {
        ...prevState,
        columnsDisplayed: [...prevState.columnsDisplayed, ...selectedColumns],
      };
    });
    handleCloseAdd();
  };

  const handleSubmit = async () => {
    setLoading(true);
    await updateSettings({
      ...state,
      showHeaders,
      type: "tileViewSettings",
      dataGridName: page,
    })
      .then((rsp) => {
        setTileViewSettings(rsp.updatedSettings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError("Error updating order settings, please try again later.");
        setLoading(false);
      });
    handleOpen();
  };

  const handleHeaderToggle = (event) => {
    setShowHeaders(event.target.checked);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <SettingsIcon />
      </IconButton>
      <Modal
        title="Settings"
        openModal={open}
        handleCloseModal={handleOpen}
        handleSubmit={handleSubmit}
        errorMsg={error}
        loading={loading}
      >
        {" "}
        <Box mb={3}>
          <FormControlLabel
            control={
              <Switch
                checked={showHeaders}
                onChange={handleHeaderToggle}
                name="showHeaders"
                color="primary"
              />
            }
            label="Show Column Headers"
          />
        </Box>
        <Divider />
        <Box mt={2} mb={2} display="flex" justifyContent="space-between">
          <Typography fontWeight="bold" mt={1}>
            Columns Displayed on Tile
          </Typography>
          <IconButton
            sx={{
              minWidth: "40px",
              height: "40px",
              border: 1,
              borderColor: "primary.main",
              borderRadius: "50%",
              marginX: 0.5,
              mr: 1,
            }}
            onClick={handleOpenAdd}
            color="primary"
          >
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, mt: 1 }}>
          {state?.columnsDisplayed?.length !== 0 && (
            <DraggableList state={state} setState={setState} />
          )}
        </Box>
      </Modal>
      <Modal
        title="Add Column"
        openModal={openAdd}
        handleCloseModal={handleCloseAdd}
        handleSubmit={handleUpdateColumnsDisplayed}
        loading={loading}
        small
      >
        <Box sx={{ minWidth: "250px" }}>
          <SearchFieldAutocomplete
            label={"Columns"}
            selectedField={selectedFields}
            searchFields={searchFields.slice(1)}
            onUpdate={updateSelectedColumn}
            multiple
          />
        </Box>
      </Modal>
    </>
  );
};

export default ColumnSelector;
