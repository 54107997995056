// hooks/usePermissionData.js
import { useState, useEffect } from "react";
import { getRole } from "../../../../../../api/Roles";
import { getFields } from "../../../../../../api/SearchFields";
import { getOfflineOrdersFields } from "../../../../../../api/OfflineOrders";
import { getProductFields } from "../../../../../../api/Products";
import actions from "../../../../../../data/actions.json";

export const usePermissionData = (roleID, isEdit, open) => {
  const [data, setData] = useState({
    general: [],
    customerOrders: [],
    offlineOrders: { orderDetails: [], products: [] },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [roleData, setRoleData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!open) return;

      setLoading(true);
      setError("");
      try {
        // Fetch role data if editing
        if (isEdit && roleID) {
          const fetchedRoleData = (await getRole(roleID)).role;
          setRoleData(fetchedRoleData);
        }

        // Fetch all other data
        const [
          customerOrderFields,
          orderDetailsFields,
          productFields,
          currentStockFields,
          predictionFields,
          inventoryOrdersFields,
          orderReimbursementsPendingFields,
          orderReimbursementsReadyForPaymentFields,
          orderReimbursementsPaidFields,
          orderReimbursementsCompletedFields,
        ] = await Promise.all([
          getFields("orderUnit"),
          getOfflineOrdersFields(),
          getProductFields(),
          getFields("InventoryInStock"),
          getFields("InventoryToOrder"),
          getFields("inventoryorders"),
          getFields("vw_OrderReimbursement_Pending"),
          getFields("vw_OrderReimbursement_ReadyForPayment"),
          getFields("vw_OrderReimbursement_Paid"),
          getFields("vw_OrderReimbursement_Completed"),
        ]);

        let fields = { ...actions };
        // customer orders
        fields.customerOrders.columns =
          customerOrderFields.searchFields.slice(1);

        // offline orders
        fields.offlineOrders.orderDetails.columns =
          orderDetailsFields.searchFields;
        fields.offlineOrders.products.columns = productFields.searchFields;

        //inventory orders
        fields.inventoryOrders.currentStock.columns =
          currentStockFields.searchFields.slice(1);
        fields.inventoryOrders.predictions.columns =
          predictionFields.searchFields.slice(1);
        fields.inventoryOrders.orders.columns =
          inventoryOrdersFields.searchFields.slice(1);

        // order reimbursements
        fields.accounting.orderReimbursements.pending.columns =
          orderReimbursementsPendingFields.searchFields.slice(1);
        fields.accounting.orderReimbursements.readyForPayment.columns =
          orderReimbursementsReadyForPaymentFields.searchFields.slice(1);
        fields.accounting.orderReimbursements.paid.columns =
          orderReimbursementsPaidFields.searchFields.slice(1);
        fields.accounting.orderReimbursements.completed.columns =
          orderReimbursementsCompletedFields.searchFields.slice(1);

        setData(fields);
      } catch (error) {
        setError("Error fetching data. Please try again later.");
        console.error("Error fetching fields:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [roleID, isEdit, open]);

  return { data, loading, error, roleData };
};
