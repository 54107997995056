import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { permissionsState } from "../atoms/atoms";
import { useSetRecoilState } from "recoil";
import useSignOut from 'react-auth-kit/hooks/useSignOut';

import { getRole } from "../api/Roles";

import Loading from "./Loading";
import Box from "@mui/material/Box";
import { loadUser } from "../common";
import AuthExpiryCheck from "./AuthExpiryCheck";

const PermissionGate = ({ children }) => {
  let navigate = useNavigate();

  const signOut = useSignOut();

  const [cookies, setCookies] = useCookies(["roleID"]);
  const [loading, setLoading] = React.useState(true);

  const setPermissions = useSetRecoilState(permissionsState);

  React.useEffect(() => {
    const user = JSON.parse(loadUser())?.id;
    if (!user) {
      signOut();
    }
  }, []);

  React.useEffect(() => {
    let didComponentMount = false;

    getRole(cookies.roleID)
      .then((rsp) => {
        if (didComponentMount) return;
        setPermissions(rsp.role);
        setLoading(false);
      })
      .catch((err) => {
        let errMessage = err.name ? err.name + " " + err.message : err.message;
        console.log(errMessage, "\n> PermissionGate is now signing out user");
        signOut();
        setCookies("roleID", "");
        if (window.location.pathname.startsWith("/login")) {
          console.log("already at login")
        } else {
          console.log("to login >>>")
          navigate("/login")
        }
      });

    return () => {
      didComponentMount = true;
    };
  }, []);

  return (
    <>
      <AuthExpiryCheck />
      {loading && (
        <Box sx={{ height: "100vh", background: "white" }}>
          <Loading
            size={60}
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 4,
              pt: "300px",
            }}
          />
        </Box>
      )}
      {!loading && children}
    </>
  );
};

export default PermissionGate;
