import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import SimpleDataGrid from "../../../../components/Datagrid/SimpleDataGrid";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";
import DatePicker from "../../../../components/DatePicker";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Checkbox } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../../atoms/atoms";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import { getTableHeight } from "../../../../common";

const MarkAsPaid = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({
      datePaid: dayjs(new Date()),
      billReference: "",
      rows: data.orders.map((order) => {
        return {
          ordernumber: order.wixlocalordernumber,
          id: order.uid,
          descriptionshort: order.descriptionshort,
          buyerfullname: order.buyerfullname,
          qty: order.qty,
          supplierperunitcost: order.supplierperunitcost,
          internationalshippingcost: order.internationalshippingcost,
          totalproductcost: order.totalproductcost,
          shippingcurrency: order.shippingcurrency,
          suppliertotalcostowed: order.suppliertotalcostowed,
          readyforpaymentcheck: order.readyforpaymentcheck,
        };
      }),
      totalCostOwed: data.orders.reduce(
        (acc, order) => acc + order.suppliertotalcostowed,
        0
      ),
    });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "UID",
      flex: 3,
      sortable: false,
    },
    {
      field: "ordernumber",
      headerName: "Order Number",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Full Name",
      field: "buyerfullname",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Model",
      field: "descriptionshort",
      flex: 3,
      sortable: false,
    },
    {
      headerName: "Qty",
      field: "qty",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Per Unit Cost",
      field: "supplierperunitcost",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Total Product Price",
      field: "totalproductcost",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "International Shipping Cost",
      field: "internationalshippingcost",
      flex: 2,
      sortable: false,
    },

    {
      headerName: "Shipping Currency",
      field: "shippingcurrency",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Total Cost Owed",
      field: "suppliertotalcostowed",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Ready For Payment",
      field: "readyforpaymentcheck",
      flex: 2,
      renderCell: (params) => (
        <Checkbox
          checked={params.value === true} // Assumes the field value is a boolean
          disabled // This makes the checkbox non-interactive
          inputProps={{ "aria-label": "Ready For Payment" }}
        />
      ),
      sortable: false,
    },
  ];

  const handleInputChange = (e, name) => {
    setState((prevState) => {
      return { ...prevState, [name]: e.target.value };
    });
  };

  const tableHeight = getTableHeight(state?.rows?.length);
  return (
    <Box>
      <DialogContentText>
        The following Orders will be marked as paid.
      </DialogContentText>
      <Box sx={{ margin: "auto", mt: 2, height: tableHeight, width: "100%" }}>
        {state.rows && (
          <SimpleDataGrid
            sx={{ height: tableHeight }}
            rows={state.rows}
            columns={columns}
            hideFooter
            disableColumnMenu
            isEditable={false}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, pb: 1 }}>
        <Typography variant="h6">
          Total Cost Owed: ${state.totalCostOwed?.toFixed(2)}
        </Typography>
      </Box>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7} mt={3}>
          <DatePicker
            label={"Date Paid *"}
            value={state?.datePaid}
            onChange={(newValue) => setState({ ...state, datePaid: dayjs(newValue) })}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            required
            fullWidth
            label={"Bill Reference"}
            value={state?.billReference}
            onChange={(e) => handleInputChange(e, "billReference")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default MarkAsPaid;
