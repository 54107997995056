import React from "react";
import { ListItem, ListItemText, Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import GroupIcon from "@mui/icons-material/Group";
import { loadUser } from "../../../../common";
import { formatDate } from "../../../../utils/dateFormatter";

// Utility function to render message content with mentions
const renderContent = (isRead, content) => {
  const mentionRegex = /\@\[([^\]]+)\]\([^\)]+\)/g;
  return content?.split(mentionRegex).map((part, index) => (
    <Typography
      key={index}
      component="span"
      sx={{
        fontWeight: index % 2 === 1 || !isRead ? "bold" : "normal",
        textOverflow: "ellipsis",
        whiteSpace: "normal", // Overrides MUI default of 'nowrap'
        color: isRead ? "rgba(0, 0, 0, 0.6)" : "#030303",
        fontSize: 12,
      }}
    >
      {index % 2 === 1 ? `@${part}` : part}
    </Typography>
  ));
};

const ConversationItem = ({
  conversation,
  handleConversationSelection,
  userMap,
  dateFormat,
  index,
  conversations,
}) => {
  const userID = JSON.parse(loadUser()).id;
  const { lastMessage } = conversation;
  const isRead = !lastMessage?.read || lastMessage?.read?.includes(userID);

  return (
    <ListItem
      onClick={() => handleConversationSelection(conversation)}
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center", // This ensures vertical centering of all elements in the ListItem
        justifyContent: "space-between", // This spreads the child elements to fill the horizontal space
        borderTop: "1px solid #ccc",
        borderBottom:
          index === conversations.length - 1 ? "1px solid #ccc" : "none",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          cursor: "pointer",
        },
      }}
    >
      <AvatarGroup conversation={conversation} userMap={userMap} />
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          marginLeft: 1,
          alignItems: "center",
        }}
      >
        <ListItemText
          primary={getPrimaryText(conversation, userID, userMap)}
          secondary={
            renderContent(isRead, lastMessage?.content) || "No messages yet. "
          }
          sx={{
            mr: 2,
            ".MuiListItemText-primary": { fontWeight: "bold" },
            ".MuiListItemText-secondary": {
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              fontSize: 12,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {lastMessage?.createdAt && (
          <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
            {formatTimestamp(lastMessage.createdAt, dateFormat)}
          </Typography>
        )}
        <UnreadIndicator isRead={isRead} />
      </Box>
    </ListItem>
  );
};

// Components for avatar display, primary text, unread indicator, and formatted timestamp
const AvatarGroup = ({ conversation, userMap }) => (
  <Box
    sx={{
      position: "relative",
      marginRight: 1,
      height: "52px",
      minWidth: "80px",
    }}
  >
    {conversation.isDefault && (
      <Avatar
        sx={{
          width: 42,
          height: 42,
          mt: 0.8,
          ml: 1.5,
        }}
      >
        <GroupIcon />
      </Avatar>
    )}
    {!conversation.isDefault &&
      conversation.members.slice(0, 3).map((userID, idx) => {
        const zIndex = 100 - idx; // Stack the first avatar on top
        const topOffset = 15 - 10 * idx; // Each subsequent avatar moves up by 10px less
        const leftOffset = 15 * idx; // Each subsequent avatar moves right by 18px, closer together

        return (
          <Avatar
            key={userID}
            src={userMap[userID]?.avatar || ""}
            alt={`@${userMap[userID]?.name}`}
            sx={{
              position: "absolute",
              left: `${leftOffset}px`,
              bottom: `${topOffset}px`, // Use bottom instead of top for upward alignment
              zIndex: zIndex,
              border: "2px solid white",
              boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)",
            }}
          />
        );
      })}
  </Box>
);

const getPrimaryText = (conversation, userID, userMap) =>
  conversation.isDefault
    ? "Everyone"
    : conversation.members
        .filter((memberID) => memberID !== userID)
        .map((userID) => `@${userMap[userID]?.name}`)
        .join(", ");

const formatTimestamp = (timestamp, dateFormat) =>
  dayjs(timestamp).isToday()
    ? dayjs(timestamp).format("h:mm A")
    : formatDate(dayjs(timestamp), dateFormat).slice(0, 5);

const UnreadIndicator = ({ isRead }) =>
  !isRead && (
    <Box
      sx={{
        width: 10,
        height: 10,
        bgcolor: "primary.main",
        borderRadius: "50%",
        mt: 0.5,
      }}
    />
  );

export default ConversationItem;
