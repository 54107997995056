import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useCookies } from "react-cookie";

import Navbar from "../../../../components/Navbar";
import FullPageContentContainer from "../../../../components/FullPageContentContainer";
import Toolbar from "@mui/material/Toolbar";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  productsState,
  orderDetailsState,
  permissionsState,
} from "../../../../atoms/atoms";

import { currencyFormatter } from "../../../../helpers/money/CurrencyFormater";

import DataGrid from "../../../../components/Datagrid/SimpleDataGrid";

const SummaryTitle = ({ title }) => {
  return (
    <Typography
      sx={{
        background: "#f2f2f2",
        color: "rgba(0,0,0,.85)",
        borderRadius: "5px",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
        p: 1,
        pl: 2,
        fontWeight: 500,
        fontSize: 18,
      }}
      variant="h6"
      component="div"
    >
      {title}
    </Typography>
  );
};

const SummaryListItem = ({ label, value }) => {
  return (
    <ListItem
      sx={{ minWidth: "150px", borderBottom: "#f2f2f2 1px solid", pl: 2 }}
    >
      <ListItemText
        sx={{ maxWidth: "280px", minWidth: "280px" }}
        primary={`${label}:`}
      />
      <ListItemText sx={{ width: "150px" }} primary={value} />
    </ListItem>
  );
};

export default function Success() {
  let navigate = useNavigate();
  let location = useLocation();

  const permissions = useRecoilValue(permissionsState);

  const products = useRecoilValue(productsState);

  const state = useRecoilValue(orderDetailsState);
  const setState = useSetRecoilState(orderDetailsState);

  const [openModal, setOpenModal] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([
    "productsColumnOrder",
  ]);
  const [columns, setColumns] = React.useState([
    { field: "itemPID", headerName: "Item PID", width: 200, editable: false },
    {
      field: "modelDescription",
      headerName: "Model Description",
      width: 300,
      editable: false,
    },
    { field: "quantity", headerName: "Quantity", width: 80, editable: false },
    {
      field: "discount",
      headerName: "Discount",
      width: 100,
      editable: false,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter().format(value),
    },
    {
      field: "linePrice",
      headerName: "Line Price",
      width: 100,
      editable: false,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter().format(value),
    },
    {
      field: "isSalesTaxIncluded",
      headerName: "Sales Tax Included",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={params.row.isSalesTaxIncluded} disabled />
              }
            />
          </FormGroup>
        );
      },
    },
    {
      field: "salesTaxPercent",
      headerName: "Sales Tax Percent",
      width: 150,
      editable: false,
    },
    {
      field: "salesTaxDollarAmount",
      headerName: "Sales Tax Dollar Amount",
      width: 200,
      editable: false,
      valueFormatter: ({ value }) => currencyFormatter().format(value),
    },
  ]);

  const handleOpenModal = () => setOpenModal(false);

  const handleCloseModal = () => setOpenModal(false);

  const [totals, setTotals] = React.useState({
    products: 0,
    shipping: 0,
    tax: 0,
    total: 0,
  });

  const calcTotals = () => {
    let productsTotal = 0;
    let taxTotal = 0;

    products.forEach((product) => {
      if (!product.itemPID) return;
      productsTotal += parseInt(product.linePrice.toFixed(2));
      taxTotal += parseInt(product.salesTaxDollarAmount.toFixed(2));
    });

    const total = state.shippingPrice + taxTotal + productsTotal;

    setTotals({
      products: productsTotal,
      shipping: state.shippingPrice,
      tax: taxTotal,
      total: total,
    });
  };

  React.useEffect(() => {
    if (state.success !== true)
      navigate(`/order/${location.pathname.split("/")[2]}`);
    if (cookies.productsColumnOrder) setColumns(cookies.productsColumnOrder);
    calcTotals();
  }, []);

  return (
    <>
      <Navbar page="Success" />
      <Toolbar />
      <FullPageContentContainer>
        <Box sx={{ flexGrow: 1 }}>
          <Grid item xs={12} md={6} mb={4}>
            <SummaryTitle title="Customer Details" />
            <List
              sx={{
                border: "#f2f2f2 1px solid",
                borderRadius: "5px",
                borderTopLeftRadius: "0",
                borderTopRight: "0",
                borderTop: "none",
                p: 0,
              }}
            >
              <SummaryListItem
                label="Store Country"
                value={state.storeCountry}
              />

              {state.storeCountry === "CA" && (
                <ListItem
                  sx={{
                    minWidth: "150px",
                    borderBottom: "#f2f2f2 1px solid",
                    pl: 2,
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={state.GrenkeyCanadaGST}
                        />
                      }
                      label="Grenke Canada Application GST Only"
                    />
                  </FormGroup>
                </ListItem>
              )}

              {state.storeCountry === "US" && (
                <ListItem
                  sx={{
                    minWidth: "150px",
                    borderBottom: "#f2f2f2 1px solid",
                    pl: 2,
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox disabled={true} checked={state.isTaxExempt} />
                      }
                      label="Grenke Canada Application GST Only"
                    />
                  </FormGroup>
                </ListItem>
              )}
              <SummaryListItem label="First Name" value={state.firstName} />
              <SummaryListItem label="Last Name" value={state.lastName} />
              <SummaryListItem label="Phone Number" value={state.phoneNumber} />
              <SummaryListItem
                label="Street Address"
                value={state.streetAddress}
              />
              <SummaryListItem label="City" value={state.city} />
              <SummaryListItem label="State" value={state.state} />
              <SummaryListItem label="Country" value={state.country} />
              <SummaryListItem label="Zip" value={state.zip} />
            </List>
          </Grid>
          <Grid item xs={12} md={6} mb={4}>
            <SummaryTitle title="Shipping" />
            <List
              sx={{
                border: "#f2f2f2 1px solid",
                borderRadius: "5px",
                borderTopLeftRadius: "0",
                borderTopRight: "0",
                borderTop: "none",
                p: 0,
              }}
            >
              <SummaryListItem
                label="Shipping Price"
                value={`$${state.shippingPrice.toFixed(2)}`}
              />
              <SummaryListItem
                label="Shipping Option"
                value={
                  state.shippingOption === 10
                    ? "-"
                    : state.shippingOption === 20
                    ? "None/Free"
                    : state.shippingOption === 30
                    ? "Low Value 34.99"
                    : state.shippingOption === 40
                    ? "Freight Insurance"
                    : state.shippingOption === 50
                    ? "Express"
                    : "Freight Insurance + Express"
                }
              />
              <SummaryListItem
                label="Shipping Tax"
                value={`$${state.shippingTax}`}
              />
            </List>
          </Grid>
          <Box sx={{ height: 500, width: "100%", mb: 2 }}>
            <SummaryTitle title="Products" />

            {columns && (
              <DataGrid
                rows={products}
                columns={columns}
                rowHeight={38}
                checkboxSelection
                disableRowSelectionOnClick
              />
            )}
          </Box>
          <Grid item xs={12} md={6} mb={2} mt={10}>
            <SummaryTitle title="Totals" />
            <List
              sx={{
                border: "#f2f2f2 1px solid",
                borderRadius: "5px",
                borderTopLeftRadius: "0",
                borderTopRight: "0",
                borderTop: "none",
                p: 0,
              }}
            >
              <SummaryListItem
                label="Products"
                value={`$${totals.products.toFixed(2)}`}
              />
              <SummaryListItem
                label="+ Shipping"
                value={`$${totals.shipping.toFixed(2)}`}
              />
              <SummaryListItem
                label={state.isSalesTaxIncluded ? "Tax (incl)" : "+ Tax"}
                value={`$${totals.tax.toFixed(2)}`}
              />
              <SummaryListItem
                label="Final Total"
                value={`$${totals.total.toFixed(2)}`}
              />
            </List>
          </Grid>
          {/* <Grid item xs={12} md={6} mb={4}>
            <List>
                {state.xero?.invoiceNumber && 
                    <> 
                        <ListItem sx={{pl:0}}>
                            <ListItemText
                                sx={{minWidth:"280px", maxWidth:"280px"}}
                                primary="Xero Invoice Number: "
                            />
                            <ListItemText
                                 sx={{width:"150px"}}
                                primary={`${state.xero?.invoiceNumber}`}
                            />
                        </ListItem>
                        <Box sx={{display:"flex"}}>
                            <Link sx={{mt:1, width:"1000px"}} href={`/redirect/${state.xero?.invoiceNumber}` } target="_blank">{`https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${state.xero?.invoiceId}`}</Link>
                        </Box>
                    </>
                }
                <Box sx={{paddingLeft: "16px"}}>
                    <Button onClick={()=>setOpenModal(true)} sx={{mt:4}} variant="outlined" >mark as paid</Button>
                </Box>
            </List>
        </Grid> */}
          {/* <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>Mark as Paid</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Are you sure you want to mark this order as paid?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOpenModal}>Cancel</Button>
                <Button onClick={handleCloseModal}>Confirm</Button>
            </DialogActions>
        </Dialog> */}

          <Box sx={{ mb: 8 }}>
            <Button
              variant="outlined"
              onClick={() => {
                setState({ ...state, success: false });
                navigate("/offlineOrders");
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </FullPageContentContainer>
    </>
  );
}
