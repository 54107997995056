import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import MainContainer from "../../layouts/main";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";

import Personal from "./pages/personal";
import Password from "./pages/password";
import General from "./pages/general";
import Notifications from "./pages/notifications";

const Settings = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  let shortCommitDetail =
    process.env.REACT_APP_LAST_COMMIT_TIME &&
    process.env.REACT_APP_LAST_COMMIT_SHA
      ? process.env.REACT_APP_LAST_COMMIT_TIME +
        " " +
        process.env.REACT_APP_LAST_COMMIT_SHA
      : "";
  let commitDetails =
    process.env.REACT_APP_LAST_COMMIT_INFO || shortCommitDetail;

  return (
    <MainContainer
      title={
        tabIndex === 0
          ? "General"
          : tabIndex === 1
          ? "Edit Personal"
          : tabIndex === 2
          ? "Change Password"
          : "Notifications"
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 50px)",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "white",
            width: "100%",
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{ width: "100%" }}
          >
            <Tab
              sx={{ pb: 0 }}
              icon={<SettingsIcon />}
              iconPosition="start"
              value={0}
              label={"General"}
              title={commitDetails}
            />
            <Tab
              sx={{ pb: 0 }}
              icon={<PersonIcon />}
              iconPosition="start"
              value={1}
              label={"Edit Personal"}
            />
            <Tab
              sx={{ pb: 0 }}
              icon={<LockOpenIcon />}
              iconPosition="start"
              value={2}
              label={"Change Password"}
            />
            <Tab
              sx={{ pb: 0 }}
              icon={<NotificationsIcon />}
              iconPosition="start"
              value={3}
              label={"Notifications"}
            />
          </Tabs>
        </Box>
        <Box pt={2} sx={{ flex: 1, overflow: "auto", overflowX: "none" }}>
          {tabIndex === 0 && <General />}
          {tabIndex === 1 && <Personal />}
          {tabIndex === 2 && <Password />}
          {tabIndex === 3 && <Notifications />}
        </Box>
      </Box>
    </MainContainer>
  );
};

export default Settings;
