import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { permissionsState, sideBarOpenState } from "../../atoms/atoms";
import MainContainer from "../../layouts/main";
import useSearchFields from "../../hooks/useSearchFields";
import useModalManager from "../../hooks/useModalManager";
import useInventoryOrders from "../../hooks/useInventoryOrders";
import useViewToggle from "../../hooks/useViewToggle";
import { getGroupKey, isGroupRow } from "../../utils/mui";
import { splitAndCapitalize } from "../../utils/stringUtils";
import { InventoryCardView } from "./views/InventoryCardView";
import { InventoryTableView } from "./views/InventoryTableView";
import ActionsMenu from "./components/actionsMenu";
import { Box } from "@mui/material";
import InventoryTileView from "./views/InventoryTileView";
import Error from "../../components/Error";
import { errorMsg } from "../../common";
import WarehouseSelect from "./components/warehouseSelect";
import { getProductsByCountry } from "../../api/Products";
import FreightFiller from "../../components/FreightFiller";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import DynamicModal from "./modals/DynamicModal";

export default function InventoryMain() {
  let navigate = useNavigate();
  const page = window.location.href.split("/").filter(Boolean).pop();
  const { openModal, closeModal, currentModal } = useModalManager();
  const sideBarOpen = useRecoilValue(sideBarOpenState);
  const permissions = useRecoilValue(permissionsState);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(() => {
    const savedWarehouse = localStorage.getItem("selectedWarehouse");
    return savedWarehouse || "all";
  });
  const [groupingModel, setGroupingModel] = useState([
    page === "stockOrders" ? "warehouseordernumber" : "category",
  ]);
  const [view, toggleView] = useViewToggle(page);
  const [products, setProducts] = useState([]);

  const { orders, ordersLoading, ordersError, refetch, handleOrdersChange } =
    useInventoryOrders(page);
  const [uniqueWarehouseOrders, setUniqueWarehouseOrders] = useState([]);

  const {
    searchFieldOptions,
    columns,
    searchFieldsLoading,
    searchFieldsError,
  } = useSearchFields(
    permissions.inventoryOrders?.[
      page === "currentStock"
        ? "currentStock"
        : page === "stockPredictions"
        ? "predictions"
        : "orders"
    ]?.columns,
    permissions.id,
    page === "currentStock"
      ? "InventoryInStock"
      : page === "stockPredictions"
      ? "InventoryToOrder"
      : "inventoryorders"
  );

  useEffect(() => {
    setSelectedRows(null);
  }, [page]);

  useEffect(() => {
    if (orders) setUniqueWarehouseOrders(createUniqueWarehouseOrders(orders));
  }, [orders]);

  useEffect(() => {
    localStorage.setItem("selectedWarehouse", selectedWarehouse);
  }, [selectedWarehouse]);

  useEffect(() => {
    setSelectedRows(null);
  }, [view]);

  useEffect(() => {
    if (page === "stockPredictions" && selectedWarehouse !== "all") {
      const countryCode = selectedWarehouse.substring(0, 2);
      getProductsByCountry(countryCode)
        .then((rsp) => setProducts(rsp.countryProducts))
        .catch(console.error);
    }
  }, [page, selectedWarehouse]);

  const filteredOrders = useMemo(
    () =>
      selectedWarehouse === "all"
        ? orders
        : orders?.filter((order) => order.warehouse === selectedWarehouse),
    [orders, selectedWarehouse]
  );

  const filteredWarehouseOrders = useMemo(
    () =>
      selectedWarehouse === "all"
        ? uniqueWarehouseOrders
        : uniqueWarehouseOrders?.filter(
            (order) => order.warehouse === selectedWarehouse
          ),
    [uniqueWarehouseOrders, selectedWarehouse]
  );

  const sortedRows = useMemo(
    () =>
      !!filteredOrders &&
      [...filteredOrders]?.sort(
        (a, b) => (a.categoryindex || 0) - (b.categoryindex || 0)
      ),
    [filteredOrders]
  );

  const createUniqueWarehouseOrders = (orders) => {
    const uniqueOrders = new Map();
    orders.forEach((order) => {
      if (!uniqueOrders.has(order.warehouseordernumber)) {
        uniqueOrders.set(order.warehouseordernumber, order);
      }
    });
    return Array.from(uniqueOrders.values());
  };

  const onActionSelect = (action) => {
    const data = {};
    if (action === "newInventoryOrder") {
      navigate("/inventoryOrders/buildOrder");
    } else if (selectedRows && isGroupRow(selectedRows[0])) {
      const warehouseOrderNumber = getGroupKey(
        selectedRows[0],
        "warehouseordernumber"
      );
      const filteredOrders = orders.filter(
        (order) => order.warehouseordernumber === warehouseOrderNumber
      );

      data.orders =
        action === "updateStatus" ||
        action === "distributeOrderCosts" ||
        action === "shipInventory"
          ? [filteredOrders[0]]
          : filteredOrders;
    }

    if (!data.orders) data.orders = selectedRows;
    if (action === "editInventoryOrder") {
      navigate(
        `/inventoryOrders/buildOrder?warehouseOrderNumber=${data.orders[0].warehouseordernumber}&&warehouse=${data.orders[0].warehouse}`
      );
    }
    openModal(action, data);
  };

  const handleRowSelection = (isNotEmpty, items) => {
    setSelectedRows(isNotEmpty ? items : null);
  };

  const handleWarehouseChange = (event) => {
    setSelectedWarehouse(event.target.value);
  };

  const handleSelectionCheck = (params) => {
    return (
      selectedRows?.length > 0 &&
      selectedRows[0].warehouseordernumber !== params.row.warehouseordernumber
    );
  };
  const bottomBarIsVisible =
    page === "stockPredictions" && selectedWarehouse !== "all";

  return (
    <MainContainer title={splitAndCapitalize(page)}>
      {(ordersError || searchFieldsError) && (
        <Error
          sx={{ width: "100%", margin: "auto", borderRadius: 0 }}
          errorMessage={errorMsg}
        />
      )}

      <Box
        sx={{
          position: "absolute",
          top: 5,
          left: `calc(50% + ${sideBarOpen ? "40px" : "-60px"})`,
          zIndex: 1202,
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        <WarehouseSelect
          selectedWarehouse={selectedWarehouse}
          handleWarehouseChange={handleWarehouseChange}
          hideLabel
        />
      </Box>
      <Box
        sx={{
          background: "white",
          pl: 1,
          py: 1,
          display: { sm: "block", md: "none" },
        }}
      >
        <Box width={"80%"}>
          <WarehouseSelect
            selectedWarehouse={selectedWarehouse}
            handleWarehouseChange={handleWarehouseChange}
          />
        </Box>
      </Box>

      {(searchFieldsLoading || (ordersLoading && view !== "table")) && (
        <Box width={"40px"} m={"auto"} mt={4}>
          <Loading />
        </Box>
      )}
      {currentModal && (
        <DynamicModal
          refetch={refetch}
          onClose={closeModal}
          currentModal={currentModal}
        />
      )}
      {!searchFieldsLoading && (
        <>
          {view === "card" && page === "stockOrders" ? (
            <>
              {!ordersLoading && (
                <InventoryCardView
                  page={page}
                  filteredWarehouseOrders={filteredWarehouseOrders}
                  columns={columns}
                  searchFieldOptions={searchFieldOptions}
                  refetch={refetch}
                  toggleView={toggleView}
                  view={view}
                />
              )}
            </>
          ) : view === "tile" ? (
            <>
              {!ordersLoading && (
                <InventoryTileView
                  page={page}
                  sortedRows={sortedRows}
                  columns={columns}
                  searchFieldOptions={searchFieldOptions}
                  refetch={refetch}
                  toggleView={toggleView}
                  view={view}
                  searchFieldsLoading={searchFieldsLoading}
                  handleOrdersChange={handleOrdersChange}
                  bottomBarIsVisible={bottomBarIsVisible}
                />
              )}
            </>
          ) : (
            <InventoryTableView
              page={page}
              sortedRows={sortedRows}
              columns={columns}
              ordersLoading={ordersLoading}
              searchFieldOptions={searchFieldOptions}
              refetch={refetch}
              handleRowSelection={handleRowSelection}
              handleSelectionCheck={handleSelectionCheck}
              onActionSelect={onActionSelect}
              groupingModel={groupingModel}
              setGroupingModel={setGroupingModel}
              selectedRows={selectedRows}
              toggleView={toggleView}
              view={view}
              selectedWarehouse={selectedWarehouse}
            />
          )}
        </>
      )}
      {!searchFieldsLoading && (
        <Box
          sx={{
            position: "fixed",
            right: { xs: 10, sm: 18 },
            bottom: {
              xs:
                page === "stockPredictions" &&
                selectedWarehouse !== "all" &&
                view !== "tile"
                  ? 105
                  : 10,
              sm:
                view !== "tile" &&
                (["stockOrders", "currentStock"].includes(page) ||
                  (page === "stockPredictions" && selectedWarehouse === "all"))
                  ? 40
                  : 25,
            },
            zIndex: 100,
          }}
        >
          <ActionsMenu
            onActionSelect={onActionSelect}
            selectedRows={selectedRows}
            currentPage={page}
          />
        </Box>
      )}
      {bottomBarIsVisible && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "white",
            padding: 2,
            py: 0.5,
            borderTop: 1,
            borderColor: "grey.300",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <Box width={"100%"} ml={sideBarOpen ? "250px" : "50px"}>
            <Box width={{ xs: "100%", sm: "450px" }} mx={"auto"}>
              <FreightFiller
                containerType={40}
                rows={filteredOrders}
                products={products}
                volumeFieldName={"needtoordervolume"}
              />
            </Box>
          </Box>
        </Box>
      )}
    </MainContainer>
  );
}
