import { atom } from "recoil";

export const productsState = atom({
  key: "Products",
  default: [],
});

export const orderDetailsState = atom({
  key: "OrderDetails",
  default: {
    storeCountry: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    shippingPrice: 0,
    shippingOption: "",
    shippingTax: 0,
    taxRate: "",
    discountSymbol: 10,
    discount: "",
    discountPercentage: 0,
    isSalesTaxIncluded: false,
    success: false,
    GrenkeyCanadaGST: false,
    isTaxExempt: false,
  },
});

export const activeStepState = atom({
  key: "ActiveState",
  default: 0,
});

export const customerOrdersFiltersState = atom({
  key: "customerOrdersFiltersState",
  default: [],
});

export const permissionsState = atom({
  key: "permissionState",
  default: {},
});

export const sideBarOpenState = atom({
  key: "sideBarOpenState",
  default: null,
});

export const cardViewSettingsState = atom({
  key: "cardViewSettingsState",
  default: {
    cardColumnHeaders: null,
    showColumnHeaders: true,
    canMoveCards: true,
    columnsDisplayed: [],
  },
});

export const orderSettingsState = atom({
  key: "orderSettingsState",
  default: null,
});
export const modalState = atom({
  key: "ModalState",
  default: {},
});

export const intermediateOrdersState = atom({
  key: "intermediateOrdersState",
  default: [],
});

export const customerOrdersState = atom({
  key: "customerOrdersState", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const orderState = atom({
  key: "orderState",
  default: [],
});

export const refetchTriggerState = atom({
  key: "refetchTriggerState",
  default: 0, // Starting with 0, but the value itself doesn't matter.
});

export const fetchNeededState = atom({
  key: "fetchNeeded",
  default: true,
});

export const conversationsState = atom({
  key: "conversationsState",
  default: [],
});

export const notificationsCountState = atom({
  key: "notificationsCountState",
  default: 0,
});
export const notificationsState = atom({
  key: "notificationsState",
  default: null,
});

export const accountingOrderReimbursementState = atom({
  key: "accountingOrderReimbursementState",
  default: [],
});

export const userSubscribedState = atom({
  key: "userSubscribedState",
  default: false, // Initial state indicating the user is not subscribed
});

export const activeConversationState = atom({
  key: "activeConversationState",
  default: null, // Initial state indicating the user is not subscribed
});
export const orderFilesState = atom({
  key: "orderFilesState",
  default: [],
});

// For updating history
export const actionPerformedState = atom({
  key: "actionPerformedState",
  default: 0,
});

export const customerOrdersRowIndexState = atom({
  key: "customerOrdersRowIndexState",
  default: 0,
});

export const messagesByConversationState = atom({
  key: "messagesByConversationState",
  default: {},
});
export const mainProductsState = atom({
  key: "mainProductsState",
  default: [],
});

export const extraProductsState = atom({
  key: "extraProductsState",
  default: {
    priorityShipping: null,
    insurance: null,
    customShipping: null,
  },
});

export const tileViewSettingsState = atom({
  key: "tileViewSettingsState",
  default: {
    columnsDisplayed: [],
  },
});
