import { useState, useCallback, useEffect, useRef } from "react";
import { GRID_ROOT_GROUP_ID } from "@mui/x-data-grid-premium";
const INITIAL_EXPANDED_GROUPS = 100;

export const useGroupExpansion = (props, apiRef, isInitialised) => {
  const { dataGridName, groupingModel, rows, isGroupExpandedByDefault } = props;
  const [expandedGroupCount, setExpandedGroupCount] = useState(
    INITIAL_EXPANDED_GROUPS
  );

  const [expandedGroups, setExpandedGroups] = useState(() => {
    const saved = localStorage.getItem(`${dataGridName}_expandedGroups`);
    return saved ? JSON.parse(saved) : {};
  });

  const handleRowExpansionChange = useCallback(
    (params) => {
      setExpandedGroups((prev) => {
        const updated = {
          ...prev,
          [params.id]: params.childrenExpanded,
        };
        localStorage.setItem(
          `${dataGridName}_expandedGroups`,
          JSON.stringify(updated)
        );
        return updated;
      });
    },
    [dataGridName]
  );

  useEffect(() => {
    if (Object.keys(apiRef.current).length !== 0) {
      apiRef.current.subscribeEvent(
        "rowExpansionChange",
        handleRowExpansionChange
      );
    }
  }, [apiRef.current, handleRowExpansionChange, isInitialised]);

  useEffect(() => {
    if (
      rows.length > 0 &&
      Object.keys(apiRef.current).length !== 0 &&
      groupingModel &&
      groupingModel.length > 0
    ) {
      const processGroups = () => {
        const groups = apiRef.current.getRowNode(GRID_ROOT_GROUP_ID).children;
        groups.forEach((groupID, index) => {
          // console.log(groupID);
          if (index < expandedGroupCount) {
            let isExpanded;
            if (groupID in expandedGroups) {
              isExpanded = expandedGroups[groupID];
            } else if (isGroupExpandedByDefault) {
              const row = apiRef.current.getRowNode(groupID);
              isExpanded = isGroupExpandedByDefault(row);
            } else {
              isExpanded = false;
            }

            apiRef.current.setRowChildrenExpansion(groupID, isExpanded);
          }
        });
      };

      requestAnimationFrame(processGroups);
    }
  }, [
    apiRef.current,
    rows.length,
    isGroupExpandedByDefault,
    groupingModel,
    isInitialised,
  ]);

  return {
    expandedGroups,
  };
};
