import React, { useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, useTheme, useMediaQuery } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchOrdersInput from "../../../components/SearchOrdersInput";
import CardViewSettingsModal from "../../../components/CardViewSettingsModal/Modal";
import CardView from "../../../components/CardView";
import FilterMenu from "../../../components/Filter";
import ViewToggleButtons from "../../CustomerOrders/components/ViewToggleButtons";

export const InventoryCardView = ({
  page,
  filteredWarehouseOrders,
  columns,
  searchFieldOptions,
  refetch,
  view,
  toggleView,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);

  const handleMobileMenuClick = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: theme.palette.background.paper,
          py: 0.5,
          px: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            gap: { xs: 1, sm: 2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {!isMobile && (
              <Box mr={1}>
                <ViewToggleButtons
                  view={view}
                  onToggleView={toggleView}
                  viewType={"card"}
                />
              </Box>
            )}
            <SearchOrdersInput table={page} handleSearch={refetch} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            maxWidth={"100%"}
          >
            <FilterMenu
              table={page}
              searchFieldOptions={searchFieldOptions}
              refetchOrders={refetch}
            />
            <CardViewSettingsModal
              dataGridName={page}
              searchFields={searchFieldOptions}
            />
            {isMobile && (
              <IconButton size="small" onClick={handleMobileMenuClick}>
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={mobileMenuAnchorEl}
        open={Boolean(mobileMenuAnchorEl)}
        onClose={handleMobileMenuClose}
      >
        <MenuItem
          onClick={() => {
            toggleView("table");
            handleMobileMenuClose();
          }}
        >
          Switch to Table View
        </MenuItem>
      </Menu>
      <Box
        sx={{
          height: { xs: "calc(100% - 155px)", md: "calc(100% - 100px)" },
          width: "100%",
          alignContent: "center",
        }}
      >
        {searchFieldOptions && (
          <CardView
            table="inventoryorders"
            data={filteredWarehouseOrders}
            searchFields={columns}
            dataGridName="stockOrders"
            defaultColumnHeader={{
              fieldName: "status",
              dataType: "string",
              label: "Status",
            }}
          />
        )}
      </Box>
    </>
  );
};
