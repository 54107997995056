import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import Link from "@mui/material/Link"; // Import Link component for the hyperlink
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { useEffect } from "react";

const EditOrderNotes = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState(data);
  }, []);

  const handleChange = (e) => {
    setState({ ...state, internalSupplierNotes: e.target.value });
  };

  const fillFromOrderNotes = () => {
    setState({ ...state, internalSupplierNotes: data.buyerNotes });
  };

  return (
    <>
      <DialogContentText>
        Use the input below to update the supplier notes. Be sure to save your
        changes.
      </DialogContentText>
      <Box mt={2} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="flex-end">
          <Link component="button" variant="body2" onClick={fillFromOrderNotes}>
            Fill from order notes
          </Link>
        </Box>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={state.internalSupplierNotes}
          onChange={handleChange}
          sx={{ mt: 1 }} // Add margin top to separate from the link
        />
      </Box>
    </>
  );
};

export default EditOrderNotes;
