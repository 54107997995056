import { API_ORDER_LINK_URL, API_SEARCH_LINK_URL } from "../consts";
import axios, { isCancel } from "axios"; // Import axios to use isCancel
import axiosInstance from "./axios"; // Your custom Axios instance
const getOrderReimbursements = (name, filterQueryString, cancelToken) => {
  let qs = filterQueryString ? `?${filterQueryString}` : "";

  return axiosInstance
    .get(
      API_SEARCH_LINK_URL +
        `/searchOrderReimbursement${
          name.charAt(0).toUpperCase() + name.slice(1)
        }${qs}`,
      {
        cancelToken: cancelToken,
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      if (isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        // Handle other errors
        throw error;
      }
    });
};

function performOrderReimbursementAction(action) {
  return axiosInstance
    .post(API_ORDER_LINK_URL + "/performOrderReimbusementAction", action)
    .then((response) => response.data);
}

export { getOrderReimbursements, performOrderReimbursementAction };
