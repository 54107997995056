import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { shippingStatuses } from "../../../common";
import TextField from "@mui/material/TextField";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { useEffect, useState } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import { getProductsByCountryAndWarehouse } from "../../../api/Products";
import Loading from "../../../components/Loading";
import { warehouses } from "../../../common";
import Typography from "@mui/material/Typography";
import { createFilterOptions } from "@mui/material/Autocomplete";

const SwapProduct = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({
      uid: data.order.uid,
      warehouse: data.order?.warehouse,
      model: data.order.descriptionshort,
      qty: data.order?.qty,
      pid: data.order.pid,
    });
  }, []);

  useEffect(() => {
    if (state.warehouse) {
      setState((prevState) => ({ ...prevState, productPIDs: null }));
      getProductsByCountryAndWarehouse(
        data.order.uid.split("-")[1],
        state.warehouse
      ).then((rsp) => {
        setState((prevState) => ({
          ...prevState,
          productPIDs: rsp.countryProducts?.map((p, i) => {
            return {
              id: i,
              label: p.pid,
              description: p.description,
            };
          }),
        }));
      });
    }
  }, [state.warehouse]);
  const handleChange = (value, name) => {
    if (name === "pid") {
      setState((prevState) => ({
        ...prevState,
        pid: value?.label || "",
        model: value?.description || "",
      }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.label} ${option.description}`,
  });

  return (
    <>
      <DialogContentText>
        Please select a product from the dropdown below to swap with the current
        product.
      </DialogContentText>
      <Box mt={3}>
        {!state.productPIDs && (
          <Box width={40} mx={"auto"}>
            <Loading />
          </Box>
        )}
        {state.productPIDs && (
          <>
            <Autocomplete
              value={
                state.productPIDs.find(
                  (option) => option.label === state.pid
                ) || null
              }
              onChange={(event, newValue) => {
                handleChange(newValue, "pid");
              }}
              options={state.productPIDs}
              renderInput={(params) => (
                <TextField label={"Product"} fullWidth {...params} required />
              )}
              filterOptions={filterOptions}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  display="flex"
                  flexDirection="column"
                  sx={{ alignItems: "flex-start !important" }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "left !important" }}
                  >
                    {option.label}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "left !important" }}
                  >
                    {option.description}
                  </Typography>
                </Box>
              )}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
            />

            <TextField
              sx={{ mt: 2 }}
              label="Qty"
              type={"number"}
              value={state.qty}
              onChange={(e) => handleChange(e.target.value, "qty")}
              required
            />
            <Autocomplete
              sx={{ mt: 2 }}
              value={state.warehouse}
              onChange={(event, newValue) => {
                handleChange(newValue, "warehouse");
              }}
              options={warehouses} // This needs to be an array of tracking numbers
              renderInput={(params) => (
                <TextField label={"Warehouse"} fullWidth {...params} required />
              )}
            />
          </>
        )}
      </Box>
    </>
  );
};
export default SwapProduct;
