import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useRecoilValue } from "recoil";
import { permissionsState } from "../../../atoms/atoms";
import { useNavigate } from "react-router-dom";

const OfflineOrderActionsMenu = ({ products, onActionSelect }) => {
  const navigate = useNavigate();
  const permissions = useRecoilValue(permissionsState).customerOrders.actions;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => () => {
    setAnchorEl(null);
    if (action) onActionSelect(action);
  };

  const canMarkAsDelivered = products.some((product) =>
    [
      "SHIPPED",
      "SYS_ARRIVED_AT_CUSTOMS",
      "SYS_CUSTOMS_DELAY",
      "SYS_CUSTOMS_CLEARED",
      "SYS_TRANSIT_DELAY",
      "OUT_FOR_DELIVERY",
      "AWAITING_COLLECTION",
      "ATTEMPTED_DELIVERY",
      "SYS_SHIPMENT_LOST",
    ].includes(product.shippingstatus)
  );

  return (
    <>
      <Button
        aria-controls="order-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          minWidth: "200px",
          color: "white",
          backgroundColor: "primary.main",
          px: 2,
          height: "40px",
          "&:hover": {
            backgroundColor: "primary.focus",
            borderColor: "primary.main",
          },
        }}
        size="large"
      >
        Order Actions
      </Button>
      <Menu
        id="order-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose()}
      >
        <MenuItem
          onClick={() =>
            navigate(`/offlineorders/${products[0].wixordernumber}`)
          }
        >
          Edit Order
        </MenuItem>

        {permissions.cancelOrder && (
          <MenuItem onClick={handleClose("cancelOrder")}>Cancel Order</MenuItem>
        )}

        {permissions.markAsDelivered && canMarkAsDelivered && (
          <MenuItem onClick={handleClose("markAsDelivered")}>
            Mark as Delivered
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default OfflineOrderActionsMenu;
