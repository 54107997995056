import React, { useEffect } from "react";
import SimpleDataGrid from "../../../../components/Datagrid/SimpleDataGrid";

import {
  Box,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../../atoms/atoms";
import { getTableHeight } from "../../../../common";

const EditCosts = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({
      markAsReady: false,
      rows: data.orders.map((order) => {
        return {
          id: order.uid,
          ordernumber: order.wixlocalordernumber,
          descriptionshort: order.descriptionshort,
          qty: order.qty,
          supplierperunitcost: parseInt(order.supplierperunitcost) || 0,
          internationalshippingcost:
            parseInt(order.internationalshippingcost) || 0,
          totalCost: 0,
        };
      }),
    });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "UID",
      flex: 2,
      sortable: false,
      hidden: true,
    },
    {
      headerName: "Order Number",
      field: "ordernumber",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Model",
      field: "descriptionshort",
      flex: 3,
      sortable: false,
    },
    {
      headerName: "Qty",
      field: "qty",
      flex: 2,
      sortable: false,
    },
    {
      field: "supplierperunitcost",
      headerName: "Per Unit Cost",
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <TextField
          sx={{ mt: 0.5 }}
          type="number"
          defaultValue={params.value.toFixed(2)}
          onBlur={(e) =>
            handleInputChange(e, "supplierperunitcost", params.row.id)
          }
          onFocus={(e) => e.target.select()}
          size={"small"}
        />
      ),
    },
    {
      field: "internationalshippingcost",
      headerName: "Shipping Cost",
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <TextField
          sx={{ mt: 0.5 }}
          type="number"
          defaultValue={params.value.toFixed(2)}
          onBlur={(e) =>
            handleInputChange(e, "internationalshippingcost", params.row.id)
          }
          onFocus={(e) => e.target.select()}
          size={"small"}
        />
      ),
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        // Perform the calculation for total cost
        const totalCost =
          params.row.supplierperunitcost * params.row.qty +
          params.row.internationalshippingcost;

        return (
          <Typography sx={{ mt: 1.5 }}>${totalCost.toFixed(2)}</Typography>
        );
      },
    },
  ];

  const handleInputChange = (e, name, uid) => {
    if (!uid) {
      setState((currentState) => ({
        ...currentState,
        [name]: e.target.checked,
      }));
    } else
      setState((currentState) => ({
        ...currentState,
        rows: currentState.rows.map((row) =>
          row.id === uid
            ? { ...row, [name]: parseFloat(e.target.value) || 0 }
            : row
        ),
      }));
  };

  const tableHeight = getTableHeight(state?.rows?.length);

  return (
    <Box>
      <Box sx={{ margin: "auto", height: tableHeight, width: "100%" }}>
        {state.rows && (
          <SimpleDataGrid
            sx={{ height: tableHeight }}
            rows={state.rows}
            columns={columns}
            hideFooter
            disableColumnMenu
          />
        )}
      </Box>
      <Box>
        <FormControlLabel
          sx={{ mt: 1 }}
          control={
            <Checkbox
              checked={state.markAsReady || false}
              onChange={(e) => handleInputChange(e, "markAsReady")}
              name="markAsReady"
              color="primary"
            />
          }
          label="Mark as ready for payment"
        />
      </Box>
    </Box>
  );
};
export default EditCosts;
