import pusher from "../pusherClient";

export const subscribeToNotificationChannel = (
  userID,
  location,
  messagesByConversation,
  setMessagesByConversation,
  setNotifications,
  activeConversationRef,
  activeConversation
) => {
  const channel = pusher.subscribe(`notifications-${userID}`);

  const handleNewMessage = ({ message }) => {
    const conversationID = message.conversationID;

    if (!location.pathname.split("/").includes("order")) {
      if (conversationID in messagesByConversation) {
        setMessagesByConversation((prevState) => ({
          ...prevState,
          [conversationID]: [...prevState[conversationID], message],
        }));
      }
    } else {
      if (activeConversationRef.current === conversationID) return;
    }
    setNotifications((prevState) => [message, ...prevState]);
  };

  const handleDeleteMessage = ({ messageID, conversationID }) => {
    if (conversationID in messagesByConversation)
      setMessagesByConversation((prevState) => ({
        ...prevState,
        [conversationID]: prevState[conversationID]?.filter(
          (message) => message.id !== messageID
        ),
      }));
    setNotifications((prevState) =>
      prevState.filter((message) => message.id !== messageID)
    );
  };

  channel.bind("new-message", handleNewMessage);
  channel.bind("delete-message", handleDeleteMessage);

  activeConversationRef.current = activeConversation;

  return () => {
    channel.unbind("new-message", handleNewMessage);
    channel.unbind("delete-message", handleDeleteMessage);
    pusher.unsubscribe(`notifications-${userID}`);
  };
};
