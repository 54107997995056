import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldLess";
import UnfoldLessIcon from "@mui/icons-material/UnfoldMore";
import SearchIcon from "@mui/icons-material/Search";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useRecoilState } from "recoil";
import { orderFilesState } from "../../../../atoms/atoms";
import {
  getFiles,
  createFile,
  uploadFile,
  renameFile,
  deleteFile,
} from "../../../../api/Files";
import Loading from "../../../../components/Loading";
import FileMenu from "../../components/FileMenu";
import FilePreview from "./FilePreview";
import DeleteModal from "../../../../components/DeleteModal";
import Modal from "../../../../components/Modal";

const FilesSection = ({ orderID, open, handleOpen }) => {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useRecoilState(orderFilesState);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [newFileName, setNewFileName] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    getFiles(orderID)
      .then((rsp) => {
        if (rsp) setFiles(rsp?.filter((file) => !file.deleted));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [orderID, setFiles]);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    newFiles.forEach(async (file) => {
      await handleFileUpload(file);
    });

    // Reset the input value
    event.target.value = null;
  };
  const handleFileUpload = async (file) => {
    const tempId = `${file.name}-${Date.now()}`;
    const tempFile = {
      id: tempId,
      name: file.name,
      size: file.size,
      contentType: file.type,
      isUploading: true,
      uploadError: false,
    };
    setFiles((prevFiles) => [...(prevFiles || []), tempFile]);

    try {
      const fileInfo = await createFile("order", orderID, file.name);
      const { uploadUrl, contentType, ...fields } = fileInfo;

      await uploadFile(uploadUrl, file, contentType);

      let url = URL.createObjectURL(file);

      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === tempId
            ? {
                ...fields,
                size: fields.size || f.size || "-",
                contentType: contentType,
                url: url,
                saveUrl: url,
                isUploading: false,
                uploadError: false,
              }
            : f
        )
      );
    } catch (error) {
      console.error("Failed to upload file:", error);
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === tempId ? { ...f, isUploading: false, uploadError: true } : f
        )
      );
    }
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleCloseRename = () => {
    setOpenRename(false);
  };
  const handleRenameSubmit = async () => {
    if (!selectedFile || !newFileName) {
      return;
    }
    setSubmitLoading(true);

    try {
      // Step 1: Update name on server
      const newFileInfo = await renameFile(
        selectedFile.id,
        newFileName,
        orderID
      );
      const { name: newName, id } = newFileInfo;

      // Update the files state with the new file name
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === id
            ? {
                ...f,
                name: newName,
                loading: false,
              }
            : f
        )
      );
    } catch (error) {
      console.error("Failed to rename file:", error);
    } finally {
      setSubmitLoading(false);
      handleCloseRename();
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      setSubmitLoading(true);
      // Delete file on server
      const deletedFileInfo = await deleteFile(selectedFile.id);

      // Update the files state to remove file
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== deletedFileInfo.id)
      );
    } catch (error) {
      console.error("Failed to delete file:", error);
    } finally {
      setSubmitLoading(false);
      setOpenDelete(false);
      // Reset the input value
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        handleFileUpload(file);
      });
    },
    [handleFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteFile = (file) => {
    setSelectedFile(file);
    setOpenDelete(true);
  };

  const handleRenameFile = (file) => {
    setNewFileName("");
    setSelectedFile(file);
    setOpenRename(true);
  };

  const handleOpenFile = (index) => {
    setSelectedFileIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedFileIndex(null);
  };

  const handleNextFile = () => {
    setSelectedFileIndex((prevIndex) => (prevIndex + 1) % files.length);
  };

  const handlePrevFile = () => {
    setSelectedFileIndex(
      (prevIndex) => (prevIndex - 1 + files.length) % files.length
    );
  };

  const filteredFiles = files?.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownloadFile = (file) => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = file.saveUrl || file.url; // Use the blob URL or the URL of the file
    link.download = file.name; // Set the download attribute to the file name
    document.body.appendChild(link); // Append to the document
    link.click(); // Programmatically click the link to trigger the download
    document.body.removeChild(link); // Remove the link from the document
  };

  const isImageFile = (file) => {
    return file.type?.startsWith("image/");
  };

  const humanFileSize = (size) => {
    if (typeof size !== "number" || isNaN(size) || size < 0) {
      return "Unknown size";
    }
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  };

  return (
    <>
      {selectedFile && (
        <Modal
          title={"Rename File"}
          openModal={openRename}
          handleCloseModal={handleCloseRename}
          children={
            <TextField
              label={"File Name"}
              fullWidth
              value={newFileName || selectedFile.name.split(".")[0]}
              onChange={(e) => setNewFileName(e.target.value)}
            />
          }
          small
          handleSubmit={handleRenameSubmit}
          loading={submitLoading}
        />
      )}
      {selectedFile && (
        <DeleteModal
          openDeleteModal={openDelete}
          handleCloseDeleteModal={handleCloseDelete}
          handleDeleteItem={handleDeleteSubmit}
          message={`Are you sure you want to permanently delete ${selectedFile?.name}? `}
          loading={submitLoading}
        />
      )}
      <Paper
        {...getRootProps()}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: open ? 1 : 0,
          minHeight: open ? "calc(100% - 98px)" : "80px",
          mb: 2,
          overflow: "hidden",
        }}
      >
        <input {...getInputProps()} style={{ display: "none" }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "80px",
            backgroundColor: "#f3f3f3",
            position: "relative",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
            Files ({files?.length || 0})
          </Typography>
          <IconButton
            sx={{
              position: "absolute",
              right: 20,
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={handleOpen}
          >
            {open ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
          </IconButton>
        </Box>

        {open && (
          <Box sx={{ flex: 1, p: 2, overflowY: "auto" }}>
            <Box display={"flex"} mb={1}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Search files..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <IconButton onClick={handleUploadClick} sx={{ ml: 2 }}>
                <UploadFileIcon />
              </IconButton>
            </Box>
            {loading && (
              <Box width={40} m={"auto"} mt={2}>
                <Loading />
              </Box>
            )}
            {!loading && files?.length === 0 ? (
              <Box
                sx={{
                  p: 5,
                  textAlign: "center",
                  border: "2px dashed #ccc",
                  borderRadius: 2,
                  mt: 2,
                }}
              >
                <CloudUploadIcon
                  sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
                />
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  No files uploaded yet
                </Typography>
                <Typography color="text.secondary">
                  Drag and drop files here, or click the upload button to add
                  files
                </Typography>
              </Box>
            ) : isDragActive ? (
              <Box
                sx={{ p: 5, textAlign: "center", border: "2px dashed #ccc" }}
              >
                <Typography>Drop the files here ...</Typography>
              </Box>
            ) : (
              <List>
                {filteredFiles?.map((file, index) => {
                  const size = humanFileSize(parseInt(file.size));
                  return (
                    <Paper key={index} sx={{ m: 1, px: "4px" }}>
                      <ListItem
                        secondaryAction={
                          !file.isUploading &&
                          !file.uploadError && (
                            <FileMenu
                              handleDownloadFile={() =>
                                handleDownloadFile(file)
                              }
                              handleRenameFile={() => handleRenameFile(file)}
                              handleDeleteFile={() => handleDeleteFile(file)}
                            />
                          )
                        }
                      >
                        <ListItemIcon
                          sx={{ "&:hover": { cursor: "pointer" } }}
                          onClick={() =>
                            !file.isUploading &&
                            !file.uploadError &&
                            handleOpenFile(index)
                          }
                        >
                          {file.isUploading ? (
                            <CircularProgress size={24} />
                          ) : file.uploadError ? (
                            <ErrorOutlineIcon color="error" />
                          ) : isImageFile(file) ? (
                            <img
                              src={file.url}
                              alt={file.name}
                              loading="lazy"
                              style={{
                                width: 50,
                                height: 50,
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <InsertDriveFileIcon sx={{ m: "auto" }} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={file.name}
                          secondary={
                            file.isUploading
                              ? "Uploading..."
                              : file.uploadError
                              ? "Upload failed"
                              : size
                          }
                          sx={{
                            pr: 1,
                            ".MuiListItemText-primary": {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            },
                            ".MuiListItemText-secondary": {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: file.uploadError
                                ? "error.main"
                                : "inherit",
                            },
                            maxWidth: "calc(100% - 70px)",
                            ml: { xs: "4px", sm: 1 },
                          }}
                        />
                      </ListItem>
                    </Paper>
                  );
                })}
              </List>
            )}
          </Box>
        )}

        {selectedFileIndex !== null && (
          <FilePreview
            handleCloseModal={handleCloseModal}
            files={files}
            selectedFileIndex={selectedFileIndex}
            handleDownloadFile={handleDownloadFile}
            isImageFile={isImageFile}
            handlePrevFile={handlePrevFile}
            handleNextFile={handleNextFile}
          />
        )}
      </Paper>
    </>
  );
};

export default FilesSection;
