import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress'

const Loading = ({sx, size}) => {
    return (
        <Box sx={sx}>
            <CircularProgress size={size || 40 }/>
        </Box>
    )
}
export default Loading