import Box from "@mui/material/Box";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Paper } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";

const DraggableList = ({ state, setState }) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(state.columnsDisplayed);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setState({ ...state, columnsDisplayed: items });
  };

  const deleteItem = (index) => {
    setState((prevState) => {
      const newArray = [...prevState.columnsDisplayed];
      newArray.splice(index, 1);
      return { ...prevState, columnsDisplayed: newArray };
    });
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 1 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="columnsDisplayed">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {state?.columnsDisplayed?.map((column, index) => (
                <Draggable
                  key={column.fieldName}
                  draggableId={column.fieldName}
                  index={index}
                >
                  {(provided) => (
                    <Paper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        padding: 1,
                        textAlign: "center",
                        mb: 1,
                        borderRadius: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box mt={1} ml={1} display={"flex"}>
                        <MenuIcon sx={{ mr: 2 }} />
                        <Typography>
                          {column.label || column.displayName}
                        </Typography>
                      </Box>
                      <IconButton onClick={() => deleteItem(index)}>
                        <ClearIcon />
                      </IconButton>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
export default DraggableList;
