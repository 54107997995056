module.exports = {
  // Note:  production here means "when it's running `npm run build`", (as opposed to "development" which is local `npm run start`
  // So "production" in this sense may be building for production but also could be building for any of the other environments
  // e.g.the callum/kat/staging firebase deployments, which are not 'true' production environments.
  // For a real production deployment, we'll use a deploy script to set the environment variables to the following
  // REACT_APP_API_ORDER_LINK_URL="https://us-central1-mclellanhill-gcp.cloudfunctions.net/orderUiLink"
  // REACT_APP_API_SEARCH_LINK_URL="https://us-central1-mclellanhill-gcp.cloudfunctions.net/searchUiAppLink"
  API_ORDER_LINK_URL:
      process.env.REACT_APP_API_ORDER_LINK_URL ||
      "https://us-central1-mclellanhill-testing-portal.cloudfunctions.net/orderUiLink",
  API_SEARCH_LINK_URL:
    process.env.REACT_APP_API_SEARCH_LINK_URL ||
    "https://us-central1-mclellanhill-testing-portal.cloudfunctions.net/searchUiAppLink",
  API_TOKEN_RELAY_IFRAME_URL:
      (process.env.REACT_APP_API_ORDER_LINK_URL ||
      "https://us-central1-mclellanhill-testing-portal.cloudfunctions.net/orderUiLink")
          .split("/").slice(0, -1).join("/") + "/tokenRelayIframe",

  PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  PUSHER_APP_KEY: process.env.REACT_APP_PUSHER_APP_KEY,
};
