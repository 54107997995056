import Box from "@mui/material/Box";
import React, { useEffect, useState, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { getProductsByCountry } from "../../../api/Products";
import Loading from "../../../components/Loading";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FreightFiller from "../../../components/FreightFiller";
import AddIcon from "@mui/icons-material/Add";
import ReusableTable from "../../../components/Table";

const CreateInventoryTable = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);

  const handleInputChange = useCallback(
    (value, field, id) => {
      const selectedProduct = products.find(
        (product) => product[field] === value
      );

      const updatedRows = state.rows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [field]: value,
            description: selectedProduct
              ? selectedProduct.description
              : row.description,
            pid: selectedProduct ? selectedProduct.pid : row.pid,
          };
        }
        return row;
      });

      setState((prevState) => ({ ...prevState, rows: updatedRows }));
    },
    [products, state.rows, setState]
  );

  const columns = [
    {
      field: "description",
      headerName: "Product Name",
      width: 350,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          fullWidth
          options={products?.map((product) => product.description)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Product Name"
              onClick={(event) => event.stopPropagation()}
            />
          )}
          value={row.description || ""}
          onChange={(event, newValue) =>
            handleInputChange(newValue, "description", row.id)
          }
        />
      ),
    },
    {
      field: "pid",
      headerName: "PID",
      width: 350,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          fullWidth
          options={products?.map((product) => product.pid)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="PID"
              onClick={(event) => event.stopPropagation()}
            />
          )}
          value={row.pid || ""}
          onChange={(event, newValue) =>
            handleInputChange(newValue, "pid", row.id)
          }
        />
      ),
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 150,
      editable: true,
      type: "number",
    },
    {
      field: "tradeFinanced",
      headerName: "Trade Financed",
      width: 150,
      editable: true,
      renderEditCell: ({ row }) => (
        <Checkbox
          checked={row.tradeFinanced || false}
          onChange={(e) =>
            handleInputChange(e.target.checked, "tradeFinanced", row.id)
          }
        />
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      editable: true,
      renderEditCell: ({ row }) => (
        <TextField
          multiline
          rows={2}
          value={row.notes || ""}
          onChange={(e) => handleInputChange(e.target.value, "notes", row.id)}
          size={"small"}
          fullWidth
        />
      ),
    },
  ];

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      rows: data.isNotBlank
        ? [
            {
              id: 0,
              description: "",
              pid: "",
              qty: 1,
              tradeFinanced: false,
              notes: "",
            },
            {
              id: 1,
              description: "",
              pid: "",
              qty: 1,
              tradeFinanced: false,
              notes: "",
            },
            {
              id: 2,
              description: "",
              pid: "",
              qty: 1,
              tradeFinanced: false,
              notes: "",
            },
            {
              id: 3,
              description: "",
              pid: "",
              qty: 1,
              tradeFinanced: false,
              notes: "",
            },
            {
              id: 4,
              description: "",
              pid: "",
              qty: 1,
              tradeFinanced: false,
              notes: "",
            },
          ]
        : [
            {
              id: 0,
              description: "",
              pid: "",
              qty: 1,
              tradeFinanced: false,
              notes: "",
            },
          ],
    }));
  }, [data.isNotBlank, setState]);

  useEffect(() => {
    getProductsByCountry(state.warehouse.slice(0, 2))
      .then((rsp) => {
        setProducts(rsp.countryProducts);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [state.warehouse]);

  const handleDeleteOrder = useCallback(
    (rowID) => {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.filter((row) => row.id !== rowID),
      }));
    },
    [setState]
  );

  const handleAdd = useCallback(() => {
    const newRows = [
      ...state.rows,
      { id: state.rows.length, description: "", pid: "", qty: 1 },
    ];
    setState((prevState) => ({ ...prevState, rows: newRows }));
  }, [state.rows, setState]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!loading && (
        <Box mb={3}>
          <FreightFiller
            containerType={state.containerSize ? state.containerSize : 20}
            products={products}
            rows={state.rows}
          />
        </Box>
      )}

      <Box sx={{ margin: "auto", width: "100%" }}>
        {loading && (
          <Box mt={2}>
            <Loading sx={{ width: "40px", m: "auto" }} />
          </Box>
        )}
        {!loading && state.rows && (
          <ReusableTable
            columns={columns}
            rows={state.rows}
            onCellChange={handleInputChange}
            handleAdd={handleAdd}
            handleDelete={handleDeleteOrder}
          />
        )}
      </Box>
    </Box>
  );
};

export default CreateInventoryTable;
