import React, { useState } from "react";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import DataGrid from "../../components/Datagrid/Datagrid";
import Error from "../../components/Error";
import FilterMenu from "../../components/Filter";
import MainContainer from "../../layouts/main";
import CardView from "../../components/CardView";
import CardViewSettingsModal from "../../components/CardViewSettingsModal/Modal";
import Loading from "../../components/Loading";
import SearchOrdersInput from "../../components/SearchOrdersInput";
import ViewToggleButtons from "./components/ViewToggleButtons";
import { ActionMenu } from "./components/Menu";
import DynamicModal from "./components/DynamicModal";

import {
  customerOrdersRowIndexState,
  customerOrdersState,
  permissionsState,
  refetchTriggerState,
} from "../../atoms/atoms";
import useViewToggle from "../../hooks/useViewToggle";
import useCustomerOrders from "../../hooks/useCustomerOrders";
import useSearchFields from "../../hooks/useSearchFields";
import useModalManager from "../../hooks/useModalManager";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { errorMsg } from "../../common";

const CustomerOrders = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const permissions = useRecoilValue(permissionsState);
  const [customerOrders, setCustomerOrders] =
    useRecoilState(customerOrdersState);
  const { ordersLoading, ordersError } = useCustomerOrders();
  const setRefetchTrigger = useSetRecoilState(refetchTriggerState);
  const setCustomerOrdersRowIndex = useSetRecoilState(
    customerOrdersRowIndexState
  );

  const [view, toggleView] = useViewToggle("customerOrders");
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const { openModal, closeModal, currentModal } = useModalManager();

  const {
    searchFieldOptions,
    columns,
    searchFieldsLoading,
    searchFieldsError,
  } = useSearchFields(
    permissions?.["customerOrders"].columns,
    permissions?.id,
    "orderUnit"
  );

  const handleRowSelection = (isNotEmpty, items) => {
    if (Math.abs((selectedRows?.length || 0) - items.length) > 1) {
      setSelectedRows(null);
      setSelectedWarehouse(null);
      return;
    }
    setSelectedRows(isNotEmpty ? items : null);
    setSelectedWarehouse(
      isNotEmpty && items.length > 0
        ? customerOrders.find((order) => order.id === items[0].id)?.warehouse
        : null
    );
  };

  const handleSearch = () => setRefetchTrigger((prev) => prev + 1);

  const onRowClick = (params) => {
    console.log("Clicked", params?.row?.wixordernumber);
    if (params?.row?.wixordernumber) {
      const rowIndex = params.api
        .getAllRowIds()
        .findIndex((rowID) => rowID === params.id);
      setCustomerOrdersRowIndex(rowIndex);
      navigate(`/order/${params.row.wixordernumber}`);
    }
  };

  const onActionSelect = (action) => {
    const selectedIDs = selectedRows.map((row) => row.id);
    const selectedOrders = customerOrders.filter((order) =>
      selectedIDs.includes(order.id)
    );
    openModal(action, { orders: selectedOrders });
  };

  const updateOrders = (state) => {
    setCustomerOrders((prevState) =>
      prevState.map((order) => {
        const updatedRow = state.rows.find((row) => row.id === order.id);
        return updatedRow ? { ...order, ...updatedRow } : order;
      })
    );
  };
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);

  const handleMobileMenuClick = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const renderCardViewControls = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.paper,
        py: 0.5,
        px: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: { xs: 1, sm: 2 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            flexWrap: "wrap",
            ml: 1,
            gap: 1,
          }}
        >
          {!isMobile && (
            <Box mr={1}>
              <ViewToggleButtons
                view={view}
                onToggleView={toggleView}
                viewType={"card"}
              />
            </Box>
          )}

          <SearchOrdersInput
            table="customerOrders"
            handleSearch={handleSearch}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          maxWidth={"100%"}
        >
          <FilterMenu
            table="customerOrders"
            searchFieldOptions={searchFieldOptions}
            refetchOrders={handleSearch}
          />
          <Box ml={1}>
            <CardViewSettingsModal
              dataGridName="customerOrders"
              searchFields={searchFieldOptions}
            />
          </Box>

          {isMobile && (
            <IconButton size="small" onClick={handleMobileMenuClick}>
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      <Menu
        anchorEl={mobileMenuAnchorEl}
        open={Boolean(mobileMenuAnchorEl)}
        onClose={handleMobileMenuClose}
      >
        <MenuItem
          onClick={() => {
            toggleView("table");
            handleMobileMenuClose();
          }}
        >
          Switch to Table View
        </MenuItem>
      </Menu>
    </Box>
  );

  const renderContent = () => {
    if (
      searchFieldsLoading &&
      (view === "table" || (view === "card" && !ordersLoading))
    ) {
      return (
        <Box width={"40px"} m={"auto"}>
          <Loading />
        </Box>
      );
    }

    if (view === "table" && !searchFieldsLoading && columns) {
      return (
        <Box sx={{ height: `calc(100% - 50px)`, width: "100%" }}>
          {currentModal && (
            <DynamicModal
              onClose={closeModal}
              currentModal={currentModal}
              refetchOrders={updateOrders}
            />
          )}
          <DataGrid
            noBorder
            rowID="id"
            rows={customerOrders}
            rowHeight={75}
            storage={{
              pinned: "pinnedLeft",
              columns: "customerOrdersColumns",
              visibilityModel: "customerOrdersVisibility",
            }}
            dataGridName="customerOrders"
            searchFields={columns}
            loading={ordersLoading}
            hideFooter
            onRowClick={onRowClick}
            handleRowSelection={
              !!permissions.customerOrders.actions.bulkEdit &&
              handleRowSelection
            }
            handleSearch={handleSearch}
            selectedWarehouse={selectedWarehouse}
            handleSelectionCheck={(params) =>
              params.row.shippingstatus === "DELIVERED" ||
              params.row["orderpaymentstatus"] === "NOT_PAID" ||
              params.row.canceled ||
              (selectedWarehouse && selectedWarehouse !== params.row.warehouse)
            }
            componentProps={{
              toolbar: {
                dataGridName: "customerOrders",
                tableName: "customerOrders",
                searchFields: searchFieldOptions,
                handleSearch,
                onActionSelect,
                selectedRows,
                ActionMenu,
                toggleView,
                view,
              },
            }}
          />
        </Box>
      );
    }

    if (view === "card") {
      if (ordersLoading) {
        return (
          <Box width={"40px"} m={"auto"}>
            <Loading />
          </Box>
        );
      }
      if (!ordersLoading && !searchFieldsLoading) {
        return (
          <Box
            sx={{
              height: { xs: "calc(100% - 100px)", sm: `calc(100% - 100px)` },
              width: "100%",
            }}
          >
            <CardView
              table="orderUnit"
              dataGridName="customerOrders"
              data={customerOrders}
              searchFields={columns}
              loading={ordersLoading}
              defaultColumnHeader={{
                fieldName: "shippingstatus",
                dataType: "string",
                label: "Shipping Status",
              }}
            />
          </Box>
        );
      }
    }

    return null;
  };

  return (
    <MainContainer title="Customer Orders">
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {(ordersError || searchFieldsError) && (
          <Error
            sx={{ width: "100%", borderRadius: 0 }}
            errorMessage={errorMsg}
          />
        )}

        {view === "card" && renderCardViewControls()}

        {renderContent()}
      </Box>
    </MainContainer>
  );
};

export default CustomerOrders;
