import * as React from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function AddMenu({
  hasPriorityShipping,
  hasInsurance,
  hasCustomShipping,
  handleAddProduct,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    // Handle menu item click based on the option
    handleAddProduct(option);
    handleClose();
  };

  return (
    <div>
      <IconButton
        size="large"
        color="primary"
        onClick={handleClick}
        sx={{
          border: 1,
          borderColor: "primary.main",
          borderRadius: "50%",
        }}
      >
        <AddIcon fontSize="medium" />
      </IconButton>

      <Menu
        id="custom-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("Add Product")}>
          Add Product
        </MenuItem>

        {!hasInsurance && (
          <MenuItem onClick={() => handleMenuItemClick("Add Insurance")}>
            Add Insurance
          </MenuItem>
        )}

        {!hasPriorityShipping && (
          <MenuItem
            onClick={() => handleMenuItemClick("Add Priority Shipping")}
          >
            Add Priority Shipping
          </MenuItem>
        )}
        {!hasCustomShipping && (
          <MenuItem onClick={() => handleMenuItemClick("Add Custom Shipping")}>
            Add Custom Shipping
          </MenuItem>
        )}
        <MenuItem onClick={() => handleMenuItemClick("Add Discount")}>
          Add Discount
        </MenuItem>
      </Menu>
    </div>
  );
}
