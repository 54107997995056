import { convertToQueryString, removeFilter, storeFilter } from "../common";

export function buildQueryString(
  searchTerm,
  filterDetails,
  andOr,
  limit,
  offset
) {
  const combinedFilters = buildCombinedFilters(searchTerm, filterDetails);
  return convertToQueryString(andOr || "and", combinedFilters, limit, offset);
}
export const buildCombinedFilters = (searchValue, filter) => {
  const combinedFilters = [];

  // Add search term filter
  if (searchValue && searchValue.length > 2) {
    combinedFilters.push({
      field: "everywhere",
      type: "Contains",
      value: searchValue,
    });
  }

  // Add filters from the selected filter
  if (filter && filter.filters.length > 0) {
    const filterData = filter.filters.map(
      ({ searchTypes, fieldName, type, value, values }) => {
        return {
          field: fieldName,
          type: searchTypes[type],
          value:
            searchTypes[type] === "Is one of" ||
            searchTypes[type] === "Is not one of"
              ? values.map((v) => v.label)
              : filter.dataType === "number"
              ? parseFloat(value)
              : value,
        };
      }
    );

    combinedFilters.push(...filterData);
  }

  return combinedFilters;
};

export const handleFilterChange = async (
  filter,
  handleSelectedFilter,
  refetchFunction,
  table
) => {
  if (!filter) removeFilter(table);
  else storeFilter(filter?.id, table);

  handleSelectedFilter(filter);

  await refetchFunction();
};
