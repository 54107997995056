import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { getOfflineOrder } from "../../../api/OfflineOrders";

import Loading from "../../../components/Loading";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { getTableHeight } from "../../../common";

export default function DetailPanelContent({ row }) {
  const [lineItems, setLineItems] = React.useState(null);
  const [totals, setTotals] = React.useState(null);
  const [productsLoading, setProductsLoading] = React.useState(true);

  const [currency, setCurrency] = React.useState("");
  React.useEffect(() => {
    getOfflineOrder(row.orderId).then((rsp) => {
      setCurrency(rsp.fullOrder.order.currency);
      setProductsLoading(false);
      const isTaxIncluded = rsp.fullOrder.order.isTaxIncluded;
      let discountTotal = 0;
      let taxTotal = 0;
      let totalPrice = 0;

      setLineItems(
        rsp?.lineItems.map((prod, index) => {
          discountTotal += prod.discount;
          taxTotal += prod.linePrice * prod.taxRate;
          totalPrice += prod.linePrice;
          return { ...prod, id: index };
        })
      );
      setTotals({
        discountTotal,
        taxTotal,
        totalPrice: totalPrice + (isTaxIncluded ? 0 : taxTotal),
      });
    });
  }, []);

  const getCurrencySymbol = () => {
    return currency === "GBP" ? "£" : "$";
  };

  const formatCurrency = (value) => {
    return `${getCurrencySymbol()}${value.toFixed(2)}`;
  };

  const tableHeight = getTableHeight(lineItems?.length);

  return (
    <Stack
      sx={{ ml: 1, py: 2, height: "90%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper
        sx={{
          flex: 1,
          width: { xs: "50%", sm: "90vw", md: "80vw", lg: "70vw" },
          p: 1,
        }}
      >
        {productsLoading && (
          <Loading sx={{ display: "flex", ml: 4, mt: 4, mb: 4 }} />
        )}
        {!productsLoading && lineItems && (
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography variant="h6">{`Order #${row.orderId}`}</Typography>
            <Box height={tableHeight}>
              <DataGridPro
                density="compact"
                columns={[
                  { field: "name", headerName: "Product", flex: 2 },
                  {
                    field: "quantity",
                    headerName: "Quantity",
                    align: "center",
                    type: "number",
                  },
                  {
                    field: "unitPrice",
                    headerName: "Unit Price",
                    type: "number",
                    valueGetter: ({ row }) =>
                      formatCurrency(row.unitPrice || 0),
                  },
                  {
                    field: "salesTaxDollarAmount",
                    headerName: "Tax",
                    type: "number",
                    valueGetter: ({ row }) =>
                      formatCurrency(row.salesTaxDollarAmount || 0),
                  },
                  {
                    field: "linePrice",
                    headerName: "Total",
                    type: "number",
                    valueGetter: ({ row }) =>
                      formatCurrency(row.linePrice || 0),
                  },
                ]}
                rows={lineItems}
                sx={{ flex: 1, height: tableHeight, borderRadius: 0 }}
                hideFooter
              />
            </Box>

            <Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography sx={{ width: 100, mr: 1, textAlign: "left" }}>
                  Discounts:
                </Typography>
                <Typography sx={{ textAlign: "right", width: 100 }}>
                  {formatCurrency(totals?.discountTotal || 0)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography sx={{ width: 100, mr: 1, textAlign: "left" }}>
                  Total Tax:
                </Typography>
                <Typography sx={{ textAlign: "right", width: 100 }}>
                  {formatCurrency(totals?.taxTotal || 0)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography sx={{ width: 100, mr: 1, textAlign: "left" }}>
                  Total:
                </Typography>
                <Typography sx={{ textAlign: "right", width: 100 }}>
                  {formatCurrency(totals?.totalPrice || 0)}
                </Typography>
              </Box>
            </Box>
          </Stack>
        )}
      </Paper>
    </Stack>
  );
}
