import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Loading from "../../../../components/Loading";
import { loadUser } from "../../../../common";
import Modal from "../../../../components/Modal";

export const AddConversation = ({
  users,
  open,
  handleClose,
  handleAddSubmit,
  loading,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [error, setError] = useState("");

  const handleAddConversation = async () => {
    setError("");
    if (selectedUsers.length === 0) {
      setError("Please select a user to start a conversation with.");
      return;
    }
    try {
      await handleAddSubmit(selectedUsers);
    } catch (err) {
      console.error(err);
      setError(
        "There was a problem creating the conversation. Please try again later."
      );
    }
  };

  const relevantUsers = users.filter(
    (user) => user.id !== JSON.parse(loadUser()).id
  );

  return (
    <Modal
      title="Create New Conversation"
      openModal={open}
      handleCloseModal={handleClose}
      handleSubmit={handleAddConversation}
      loading={loading}
      errorMsg={error}
    >
      {users ? (
        <Autocomplete
          multiple
          options={relevantUsers}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          onChange={(event, newValue) => setSelectedUsers(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select users"
              placeholder="Add users"
              fullWidth
              margin="normal"
            />
          )}
        />
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
