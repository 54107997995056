import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import Table from "../../../components/Table";

const ShipInventory = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders,
    });
  }, [data.orders, setState]);

  const handleInputChange = useCallback(
    (value, field, id) => {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        ),
      }));
    },
    [setState]
  );

  const columns = [
    {
      field: "warehouseordernumber",
      headerName: "Warehouse Order Number",
      width: 100,
    },
    {
      field: "dateproductioncomplete",
      headerName: "Date Production Complete",
      width: 300,
      editable: true,
      type: "date",
    },
    {
      field: "dateshipped",
      headerName: "Date Shipped",
      width: 300,
      editable: true,
      type: "date",
    },
    {
      field: "estportarrivaldate",
      headerName: "Estimated Port Arrival Date",
      width: 300,
      editable: true,
      type: "date",
    },
    {
      field: "vesselname",
      headerName: "Vessel Name",
      width: 200,
      editable: true,
    },
    {
      field: "trackingnumber",
      headerName: "Tracking Number",
      width: 200,
      editable: true,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        position: "relative",
      }}
    >
      <Typography variant="body1" color="text.secondary">
        Update shipping details for the following orders.
      </Typography>

      <Box
        sx={{
          width: "100%",
        }}
      >
        <Table
          columns={columns}
          rows={state.rows}
          onCellChange={handleInputChange}
        />
      </Box>
    </Box>
  );
};

export default ShipInventory;
