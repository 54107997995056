import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";

import Table from "../../../components/Table";
import Typography from "@mui/material/Typography";

const AddSerialNumbers = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders.map((order) => ({
        ...order,
      })),
    });
  }, [data.orders, setState]);

  const handleCellChange = (value, field, id) => {
    setState((prevState) => {
      const updatedRows = prevState.rows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      return { ...prevState, rows: updatedRows };
    });
  };

  const columns = [
    {
      field: "ordernumber",
      headerName: "Order Number",
    },
    {
      field: "buyerfullname",
      headerName: "Buyer Full Name",
    },
    {
      headerName: "Model",
      field: "descriptionshort",
      sortable: false,
    },
    { headerName: "Qty", field: "qty", width: 100, sortable: false },

    {
      headerName: "Serial Number *",
      field: "serialnumber",
      editable: true,
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="body1" color="text.secondary">
        Add serial numbers to the following order units. Please ensure that the
        serial number is correctly filled in before proceeding.
      </Typography>

      <Box
        sx={{
          width: "100%",
          overflowX: "auto",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #f0f0f0",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderBottom: "none",
          },
        }}
      >
        <Table
          columns={columns}
          rows={state.rows}
          onCellChange={handleCellChange}
        />
      </Box>
    </Box>
  );
};

export default AddSerialNumbers;
