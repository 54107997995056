import dayjs from "dayjs";
import { officeTimezoneMap } from "./constants";

export const updateRowDates = (row, columns, newTimezone) => {
  const updatedRow = { ...row };
  columns.forEach((column) => {
    if (column.type === "date" && updatedRow[column.field]) {
      updatedRow[column.field] = dayjs(updatedRow[column.field])
        .tz(officeTimezoneMap[newTimezone], true)
        .format();
    }
  });
  return updatedRow;
};
