import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const ConfirmResetPasswordModal = ({
  open,
  onClose,
  onConfirm,
  userName,
  loading,
  errorMessage,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Password Reset</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to reset the password for {userName}? An email
          will be sent to the user with instructions to reset their password.
        </DialogContentText>
        {errorMessage && (
          <Box mt={2}>
            <Alert severity="error">{errorMessage}</Alert>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <LoadingButton onClick={onConfirm} color="primary" loading={loading}>
          Confirm Reset
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmResetPasswordModal;
