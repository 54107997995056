import { selector } from "recoil";
import {
  activeStepState,
  refetchTriggerState,
  intermediateOrdersState,
  permissionsState,
  conversationsState,
} from "../atoms/atoms";
import {
  customerOrdersFirstBatchLength,
  loadFilter,
  loadSearchTerm,
} from "../common";
import { getFilter } from "../api/Filters";
import { buildQueryString } from "../utils/filterUtils";
import { getOrders } from "../api/CustomerOrders";
import axios from "axios";

export const updatedConversationsSelector = selector({
  key: "UpdatedConversationsSelector",
  get: ({ get }) => {
    return get(conversationsState);
  },
  set: ({ set, get }, newMessage) => {
    const conversations = get(conversationsState);
    const updatedConversations = conversations.map((conversation) => {
      if (conversation.id === newMessage.conversationID) {
        return { ...conversation, lastMessage: newMessage };
      }
      return conversation;
    });
    set(conversationsState, updatedConversations);
  },
});

export const activeStepSelector = selector({
  key: "setActiveStep",
  get: ({ get }) => get(activeStepState),
  set: ({ set, get }, next) => {
    if (next === undefined) {
      return set(activeStepState, 0);
    }
    const activeStep = get(activeStepState);
    if (next) set(activeStepState, activeStep + 1);
    else set(activeStepState, activeStep - 1);
  },
});

export const firstFetchOrdersSelector = selector({
  key: "firstFetchOrdersSelector",
  get: async ({ get }) => {
    try {
      get(refetchTriggerState);
      const permissions = get(permissionsState);

      const filterId = loadFilter("customerOrders");
      const searchTerm = loadSearchTerm("customerOrders");

      const filterDetails = filterId
        ? (await getFilter(filterId)).filter
        : null;

      const andOr = filterDetails?.andOr || "and";
      const fetchQuery = buildQueryString(
        searchTerm,
        filterDetails,
        andOr,
        customerOrdersFirstBatchLength,
        0
      );
      const orderResults = await fetchOrders(fetchQuery);
      return orderResults.filter((order) =>
        validateOrderState(order, permissions)
      );
    } catch (err) {
      console.log(err);
      throw new err();
    }
  },
});

export const secondFetchOrdersSelector = selector({
  key: "secondFetchOrdersSelector",
  get: async ({ get }) => {
    try {
      const firstBatch = get(intermediateOrdersState);
      if (!firstBatch.length) return { isCancelled: true };

      const permissions = get(permissionsState);
      const filterId = loadFilter("customerOrders");
      const searchTerm = loadSearchTerm("customerOrders");
      const filterDetails = filterId
        ? (await getFilter(filterId)).filter
        : null;
      const andOr = filterDetails?.andOr || "and";
      const fetchQuery = buildQueryString(
        searchTerm,
        filterDetails,
        andOr,
        null,
        customerOrdersFirstBatchLength
      );

      const secondBatch = await fetchOrders(fetchQuery);
      const validatedOrders = secondBatch.filter((order) =>
        validateOrderState(order, permissions)
      );

      return validatedOrders;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
});

function validateOrderState(order, permissions) {
  if (
    permissions.name !== "Super Admin" &&
    !permissions.customerOrders.actions?.viewHiddenOrders
  ) {
    return !(
      order["orderpaymentstatus"] === "NOT_PAID" || order["canceled"] === true
    );
  }
  return true;
}

async function fetchOrders(query) {
  try {
    const source = axios.CancelToken.source();
    const response = await getOrders(query, { cancelToken: source.token });
    return response?.results || [];
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Fetch canceled:", error.message);
      return { isCancelled: true };
    }
    console.log("ERROR: ", error);
    throw error;
  }
}
