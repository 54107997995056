export const validateFields = {
  newInventoryOrder: (details) => {
    if (!details.dateOrdered || !details.warehouse || !details.supplier) {
      return "Please fill in all required fields: Date Ordered, Warehouse, and Supplier.";
    }
    if (!details.rows || details.rows.length === 0) {
      return "Please add at least one item to the order.";
    }
    for (let item of details.rows) {
      if (!item.pid || !item.description || !item.qty) {
        return "Please fill in all required fields for each item: PID, Description, and Quantity.";
      }
    }
    return null;
  },

  createAuditCorrection: (details) => {
    if (!details.dateOrdered || !details.warehouse) {
      return "Please fill in all required fields: Date Ordered and Warehouse.";
    }
    if (!details.rows || details.rows.length === 0) {
      return "Please add at least one item to the correction.";
    }
    return null;
  },

  warehouseTransfer: (details) => {
    if (!details.dateOrdered || !details.warehouse || !details.supplier) {
      return "Please fill in all required fields: Date Ordered, Warehouse (to), and Supplier (from).";
    }
    if (!details.rows || details.rows.length === 0) {
      return "Please add at least one item to transfer.";
    }
    return null;
  },

  editInventoryOrder: () => null,

  shipInventory: () => null, // No additional validation needed as per the requirements

  receiveInventory: (details) => {
    if (!Array.isArray(details.rows)) {
      return "Invalid data structure for receiving inventory.";
    }
    return null;
  },

  updateStatus: (details) => {
    for (let item of details.rows) {
      if (!item.status) return "Please fill in all order statuses.";
    }
    return null;
  },

  addCostsByItem: (details) => {
    if (!Array.isArray(details.rows)) {
      return "Invalid data structure for adding costs by item.";
    }
    for (let item of details.rows) {
      let hasFilledGroup = false;

      // Customs group
      if (item.customsfees || item.customscurrency || item.customsdatepaid) {
        if (
          !item.customsfees ||
          !item.customscurrency ||
          !item.customsdatepaid
        ) {
          return "Please fill in all customs-related fields: Customs Fees, Customs Currency, and Customs Date Paid.";
        }
        hasFilledGroup = true;
      }

      // International shipping group
      if (
        item.internationalshipmentcost ||
        item.currencyintshipping ||
        item.intshipdatepaid
      ) {
        if (
          !item.internationalshipmentcost ||
          !item.currencyintshipping ||
          !item.intshipdatepaid
        ) {
          return "Please fill in all international shipping fields: International Shipment Cost, Currency, and Date Paid.";
        }
        hasFilledGroup = true;
      }

      // Local shipping group
      if (
        item.localshipmentcost ||
        item.currencylocalshipping ||
        item.locshipdatepaid
      ) {
        if (
          !item.localshipmentcost ||
          !item.currencylocalshipping ||
          !item.locshipdatepaid
        ) {
          return "Please fill in all local shipping fields: Local Shipment Cost, Currency, and Date Paid.";
        }
        hasFilledGroup = true;
      }

      if (!hasFilledGroup) {
        return "Please fill in at least one group of fields (Customs, International Shipping, or Local Shipping).";
      }
    }
    return null;
  },

  distributeOrderCosts: (details) => {
    let hasFilledGroup = false;

    // Customs group
    if (
      details.customsfees ||
      details.customscurrency ||
      details.customsdatepaid
    ) {
      if (
        !details.customsfees ||
        !details.customscurrency ||
        !details.customsdatepaid
      ) {
        return "Please fill in all customs-related fields: Customs Fees, Customs Currency, and Customs Date Paid.";
      }
      hasFilledGroup = true;
    }

    // International shipping group
    if (
      details.internationalshipmentcost ||
      details.currencyintshipping ||
      details.intshipdatepaid
    ) {
      if (
        !details.internationalshipmentcost ||
        !details.currencyintshipping ||
        !details.intshipdatepaid
      ) {
        return "Please fill in all international shipping fields: International Shipment Cost, Currency, and Date Paid.";
      }
      hasFilledGroup = true;
    }

    // Local shipping group
    if (
      details.localshipmentcost ||
      details.currencylocalshipping ||
      details.locshipdatepaid
    ) {
      if (
        !details.localshipmentcost ||
        !details.currencylocalshipping ||
        !details.locshipdatepaid
      ) {
        return "Please fill in all local shipping fields: Local Shipment Cost, Currency, and Date Paid.";
      }
      hasFilledGroup = true;
    }

    if (!hasFilledGroup) {
      return "Please fill in at least one group of fields (Customs, International Shipping, or Local Shipping).";
    }

    return null;
  },

  markAsPaid: (details) => {
    if (!Array.isArray(details.rows)) {
      return "Invalid data structure for marking as paid.";
    }
    for (let item of details.rows) {
      if (item.paid === undefined) {
        return "Please specify the Paid status for each item.";
      }
    }
    return null;
  },

  addMachineSpecs: () => null, // No additional validation needed as per the requirements
};
