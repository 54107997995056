import React, { useEffect, useState } from "react";
import {
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import SearchOrdersInput from "../SearchOrdersInput";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ViewToggleButtons from "../../pages/CustomerOrders/components/ViewToggleButtons";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import FiltersMenu from "../Filter";

const CustomToolbar = ({
  tableName,
  handleSearch,
  searchFields,
  onActionSelect,
  page,
  selectedRows,
  ActionMenu,
  onUnpinAllColumns,
  onPinAllColumns,
  dataGridName,
  toggleView,
  view,
  viewType = "card",
}) => {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPinned, setIsPinned] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const savedPinState = localStorage.getItem(`${dataGridName}_pinState`);
    setIsPinned(savedPinState ? JSON.parse(savedPinState) : true);
  }, [dataGridName]);

  const handleExport = (options) => {
    apiRef.current.exportDataAsCsv(options);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePinToggle = () => {
    if (isPinned) {
      onUnpinAllColumns();
    } else {
      onPinAllColumns();
    }
    setIsPinned(!isPinned);
    localStorage.setItem(`${dataGridName}_pinState`, JSON.stringify(!isPinned));
    handleMenuClose();
  };

  const handleViewChange = () => {
    toggleView(
      view === "table" ? (viewType === "card" ? "card" : "tile") : "table"
    );
    handleMenuClose();
  };

  const menuItems = [
    <MenuItem
      key="export"
      onClick={() => handleExport({ fileName: tableName })}
    >
      <FileDownloadIcon sx={{ mr: 1, fontSize: 20 }} />
      Export
    </MenuItem>,
    <MenuItem key="pin" onClick={handlePinToggle}>
      {isPinned ? (
        <>
          <PushPinOutlinedIcon sx={{ mr: 1, fontSize: 20 }} />
          Unpin Columns
        </>
      ) : (
        <>
          <PushPinIcon sx={{ mr: 1, fontSize: 20 }} />
          Pin Columns
        </>
      )}
    </MenuItem>,
  ];

  if (toggleView && isMobile) {
    menuItems.push(
      <Divider key="divider" />,
      <MenuItem key="viewToggle" onClick={handleViewChange}>
        {view === "table"
          ? `Switch to ${viewType === "card" ? "Card" : "Tile"} View`
          : "Switch to Table View"}
      </MenuItem>
    );
  }

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.paper,
        py: 0.5,
        px: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: { xs: 1, sm: 2 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          {!isMobile && onActionSelect && (
            <ActionMenu
              onActionSelect={onActionSelect}
              currentPage={page}
              selectedRows={selectedRows}
            />
          )}
          {!isMobile && toggleView && (
            <Box mr={1}>
              <ViewToggleButtons
                view={view}
                onToggleView={toggleView}
                viewType={viewType}
              />
            </Box>
          )}
          <SearchOrdersInput table={tableName} handleSearch={handleSearch} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            maxWidth: "100%",
          }}
        >
          {isMobile && onActionSelect && (
            <Box>
              <ActionMenu
                onActionSelect={onActionSelect}
                currentPage={page}
                selectedRows={selectedRows}
              />
            </Box>
          )}
          <Box display={"flex"} alignItems={"center"} gap={{ xs: null, sm: 1 }}>
            <FiltersMenu
              table={tableName}
              searchFieldOptions={searchFields}
              refetchOrders={handleSearch}
              hasActions={!!onActionSelect}
            />
            <Tooltip title="More options">
              <IconButton size="small" onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: { minWidth: 200 },
        }}
      >
        {menuItems}
      </Menu>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
