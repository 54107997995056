import React, { useEffect, useRef } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  activeConversationState,
  messagesByConversationState,
  notificationsCountState,
  notificationsState,
  userSubscribedState,
} from "../../../atoms/atoms";
import { loadUser } from "../../../common";
import pusher from "../../../services/pusher/pusherClient";
import { subscribeToNotificationChannel } from "../../../services/pusher/channels/notificationChannel";

const NotificationsIconMenu = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const [activeConversation, setActiveConversation] = useRecoilState(
    activeConversationState
  );
  const [messagesByConversation, setMessagesByConversation] = useRecoilState(
    messagesByConversationState
  );
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [count, setCount] = useRecoilState(notificationsCountState);

  const userID = JSON.parse(loadUser())?.id;

  const activeConversationRef = useRef(activeConversation);

  useEffect(() => {
    const cleanup = subscribeToNotificationChannel(
      userID,
      location,
      messagesByConversation,
      setMessagesByConversation,
      setNotifications,
      activeConversationRef,
      activeConversation
    );

    return () => {
      cleanup();
    };
  }, [
    userID,
    location,
    messagesByConversation,
    setMessagesByConversation,
    setNotifications,
    activeConversationRef,
    activeConversation,
  ]);

  useEffect(() => {
    const unreadLength = notifications?.filter(
      (notification) => !notification?.read?.includes(userID)
    ).length;
    setCount(unreadLength);
  }, [notifications]);

  const handleClick = () => {
    navigate("/home");
  };

  return (
    <IconButton onClick={handleClick}>
      {count > 0 ? (
        <Badge badgeContent={count} color={"primary"}>
          <NotificationsIcon />
        </Badge>
      ) : (
        <NotificationsIcon />
      )}
    </IconButton>
  );
};

export default NotificationsIconMenu;
