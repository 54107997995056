import { Box, IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Typography from "@mui/material/Typography";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import React from "react";

export const Header = ({
  selectedConversation,
  handleConversationSelection,
  open,
  handleOpen,
  userMap,
}) => {
  const members = selectedConversation?.members
    ?.map((userID) => "@" + userMap[userID]?.name)
    .join(", ");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center", // Align items vertically in the center
        minHeight: "80px",
        backgroundColor: "#f3f3f3",
        position: "relative", // Position relative for absolute positioning of the icon
      }}
    >
      {selectedConversation && open && (
        <IconButton
          sx={{
            position: "absolute", // Position the icon absolutely to place it on the right
            left: 20, // Align to the right edge of the Box
            top: "50%", // Align vertically in the middle
            transform: "translateY(-50%)", // Offset the icon by half its height to center it
          }}
          onClick={() => handleConversationSelection(null)}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      )}
      {selectedConversation ? (
        selectedConversation.isDefault ? (
          <Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
            Everyone
          </Typography>
        ) : (
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              width: "calc(100% - 160px)", // Ensure width accounts for button space
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              ml: 3, // Margin to prevent overlap with back button
              mx: "auto",
            }}
          >
            {members}
          </Typography>
        )
      ) : (
        <Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
          Conversations
        </Typography>
      )}
      <IconButton
        sx={{
          position: "absolute", // Position the icon absolutely to place it on the right
          right: 20, // Align to the right edge of the Box
          top: "50%", // Align vertically in the middle
          transform: "translateY(-50%)", // Offset the icon by half its height to center it
        }}
        onClick={handleOpen}
      >
        {open ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      </IconButton>
    </Box>
  );
};
