import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import Table from "../../../components/Table";

import TextField from "@mui/material/TextField";

const ReceiveInventory = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders,
    });
  }, [data.orders, setState]);

  const handleInputChange = useCallback(
    (value, field, id) => {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        ),
      }));
    },
    [setState]
  );

  const columns = [
    {
      field: "warehouseordernumber",
      headerName: "Warehouse Order Number",
      width: 100,
    },
    {
      field: "rownumber",
      headerName: "Row Number",
      width: 150,
    },

    {
      field: "dateinventoryreceived",
      headerName: "Date Inventory Received",
      width: 300,
      editable: true,
      type: "date",
    },
    {
      field: "inventoryreceived",
      headerName: "Received",
      width: 100,
      editable: true,

      type: "boolean",
    },
    {
      field: "quantityreceived",
      headerName: "Quantity Received",
      width: 100,
      type: "number",
      editable: true,
      min: 0,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 400,
      editable: true,
      renderEditCell: ({ row }) => (
        <TextField
          multiline
          rows={2}
          value={row.notes || ""}
          onChange={(e) => handleInputChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="body1" color="text.secondary">
        Update receiving details for the following inventory items.
      </Typography>

      <Box
        sx={{
          width: "100%",
        }}
      >
        <Table
          columns={columns}
          rows={state.rows}
          onCellChange={handleInputChange}
        />
      </Box>
    </Box>
  );
};

export default ReceiveInventory;
