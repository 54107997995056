import React from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const SimpleDataGrid = ({
  rows,
  columns,
  sx = {},
  checkboxSelection,
  isRowSelectable,
  onSelectionModelChange,
  singleSelect,
  selectionModel,
  hideFooter,
  disableColumnMenu,
  isEditable = true,
}) => {
  const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    borderRadius: "5px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
  }));
  const handleRowClick = (params, event, details) => {
    if (!checkboxSelection) {
      event.defaultMuiPrevented = true;
    }
  };

  const backgroundColor = !isEditable ? "rgba(0, 0, 0, 0.05)" : "";

  return (
    <Box
      sx={{
        overflowX: "auto",
        overflowY: "hidden",
        width: "100%",
        maxWidth: "100%",
        height: sx.height,
      }}
    >
      <StyledDataGrid
        rows={rows}
        columns={columns}
        checkboxSelection={checkboxSelection}
        onRowClick={handleRowClick}
        isRowSelectable={isRowSelectable}
        onRowSelectionModelChange={onSelectionModelChange}
        singleSelect={singleSelect}
        rowSelectionModel={selectionModel}
        rowHeight={50}
        sx={{
          ...sx,
          backgroundColor,
          width: { xs: 1200, sm: "100%" },
        }}
        hideFooter={hideFooter}
        disableColumnMenu={disableColumnMenu}
      />
    </Box>
  );
};
export default SimpleDataGrid;
