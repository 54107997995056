import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { useEffect } from "react";
import DialogContentText from "@mui/material/DialogContentText";

const CancelOrderModalContent = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({ checked: false });
  }, []);

  return (
    <Box>
      <DialogContentText>
        Are you sure you would like to cancel order {data.orderNumber}? Note
        this will also mark the order as refunded.
      </DialogContentText>
      {state.checked !== undefined && (
        <FormGroup sx={{ mt: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checked}
                onChange={(e) => setState({ checked: !state.checked })}
              />
            }
            label={`I confirm I have refunded the customer the amount of $${data.orderTotal}. `}
          />
        </FormGroup>
      )}
    </Box>
  );
};
export default CancelOrderModalContent;
