import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function getSettings(dataGridName) {
  return axios
    .get(`${API_ORDER_LINK_URL}/userSettings?type=cardSettings&dataGridName=${dataGridName}`)
    .then((response) => response.data);
}

function updateSettings(settings) {
  return axios
    .patch(`${API_ORDER_LINK_URL}/userSettings`, { type: "cardSettings", settings: settings })
    .then((response) => response.data);
}

export { getSettings, updateSettings };
