import React from "react";
import TextField from "@mui/material/TextField";

const MKTextField = (props) => {
  const {
    sx,
    changeValue,
    type,
    searchInputRef,
    rows,
    startIcon,
    filterIndex,
  } = props;
  return (
    <TextField
      {...props}
      sx={{
        ...sx,
      }}
      onChange={(e) => changeValue(e.target.value)}
      inputProps={
        type && {
          type: "password",
          autoComplete: "new-password",
        }
      }
      type="search"
      inputRef={(ref) => {
        if (searchInputRef) {
          return (searchInputRef.current[filterIndex] = ref);
        }
      }}
      rows={rows || 1}
      InputProps={startIcon}
      helperText={props.error}
    />
  );
};
export default MKTextField;
