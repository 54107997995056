// components/RecursiveAccordion.js
import React, { useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const isLeafNode = (value) => {
  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every((item) => typeof item === "object" && "fieldName" in item)
  );
};

const RecursiveAccordion = React.memo(
  ({ data, checkedState, handleCheck, parentKey = "" }) => {
    const toReadableTitle = useMemo(() => {
      return (str) =>
        str
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
    }, []);

    const checkStates = useMemo(() => {
      return (key, fields) => {
        let allChecked = true;
        let someChecked = false;
        const checkField = (fieldKey, fieldValue) => {
          if (fieldValue.hasOwnProperty("fieldName")) {
            const fullKey = `${fieldKey}.${fieldValue.fieldName}`;
            allChecked = allChecked && checkedState[fullKey] === true;
            someChecked = someChecked || checkedState[fullKey] === true;
          } else if (Array.isArray(fieldValue)) {
            fieldValue.forEach((item) => checkField(fieldKey, item));
          } else if (typeof fieldValue === "object") {
            Object.entries(fieldValue).forEach(([nestedKey, nestedValue]) => {
              checkField(`${fieldKey}.${nestedKey}`, nestedValue);
            });
          }
        };
        checkField(key, fields);
        return { allChecked, someChecked };
      };
    }, [checkedState]);

    return Object.entries(data).map(([key, value]) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const leafNode = isLeafNode(value);
      const { allChecked, someChecked } = checkStates(fullKey, value);

      return (
        <Accordion
          key={key}
          sx={{
            width: "100%",
            mb: parentKey ? 0 : 2,
            border: parentKey ? "none" : undefined,
            boxShadow: parentKey ? "none" : undefined,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <FormControlLabel
              aria-label={`Select all in ${key}`}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  checked={allChecked}
                  indeterminate={!allChecked && someChecked}
                  onChange={(event) =>
                    handleCheck(fullKey, event.target.checked)
                  }
                />
              }
              label={toReadableTitle(key)}
            />
          </AccordionSummary>
          <AccordionDetails sx={{ pr: 0 }}>
            <Grid container spacing={2}>
              {leafNode ? (
                value.map((field) => (
                  <Grid item xs={12} sm={4} key={field.fieldName}>
                    <Tooltip title={field.displayName} placement="top">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              checkedState[`${fullKey}.${field.fieldName}`] ||
                              false
                            }
                            onChange={(event) =>
                              handleCheck(
                                `${fullKey}.${field.fieldName}`,
                                event.target.checked
                              )
                            }
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              maxWidth: {
                                xs: "calc(100% - 40px)",
                                sm: "calc(100% - 20px)",
                              },
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                            }}
                          >
                            {field.displayName}
                          </Typography>
                        }
                        sx={{ width: "100%" }}
                      />
                    </Tooltip>
                  </Grid>
                ))
              ) : (
                <RecursiveAccordion
                  data={value}
                  checkedState={checkedState}
                  handleCheck={handleCheck}
                  parentKey={fullKey}
                />
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  }
);

export default RecursiveAccordion;
