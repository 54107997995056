import React, { useCallback, useMemo, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { renderCellComponent } from "../Datagrid/utils";
import { useDateFormat } from "../../contexts/DateFormatContext";
import { useRecoilState } from "recoil";
import { tileViewSettingsState } from "../../atoms/atoms";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TileCard from "./TileCard";

const TileView = ({
  items,
  groupBy,
  getMenuItems,
  getHighlightInfo = () => ({ isHighlighted: false }),
  onMenuItemClick,
}) => {
  const [tileViewSettings] = useRecoilState(tileViewSettingsState);
  const { dateFormat } = useDateFormat();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  const handleMenuClick = useCallback((event, item) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
    setMenuItems(getMenuItems(item));
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedItem(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (menuItem) => {
      onMenuItemClick(menuItem, selectedItem);
      handleMenuClose();
    },
    [onMenuItemClick, selectedItem, handleMenuClose]
  );

  const groupedItems = useMemo(() => {
    if (!groupBy) return [{ items, groupName: "All Items" }];

    const groups = items.reduce((acc, item) => {
      const groupValue = item[groupBy];
      if (!acc[groupValue]) {
        acc[groupValue] = {
          items: [],
          categoryIndex: item.categoryindex || 0,
        };
      }
      acc[groupValue].items.push(item);
      return acc;
    }, {});

    return Object.entries(groups)
      .map(([key, value]) => ({
        groupName: key,
        ...value,
      }))
      .sort((a, b) => a.categoryIndex - b.categoryIndex);
  }, [items, groupBy]);

  const renderField = useCallback(
    (item, field) => {
      if (field.dataType === "image") {
        return null; // We'll handle images separately
      }

      if (field.fieldName === "inventoryItem" || field.fieldName === "title") {
        return (
          <Typography variant="h6" component="div">
            {renderCellComponent(
              field,
              field.fieldName,
              { value: item[field.fieldName], row: item },
              () => {},
              dateFormat,
              false
            )}
          </Typography>
        );
      }
      return (
        <Box sx={{ mb: 1 }}>
          {tileViewSettings.showHeaders && (
            <Typography variant="body2" color="text.secondary">
              {field.displayName}:
            </Typography>
          )}
          <Box
            sx={{
              "& #card-text": {
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                lineHeight: 1.4,
              },
            }}
          >
            {renderCellComponent(
              field,
              field.fieldName,
              { value: item[field.fieldName], row: item },
              () => {},
              dateFormat,
              false
            )}
          </Box>
        </Box>
      );
    },
    [dateFormat, tileViewSettings]
  );

  const imageField = useMemo(
    () =>
      tileViewSettings.columnsDisplayed?.find(
        (field) => field.dataType === "image"
      ),
    [tileViewSettings.columnsDisplayed]
  );

  return (
    <Box gap={1}>
      {groupedItems?.map((group, groupIndex) => (
        <Accordion key={groupIndex} defaultExpanded sx={{ mb: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${groupIndex}a-content`}
            id={`panel${groupIndex}a-header`}
          >
            <Typography variant="h6" fontWeight={"bold"}>
              {group.groupName} ({group.items.length} items)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {group.items.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                  <TileCard
                    item={item}
                    highlightInfo={getHighlightInfo(item)}
                    imageField={imageField}
                    tileViewSettings={tileViewSettings}
                    renderField={renderField}
                    onMenuClick={handleMenuClick}
                    menuItems={menuItems}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default TileView;
