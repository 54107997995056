import { useState, useEffect } from "react";
import { getAllFilters } from "../api/Filters"; // Make sure the path is correct

const useSavedFilters = (table) => {
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSavedFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    setLoading(true);
    setError("");
    setFilters(null);

    const fetchFilters = async () => {
      try {
        const response = await getAllFilters(table);
        setFilters(response.filters);
      } catch (err) {
        console.error(err);
        setError("We're unable to load your filters at the moment.");
      } finally {
        setLoading(false);
      }
    };

    fetchFilters();
  }, [table]); // Dependency array ensures this effect runs when `table` changes

  return {
    savedFiltersLoading: loading,
    savedFiltersError: error,
    savedFilters: filters,
    handleSavedFiltersChange,
  }; // Return the state values for use in your component
};

export default useSavedFilters;
