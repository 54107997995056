import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DataGridCell } from "./DataGridCell";
import { AutocompleteEditInputCell } from "./AutoComplete";
import { currencyFormatter } from "../../helpers/money/CurrencyFormater";
import { renderCellComponent } from "./utils";
import { getActions, isActionAllowed } from "./gridActions";

export const setupColumns = ({
  searchFields,
  storage,
  dataGridName,
  role,
  dateFormat,
  onRowClick,
  productPIDs,
  handleOpenDeleteModal,
  openModal,
  navigate,
}) => {
  try {
    const storedColumns =
      JSON.parse(localStorage.getItem(`${dataGridName}_columnProperties`)) ||
      [];
    const columnVisibilityM =
      JSON.parse(localStorage.getItem(storage.visibilityModel)) || {};

    if (!searchFields || !Array.isArray(searchFields)) {
      console.error("searchFields is undefined or not an array");
      return {
        newGridColumns: [],
        newColumnVisibilityModel: {},
        newPinnedLeft: [],
      };
    }

    const searchFieldsWithoutDuplicates = removeDuplicates(searchFields);

    // Merge stored column properties with latest search fields
    const columnValues = searchFieldsWithoutDuplicates.map((field) => {
      const storedField = storedColumns.find(
        (col) => col.field === field.fieldName
      );
      return mergeColumnProperties(field, storedField);
    });

    const newGridColumns = [];
    const newColumnVisibilityModel = {};
    const newPinnedLeft = [];

    const style = document.createElement("style");
    document.head.appendChild(style);
    let hasActionsColumn = false;

    columnValues.forEach((field, index) => {
      if (!field || field.fieldName === "everywhere") return;

      const fieldName = field.field || field.fieldName;
      const headerName = field.displayName || field.headerName;

      if (fieldName === "actions") {
        hasActionsColumn = true;
        if (isActionAllowed(dataGridName, role)) {
          const actionColumn = {
            field: "actions",
            headerName: "",
            type: "actions",
            width: 100,
            getActions: (params) =>
              getActions(
                dataGridName,
                params,
                handleOpenDeleteModal,
                openModal,
                navigate
              ),
          };
          newGridColumns.push(actionColumn);
          newColumnVisibilityModel["actions"] = true;
        }
      } else {
        const column = {
          ...field,
          field: fieldName,
          headerName: headerName,
          editable: field?.isEditable,
          width: field?.width || 200,
          type: getType(field),
          dataType: !field.isEditable ? field.dataType : undefined,
          align: field?.horizontalCellAlignment,
          headerAlign: "left",
        };

        setColumnVisibility(
          newColumnVisibilityModel,
          columnVisibilityM,
          fieldName,
          field
        );

        if (column.type === "boolean" || column.type === "number") {
          // Leave as is
        } else if (
          fieldName === "wixordernumber" &&
          dataGridName === "customerOrders"
        ) {
          column.renderCell = createButtonCell(fieldName, field, onRowClick);
        } else if (
          fieldName === "warehouseordernumber" &&
          dataGridName === "stockOrders"
        ) {
          column.renderCell = createButtonCell(fieldName, field, onRowClick);
        } else if (fieldName === "itemPID" || fieldName === "description") {
          column.align = "center";
          column.renderEditCell = createAutocompleteEditCell(
            fieldName,
            productPIDs
          );
        } else if (field.dataType === "number") {
          setupNumberColumn(column, field, style);
        } else {
          column.renderCell = (params) =>
            params.value !== undefined && (
              <DataGridCell {...field}>
                {renderCellComponent(
                  field,
                  fieldName,
                  params,
                  onRowClick,
                  dateFormat,
                  true
                )}
              </DataGridCell>
            );
        }

        if (window.innerWidth >= 600 && field.isPinned) {
          newPinnedLeft.push(fieldName);
        }

        newGridColumns.push(column);
      }
    });

    if (!hasActionsColumn && isActionAllowed(dataGridName, role)) {
      addActionsColumn(
        newGridColumns,
        newColumnVisibilityModel,
        dataGridName,
        handleOpenDeleteModal,
        openModal,
        navigate
      );
    }

    return { newGridColumns, newColumnVisibilityModel, newPinnedLeft };
  } catch (error) {
    console.error("Error in setupColumns:", error);
    return {
      newGridColumns: [],
      newColumnVisibilityModel: {},
      newPinnedLeft: [],
    };
  }
};

const removeDuplicates = (columns) => {
  const newColumns = [];
  columns.forEach((column) => {
    const fieldName = column.fieldName;
    if (!newColumns.some((col) => col.fieldName === fieldName)) {
      newColumns.push(column);
    }
  });
  return newColumns;
};

const getType = (field) => {
  if (field.type) {
    return field.type;
  } else if (
    (field.dataType === "boolean" || field.dataType === "number") &&
    field.isEditable
  ) {
    return field.dataType;
  }
  return undefined;
};

const setColumnVisibility = (
  newColumnVisibilityModel,
  columnVisibilityM,
  fieldName,
  field
) => {
  if (columnVisibilityM && columnVisibilityM.hasOwnProperty(fieldName)) {
    newColumnVisibilityModel[fieldName] = columnVisibilityM[fieldName];
  } else if (!!field.isHidden) {
    newColumnVisibilityModel[fieldName] = !field.isHidden;
  } else {
    newColumnVisibilityModel[fieldName] = true;
  }
};

const createButtonCell = (fieldName, field, onRowClick) => (params) =>
  !!params.value && (
    <DataGridCell {...field}>
      <Button
        sx={{
          m: "auto",
          lineHeight: 0.9,
          "&:hover": { background: "#f7f1ef" },
        }}
        color={"primary"}
        variant="outlined"
        onClick={() => onRowClick(params)}
      >
        {params.row[fieldName] || params.formattedValue}
      </Button>
    </DataGridCell>
  );

const createAutocompleteEditCell = (fieldName, productPIDs) => (params) =>
  (
    <Box mt={1.5} width={"100%"}>
      <AutocompleteEditInputCell
        title={fieldName === "itemPID" ? "Item PID" : "Model Description"}
        freeSolo={false}
        PID={fieldName === "itemPID"}
        params={params}
        options={productPIDs}
      />
    </Box>
  );

const setupNumberColumn = (column, field, style) => {
  // Can't use renderCell as sum, aggregate, etc. won't work
  column.type = "number"; // Ensure the column type is set to 'number'
  column.valueFormatter = (params) => {
    if (params !== undefined) return formatValue(params, field, params);
  };

  if (field.isEditable) {
    column.renderEditCell = createNumberEditCell(field);
  }

  column.cellClassName = `color-${field.fieldName}`;
  style.sheet.insertRule(
    `.color-${field.fieldName} {background: ${field.columnColour} !important;}`,
    style.sheet.cssRules.length
  );
};

const createNumberEditCell = (field) => (params) =>
  (
    <Box
      display="flex"
      mt={1.5}
      alignContent={"center"}
      alignItems={"center"}
      justifyContent={"space-around"}
      width={"100%"}
    >
      {field.isCurrency && <Typography>$</Typography>}
      <TextField
        sx={{ width: "80%" }}
        type={"number"}
        value={params.value || ""}
        onChange={(event) => {
          const newValue = event.target.value;
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue,
          });
        }}
        InputProps={{ inputProps: { min: 0 } }}
      />
    </Box>
  );

const formatValue = (value, field, params) => {
  if (field.isPercent) {
    if (typeof value === "string" && value?.split("").includes("%"))
      return value;
    return `${(value * 100)?.toFixed(2)}%`;
  } else if (field.isCurrency) {
    const formatter = currencyFormatter(params?.row?.ordercountrycode);
    return formatter.format(value);
  }
  return value;
};

const addActionsColumn = (
  newGridColumns,
  newColumnVisibilityModel,
  dataGridName,
  handleOpenDeleteModal,
  openModal,
  navigate
) => {
  const actionColumn = {
    field: "actions",
    headerName: "",
    type: "actions",
    width: 100,
    getActions: (params) =>
      getActions(
        dataGridName,
        params,
        handleOpenDeleteModal,
        openModal,
        navigate
      ),
  };
  newGridColumns.push(actionColumn);
  newColumnVisibilityModel["actions"] = true;
};

const mergeColumnProperties = (latestField, storedField) => {
  if (!storedField) return latestField;
  return {
    ...latestField,
    width: storedField.width || latestField.width,
    isPinned: storedField.isPinned ?? latestField.isPinned,
    isHidden: storedField.isHidden ?? latestField.isHidden,
  };
};

// Other helper functions remain the same
