import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useCookies } from "react-cookie";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import TextField from "../../../../components/TextField";
import CreateUserModal from "./components/CreateUserModal";
import DeleteModal from "../../../../components/DeleteModal";
import UserList from "./components/UserList";
import { permissionsState } from "../../../../atoms/atoms";
import { createUser, deleteUser, updateUser } from "../../../../api/Users";
import { resetPassword } from "../../../../api/Auth";
import ConfirmResetPasswordModal from "./components/ConfirmResetPasswordModal";

const Users = ({ roles, users = [], handleUserChange, loading }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["_auth_state"]);
  const permissions = useRecoilValue(permissionsState);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState("");

  const [message, setMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (!permissions.general.addOthers) navigate("/");
  }, [permissions.general.addOthers, navigate]);

  useEffect(() => {
    setMessage("");
  }, [openAddModal, openEditModal]);

  const filteredUsers = users?.filter((user) => {
    const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const handleAddSubmit = async (user) => {
    setSubmitLoading(true);
    try {
      await createUser(user);
      handleUserChange([...users, user]);
      setOpenAddModal(false);
    } catch (err) {
      console.error(err);
      setMessage(`Error: ${err.response.data.message}`);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleUpdateSubmit = async (
    userID,
    firstName,
    lastName,
    email,
    role,
    image
  ) => {
    setSubmitLoading(true);
    try {
      await updateUser(userID, firstName, lastName, email, role, image);
      const newUsers = users.map((user) =>
        user.id === userID
          ? {
              ...user,
              firstName,
              lastName,
              email,
              roleID: role,
              avatarImage: image,
            }
          : user
      );
      handleUserChange(newUsers);
      updateLocalStorage(userID, {
        firstName,
        lastName,
        email,
        roleID: role,
        avatarImage: image,
      });
      setOpenEditModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    if (
      selectedUser.email === cookies["_auth_state"] ||
      (selectedUser.roleID === "Super Admin" &&
        users.filter((obj) => obj.roleID === "Super Admin").length === 1)
    ) {
      return setOpenDeleteModal(false);
    }

    setSubmitLoading(true);
    try {
      await deleteUser(selectedUser.id);
      const newUsers = users.filter((u) => u.email !== selectedUser.email);
      handleUserChange(newUsers);
      setOpenDeleteModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitLoading(false);
    }
  };

  const updateLocalStorage = (userID, userData) => {
    const currentUser = JSON.parse(localStorage.getItem("USER"));
    if (currentUser.id === userID) {
      localStorage.setItem("USER", JSON.stringify({ id: userID, ...userData }));
    }
  };
  const handlePasswordReset = async () => {
    setSubmitLoading(true);
    setResetPasswordError("");
    console.log(selectedUser);
    try {
      await resetPassword(selectedUser.email);
      setMessage("Password reset email sent successfully");
      setOpenResetPasswordModal(false);
    } catch (err) {
      console.error(err);
      setResetPasswordError(
        `Error: ${
          err.response?.data?.message ||
          "Failed to send password reset email. Please try again later."
        }`
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCloseResetPasswordModal = () => {
    setOpenResetPasswordModal(false);
    setResetPasswordError("");
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "left", sm: "left", md: "center" }}
        mb={2}
      >
        <TextField
          disabled={loading}
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          changeValue={setSearchTerm}
          sx={{
            width: { xs: "100%", sm: "400px" },
            mb: { xs: 2, sm: 2, md: 0 },
          }}
          startIcon={{
            startAdornment: (
              <SearchIcon color="primary" style={{ marginRight: "8px" }} />
            ),
          }}
        />
        <Button
          disabled={loading}
          onClick={() => setOpenAddModal(true)}
          variant="outlined"
          sx={{ width: "185px" }}
        >
          Add Team Member
        </Button>
      </Box>
      <Box
        sx={{
          background: "white",
          borderRadius: "8px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          padding: "1rem",
        }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={40} />
          </Box>
        ) : (
          <>
            <Typography mb={1}>Active Members ({users.length})</Typography>
            <UserList
              filteredUsers={filteredUsers}
              roles={roles}
              handlePasswordReset={(user) => {
                setSelectedUser(user);
                setOpenResetPasswordModal(true);
              }}
              handleOpenEditModal={(user) => {
                setSelectedUser(user);
                setOpenEditModal(true);
              }}
              handleOpenDeleteModal={(user) => {
                setSelectedUser(user);
                setOpenDeleteModal(true);
              }}
            />
          </>
        )}
      </Box>
      {permissions?.general?.addOthers && (
        <>
          <CreateUserModal
            title="Add Team Member"
            openModal={openAddModal}
            handleCloseModal={() => setOpenAddModal(false)}
            userLoading={submitLoading}
            message={message}
            handleAddSubmit={handleAddSubmit}
          />
          <CreateUserModal
            title="Edit Team Member"
            openModal={openEditModal}
            isEdit
            handleCloseModal={() => setOpenEditModal(false)}
            userLoading={submitLoading}
            message={message}
            handleUpdateSubmit={handleUpdateSubmit}
            userDetails={selectedUser}
          />
        </>
      )}
      <DeleteModal
        message={`Are you sure you want to delete ${selectedUser.firstName}  ${selectedUser.lastName}?`}
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={() => setOpenDeleteModal(false)}
        handleDeleteItem={handleDeleteUser}
        loading={submitLoading}
      />{" "}
      <ConfirmResetPasswordModal
        open={openResetPasswordModal}
        onClose={handleCloseResetPasswordModal}
        onConfirm={handlePasswordReset}
        userName={`${selectedUser.firstName} ${selectedUser.lastName}`}
        loading={submitLoading}
        errorMessage={resetPasswordError}
      />
    </Box>
  );
};

export default Users;
