import * as React from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Skeleton from "@mui/material/Skeleton";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { orderDetailsState, activeStepState } from "../../../atoms/atoms";
import { activeStepSelector } from "../../../selectors/selectors";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export default function CustomerDetails(props) {
  const state = useRecoilValue(orderDetailsState);
  const activeStep = useRecoilValue(activeStepState);
  const setActiveStep = useSetRecoilState(activeStepSelector);

  const handleStep = (forward) => {
    window.scrollTo(0, 0);
    setActiveStep(forward);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {props.errorMessage && (
        <Stack sx={{ width: "80%", margin: "auto", mb: 2 }} spacing={2}>
          <Alert severity="error">{props.errorMessage}</Alert>
        </Stack>
      )}
      <Paper
        elevation={2}
        sx={{
          borderRadius: "20px",
          p: 5,
          pt: 3,
          px: { xs: 2, sm: 10 },
          textAlign: "center",
        }}
      >
        <Typography variant={"h5"} sx={{ mb: 1, fontWeight: "600" }}>
          Edit Customer Details
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {props.orderLoading && (
          <Grid
            container
            spacing={2}
            margin="auto"
            mb={1.5}
            sx={{ width: "100% !important", maxWidth: "800px" }}
          >
            <Skeleton
              variant="rectangular"
              sx={{ mt: 1, mb: 1 }}
              width="100%"
              height={56}
            />
            <Skeleton
              variant="rectangular"
              sx={{ mt: 1, mb: 1 }}
              width="100%"
              height={56}
            />
            <Skeleton
              variant="rectangular"
              sx={{ mt: 1, mb: 1 }}
              width="100%"
              height={56}
            />
            <Skeleton
              variant="rectangular"
              sx={{ mt: 1, mb: 1 }}
              width="100%"
              height={56}
            />
            <Skeleton
              variant="rectangular"
              sx={{ mt: 1, mb: 1 }}
              width="100%"
              height={56}
            />
            <Skeleton
              variant="rectangular"
              sx={{ mt: 1, mb: 1 }}
              width="100%"
              height={56}
            />
            <Skeleton
              variant="rectangular"
              sx={{ mt: 1, mb: 1 }}
              width="100%"
              height={56}
            />
            <Skeleton
              variant="rectangular"
              sx={{ mt: 1, mb: 1 }}
              width="100%"
              height={56}
            />
          </Grid>
        )}
        {!props.errorMessage && !props.orderLoading && (
          <Box>
            <Grid
              container
              spacing={2}
              margin="auto"
              mb={1.5}
              sx={{
                pt: 2,
                width: "100% !important",
                maxWidth: "1000px",
              }}
            >
              <Grid item xs={12} sx={{ pl: "0 !important" }}>
                <TextField
                  disabled
                  fullWidth
                  label="Store Country"
                  name="storeCountry"
                  value={state.storeCountry}
                />
              </Grid>
              {state.storeCountry === "CA" && (
                <Grid
                  sx={{ paddingTop: "8px !important", pl: "0 !important" }}
                  item
                  xs={12}
                  sx={{ mt: 1, pl: "0 !important" }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="GrenkeyCanadaGST"
                          checked={state.GrenkeyCanadaGST}
                          onChange={(e) =>
                            props.handleChange(e, e.target.value)
                          }
                        />
                      }
                      label="Grenke Canada Application GST Only"
                    />
                  </FormGroup>
                </Grid>
              )}
              {state.storeCountry === "US" && (
                <Grid
                  sx={{
                    mt: 1,
                    paddingTop: "8px !important",
                    pl: "0 !important",
                  }}
                  item
                  xs={12}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isTaxExempt"
                          checked={state.isTaxExempt}
                          onChange={(e) =>
                            props.handleChange(e, e.target.value)
                          }
                        />
                      }
                      label="Is Tax Exempt"
                    />
                  </FormGroup>
                </Grid>
              )}
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pl: "0 !important",
                  mt: 1,
                }}
                item
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  id="first-name"
                  label="First Name"
                  name="firstName"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.firstName}
                  autoComplete="first-name"
                  sx={{ width: "49%" }}
                />
                <TextField
                  required
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  id="lastName"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.lastName}
                  autoComplete="last-name"
                  sx={{ width: "49%" }}
                />
              </Grid>
              <Grid item xs={12} sx={{ pl: "0 !important", mt: 1 }}>
                <TextField
                  required
                  fullWidth
                  name="phoneNumber"
                  label="Phone Number"
                  id="phone-number"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.phoneNumber}
                  autoComplete="phone-number"
                />
              </Grid>{" "}
              <Grid item xs={12} sx={{ pl: "0 !important", mt: 1 }}>
                <TextField
                  fullWidth
                  name="additionalPhoneNumber"
                  label="Additional Phone Number"
                  id="phone-number"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.additionalPhoneNumber}
                  autoComplete="phone-number"
                />
              </Grid>{" "}
              <Grid item xs={12} sx={{ pl: "0 !important", mt: 1 }}>
                <TextField
                  required
                  fullWidth
                  name="emailAddress"
                  label="Email Address"
                  id="phone-number"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.emailAddress}
                  autoComplete="phone-number"
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 1, pl: "0 !important" }}>
                <TextField
                  fullWidth
                  name="company"
                  label="Company"
                  id="company"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.company}
                  autoComplete="street-address"
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 1, pl: "0 !important" }}>
                <TextField
                  required
                  fullWidth
                  name="streetAddress"
                  label="Street Address"
                  id="street-address"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.streetAddress}
                  autoComplete="street-address"
                />
              </Grid>
              <Grid
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  pl: "0 !important",
                }}
                item
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  id="state"
                  label="State"
                  name="state"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.state}
                  autoComplete="state"
                  sx={{ width: "49%" }}
                />
                <TextField
                  required
                  fullWidth
                  name="city"
                  label="City"
                  id="city"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.city}
                  autoComplete="city"
                  sx={{ width: "49%" }}
                />
              </Grid>
              <Grid
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  pl: "0 !important",
                }}
                item
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  id="country"
                  label="Country"
                  name="country"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.country}
                  autoComplete="country"
                  sx={{ width: "49%" }}
                />
                <TextField
                  required
                  fullWidth
                  name="zip"
                  label="Zip"
                  id="zip"
                  onChange={(e) => props.handleChange(e, e.target.value)}
                  value={state.zip}
                  autoComplete="zip"
                  sx={{ width: "49%" }}
                />
              </Grid>{" "}
              <Grid
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  pl: "0 !important",
                }}
                item
                xs={12}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isResidential"
                      checked={state.isResidential}
                      onChange={(e) => props.handleChange(e, e.target.checked)}
                    />
                  }
                  label="Is Residential"
                />
              </Grid>
            </Grid>
          </Box>
        )}{" "}
      </Paper>
      {!props.orderLoading && (
        <Paper
          elevation={2}
          sx={{
            width: "80%",
            m: "auto",
            mt: 2,
            p: 1,
            pt: "8px",
            borderRadius: "20px",
          }}
        >
          <MobileStepper
            variant="progress"
            steps={4}
            position="static"
            activeStep={activeStep}
            sx={
              state.storeCountry === "CA"
                ? { width: "100%", mr: 2, p: 0 }
                : { width: "100%", p: 0 }
            }
            nextButton={
              <Button
                size="small"
                onClick={() => handleStep(true)}
                disabled={props.errorMessage ? true : false}
              >
                Next
              </Button>
            }
            backButton={
              <Button size="small" disabled={true}>
                Back
              </Button>
            }
          />
        </Paper>
      )}{" "}
    </Box>
  );
}
