import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function createUser(user) {
  return axios
    .post(`${API_ORDER_LINK_URL}/users`, user)
    .then((response) => response.data);
}

function deleteUser(userID) {
  return axios
    .delete(`${API_ORDER_LINK_URL}/users?userID=${userID}`)
    .then((response) => response.data);
}

function getAllUsers() {
  return axios
    .get(`${API_ORDER_LINK_URL}/users`)
    .then((response) => response.data);
}

function updatePassword(userID, oldPassword, newPassword, confirmNewPassword) {
  return axios
    .patch(`${API_ORDER_LINK_URL}/users`, {
      userID,
      oldPassword,
      newPassword,
      confirmNewPassword,
    })
    .then((response) => response.data);
}

function updateUser(userID, firstName, lastName, email, roleID, image) {
  return axios
    .patch(`${API_ORDER_LINK_URL}/users`, {
      userID,
      firstName,
      lastName,
      email,
      roleID,
      image,
    })
    .then((response) => response.data);
}

export { createUser, deleteUser, getAllUsers, updatePassword, updateUser };
