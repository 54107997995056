import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const TableHeader = ({ columns, handleAdd, handleDelete }) => (
  <TableHead>
    <TableRow>
      {columns.map((column, index) => {
        return (
          <TableCell
            key={column.field}
            sx={{
              minWidth: column.width || 150,
              width: column.width || "auto",
              maxWidth: column.maxWidth || "none",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "bold",
              borderRight: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            {index === 0 && handleAdd ? (
              <Box display="flex" alignItems="center">
                <IconButton onClick={handleAdd} size="small" sx={{ mr: 1 }}>
                  <AddCircleIcon fontSize="large" />
                </IconButton>
                {column.headerName}
                {column.required ? "*" : ""}
              </Box>
            ) : (
              `${column.headerName} ${column.required ? "*" : ""} `
            )}
          </TableCell>
        );
      })}
      {handleDelete && (
        <TableCell
          sx={{
            position: "sticky",
            right: 0,
            background: "white",
            zIndex: 2,
            minWidth: 50,
            width: 50,
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
          }}
        />
      )}
    </TableRow>
  </TableHead>
);

export default TableHeader;
