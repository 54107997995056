import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const AddressDisplay = ({ title, address }) => (
  <>
    <Typography variant="h6" color="primary" sx={multilineEllipsisStyle}>
      {title}
    </Typography>
    {address.split("\n").map((line, index) => (
      <Typography key={index} variant="body2" sx={{ whiteSpace: "normal" }}>
        {line}
      </Typography>
    ))}
  </>
);

const multilineEllipsisStyle = {
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 5,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
};
export const DisplayInfo = ({ order, formattedPhone }) => (
  <Box sx={{ width: "100%" }}>
    <Typography
      variant="h4"
      fontWeight="bold"
      sx={{ ...multilineEllipsisStyle }}
    >
      {order.shippingcompany}
    </Typography>
    <Typography variant="h6" sx={{ ...multilineEllipsisStyle }}>
      {order.shippingemail}
    </Typography>
    <Typography variant="h6" sx={{ ...multilineEllipsisStyle }}>
      {formattedPhone}
    </Typography>

    <Grid container mt={2} spacing={3} sx={{ maxWidth: "500px" }}>
      <Grid item sm={12} md={6}>
        <AddressDisplay
          title="Billing Address"
          address={order.billingfulladdress}
        />
      </Grid>
      <Grid item sm={12} md={6} mt={{ sm: 2, md: 0 }}>
        <AddressDisplay
          title="Shipping Address"
          address={order.shippingfulladdress}
        />
      </Grid>
    </Grid>
  </Box>
);
