import React from "react";
import { BrowserRouter, Routes, Route, redirect } from "react-router-dom";
import RequireAuth from "@auth-kit/react-router/RequireAuth";

import OfflineOrders from "./pages/OfflineOrders";
import EditOrder from "./pages/EditOrder";
import Success from "./pages/EditOrder/sections/Success";
import CustomerOrders from "./pages/CustomerOrders";

import IdentityManagement from "./pages/IdentityManagement/Home";
import SignIn from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import PermissionGate from "./components/PermissionGate";
import Settings from "./pages/Settings";
import Order from "./pages/Order";
import OrderReimbursements from "./pages/Accounting/OrderReimbursements/OrderReimbursements";

import InventoryMain from "./pages/InventoryOrders/InventoryMain";
import Home from "./pages/Home";
import { DateFormatProvider } from "./contexts/DateFormatContext";
import InventoryOrder from "./pages/InventoryOrder";
import BuildInventoryOrder from "./pages/BuildInventoryOrders";
const Router = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route
          exact
          path={"/order/:orderUuid"}
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath={"/login"}>
                  <Order />
                </RequireAuth>{" "}
              </DateFormatProvider>
            </PermissionGate>
          }
        />{" "}
        <Route
          exact
          path={"/inventoryOrder/:orderWarehouseNumber"}
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <InventoryOrder />
                </RequireAuth>{" "}
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          exact
          path="/offlineorders"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <OfflineOrders />
                </RequireAuth>{" "}
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          exact
          path="/customerorders"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <CustomerOrders />
                </RequireAuth>{" "}
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          exact
          path="/accounting/orderReimbursements/pending"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <OrderReimbursements />
                </RequireAuth>{" "}
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          exact
          path="/accounting/orderReimbursements/readyForPayment"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <OrderReimbursements />
                </RequireAuth>{" "}
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          exact
          path="/accounting/orderReimbursements/paid"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <OrderReimbursements />
                </RequireAuth>{" "}
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          exact
          path="/inventoryOrders/currentStock"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <InventoryMain />
                </RequireAuth>{" "}
              </DateFormatProvider>
            </PermissionGate>
          }
        />{" "}
        <Route
          exact
          path="/inventoryOrders/stockPredictions"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <InventoryMain />
                </RequireAuth>{" "}
              </DateFormatProvider>
            </PermissionGate>
          }
        />{" "}
        <Route
          exact
          path="/inventoryOrders/stockOrders"
          element={
            <PermissionGate>
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <InventoryMain />
                </RequireAuth>
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          exact
          path="/inventoryOrders/buildOrder"
          element={
            <PermissionGate>
              <DateFormatProvider>
                <RequireAuth loginPath="/login">
                  <BuildInventoryOrder />
                </RequireAuth>
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          exact
          path="/accounting/orderReimbursements/completed"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <OrderReimbursements />
                </RequireAuth>
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          path="/offlineorders/:orderUuid"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <EditOrder />
                </RequireAuth>
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          path="/offlineorders/:orderUuid/success"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <Success />
                </RequireAuth>
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          path="/iam"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <IdentityManagement />
                </RequireAuth>
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route
          path="/settings"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <Settings />
                </RequireAuth>
              </DateFormatProvider>
            </PermissionGate>
          }
        />{" "}
        <Route
          path="/home"
          element={
            <PermissionGate>
              {" "}
              <DateFormatProvider>
                <RequireAuth fallbackPath="/login">
                  <Home />
                </RequireAuth>
              </DateFormatProvider>
            </PermissionGate>
          }
        />
        <Route path="*" element={<SignIn />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
