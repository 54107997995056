import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useRecoilValue } from "recoil";
import { orderFilesState } from "../../../../atoms/atoms";
import { loadUser } from "../../../../common";
import {useDateFormat} from "../../../../contexts/DateFormatContext";

// Extend dayjs with the plugins
dayjs.extend(relativeTime);
dayjs.extend(isToday);

const Message = ({
  id,
  name,
  image,
  content,
  state,
  files,
  createdAt,
  showTimestamp,
  currentUserID,
  handleDeleteMessage,
  handleImageLoad,
}) => {
  const storedFiles = useRecoilValue(orderFilesState);
  const userID = JSON.parse(loadUser()).id;

  const { dateFormat } = useDateFormat();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteMessage = () => {
    handleClose();
    handleDeleteMessage(id);
  };

  const isUserMessage = userID === currentUserID;

  const formatTimestamp = (timestamp, showSeconds = false) => {
    const date = dayjs(timestamp);
    let ss = showSeconds ? ":ss" : "";
    let longDateFormat = dateFormat === "MM/DD/YYYY" ?  "ddd, MMM D" : "ddd, D MMM"
    return date.isToday()
      ? date.format(`h:mm${ss} A`)
      : date.format( `${longDateFormat}, h:mm${ss} A `);
  };
  const renderFile = (file) => {
    const storedFile = storedFiles.find(
      (storedFile) =>
        storedFile.id === file.id || storedFile.id === file.fileInfo?.id
    );
    const fileUrl =
      file.url || file.preview || storedFile?.url || file.fileInfo?.url;
    // console.log(file);
    if (!fileUrl) {
      return (
        <Box
          key={file.name}
          sx={{ display: "flex", alignItems: "center", my: 1 }}
        >
          <InsertDriveFileIcon sx={{ mr: 1 }} />
          <Typography variant="body2" color="error">
            {file.name} (deleted)
          </Typography>
        </Box>
      );
    }

    if (isImageFile(file) || isImageFile(storedFile)) {
      return (
        <Box
          onLoad={handleImageLoad}
          key={file.name}
          component="img"
          src={fileUrl}
          alt={file.name}
          sx={{
            borderRadius: 1,
            maxWidth: "95%",
            maxHeight: "200px",
            objectFit: "contain",
            mt: 1,
            display: "block",
          }}
        />
      );
    } else {
      return (
        <Box
          key={file.name}
          sx={{ display: "flex", alignItems: "center", my: 1 }}
        >
          <InsertDriveFileIcon sx={{ mr: 1 }} />
          <Typography variant="body2">{file.name}</Typography>
        </Box>
      );
    }
  };
  const renderContent = (content) => {
    const mentionRegex = /\@\[([^\]]+)\]\([^\)]+\)/g;
    return (
      <Typography
        variant="body1"
        sx={{
          py: 1,
          px: 1.5,
          backgroundColor: "#f4f4f4",
          borderRadius: 2,
          wordWrap: "break-word",
          display: "inline-block",
          whiteSpace: "pre-wrap",
        }}
      >
        {content.split(mentionRegex).map((part, index, parts) => {
          if (index % 2 === 1) {
            return (
              <Typography key={index} component="span" color="primary">
                @{part}
              </Typography>
            );
          }
          return part;
        })}
      </Typography>
    );
  };

  const isImageFile = (file) => {
    return (
      file?.type?.startsWith("image/") ||
      file?.contentType?.startsWith("image/")
    );
  };

  return (
    <Box>
      {showTimestamp && createdAt && (
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={{ textAlign: "center", mt: 2 }}
        >
          {formatTimestamp(createdAt)}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Avatar src={image} alt="Sender" sx={{ marginRight: 2, mt: 2.1 }} />
            <Box sx={{ flex: 1 }}>
              <Typography variant="caption" sx={{ color: "grey" }} title={createdAt ? formatTimestamp(createdAt, true) : ""}>
                {name}
              </Typography>
              <Box sx={{ position: "relative" }}>
                {content && renderContent(content)}
                {files?.map((file) => renderFile(file))}
                {state === "error" && (
                  <ErrorIcon
                    sx={{
                      position: "absolute",
                      color: "red",
                      fontSize: "1rem",
                      right: 0,
                      bottom: -8,
                    }}
                  />
                )}
                {state === "success" && (
                  <CheckCircleIcon
                    sx={{
                      position: "absolute",
                      color: "green",
                      fontSize: "1rem",
                      right: files?.length > 0 ? 0 : null,
                      bottom: -8,
                    }}
                  />
                )}
              </Box>
              {state === "sending" && (
                <Typography
                  fontSize={10}
                  sx={{
                    ml: 0.5,
                    color: "primary.main",
                  }}
                >
                  sending...
                </Typography>
              )}
            </Box>

            <Menu
              id="message-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onDeleteMessage}>Delete</MenuItem>
            </Menu>
          </Box>{" "}
          {state !== "error" && state !== "sending" && isUserMessage && (
            <IconButton
              aria-label="more"
              aria-controls="message-menu"
              aria-haspopup="true"
              onClick={handleClick}
              size="small"
              sx={{ height: "30px", width: "30px" }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Message;
