import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHeader from "./TableHeader";
import { TableRowComponent } from "./TableRowComponent";

const NormalTable = ({
  columns,
  rows,
  handleInputChange,
  handleAdd,
  handleDelete,
  globalTimezone,
}) => (
  <Table sx={{ borderCollapse: "separate", borderSpacing: 0 }}>
    <TableHeader
      columns={columns}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
    />
    <TableBody>
      {rows?.map((row) => (
        <TableRowComponent
          key={row.id}
          row={row}
          columns={columns}
          handleInputChange={handleInputChange}
          handleDelete={handleDelete}
          globalTimezone={globalTimezone}
        />
      ))}
    </TableBody>
  </Table>
);

export default NormalTable;
