import React, { useEffect, useState, useCallback } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  mainProductsState,
  extraProductsState,
  orderDetailsState,
  activeStepState,
  permissionsState,
} from "../../atoms/atoms";
import { activeStepSelector } from "../../selectors/selectors";
import Navbar from "../../components/Navbar";
import FullPageContentContainer from "../../components/FullPageContentContainer";
import Toolbar from "@mui/material/Toolbar";
import Skeleton from "@mui/material/Skeleton";
import CustomerDetails from "./sections/CustomerDetails";
import Products from "./sections/Products";
import Summary from "./sections/Summary";
import { getOfflineOrder } from "../../api/OfflineOrders";
import { getProductsByCountry, getProductFields } from "../../api/Products";
import { Alert, Stack } from "@mui/material";

export default function EditOrder() {
  const [errorMessage, setErrorMessage] = useState("");
  const [orderLoading, setOrderLoading] = useState(true);
  const [orderUnit, setOrderUnit] = useState(null);
  const [searchFieldOptions, setSearchFieldOptions] = useState([]);
  const [productPIDs, setProductPIDs] = useState([]);
  const [productsError, setProductsError] = useState(false);

  const activeStep = useRecoilValue(activeStepState);
  const setActiveStep = useSetRecoilState(activeStepSelector);
  const [mainProducts, setMainProducts] = useRecoilState(mainProductsState);
  const [, setExtraProducts] = useRecoilState(extraProductsState);
  const [orderState, setOrderState] = useRecoilState(orderDetailsState);
  const permissions = useRecoilValue(permissionsState);

  useEffect(() => {
    setActiveStep();
    const id = window.location.href.split("/").filter(Boolean).pop();
    handleGetOrderUnit(id);
  }, []);

  const handleGetOrderUnit = async (id) => {
    setProductsError(false);
    try {
      const rsp = await getOfflineOrder(id);
      setOrderUnit(rsp.fullOrder);
      // Separate main products and extra products
      const mainItems = rsp.products
        .filter(
          (product) =>
            ![
              "XTRA-1-PTYSHIP-0-0",
              "XTRA-1-INSURE1-0-0",
              "CUSTOM-SHIPPING",
            ].includes(product.itemPID)
        )
        .map((lineItem) => ({
          ...lineItem,
          isSalesTaxIncluded:
            lineItem.isSalesTaxIncluded !== undefined
              ? lineItem.isSalesTaxIncluded
              : rsp.fullOrder.order.isTaxIncluded,
          isRefurb: rsp.fullOrder.item.newOrRefurb === "Refurbished",
        }));

      setMainProducts(mainItems);

      const extraItems = {
        priorityShipping:
          rsp.products.find(
            (product) => product.itemPID === "XTRA-1-PTYSHIP-0-0"
          ) || null,
        insurance:
          rsp.products.find(
            (product) => product.itemPID === "XTRA-1-INSURE1-0-0"
          ) || null,
        customShipping:
          rsp.products.find(
            (product) => product.itemPID === "CUSTOM-SHIPPING"
          ) || null,
      };

      setExtraProducts(extraItems);
      setOrderState((prevState) => ({
        ...prevState,
        ...rsp.orderState,
        isRefurb: rsp.fullOrder.item.newOrRefurb === "Refurbished",
        isSalesTaxIncluded:
          rsp.fullOrder.order.isTaxIncluded !== undefined
            ? rsp.fullOrder.order.isTaxIncluded
            : rsp.fullOrder.order.isSalesTaxIncluded,
        additionalPhoneNumber: rsp.fullOrder.shipping.additionalPhone,
        emailAddress: rsp.fullOrder.shipping.email,
        company: rsp.fullOrder.shipping.company,
        isResidential: rsp.fullOrder.shipping.isResidential,
        priorityShippingPrice: extraItems.priorityShipping?.salePrice || null,
        freightShippingPrice: extraItems.insurance?.salePrice || null,
        customShippingPrice:
          extraItems.customShipping?.salePrice ||
          rsp.fullOrder.order.totalShipping ||
          null,
      }));

      const countryCode = rsp.countryCode;
      await handleGetProductsByCountry(countryCode);
      await handleGetProductFields(countryCode);
    } catch (err) {
      console.error(err);
      setOrderState((prev) => ({ ...prev, storeCountry: "" }));
      setErrorMessage("Order not found.");
    } finally {
      setOrderLoading(false);
    }
  };

  const handleGetProductFields = async (countryCode) => {
    try {
      const rsp = await getProductFields(countryCode);

      const newSearchFieldOptions = rsp.searchFields
        .filter((field) => {
          const fieldName = field.fieldName;
          const columns = permissions?.offlineOrders?.products?.columns;
          return (
            permissions?.id === "Super Admin" || (columns && columns[fieldName])
          );
        })
        .map((field, i) => ({ id: i, ...field }));
      setSearchFieldOptions(newSearchFieldOptions);
    } catch (err) {
      setProductsError(true);
      console.error(err);
    }
  };

  const handleGetProductsByCountry = async (storeCountry) => {
    try {
      const rsp = await getProductsByCountry(storeCountry);
      setProductPIDs(
        rsp.countryProducts.map((product, i) => ({
          id: i,
          label: product.pid,
          description: product.description,
          price: product.price,
          sku: product.sku,
          weight: product.shipping_weight,
        }))
      );
    } catch (err) {
      setProductPIDs([]);
      console.error(err);
    }
  };

  const handleTaxRateChange = useCallback(
    (taxRate, isGrenkeyCanadaGST) => {
      setMainProducts((prevProducts) =>
        prevProducts.map((prod) =>
          updateProductValues({ ...prod, taxRate: taxRate }, prod.discount)
        )
      );
      setOrderState((prev) => ({
        ...prev,
        GrenkeyCanadaGST: isGrenkeyCanadaGST
          ? !prev.GrenkeyCanadaGST
          : prev.GrenkeyCanadaGST,
      }));
    },
    [setMainProducts, setOrderState]
  );

  const handleChange = useCallback(
    (e, value) => {
      const { name } = e.target;
      if (name === "GrenkeyCanadaGST") {
        handleTaxRateChange(e.target.checked ? 0.05 : orderState.taxRate, true);
      } else if (name === "isTaxExempt") {
        handleTaxRateChange(e.target.checked ? 0 : orderState.taxRate);
      } else {
        setOrderState((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    },
    [handleTaxRateChange, orderState.taxRate, setOrderState]
  );

  const handleDiscountSymbolChange = useCallback(
    (e) => {
      setOrderState((prev) => ({ ...prev, discountSymbol: e.target.value }));
    },
    [setOrderState]
  );

  const ensureNonNegative = useCallback((value) => Math.max(0, value || 0), []);

  const updateProductValues = useCallback(
    (product, discount) => {
      const productDetails = productPIDs.find(
        (p) => p.label === product.itemPID
      );
      if (!productDetails) return product;

      const productPrice = ensureNonNegative(productDetails?.price || 0);
      let linePrice = ensureNonNegative(
        productPrice * product.quantity - discount
      );

      const basePrice = orderState.isSalesTaxIncluded
        ? linePrice / (1 + product.taxRate)
        : linePrice;

      const salesTaxDollarAmount = orderState.isSalesTaxIncluded
        ? linePrice - basePrice
        : basePrice * product.taxRate;

      return {
        ...product,
        quantity: ensureNonNegative(product.quantity) || 0,
        linePrice: linePrice || 0,
        discount: ensureNonNegative(discount) || 0,
        salesTaxDollarAmount: ensureNonNegative(salesTaxDollarAmount),
        salesTaxPercent: `${product.taxRate * 100}%`,
      };
    },
    [productPIDs, orderState.isSalesTaxIncluded, ensureNonNegative]
  );

  const handleDiscountChange = useCallback(
    (discountValue) => {
      if (orderState.discountSymbol === 10) {
        // Dollar amount discount
        setOrderState((prev) => ({ ...prev, discountPercentage: 0 }));

        let remainingDiscount = discountValue;
        let updatedProducts = [...mainProducts];

        while (
          remainingDiscount > 0 &&
          updatedProducts.some((p) => p.linePrice > 0)
        ) {
          const availableProducts = updatedProducts.filter(
            (p) => p.linePrice > 0
          );
          let discountPerProduct = remainingDiscount / availableProducts.length;

          updatedProducts = updatedProducts.map((product) => {
            if (product.linePrice > 0) {
              const actualDiscount = Math.min(
                product.linePrice,
                discountPerProduct
              );
              remainingDiscount -= actualDiscount;
              return updateProductValues(
                product,
                (product.discount || 0) + actualDiscount
              );
            }
            return product;
          });
        }

        setMainProducts(updatedProducts);
      } else {
        // Percentage discount
        setOrderState((prev) => ({
          ...prev,
          discountPercentage: discountValue,
        }));
        setMainProducts((prevProducts) =>
          prevProducts.map((product) => {
            const discount = Math.min(
              product.linePrice,
              (product.linePrice * (parseInt(discountValue) / 100)).toFixed(2)
            );
            return updateProductValues(product, discount);
          })
        );
      }

      setOrderState((prev) => ({ ...prev, discount: "" }));
    },
    [
      orderState.discountSymbol,
      setMainProducts,
      setOrderState,
      updateProductValues,
      mainProducts,
    ]
  );

  return (
    <>
      <Navbar
        page={
          orderUnit &&
          `Edit Offline Order ${orderUnit.uid?.split("-")[0]}-${
            orderUnit.uid?.split("-")[1]
          }`
        }
        cancel
      />
      <Toolbar />
      {activeStep === 0 && (
        <FullPageContentContainer
          sx={{ pt: 0, pb: 1 }}
          maxWidth="md"
          minHeight="calc(100vh - 80px)"
        >
          <CustomerDetails
            orderLoading={orderLoading}
            errorMessage={errorMessage}
            handleChange={handleChange}
          />
        </FullPageContentContainer>
      )}
      {activeStep === 1 && (
        <FullPageContentContainer
          maxWidth="1800px"
          minHeight="calc(100vh - 65px)"
        >
          {productsError && (
            <Stack sx={{ width: "100%", marginBottom: 2 }} spacing={2}>
              <Alert severity="error">
                Error loading product data. Please try again.
              </Alert>
            </Stack>
          )}
          {searchFieldOptions.length && productPIDs.length ? (
            <Products
              updateDiscountSymbol={handleDiscountSymbolChange}
              applyDiscount={handleDiscountChange}
              productPIDs={productPIDs}
              searchFieldOptions={searchFieldOptions}
              error={productsError}
              updateProductValues={updateProductValues}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{ mt: 2, mb: 4, borderRadius: "5px" }}
              width="100%"
              height={600}
            />
          )}
        </FullPageContentContainer>
      )}
      {activeStep === 2 && (
        <FullPageContentContainer minHeight="calc(100vh - 64px)">
          <Summary
            orderDetails={orderUnit}
            searchFieldOptions={searchFieldOptions}
            productPIDs={productPIDs}
          />
        </FullPageContentContainer>
      )}
    </>
  );
}
