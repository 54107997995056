import axios from './axios'
import { API_ORDER_LINK_URL } from '../consts'

function resetPassword(email) {
    return axios.post(API_ORDER_LINK_URL + '/resetPassword', {email:`${email}`}).then(response =>(response.data))
  }

function login(email, password) {
   return axios.post(API_ORDER_LINK_URL + '/login', {email,password}).then(response => (response.data))
}

function checkPasswordToken(token) {
    return axios.post(API_ORDER_LINK_URL + '/checkPasswordToken', {token:`${token}`}).then(response =>(response.data))
}

 function confirmPasswordReset(token, password, confirmPassword) {
    return axios.post(API_ORDER_LINK_URL + '/confirmPasswordReset', {"token": `${token}`, "password": `${password}`, "confirmPassword": `${confirmPassword}`}).then(response =>(response.data))
}

export {resetPassword, login, checkPasswordToken, confirmPasswordReset}