import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import Modal from "../Modal";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { cardViewSettingsState } from "../../atoms/atoms";
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import { updateSettings } from "../../api/cardSettings";
import Error from "../Error";
import DraggableList from "../DraggableList/DraggableList";
import { SearchFieldAutocomplete } from "../AutoComplete/AutoComplete";

const CardViewSettingsModal = ({ dataGridName, searchFields }) => {
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const [loading, setLoading] = useState(false);

  const settingsState = useRecoilValue(cardViewSettingsState);
  const setSettingsState = useSetRecoilState(cardViewSettingsState);

  const [columnOptions, setColumnOptions] = useState(null);

  const [state, setState] = useState(null);
  const [selectedFields, setSelectedFields] = useState("");

  const [error, setError] = useState(false);

  useEffect(() => {
    if (searchFields.length > 0) {
      const updatedFields = searchFields.slice(1);
      const filteredFields = updatedFields.filter(
        (field) =>
          field.searchTypes.includes("Is one of") ||
          field.searchTypes.includes("Is not one of")
      );
      setColumnOptions(filteredFields);
    }
  }, [searchFields]);

  useEffect(() => {
    setSelectedFields("");
  }, [openAdd]);

  useEffect(() => {
    setState({ ...settingsState });
  }, [settingsState]);

  const handleUpdateSettings = useCallback(() => {
    setLoading(true);
    setError(false);
    updateSettings({ ...state, dataGridName: dataGridName })
      .then((rsp) => {
        setSettingsState({
          ...rsp.updatedSettings,
        });
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  }, [state]);

  const updateColumnColumn = (value) => {
    setState({ ...state, cardColumnHeaders: value });
  };

  const updateShowColumnHeaders = (e) => {
    setState({ ...state, showColumnHeaders: e.target.checked });
  };
  const updateCanMoveCards = (e) => {
    setState({ ...state, canMoveCards: e.target.checked });
  };

  const updateSelectedColumn = (value) => {
    setSelectedFields(value);
  };
  const handleUpdateColumnsDisplayed = () => {
    setState((prevState) => {
      // Check if the selectedField already exists in columnsDisplayed
      const selectedColumns = selectedFields?.filter(
        (selectedField) =>
          !prevState.columnsDisplayed.some(
            (column) => column.fieldName === selectedField.fieldName
          )
      );

      return {
        ...prevState,
        columnsDisplayed: [...prevState.columnsDisplayed, ...selectedColumns],
      };
    });
    setOpenAdd(false);
  };
  return (
    <>
      <IconButton
        disabled={!settingsState.cardColumnHeaders}
        sx={{ height: "40px" }}
        onClick={() => setOpen(true)}
      >
        <SettingsIcon />
      </IconButton>
      <Modal
        title="Settings"
        openModal={open}
        handleCloseModal={() => setOpen(false)}
        handleSubmit={handleUpdateSettings}
        loading={loading}
      >
        {error && (
          <Error
            sx={{ mb: 4 }}
            errorMessage={"Error updating settings, please try again later. "}
          />
        )}
        <Box sx={{ minWidth: "250px" }}>
          <Typography fontWeight={"bold"} sx={{ mb: 2 }}>
            Card Column Headers
          </Typography>
          {columnOptions && (
            <SearchFieldAutocomplete
              selectedFields={state.cardColumnHeaders}
              searchFields={columnOptions}
              onUpdate={updateColumnColumn}
            />
          )}
          <Divider sx={{ mt: 3 }} />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography fontWeight={"bold"} sx={{ mt: 3, mb: 1 }}>
              Show Column Headers
            </Typography>
            <Switch
              sx={{ mt: 2, ml: 1 }}
              onChange={updateShowColumnHeaders}
              checked={state?.showColumnHeaders}
            />
          </Box>{" "}
          <Divider sx={{ mt: 2 }} />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography fontWeight={"bold"} sx={{ mt: 3, mb: 1 }}>
              Can Move Cards Across Columns{" "}
            </Typography>
            <Switch
              sx={{ mt: 2, ml: 1 }}
              onChange={updateCanMoveCards}
              checked={state?.canMoveCards}
            />
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box mb={2} mt={3} display={"flex"} justifyContent={"space-between"}>
            <Typography fontWeight={"bold"} mt={1}>
              Column Displayed on Card
            </Typography>
            <IconButton
              sx={{
                minWidth: "40px",
                height: "40px",
                border: 1,
                borderColor: "primary.main",
                borderRadius: "50%",
                marginX: 0.5, // Add this for horizontal spacing between buttons
              }}
              onClick={() => setOpenAdd(true)}
              color={"primary"}
            >
              <AddIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, mt: 1 }}>
            {state?.columnsDisplayed?.length !== 0 && (
              <DraggableList state={state} setState={setState} />
            )}
          </Box>
        </Box>
      </Modal>
      <Modal
        title="Add Column"
        openModal={openAdd}
        handleCloseModal={() => setOpenAdd(false)}
        handleSubmit={handleUpdateColumnsDisplayed}
        loading={loading}
        small
      >
        <Box sx={{ minWidth: "250px" }}>
          {columnOptions && (
            <SearchFieldAutocomplete
              label={"Columns"}
              selectedField={selectedFields}
              searchFields={searchFields.slice(1)}
              onUpdate={updateSelectedColumn}
              multiple
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default CardViewSettingsModal;
