// components/UserList.js
import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ComputerIcon from "@mui/icons-material/Computer";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import StorefrontIcon from "@mui/icons-material/Storefront";
import UserActionMenu from "./UserActionMenu";

const IconMap = {
  Manager: <BusinessCenterIcon fontSize="large" />,
  Developer: <ComputerIcon fontSize="large" />,
  Support: <SupportAgentIcon fontSize="large" />,
  Engineer: <EngineeringIcon fontSize="large" />,
  Employee: <AccountCircleIcon fontSize="large" />,
  Logistics: <LocalShippingIcon fontSize="large" />,
  Maintenance: <CleaningServicesIcon fontSize="large" />,
  Retail: <StorefrontIcon fontSize="large" />,
};

const UserList = ({
  filteredUsers,
  roles,
  handleOpenEditModal,
  handleOpenDeleteModal,
  handlePasswordReset,
}) => {
  // Sort users alphabetically by first name
  const sortedUsers = [...filteredUsers].sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  );

  return (
    <List
      sx={{
        p: 2,
        pt: 2,
        maxHeight: { xs: "calc(100vh - 340px)", sm: "calc(100vh - 280px)" },
        overflow: "auto",
      }}
    >
      {sortedUsers?.map((user) => {
        const role = roles?.find((role) => role.id === user.roleID);
        return (
          <motion.div
            key={user.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ListItem
              sx={{
                "&:hover": { opacity: "0.9" },
                p: 2,
                marginBottom: "1rem",
                borderRadius: "8px",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
              }}
            >
              <ListItemAvatar
                sx={{
                  mr: 1,
                  "@media (max-width: 600px)": {
                    mr: 0,
                    marginBottom: "1rem",
                  },
                }}
              >
                <Avatar
                  sx={{ width: "60px", height: "60px" }}
                  src={user.avatarImage}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${user.firstName} ${user.lastName}`}
                secondary={user.email}
                sx={{
                  width: "100%",
                  mr: { xs: "0 !important", sm: 10 },
                  overflow: "hidden",
                  ".MuiListItemText-primary": {
                    fontSize: "18px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: { xs: "100%", sm: "90%", md: "90%" },
                  },
                  ".MuiListItemText-secondary": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: { xs: "100%", sm: "90%", md: "90%" },
                  },
                }}
              />
              <Box mr={2} mt="6px">
                {role?.icon && IconMap[role?.icon]}
              </Box>
              <Typography
                sx={{
                  flexShrink: 0,
                  mr: 4,
                  "@media (max-width: 600px)": { marginRight: 0 },
                }}
              >
                {role?.name}
              </Typography>
              <UserActionMenu
                user={user}
                onEdit={handleOpenEditModal}
                onDelete={handleOpenDeleteModal}
                onResetPassword={handlePasswordReset}
              />
            </ListItem>
          </motion.div>
        );
      })}
    </List>
  );
};

export default UserList;
