import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import React from "react";

export const SearchFieldAutocomplete = ({
  label,
  selectedFields,
  searchFields,
  onUpdate,
  multiple,
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      options={searchFields}
      value={selectedFields}
      disablePortal={false}
      renderOption={(props, option) => (
        <li {...props} key={option.key}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          sx={{
            ".MuiInputBase-root": { borderRadius: "20px" },
          }}
        />
      )}
      onChange={(e, values) => {
        onUpdate(values);
      }}
      isOptionEqualToValue={(option, value) => {
        if (!value?.label) return option?.label === value;
        return option?.label === value?.label;
      }}
    />
  );
};
