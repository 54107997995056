import React, { useState } from "react";
import Box from "@mui/material/Box";
import ScheduleShipment from "../modals/ScheduleShipment";
import ShipItems from "../modals/ShipItems";
import AddSerialNumbers from "../modals/AddSerialNumbers";
import { splitAndCapitalize } from "../../../utils/stringUtils";
import { RenderModalActions } from "./RenderModalActions";
import Modal from "../../../components/Modal";

const DynamicModal = ({ onClose, currentModal, refetchOrders }) => {
  const [error, setError] = useState("");

  const handleError = (err) => setError(err);

  const renderModalContent = () => {
    switch (currentModal?.type) {
      case "scheduleShipment":
        return <ScheduleShipment data={currentModal.data} />;
      case "shipItems":
        return <ShipItems data={currentModal.data} />;
      case "addSerialNumbers":
        return <AddSerialNumbers data={currentModal.data} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      openModal={!!currentModal}
      handleCloseModal={onClose}
      title={splitAndCapitalize(currentModal?.type || "")}
      errorMsg={error}
      actions={
        <RenderModalActions
          currentModal={currentModal}
          onClose={onClose}
          handleError={handleError}
          refetchOrders={refetchOrders}
        />
      }
      maxWidth="xl"
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {renderModalContent()}
      </Box>
    </Modal>
  );
};

export default DynamicModal;
