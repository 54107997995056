import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const TimezoneSelector = ({
  globalTimezone,
  handleGlobalTimezoneChange,
  officeTimezoneMap,
}) => (
  <FormControl
    sx={{
      position: "absolute",
      top: -45,
      right: 0,
      width: 90,
    }}
  >
    <InputLabel id="global-timezone-label">Timezone</InputLabel>
    <Select
      labelId="global-timezone-label"
      value={globalTimezone}
      onChange={handleGlobalTimezoneChange}
      label="Timezone"
      size="small"
    >
      {Object.keys(officeTimezoneMap).map((key) => (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default TimezoneSelector;
