import * as React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, easing, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";

import { confirmPasswordReset } from "../../../api/Auth";
import { checkPasswordToken } from "../../../api/Auth";
import Error from "../../../components/Error";
import { Paper } from "@mui/material";

const theme = createTheme();

export default function ResetPassword() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [wrongToken, setWrongToken] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [loadingToken, setLoadingToken] = React.useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasPasswordError = !password;
    const hasConfirmPasswordError = !confirmPassword;
    setPasswordError(hasPasswordError);
    setConfirmPasswordError(hasConfirmPasswordError);
    if (hasPasswordError || hasConfirmPasswordError) return;
    if (password !== confirmPassword)
      return setErrorMessage("Passwords must match. Please try again. ");
    setLoading(true);
    confirmPasswordReset(
      searchParams.get("resetToken"),
      password,
      confirmPassword
    )
      .then(() => {
        navigate("/login");
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(true);
        setErrorMessage(
          "There was an issue with updating your password. Please try again. "
        );
      });
  };

  React.useEffect(() => {
    checkPasswordToken(searchParams.get("resetToken"))
      .then((rsp) => {
        if (rsp.isValid) {
          setWrongToken(false);
        } else {
          setWrongToken(true);
        }
        setLoadingToken(false);
      })
      .catch((err) => {
        setWrongToken(true);
        setLoadingToken(false);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {loadingToken && (
        <Box
          sx={{
            minHeight: "600px",
            display: "flex",
            justifyContent: "center",
            mt: 7,
            mb: 4,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!loadingToken && (
        <Container component="main" sx={{ pt: 2 }}>
          <CssBaseline />
          {wrongToken && (
            <Error
              errorMessage={
                "Your token is incorrect. Please try again with a different token."
              }
            />
          )}
          {errorMessage && <Alert color="error">{errorMessage}</Alert>}
          {!wrongToken && (
            <Box
              width={{ xs: "90%", sm: "500px" }}
              m={"auto"}
              sx={{ pt: 8, height: "100vh" }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                  mt: 1,
                  p: 4,
                  textAlign: "center",
                  borderRadius: "20px",
                  background: "white",
                }}
              >
                <Avatar sx={{ m: "auto", my: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                  Reset Password
                </Typography>

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="New Password"
                  name="password"
                  type="password"
                  autoFocus
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={passwordError}
                  helperText={passwordError && "Password required."}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Confirm New Password"
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={confirmPasswordError}
                  helperText={
                    confirmPasswordError && "Confirm password required."
                  }
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSubmit}
                  loading={loading}
                >
                  RESET PASSWORD
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Container>
      )}
    </ThemeProvider>
  );
}
