import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import Modal from "../../../components/Modal";
import { getInventoryOrderSummaryHistory } from "../../../api/History";
import Loading from "../../../components/Loading";
import Table from "../../../components/Table";
import { useDateFormat } from "../../../contexts/DateFormatContext";
import dayjs from "dayjs";
import { SearchFieldAutocomplete } from "../../../components/AutoComplete/AutoComplete";

const HistoryModal = ({ open, onClose, item, searchFields }) => {
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(true);

  const [selectedColumns, setSelectedColumns] = useState("");

  const { dateFormat } = useDateFormat();
  const specialDateFormat =
    dateFormat === "DD/MM/YYYY" ? "YYYY-MM-DD" : dateFormat;

  const formatDateTime = (date, secs = true) =>
    dayjs(date).format(`${specialDateFormat} HH:mm${secs ? ":ss" : ""}`);

  useEffect(() => {
    getInventoryOrderSummaryHistory(item.sid)
      .then((rsp) => {
        setHistory(rsp.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setHistory([]);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      field: "createdAt",
      headerName: "Date Changed",
      width: 200,
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Typography
            fontSize={13}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatDateTime(params.row.createdAt)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "changedFrom",
      headerName: "Changed From",
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Typography
            fontSize={13}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {Object.keys(params.row.contextData.changedFromData).join(",")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "changedToData",
      headerName: "Changed To",
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Typography
            fontSize={13}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {Object.keys(params.row.contextData.changedToData).join(",")}
          </Typography>
        </Box>
      ),
    },
  ];
  const updateSelectedColumns = (value) => {
    setSelectedColumns(value);
  };

  const filteredHistory =
    selectedColumns.length === 0
      ? history
      : history?.filter(
          (history) =>
            !!history.contextData?.changedFieldsList.find((field) =>
              Array.isArray(field)
                ? selectedColumns.includes(field[0]?.toLowerCase())
                : selectedColumns.includes(field?.toLowerCase())
            )
        );

  return (
    <Modal
      openModal={open}
      handleCloseModal={onClose}
      title={`History for item ${item.sid}`}
      actions={<></>}
      large
    >
      <Box>
        {loading && (
          <Box mx={"auto"} width={40}>
            <Loading />{" "}
          </Box>
        )}
        {!loading && searchFields && (
          <Box mb={2}>
            <SearchFieldAutocomplete
              label={"Filter Columns"}
              selectedField={selectedColumns}
              searchFields={searchFields?.slice(1) || []}
              onUpdate={updateSelectedColumns}
              multiple
            />
          </Box>
        )}
        {!loading && history.length === 0 && (
          <Box textAlign="center">
            <Typography>No history available. </Typography>
          </Box>
        )}
        {!loading && history?.length !== 0 && (
          <Table
            rows={filteredHistory}
            columns={columns}
            pagination
            rowsPerPage={10}
          />
        )}
      </Box>
    </Modal>
  );
};

export default HistoryModal;
