import { useState } from "react";

const useModalManager = () => {
  const [currentModal, setCurrentModal] = useState(null);

  const openModal = (type, data) => setCurrentModal({ type, data });
  const closeModal = () => setCurrentModal(null);

  return { openModal, closeModal, currentModal };
};

export default useModalManager;
