import { useState, useEffect } from "react";

import { useRecoilState, useRecoilValueLoadable } from "recoil";
import {
  customerOrdersState,
  fetchNeededState,
  intermediateOrdersState,
} from "../atoms/atoms";
import {
  firstFetchOrdersSelector,
  secondFetchOrdersSelector,
} from "../selectors/selectors";
import { customerOrdersFirstBatchLength } from "../common";

const useCustomerOrders = () => {
  const [, setCustomerOrders] = useRecoilState(customerOrdersState);
  const [, setIntermediateOrderse] = useRecoilState(intermediateOrdersState);
  const firstBatchLoadable = useRecoilValueLoadable(firstFetchOrdersSelector);
  const secondBatchLoadable = useRecoilValueLoadable(secondFetchOrdersSelector);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [fetchNeeded, setFetchNeeded] = useRecoilState(fetchNeededState);

  useEffect(() => {
    switch (firstBatchLoadable.state) {
      case "hasValue":
        if (fetchNeeded && !firstBatchLoadable.contents.isCancelled) {
          setIntermediateOrderse(firstBatchLoadable.contents);
          setCustomerOrders(firstBatchLoadable.contents);
        }
        setIsLoading(false);

        break;
      case "loading":
        setIsLoading(true);
        setErrorMsg(""); // Clear error message on new load
        setFetchNeeded(true);
        break;
      case "hasError":
        setErrorMsg(
          "There was an error fetching the initial orders. Please try again later."
        );
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [firstBatchLoadable, secondBatchLoadable.state]);

  useEffect(() => {
    switch (secondBatchLoadable.state) {
      case "hasValue":
        if (
          !secondBatchLoadable.contents.isCancelled &&
          firstBatchLoadable.state !== "loading" &&
          fetchNeeded
        ) {
          // Check the first few rows of the second batch to see if there are dup rows/conflicts with the first
          // (e.g. could be caused by inconsistent server ORDER BY sorting between 1st and 2nd batch)
          let checkCount = customerOrdersFirstBatchLength;
          let existingIDs = firstBatchLoadable.contents.map((row) => row.id);
          let conflictingRows = secondBatchLoadable.contents
            .slice(0, checkCount)
            .filter((row) => existingIDs.includes(row.id));
          if (conflictingRows.length === 0) {
            setCustomerOrders([
              ...firstBatchLoadable.contents,
              ...secondBatchLoadable.contents,
            ]);
          }
          setFetchNeeded(false);
        }
        break;
      case "hasError":
        setErrorMsg(
          "There was an error fetching additional orders. Please try again later."
        );
        setIsLoading(false);
        break;
      default:
        break;
    }
  }, [secondBatchLoadable]);

  return {
    ordersLoading: isLoading,
    ordersError: errorMsg,
  };
};
export default useCustomerOrders;
