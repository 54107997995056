import React, { useState } from "react";
import Box from "@mui/material/Box";
import { splitAndCapitalize } from "../../../../utils/stringUtils";
import MarkReadyForPayment from "../modals/markReadyForPayment";
import EditCosts from "../modals/editCosts";
import MarkAsPaid from "../modals/markAsPaid";
import UnmarkReadyForPayment from "../modals/UnmarkReadyForPayment";
import MarkAsPaymentReceived from "../modals/MarkAsPaymentReceived";
import UnmarkItemsAsPaid from "../modals/UnmarkItemsAsPaid";
import UnmarkAsPaymentReceived from "../modals/UnmarkAsPaymentReceived";
import { RenderModalActions } from "./RenderModalActions";
import EditSupplierFinanceDetails from "../modals/EditSupplierFinanceDetails";
import Modal from "../../../../components/Modal";

const DynamicModal = ({ table, onClose, currentModal, refetchOrders }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleError = (err) => setError(err);

  const renderModalContent = () => {
    switch (currentModal?.type) {
      case "unmarkAsPaymentReceived":
        return <UnmarkAsPaymentReceived data={currentModal.data} />;
      case "unmarkItemsAsPaid":
        return <UnmarkItemsAsPaid data={currentModal.data} />;
      case "markAsPaymentReceived":
        return <MarkAsPaymentReceived data={currentModal.data} />;
      case "unmarkReadyForPayment":
        return <UnmarkReadyForPayment data={currentModal.data} />;
      case "markAsPaid":
        return <MarkAsPaid data={currentModal.data} />;
      case "markReadyForPayment":
        return <MarkReadyForPayment data={currentModal.data} />;
      case "editCosts":
        return <EditCosts data={currentModal.data} />;
      case "editSupplierFinanceDetails":
        return <EditSupplierFinanceDetails data={currentModal.data} />;
      default:
        return null;
    }
  };

  const customActions = (
    <RenderModalActions
      table={table}
      currentModal={currentModal}
      onClose={onClose}
      handleError={handleError}
      refetchOrders={refetchOrders}
    />
  );

  return (
    <Modal
      title={splitAndCapitalize(currentModal?.type || "")}
      openModal={!!currentModal}
      handleCloseModal={onClose}
      loading={loading}
      errorMsg={error}
      actions={customActions}
      maxWidth={"xl"}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default DynamicModal;
