import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Button, Typography, IconButton } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";

const isPdfFile = (file) => {
  return file.type === "application/pdf";
};

const FilePreview = ({
  files,
  selectedFileIndex,
  handleDownloadFile,
  isImageFile,
  handlePrevFile,
  handleNextFile,
  handleCloseModal,
}) => {
  const selectedFile = files[selectedFileIndex];

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={selectedFileIndex !== null}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          maxWidth: "600px",
          width: "100%",
          height: { xs: "70vh", sm: "80vh" }, // Slightly reduced height
          margin: "auto",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: 2,
          "&::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Button
            variant="outlined"
            startIcon={<CloudDownloadIcon />}
            onClick={() => handleDownloadFile(selectedFile)}
          >
            Download
          </Button>
          <IconButton onClick={handleCloseModal} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {isImageFile(selectedFile) ? (
            <img
              src={selectedFile.url}
              alt={selectedFile.name}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          ) : isPdfFile(selectedFile) ? (
            <iframe
              src={`${selectedFile.url}#pagemode=none`}
              title={`File Preview: ${selectedFile.name}`}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          ) : (
            <Typography>Preview not available</Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          {files.length > 1 ? (
            <>
              <Button variant="outlined" onClick={handlePrevFile}>
                Prev
              </Button>
              <Typography variant="caption">
                {`${selectedFileIndex + 1} of ${files.length}`}
              </Typography>
              <Button variant="outlined" onClick={handleNextFile}>
                Next
              </Button>
            </>
          ) : (
            <Typography variant="caption" sx={{ margin: "auto" }}>
              {`${selectedFileIndex + 1} of ${files.length}`}
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FilePreview;
