import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const OrderBanner = ({ products, order, onActionSelect }) => {
  const isOnHold = order["shippingstatus"] === "HOLD_SHIPMENT";

  const isCancelled = order["canceled"];
  const isUnpaid = order["orderpaymentstatus"] === "NOT_PAID";
  const isAllDelivered = products?.every(
    (product) => product.shippingstatus === "DELIVERED"
  );

  return (
    <>
      {isAllDelivered && (
        <Box
          sx={{ background: "green", p: 1, width: 120, borderRadius: "2px" }}
        >
          <Typography color={"white"} textAlign={"center"}>
            DELIVERED
          </Typography>
        </Box>
      )}
      {(isCancelled || isUnpaid) && (
        <Box
          sx={{ background: "#f4615d", p: 1, width: 120, borderRadius: "2px" }}
        >
          <Typography color={"white"} textAlign={"center"}>
            {isCancelled ? "CANCELLED" : "NOT PAID"}
          </Typography>
        </Box>
      )}
      {!isCancelled && !isUnpaid && isOnHold && (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onActionSelect("holdOrder")}
          sx={{ width: 120, borderRadius: "2px" }}
        >
          On Hold
        </Button>
      )}
    </>
  );
};
export default OrderBanner;
