import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import Table from "../../../components/Table";
import Checkbox from "@mui/material/Checkbox";
import DatePicker from "../../../components/DatePicker";

const MarkAsPaid = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders,
    });
  }, [data.orders, setState]);

  const handleInputChange = useCallback(
    (value, field, id) => {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        ),
      }));
    },
    [setState]
  );

  const columns = [
    {
      field: "warehouseordernumber",
      headerName: "Warehouse Order Number",
      width: 100,
    },
    {
      field: "rownumber",
      headerName: "Row Number",
      width: 100,
    },
    {
      field: "paid",
      headerName: "Paid",
      width: 100,
      editable: true,
      renderEditCell: ({ row, handleInputChange }) => (
        <Checkbox
          checked={row.paid}
          onChange={(e) =>
            handleInputChange(e.target.checked, "paid", row.rownumber)
          }
        />
      ),
    },
    {
      field: "datepaid",
      headerName: "Date Paid",
      width: 300,
      editable: true,
      type: "date",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="body1" color="text.secondary">
        Mark orders as paid and set the payment date.
      </Typography>

      <Table
        columns={columns}
        rows={state.rows}
        onCellChange={handleInputChange}
      />
    </Box>
  );
};

export default MarkAsPaid;
