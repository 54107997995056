import { Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

export const Link = ({
  open,
  link,
  handleOnClick,
  permissions,
  permissionsRequired,
  isSubItem = false,
}) => {
  // Check if any permissions are set to true
  if (!permissions && permissionsRequired) return null;
  if (
    (permissions &&
      !Object.values(permissions).some((value) => value === true)) ||
    permissions?.name === "Super Admin"
  )
    return null;

  const isSelected = window.location.pathname === link.url;

  return (
    <Tooltip
      title={!open && link.header}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            ml: "5px !important",
          },
        },
      }}
    >
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: { xs: 30, sm: 48 },
            justifyContent: open ? "initial" : "center",
            position: "relative",
            background: isSelected ? "white" : "",
            borderRadius: 15,
            m: !open && isSelected ? 1 : 1,
            "&:hover": { background: isSelected ? "white" : "" },
            pl: isSubItem && open ? 2 : 2,
            ml: !open ? 1 : isSubItem ? 1 : 2,
          }}
          onClick={handleOnClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              zIndex: 1,
              mx: open ? 0 : "auto",
            }}
          >
            {React.cloneElement(link.icon, {
              sx: {
                color: isSelected ? "primary.main" : "rgb(197,197,197)",
                fontSize: "1.5rem",
              },
            })}
          </ListItemIcon>
          <ListItemText
            primary={link.header}
            sx={{
              display: open ? "block" : "none",
              ml: 2,
              color: isSelected ? "primary.main" : "rgba(197,197,197,0.7)",
              "& .MuiTypography-root": {
                fontSize: "1rem",
                fontWeight: isSelected ? 500 : 400,
              },
            }}
          />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
