import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  conversationsState,
  notificationsState,
  messagesByConversationState,
  orderFilesState,
} from "../../../../atoms/atoms";
import { loadUser } from "../../../../common";
import {
  createMessage,
  deleteMessage,
  getMessagesByConversationID,
  updateMessages,
} from "../../../../api/Messages";
import { createFile, uploadFile } from "../../../../api/Files";
import { subscribeToConversation } from "../../../../services/pusher/channels/conversationChannel";

const initialState = {
  message: "",
  messages: [],
  loading: true,
  error: null,
};

function conversationReducer(state, action) {
  switch (action.type) {
    case "SET_MESSAGE":
      return { ...state, message: action.payload };
    case "SET_MESSAGES":
      return { ...state, messages: action.payload };
    case "ADD_MESSAGE":
      return { ...state, messages: [...state.messages, action.payload] };
    case "UPDATE_MESSAGE":
      const messageIndex = state.messages.findIndex(
        (msg) =>
          msg.id === action.payload.id || msg.tempId === action.payload.tempId
      );
      if (messageIndex === -1) {
        // Message doesn't exist, add it to the array
        return {
          ...state,
          messages: [...state.messages, action.payload],
        };
      } else {
        // Message exists, update it
        return {
          ...state,
          messages: state.messages.map((msg, index) =>
            index === messageIndex ? { ...msg, ...action.payload } : msg
          ),
        };
      }
    case "DELETE_MESSAGE":
      return {
        ...state,
        messages: state.messages.filter((msg) => msg.id !== action.payload),
      };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export const useConversationState = (conversation) => {
  const [state, dispatch] = useReducer(conversationReducer, initialState);
  const { message, messages, loading, error } = state;

  const messagesEndRef = useRef();
  const fileInputRef = useRef();

  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [conversations, setConversations] = useRecoilState(conversationsState);
  const [messagesByConversation, setMessagesByConversation] = useRecoilState(
    messagesByConversationState
  );

  const [files, setFiles] = useRecoilState(orderFilesState);
  const [previewFiles, setPreviewFiles] = useState([]);

  const userID = JSON.parse(loadUser()).id;

  const updateConversationLastMessage = useCallback(
    (successfulMessage) => {
      setConversations((prevConversations) =>
        prevConversations.map((conv) => {
          if (conv.id === conversation.id) {
            return { ...conv, lastMessage: successfulMessage };
          }
          return conv;
        })
      );
    },
    [conversation.id, setConversations]
  );

  const updateStateWithFetchedMessages = useCallback(
    (fetchedMessages) => {
      const messageIds = new Set(fetchedMessages.map((msg) => msg.id));
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => {
          if (messageIds.has(notification?.id)) {
            return {
              ...notification,
              read: [...new Set([...notification.read, userID])],
            };
          }
          return notification;
        })
      );

      setMessagesByConversation((prevState) => ({
        ...prevState,
        [conversation.id]: fetchedMessages,
      }));

      if (
        conversation?.lastMessage &&
        !conversation.lastMessage.read?.includes(userID)
      ) {
        setConversations((prevConversations) =>
          prevConversations.map((conv) => {
            if (conv.id === conversation.id) {
              return {
                ...conv,
                lastMessage: {
                  ...conv.lastMessage,
                  read: [...(conv.lastMessage?.read || []), userID],
                },
              };
            }
            return conv;
          })
        );
      }
    },
    [
      conversation.id,
      userID,
      setNotifications,
      setMessagesByConversation,
      setConversations,
    ]
  );

  const updateMessageState = useCallback(
    (messageId, updatedMessage) => {
      dispatch({ type: "UPDATE_MESSAGE", payload: updatedMessage });
      setMessagesByConversation((prevState) => {
        console.log("Previous state:", prevState);
        const conversationMessages = prevState[conversation.id] || [];
        const messageIndex = conversationMessages.findIndex(
          (msg) => msg.id === messageId || msg.tempId === messageId
        );
        let updatedMessages;
        if (messageIndex === -1) {
          // Message doesn't exist, add it to the array
          updatedMessages = [...conversationMessages, updatedMessage];
        } else {
          // Message exists, update it
          updatedMessages = conversationMessages.map((msg, index) =>
            index === messageIndex ? { ...msg, ...updatedMessage } : msg
          );
        }
        console.log("Updated messages:", updatedMessages);
        return {
          ...prevState,
          [conversation.id]: updatedMessages,
        };
      });
    },
    [conversation.id, setMessagesByConversation]
  );

  const sendMessage = useCallback(
    (newMessage) => {
      const tempId = `temp-${Date.now()}`;
      const messageWithTempId = { ...newMessage, id: tempId, tempId: tempId };
      console.log("Sending message:", messageWithTempId);
      dispatch({ type: "ADD_MESSAGE", payload: messageWithTempId });

      createMessage(
        conversation.orderID,
        conversation.id,
        newMessage.content,
        newMessage.mentions?.length ? newMessage.mentions : null
      )
        .then((rsp) => {
          console.log("Received response:", rsp);
          const successfulMessage = {
            ...rsp,
            state: "success",
            tempId: tempId,
          };
          console.log("Updating with successful message:", successfulMessage);
          updateMessageState(tempId, successfulMessage);
          updateConversationLastMessage(successfulMessage);
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          updateMessageState(tempId, { ...messageWithTempId, state: "error" });
        });
    },
    [conversation, updateMessageState, updateConversationLastMessage]
  );

  const handleNewMessage = useCallback(
    (newMessage) => {
      dispatch({ type: "ADD_MESSAGE", payload: newMessage });
      setMessagesByConversation((prevState) => ({
        ...prevState,
        [conversation.id]: [...(prevState[conversation.id] || []), newMessage],
      }));
    },
    [conversation.id, setMessagesByConversation]
  );

  const deleteMessageLocally = useCallback(
    (messageId) => {
      dispatch({ type: "DELETE_MESSAGE", payload: messageId });
      setMessagesByConversation((prevState) => ({
        ...prevState,
        [conversation.id]: prevState[conversation.id].filter(
          (msg) => msg.id !== messageId
        ),
      }));
    },
    [conversation.id, setMessagesByConversation]
  );

  const handleDeleteMessage = useCallback(
    (messageId) => {
      deleteMessageLocally(messageId);
      deleteMessage(messageId)
        .then((rsp) => {
          console.log(rsp);
        })
        .catch((err) => {
          console.error("Error deleting message:", err);
          // Optionally, you could add logic here to revert the deletion if the API call fails
        });
    },
    [deleteMessageLocally]
  );

  const extractMentionIdsFromMessage = useCallback((message, isDefault) => {
    const mentionIds = [];
    if (isDefault) {
      const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
      message.match(mentionRegex)?.forEach((mention) => {
        const id = mention.match(/\(([^)]+)\)/)[1];
        mentionIds.push(id);
      });
    }
    return mentionIds;
  }, []);

  const handleFileSelection = useCallback(
    (event) => {
      const newFiles = Array.from(event.target.files)
        ?.filter(
          (file) =>
            !previewFiles.some((prevFile) => prevFile.name === file.name)
        )
        .map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

      if (newFiles.length === 0) {
        console.log("No new files to preview");
        return;
      }

      setPreviewFiles((prevFiles) => [...prevFiles, ...newFiles]);
      event.target.value = null;
    },
    [previewFiles]
  );

  const handleRemoveFile = useCallback((index) => {
    setPreviewFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      URL.revokeObjectURL(newFiles[index].preview);
      newFiles.splice(index, 1);
      return newFiles;
    });
  }, []);

  const sendMessageWithFiles = useCallback(
    async (messageContent) => {
      const tempId = `temp-${Date.now()}`;

      // Create a list of file names if there's no message content
      const fileNames = previewFiles.map((file) => file.name).join(", ");
      const content = messageContent.trim() || `Uploaded files: ${fileNames}`;

      const newMessage = {
        id: tempId,
        tempId: tempId,
        content: content,
        conversationID: conversation.id,
        userID: userID,
        createdAt: new Date().toISOString(),
        orderID: conversation.orderID,
        read: [],
        starred: [],
        state: "sending",
        files: previewFiles,
      };

      dispatch({ type: "ADD_MESSAGE", payload: newMessage });
      dispatch({ type: "SET_MESSAGE", payload: "" }); // Clear the message input

      setMessagesByConversation((prevState) => ({
        ...prevState,
        [conversation.id]: [...prevState[conversation.id], newMessage],
      }));

      try {
        const uploadPromises = previewFiles.map(async (file) => {
          const fileInfo = await createFile(
            "order",
            conversation.orderID,
            file.name
          );
          const { uploadUrl, contentType } = fileInfo;
          await uploadFile(uploadUrl, file, contentType);
          return {
            ...fileInfo,
            size: file.size,
            url: file.preview,
          };
        });
        const uploadedFiles = await Promise.all(uploadPromises);

        console.log(uploadedFiles);

        setFiles((prevFiles) => [
          ...(prevFiles || []),
          ...uploadedFiles.map((uploadedFile) => ({
            name: uploadedFile.name,
            type: uploadedFile.type,
            url: uploadedFile.url, // Use the server-provided URL
            size: uploadedFile.size,
            id: uploadedFile.id,
          })),
        ]);

        const messageResponse = await createMessage(
          newMessage.orderID,
          newMessage.conversationID,
          newMessage.content,
          newMessage.mentions,
          uploadedFiles
        );

        const updatedMessage = {
          ...messageResponse,
          tempId: tempId,
          state: "success",
          files: uploadedFiles.map((uploadedFile) => ({
            name: uploadedFile.name,
            type: uploadedFile.type,
            url: uploadedFile.url, // Use the server-provided URL
            size: uploadedFile.size,
            id: uploadedFile.id,
          })), // Use the uploaded files with server URLs
        };

        updateMessageState(tempId, updatedMessage);
        updateConversationLastMessage(updatedMessage);

        // Clear preview files after successful upload
        setPreviewFiles([]);
      } catch (error) {
        console.error("Failed to send message with files:", error);
        const errorMessage = {
          ...newMessage,
          state: "error",
        };
        updateMessageState(tempId, errorMessage);
      } finally {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollTop =
            messagesEndRef.current.scrollHeight;
        }
      }
    },
    [
      previewFiles,
      conversation,
      userID,
      setFiles,
      setMessagesByConversation,
      updateMessageState,
      updateConversationLastMessage,
    ]
  );

  const handleSubmit = useCallback(() => {
    if (!message.trim() && previewFiles.length === 0) return;

    const messageContent = message.trim();

    if (previewFiles.length > 0) {
      sendMessageWithFiles(messageContent);
    } else {
      sendMessage({
        content: messageContent,
        conversationID: conversation.id,
        mentions: conversation.isDefault
          ? extractMentionIdsFromMessage(messageContent, conversation.isDefault)
          : null,
        orderID: conversation.orderID,
        userID: userID,
        createdAt: new Date().toISOString(),
        state: "sending",
      });
    }
    dispatch({ type: "SET_MESSAGE", payload: "" }); // Clear the message input
  }, [
    message,
    previewFiles,
    conversation,
    userID,
    sendMessage,
    sendMessageWithFiles,
    extractMentionIdsFromMessage,
  ]);

  useEffect(() => {
    const fetchMessages = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const fetchedMessages = await getMessagesByConversationID(
          conversation.id
        );
        dispatch({ type: "SET_MESSAGES", payload: fetchedMessages });
        updateStateWithFetchedMessages(fetchedMessages);
      } catch (error) {
        dispatch({ type: "SET_ERROR", payload: "Failed to fetch messages" });
        console.error(error);
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };

    if (messagesByConversation[conversation.id]) {
      dispatch({
        type: "SET_MESSAGES",
        payload: messagesByConversation[conversation.id],
      });
      updateStateWithFetchedMessages(messagesByConversation[conversation.id]);
      dispatch({ type: "SET_LOADING", payload: false });
    } else {
      fetchMessages();
    }
  }, [conversation.id]);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messagesEndRef]);

  const handleImageLoad = useCallback(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    const cleanup = subscribeToConversation(
      conversation.id,
      userID,
      handleNewMessage,
      deleteMessageLocally
    );
    return () => cleanup();
  }, [conversation.id, userID, handleNewMessage, handleDeleteMessage]);

  return {
    messagesEndRef,
    fileInputRef,
    message,
    setMessage: (newMessage) =>
      dispatch({ type: "SET_MESSAGE", payload: newMessage }),
    messages,
    loading,
    error,
    handleSubmit,
    handleFileSelection,
    handleUploadClick: () => fileInputRef.current?.click(),
    handleDeleteMessage,
    isFarApart: (prevTimestamp, currentTimestamp) => {
      const prevDate = new Date(prevTimestamp);
      const currentDate = new Date(currentTimestamp);
      return currentDate - prevDate > 5 * 60 * 1000; // 5 minutes threshold
    },
    previewFiles,
    handleRemoveFile,
    handleImageLoad,
  };
};
