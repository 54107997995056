import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Select,
  MenuItem,
  Button,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  CardContent,
  Card,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ProductTile from "../components/ProductTile";
import FreightFiller from "../../../components/FreightFiller";
import AddProductModal from "../components/AddProductModal";
import WarehouseSelect from "../../InventoryOrders/components/warehouseSelect";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Loading from "../../../components/Loading";
import CalculateIcon from "@mui/icons-material/Calculate";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useLocation } from "react-router-dom";

const BuildInventoryOrderScreen = ({
  error,
  orderData,
  onCreateOrder,
  handleAddProduct,
  handleProductsChange,
  handleDeleteOrder,
  handleLockedPidChange,
  selectedWarehouse,
  handleWarehouseChange,
  warehouseProducts,
  containerSize,
  handleContainerSizeChange,
  fetchRecommendedQuantities,
  lockedProducts,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const warehouseOrderNumber = searchParams.get("warehouseOrderNumber");
  const isMedium = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { products } = orderData;

  const [isResetConfirmOpen, setIsResetConfirmOpen] = useState(false);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [calculateLoading, setCalculateLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const handleQuantityChange = (productId, newQuantity) => {
    handleProductsChange(
      newQuantity < 0 ? 0 : Math.round(newQuantity),
      "qty",
      productId
    );
  };

  const handleRecalculate = async () => {
    setCalculateLoading(true);
    await fetchRecommendedQuantities();
    setCalculateLoading(false);
  };

  const handleResetConfirm = () => {
    setIsResetConfirmOpen(true);
  };

  const handleResetCancel = () => {
    setIsResetConfirmOpen(false);
  };

  const handleResetConfirmed = async () => {
    setResetLoading(true);
    await fetchRecommendedQuantities(true);
    setResetLoading(false);
    setIsResetConfirmOpen(false);
  };

  const isLoading = !warehouseProducts || !orderData.products;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Paper
        sx={{
          m: 1,
          backgroundColor: "#ffffff",
          borderRadius: 2,
          py: 1,
          px: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            flexWrap: isMedium ? "wrap" : "nowrap",
            alignItems: "center",
            gap: 2,
            pt: { xs: 1, md: 1, lg: 0 },
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "stretch" : "center",
              gap: 2,
              width: isMobile ? "100%" : "auto",
            }}
          >
            <WarehouseSelect
              warehouses={["AUGC", "CAVC", "USTX", "NZAKL", "CNFS"]}
              selectedWarehouse={selectedWarehouse}
              handleWarehouseChange={handleWarehouseChange}
            />
            <FormControl
              variant="outlined"
              color="primary"
              size="small"
              sx={{ minWidth: 120 }}
            >
              <InputLabel>Order Size</InputLabel>
              <Select
                value={containerSize}
                onChange={handleContainerSizeChange}
                label="Order Size"
              >
                <MenuItem value="20">20ft</MenuItem>
                <MenuItem value="40">40ft</MenuItem>
                <MenuItem value="allNeeded">All Needed</MenuItem>
                <MenuItem value="blank">Blank</MenuItem>
              </Select>
            </FormControl>{" "}
            <LoadingButton
              onClick={handleResetConfirm}
              variant="outlined"
              startIcon={<RestartAltIcon />}
              fullWidth={isMobile}
            >
              Reset
            </LoadingButton>
            <LoadingButton
              onClick={handleRecalculate}
              variant="outlined"
              startIcon={<CalculateIcon />}
              loading={calculateLoading}
              fullWidth={isMobile}
            >
              Recalculate
            </LoadingButton>
          </Box>

          <Box
            sx={{
              mt: isMobile ? 2 : 0,
              minWidth: { xs: "100%", sm: 400 },
            }}
          >
            <FreightFiller
              volumeFieldName={"cubicVolume"}
              quantitiyFieldName={"qty"}
              containerType={
                containerSize === "allNeeded" || containerSize === "blank"
                  ? 40
                  : containerSize
              }
              products={warehouseProducts}
              rows={orderData.products}
            />
          </Box>
        </Box>
      </Paper>

      {isLoading ? (
        <Loading sx={{ width: 40, mx: "auto", mt: 4 }} />
      ) : (
        <Paper
          sx={{
            m: 1,
            mt: 0,
            py: 1,
            px: 3,
            height: {
              xs: `calc(100vh - ${error ? 475 : 415}px)`,
              sm: `calc(100vh - ${error ? 485 : 415}px)`,
              md: `calc(100vh - ${error ? 305 : 235}px)`,
              lg: `calc(100vh - ${error ? 240 : 180}px)`,
            },
            overflow: "auto",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Grid container spacing={2}>
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={product?.pid}>
                <ProductTile
                  isLocked={lockedProducts[product.pid]}
                  product={product}
                  onQuantityChange={handleQuantityChange}
                  onLockToggle={handleLockedPidChange}
                  handleDeleteOrder={handleDeleteOrder}
                />
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ position: "relative", height: "100%" }}>
                <CardContent
                  sx={{
                    textAlign: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <IconButton
                    sx={{ pr: 1 }}
                    onClick={() => setIsAddModalOpen(true)}
                  >
                    <AddCircleIcon fontSize={"large"} color={"primary"} />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          </Grid>{" "}
        </Paper>
      )}

      <Box
        sx={{
          position: "fixed",
          bottom: { xs: 10, sm: 20 },
          right: { xs: 10, sm: 20 },
        }}
      >
        <Button
          onClick={() =>
            onCreateOrder({ products, selectedWarehouse, containerSize })
          }
          variant="contained"
          color="primary"
          disabled={isLoading || orderData.products?.length === 0}
          sx={{
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.dark,
              color: "white",
            },
          }}
        >
          {warehouseOrderNumber ? "Update Order" : "Create Order"}
        </Button>
      </Box>
      <Dialog
        open={isResetConfirmOpen}
        onClose={handleResetCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Reset"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to reset? This action will clear all locked
            products.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetCancel}>Cancel</Button>
          <LoadingButton
            loading={resetLoading}
            onClick={handleResetConfirmed}
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <AddProductModal
        open={isAddModalOpen}
        handleClose={() => setIsAddModalOpen(false)}
        warehouseProducts={warehouseProducts || []}
        addProduct={handleAddProduct}
      />
    </Box>
  );
};

export default BuildInventoryOrderScreen;
