import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import SimpleDataGrid from "../../../../components/Datagrid/SimpleDataGrid";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import DialogContentText from "@mui/material/DialogContentText";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../../atoms/atoms";
import { getTableHeight } from "../../../../common";

const MarkReadyForPayment = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders.map((order) => {
        return {
          id: order.uid,
          ordernumber: order.wixlocalordernumber,
          descriptionshort: order.descriptionshort,
          buyerfullname: order.buyerfullname,
          qty: order.qty,
          supplierperunitcost: order.supplierperunitcost,
          internationalshippingcost: order.internationalshippingcost,
          totalproductcost: order.totalproductcost,
          shippingcurrency: order.shippingcurrency,
          suppliertotalcostowed: order.suppliertotalcostowed,
          readyforpaymentcheck: order.readyforpaymentcheck,
        };
      }),
    });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "UID",
      flex: 3,
      sortable: false,
    },
    {
      field: "ordernumber",
      headerName: "Order Number",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Full Name",
      field: "buyerfullname",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Model",
      field: "descriptionshort",
      flex: 3,
      sortable: false,
    },
    {
      headerName: "Qty",
      field: "qty",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Per Unit Cost",
      field: "supplierperunitcost",
      flex: 2,

      renderCell: (params) => (
        <Box m={"auto"}>
          {!params.value ? ( // Check if the value is missing
            <WarningIcon
              color="warning"
              titleAccess="Per Unit Cost is missing"
              sx={{ mt: 1.5 }}
            />
          ) : (
            <Typography variant={"p"}>{params.value}</Typography>
          )}
        </Box>
      ),
      sortable: false,
    },
    {
      headerName: "Total Product Price",
      field: "totalproductcost",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "International Shipping Cost",
      field: "internationalshippingcost",
      flex: 2,
      renderCell: (params) => (
        <Box m={"auto"}>
          {!params.value ? ( // Check if the value is missing
            <WarningIcon
              color="warning"
              titleAccess="International Shipping Cost is missing"
              sx={{ mt: 1.5 }}
            />
          ) : (
            <Typography variant={"p"}>{params.value}</Typography>
          )}
        </Box>
      ),
      sortable: false,
    },

    {
      headerName: "Shipping Currency",
      field: "shippingcurrency",
      flex: 2,
      sortable: false,
    },
    {
      headerName: "Total Cost Owed",
      field: "suppliertotalcostowed",
      flex: 2,
      sortable: false,
    },
  ];

  const tableHeight = getTableHeight(state?.rows?.length);

  return (
    <Box>
      <DialogContentText>
        The following Orders will be marked as ready for payment.
      </DialogContentText>
      <Box sx={{ margin: "auto", mt: 2, height: tableHeight, width: "100%" }}>
        {state.rows && (
          <SimpleDataGrid
            sx={{
              height: tableHeight,
            }}
            rows={state.rows}
            columns={columns}
            hideFooter
            disableColumnMenu
            isEditable={false}
          />
        )}
      </Box>
    </Box>
  );
};
export default MarkReadyForPayment;
