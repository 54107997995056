import React, { useCallback, useEffect, useState } from "react";
import MainContainer from "../../layouts/main";
import { Divider, Grid, Paper, Typography } from "@mui/material";
import { getMessagesByUserID, updateMessages } from "../../api/Messages";
import NotificationsDataGrid from "./components/NotificationsTable";
import { getAllUsers } from "../../api/Users";
import {
  loadNotificationsView,
  loadUser,
  storeNotificationsView,
} from "../../common";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useRecoilState } from "recoil";
import { notificationsState } from "../../atoms/atoms";
import ActionsMenu from "./components/ActionsMenu";
export default function Home() {
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const [filteredNotifications, setFilteredNotifications] = useState(null);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterMode, setFilterMode] = useState(
    loadNotificationsView() || "all"
  );
  const currentUser = JSON.parse(loadUser());

  useEffect(() => {
    const newNotifications = filterNotifications(notifications, filterMode);

    if (notifications) setFilteredNotifications(newNotifications);
  }, [notifications?.length]);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelection = (newSelection) => {
    setSelectedRows(newSelection);
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const usersResponse = await getAllUsers();
        setUsers(usersResponse.users || []);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, []);

  const filterNotifications = (notifications, mode) => {
    switch (mode) {
      case "all":
        return notifications;
      case "unread":
        return notifications.filter(
          (notification) => !notification.read?.includes(currentUser.id)
        );
      case "starred":
        return notifications.filter((notification) =>
          notification?.starred?.includes(currentUser.id)
        );
      default:
        return notifications;
    }
  };

  const handleShowUnread = () => {
    storeNotificationsView("unread");

    setFilterMode("unread");
    setFilteredNotifications(filterNotifications(notifications, "unread"));
  };

  const handleShowStarred = () => {
    storeNotificationsView("starred");

    setFilterMode("starred");
    setFilteredNotifications(filterNotifications(notifications, "starred"));
  };

  const handleShowAll = () => {
    storeNotificationsView("all");

    setFilterMode("all");
    setFilteredNotifications(notifications);
  };

  const handleRowChange = (updatedRow) => {
    // Assuming updatedRow is the row with updated data
    setNotifications(
      notifications.map((notification) => {
        if (notification.id === updatedRow.id) {
          return updatedRow; // Update the row with the new data
        }
        return notification; // Return unmodified rows as is
      })
    );

    // If the current filter mode is 'starred' and the row is no longer starred,
    // it should be removed from the view.
    if (
      filterMode === "starred" &&
      !updatedRow.starred.includes(currentUser.id)
    ) {
      setFilteredNotifications(
        filteredNotifications.filter(
          (notification) => notification.id !== updatedRow.id
        )
      );
    } else {
      // If the row is updated to starred, and we're in the 'starred' mode, add it to the view.
      // Or just update the row if we're in the 'all' mode.
      setFilteredNotifications(
        filteredNotifications.map((notification) => {
          if (notification.id === updatedRow.id) {
            return updatedRow;
          }
          return notification;
        })
      );
    }
  };

  const handleBulkRowsChange = (updatedRows) => {
    const updatedNotifications = notifications.map((notification) => {
      const updatedRow = updatedRows.find((row) => row.id === notification.id);
      return updatedRow ? { ...notification, ...updatedRow } : notification;
    });
    setNotifications((prevState) =>
      prevState.map((notification) => {
        const updatedRow = updatedRows.find(
          (row) => row.id === notification.id
        );
        return updatedRow ? { ...notification, ...updatedRow } : notification;
      })
    );

    // Update the filtered notifications as well
    const updatedFilteredNotifications = filterNotifications(
      updatedNotifications,
      filterMode
    );

    setFilteredNotifications(updatedFilteredNotifications);
  };
  const handleReadSubmit = async (actionType) => {
    const oldNotifications = notifications;

    const updatedRows = notifications.map((notification) => {
      if (selectedRows.includes(notification.id)) {
        if (
          actionType === "markRead" &&
          !notification.read.includes(currentUser.id)
        ) {
          return {
            ...notification,
            read: [...notification.read, currentUser.id],
          };
        } else if (actionType === "markUnread") {
          return {
            ...notification,
            read: notification.read.filter((id) => id !== currentUser.id),
          };
        }
      }
      return notification;
    });

    // Optimistically update the UI
    handleBulkRowsChange(updatedRows);

    // Try to commit the changes to the backend
    try {
      await updateMessages(selectedRows, actionType);
      // Update successful, possibly update state based on response if necessary
    } catch (error) {
      console.error("Failed to update messages: ", error);
      // Revert changes on failure
      handleBulkRowsChange(oldNotifications); // Reverting to the original notifications before the optimistic update
    }
  };

  const handleStarSubmit = async (actionType) => {
    const oldNotifications = notifications;
    const updatedRows = notifications.map((notification) => {
      if (selectedRows.includes(notification.id)) {
        if (
          actionType === "addStar" &&
          !notification.starred.includes(currentUser.id)
        ) {
          return {
            ...notification,
            starred: [...notification.starred, currentUser.id],
          };
        } else if (actionType === "removeStar") {
          return {
            ...notification,
            starred: notification.starred.filter((id) => id !== currentUser.id),
          };
        }
      }
      return notification;
    });
    // Optimistically update the UI
    handleBulkRowsChange(updatedRows);

    // Try to commit the changes to the backend
    try {
      await updateMessages(selectedRows, actionType);
      // Update successful, possibly update state based on response if necessary
    } catch (error) {
      console.error("Failed to update messages: ", error);
      // Revert changes on failure
      handleBulkRowsChange(oldNotifications); // Reverting to the original notifications before the optimistic update
    }
  };

  return (
    <MainContainer title={""}>
      <Paper
        elevation={3}
        sx={{
          overflow: "auto",
          height: "calc(100vh - 80px)",
          m: 2,
          p: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" fontWeight="bold" sx={{ mb: 3 }}>
          My Notifications
        </Typography>

        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <ActionsMenu
              selectedRows={selectedRows}
              handleReadSubmit={handleReadSubmit}
              handleStarSubmit={handleStarSubmit}
              notifications={filteredNotifications}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              display="flex"
              justifyContent="flex-end"
              flexWrap="wrap"
              gap={1}
            >
              <Button
                startIcon={<MailOutlineIcon />}
                variant={filterMode === "unread" ? "contained" : "outlined"}
                onClick={handleShowUnread}
                sx={{ width: { xs: "100%", sm: "120px" } }}
              >
                Unread
              </Button>
              <Button
                startIcon={<StarBorderIcon />}
                variant={filterMode === "starred" ? "contained" : "outlined"}
                onClick={handleShowStarred}
                sx={{ width: { xs: "100%", sm: "120px" } }}
              >
                Starred
              </Button>
              <Button
                startIcon={<FavoriteBorderIcon />}
                variant={filterMode === "all" ? "contained" : "outlined"}
                onClick={handleShowAll}
                sx={{ width: { xs: "100%", sm: "120px" } }}
              >
                All
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box flexGrow={1}>
          <NotificationsDataGrid
            handleRowSelection={handleRowSelection}
            selectedRows={selectedRows}
            users={users}
            rows={filteredNotifications}
            loading={loading || !filteredNotifications}
            handleRowChange={handleRowChange}
          />
        </Box>
      </Paper>
    </MainContainer>
  );
}
