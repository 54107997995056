import axios from './axios'
import { API_SEARCH_LINK_URL } from '../consts'

function getFields(table) {
  return axios.get(API_SEARCH_LINK_URL + `/getSearchFields?searchTable=${table}`).then(response => (response.data))
}

function getFieldValues(table, searchField) {
    return axios.get(API_SEARCH_LINK_URL + `/getSearchFieldUniqueData?searchTable=${table}&searchField=${searchField}`).then(response => (response.data))
}

export {getFieldValues, getFields}