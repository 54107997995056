export const isGroupRow = (row) => {
  return (
    row &&
    Object?.getOwnPropertySymbols(row).some(
      (sym) => sym.toString() === "Symbol(mui.id_autogenerated)"
    )
  );
};
export const getGroupKey = (row, headerName) => {
  if (isGroupRow(row)) {
    const symbol = Object.getOwnPropertySymbols(row).find(
      (sym) => sym.toString() === "Symbol(mui.id_autogenerated)"
    );
    if (symbol) {
      const key = row[symbol];
      return key.split("/")[1]; // Extract the part after the slash
    }
  }
  if (!row) return null;
  return row[headerName];
};
