import React, { useEffect, useRef, useState } from "react";
import { TextField, InputAdornment, IconButton, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { loadSearchTerm, storeSearchTerm } from "../../common";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const SearchOrdersInput = ({ handleSearch, disabled, table }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showInput, setShowInput] = useState(!isMobile); // Open by default if not mobile

  useEffect(() => {
    setSearchTerm(loadSearchTerm(table));
  }, [table]);

  useEffect(() => {
    setShowInput(!isMobile);
  }, [isMobile]);

  const searchDebounceTimer = useRef(null);
  const clearDebounceTimer = () => {
    if (searchDebounceTimer.current) {
      clearTimeout(searchDebounceTimer.current);
    }
  };

  const debouncedSearch = async () => {
    clearDebounceTimer();
    searchDebounceTimer.current = setTimeout(async () => {
      try {
        await handleSearch();
      } catch (error) {
        console.error("Error in fetching orders:", error);
      }
    }, 1000);
  };

  const handleSearchTermChange = async (searchTerm) => {
    storeSearchTerm(searchTerm, table);
    setSearchTerm(searchTerm);

    if (searchTerm.length === 0) {
      clearDebounceTimer();

      await handleSearch();
    }

    if (searchTerm.length < 3) return clearDebounceTimer();

    await debouncedSearch(searchTerm);
  };

  const handleClearClick = async () => {
    await handleSearchTermChange("");
  };

  return (
    <>
      {!showInput && (
        <Box
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          sx={{ flex: 1 }}
        >
          <Tooltip title="Search orders">
            <IconButton size="small" onClick={() => setShowInput(!showInput)}>
              <SearchIcon color={"primary"} />
            </IconButton>
          </Tooltip>
          <Typography sx={{ ml: 0.5 }}>{searchTerm}</Typography>
        </Box>
      )}
      {showInput && (
        <Box
          sx={{
            minWidth: "300px",
            width: { xs: "100%", sm: 0.5, md: 0.4 },
          }}
        >
          <TextField
            fullWidth
            placeholder="Search orders..."
            variant="outlined"
            size="small"
            value={searchTerm || ""}
            disabled={disabled}
            onChange={(e) => handleSearchTermChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    onClick={() => setShowInput(!showInput)}
                  >
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ pr: 1 }}>
                  {searchTerm && (
                    <IconButton onClick={handleClearClick} edge="end">
                      <ClearIcon color="primary" fontSize={"small"} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
};

export default SearchOrdersInput;
